import React,{useState,useEffect} from 'react'
import Base from '../Base'
import Api from '../Services/ApiCall';
import { Link } from "react-router-dom";
import { Images } from '../../Images/Index'

export default function Companyprofiletable() {
    useEffect(() => {
        Get()
    }, []);


    const [place, setLocation] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [website, setWebsite] = useState("");
    const [emailid, setEmailid] = useState("");
    const [contactnumber, setContactnumber] = useState("");
    const [pannumber, setPannumber] = useState("");
    const [bankname, setBankName] = useState("");
    const [bankaccnumber, setBankAccNumber] = useState("");
    const [bankbranch, setBankBranch] = useState("");
    const [bankifsc, setBankIFSC] = useState("");
    const [logo, setLogo] = useState("");
    const [gstnumber, setGstNumber] = useState("");

async function Get(id){
    var Param ={Mstcompanyprofileid:3}
    var url = "/Company/BindCompany";
    let _response = await Api.BindGrid(Param,url) 
    
    setCompanyname(_response[0].Companyname);
    setLocation(_response[0].Location);
    setWebsite(_response[0].Website);
    setEmailid(_response[0].Emailid);
    setContactnumber(_response[0].Phonenumber);
    setPannumber(_response[0].Pannumber);
    setBankName(_response[0].Bankname);
    setBankAccNumber(_response[0].Bankaccountnumber);
    setBankBranch(_response[0].Bankbranch);
    setBankIFSC(_response[0].Bankifsc);
    setLogo(JSON.parse(_response[0].Logo));
    setGstNumber(_response[0].SearchField);
  
 }

const {base64Data} = logo;
    const css = {
        color: " blue",
        backgroundcolor: "#282c34",
        fontFamily: "Arial",
        textAlign: "center",
        marginLeft: "50px",

    }
    const profilecss = {

        color: " blue",
        backgroundcolor: "#282c34",
        fontFamily: "Arial",
        textAlign: "center",
        marginLeft: "30px",

    }
    const css1 = {
        color: " blue",
        backgroundcolor: "#282c34",
        fontFamily: "Arial",
        textAlign: "center",
        marginLeft: "50px",
    }

    const css2 = {
        color: " blue",
        backgroundcolor: "#282c34",
        fontFamily: "Arial",
        textAlign: "center",

    }

    const css3 = {
        color: " blue",
        backgroundcolor: "#282c34",
        fontFamily: "Arial",
        textAlign: "center",

    }
    const css4 = {
        color: " blue",
        backgroundcolor: "#282c34",
        fontFamily: "Arial",
        textAlign: "center",

    }
    const para = {
        textAlign: "center",
        color: "purple",
        fontSize: "14px",
        fontFamily: "Sanaserif",
    }
    return (
        <div>
            <Base compayprofile={true} />
            <div id="main" class="main">
                <div class="pagetitle">
                   
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>

                            <li class="breadcrumb-item">Admin</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-lg-12">

                    <div class="card">
                        <div class="card-body">
                            <div class="text-center" style={{color:"red"}}>
                                <h3>Company Profile</h3> 
                            </div>
                            <br></br>
                            {Api.CheckUpdate(2) == 1 ?
                            <button type="button" class="btn btn-primary Add">
                                <Link to={'/companyprofileform'} state={{
                                    ID: 3, Flag: 'U' }}>
                                    Edit_
                                </Link>
                            </button>
                            :null}
                           
                            <h5 class style={css}> Company Name:{companyname}</h5>
                            <h5 class style={css1}>Location:{place}</h5>
                            <h5 class style={css2}>Website URL:{website}</h5>
                            <h5 class style={css3}>Contact Info:{emailid} </h5>
                            <h5 class style={css4}>Mobile No:{contactnumber}</h5>
                            <h5 class style={css4}>Pan No:{pannumber}</h5>
                            <h5 class style={css4}>Bank Name:{bankname}</h5>
                            <h5 class style={css4}>Bank Account Number:{bankaccnumber}</h5>
                            <h5 class style={css4}>Bank Branch:{bankbranch}</h5>
                            <h5 class style={css4}>Bank IFSC:{bankifsc}</h5>
                            <h5 class style={css4}>GST Number:{gstnumber}</h5>
                            {/* <div class style={para}>
                                <p>Quick Job Apply / You search your job here</p>
                            </div> */}
                            <div class="text-center" >
                                    <img src={`data:image;base64,${base64Data}`} style={{width:"25%"}} />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
