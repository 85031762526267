import React, { useState, useEffect } from 'react'
import { Images } from '../Images/Index';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Api from "../Components/Services/ApiCall";
import CSS from "../App.css";
import $ from 'jquery';
import Departmentcreation from './Admin/Departmentcreation';
import Agentcommision from './Admin/Agentcommision';

function Base({ compayprofile, Rolecreationgrid, Departmentcreate, Staffgrade, Officegrade,
  Officecreation, Employeecreate, Agentcreate, Employercreate, Jobseekercreate, Jobseekerplan, Employerplan,
  Brokercommission, Agentcommission, Divisional, Salesbill, Salesbillreturn, Receipt, PaymentExpence, Bankaccount,
  Deposit, Branchperformance, Employeeperformance, Agentbrokerperformance, Digitalsignature,
  EmploeePaymentReport, OnlinePaymentReport, Declarecommission, BoEmployerApproval, BoJobseekerApproval, Master,
  Employent }) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const navigate = useNavigate();
  const [clickLogoutshow, setclickLogoutshow] = useState(false)
  const onclicklogoutshow = (showlogoutbtn) => {
    setclickLogoutshow(showlogoutbtn)
  }
  const [clickNotificationshow, setclickNotificationshow] = useState(false)
  const onclickNotificationshow = (showNotificationbtn) => {
    setclickNotificationshow(showNotificationbtn)
  }
  const [clickAdminshow, setclickAdminshow] = useState(false)
  const onclickAdminshow = (showAdmindropdown) => {
    setclickAdminshow(showAdmindropdown)
  }
  const [clickTranscationshow, setclickTranscationshow] = useState(false)
  const onclickTranscationshow = (showTranscationdropdown) => {
    setclickTranscationshow(showTranscationdropdown)
  }
  const [clickStaffofficeshow, setclickStaffofficeshow] = useState(false)
  const onclickStaffofficeshow = (showStaffofficedropdown) => {
    setclickStaffofficeshow(showStaffofficedropdown)
  }
  const [clickPlancreationshow, setclickPlancreationshow] = useState(false)
  const onclickPlancreationshow = (showPlancreationdropdown) => {
    setclickPlancreationshow(showPlancreationdropdown)
  }
  const [clickMastercreationshow, setclickMastercreationshow] = useState(false)
  const onclickMastercreationshow = (clickMastercreationshow) => {
    setclickMastercreationshow(clickMastercreationshow)
  }
  const [clickCommisionshow, setclickCommisionshow] = useState(false)
  const onclickCommisionshow = (showCommisiondropdown) => {
    setclickCommisionshow(showCommisiondropdown)
  }
  const [clickSalesshow, setclickSalesshow] = useState(false)
  const onclickSalesshow = (showSalesdropdown) => {
    setclickSalesshow(showSalesdropdown)
  }
  const [clickServicesshow, setclickServicesshow] = useState(false)
  const onclickServicesshow = (showServicesdropdown) => {
    setclickServicesshow(showServicesdropdown)
  }
  const [clickGeneralsettingshow, setclickGeneralsettingshow] = useState(false)
  const onclickGeneralsettingshow = (showGeneralsettingdropdown, value) => {
    setIconchanges(value);
    setclickGeneralsettingshow(showGeneralsettingdropdown)
  }
  const [clickRegistrationshow, setclickRegistrationshow] = useState(false)
  const onclickRegistrationshow = (showRegistrationdropdown, value) => {
    setIconchanges(value);
    setclickRegistrationshow(showRegistrationdropdown)
  }
  const [clickAgentshow, setclickAgentshow] = useState(false)
  const onclickAgentshow = (showAgentdropdown) => {
    setclickAgentshow(showAgentdropdown)
  }
  const [clickReportshow, setclickReportshow] = useState(false)
  const onclickReportshow = (showReportdropdown) => {
    setclickReportshow(showReportdropdown)
  }
  const [clickUsershow, setclickUsershow] = useState(false)
  const onclickUsershow = (showUserdropdown) => {
    setclickUsershow(showUserdropdown)
  }
  const [clickResumeshow, setclickResumeshow] = useState(false)
  const onclickResumeshow = (showResumedropdown) => {
    setclickResumeshow(showResumedropdown)
  }
  const [clickTranscationsshow, setclickTranscationsshow] = useState(false)
  const onclickTranscationsshow = (showTranscationsdropdown) => {
    setclickTranscationsshow(showTranscationsdropdown)
  }
  const [toggleSidebar, settoggleSidebar] = useState(false)
  const onclickToggleshow = (value) => {
    settoggleSidebar(value)
    if (value) {
      $('body').addClass('toggle-sidebar')
    }
    else {
      $('body').removeClass('toggle-sidebar')
    }
  }
  const logout = () => {
    navigate('/')
  }
  const menu = JSON.parse(sessionStorage.getItem("roleaccess"))
  const [username, setusername] = useState("");
  const [show, setShow] = useState("");
  useEffect(() => {
    setusername(sessionStorage.getItem("username"))
  });
  function checkmenu(id) {
    return menu.filter(function (menu) {
      try {

        return menu.MenuID == id;
      }
      catch {

        return menu.MenuID == id;
      }
    });
  }
  const [iconchanges, setIconchanges] = useState(false);
  return (
    <>
      <body class={toggleSidebar ? "toggle-sidebar" : ""} >
        <div id="header" class="header fixed-top d-flex align-items-center">
          <div class="d-flex align-items-center justify-content-between">
            <a class="logo d-flex align-items-center">
              <img src={Images.mainlogo} alt="pelago" className="userlg_img" />
            </a>
            <i class="bi bi-list toggle-sidebar-btn" onClick={() => onclickToggleshow(!toggleSidebar)}></i>
          </div>
          <nav class="header-nav ms-auto" >
            <ul class="d-flex align-items-center">
              <li class="nav-item dropdown pe-3">
                <a style={{ cursor: 'pointer' }} class={clickNotificationshow ? "nav-link nav-profile d-flex align-items-center pe-0 show" : "nav-link nav-profile d-flex align-items-center pe-0 "} onClick={(Notification) => onclickNotificationshow(!clickNotificationshow)} data-bs-toggle="dropdown">
                  <li class="dropdown-header">
                    <a class="nav-link nav-icon" data-bs-toggle="dropdown" >
                      <i class="bi bi-bell"  ></i>
                      <span class="badge bg-primary badge-number">0</span>
                    </a>
                  </li>
                </a>
              </li>
              <li class="nav-item dropdown pe-3">
                <a style={{ cursor: 'pointer' }} class={clickLogoutshow ? "nav-link nav-profile d-flex align-items-center pe-0 show" : "nav-link nav-profile d-flex align-items-center pe-0 "} onClick={() => onclicklogoutshow(!clickLogoutshow)} data-bs-auto-close="outside" data-bs-toggle="dropdown">
                  <i class="bi bi-person-circle"></i>
                  <span class="d-none d-md-block dropdown-toggle ps-2">{username} </span>
                </a>
                <ul id="Profiledropdown" class={clickLogoutshow ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile show" : "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile"} style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(-16px, 38px)" }}>
                  <li class="dropdown-header">
                    <h6></h6>
                    <span>
                      {sessionStorage.getItem("roleaUsertypeccess") || "Employer"}
                    </span>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <Link to='/Myprofile' state={{ ID: sessionStorage.getItem("MUserID"), Flag: 'Prof' }}>
                      <a class="dropdown-item d-flex align-items-center">
                        <i class="bi bi-person"></i>
                        <span>My Profile</span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <a class="dropdown-item d-flex align-items-center" style={{ cursor: 'pointer' }}>
                      <i class="bi bi-box-arrow-right"></i>
                      <span onClick={logout}>Sign Out</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <div id="sidebar" class="sidebar">
          <ul class="sidebar-nav" id="sidebar-nav">
            {Api.CheckView(1) == 1 ?
              <li class="nav-item">
                <a
                  class="nav-link " href='/dash'>
                  <i class="bi bi-grid"></i>
                  <span>Dashboard</span>
                </a>
              </li>
              : null}
            {(Api.CheckView(2) == 1 || Api.CheckView(3) == 1 || Api.CheckView(4) == 1
              || Api.CheckView(5) == 1 || Api.CheckView(6) == 1 || Api.CheckView(7) == 1
              || Api.CheckView(8) == 1 || Api.CheckView(9) == 1) ?
              <li class="nav-item">
                <a class={clickGeneralsettingshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickGeneralsettingshow(!clickGeneralsettingshow)} data-bs-toggle="collapse"  >
                  <i class="bi bi-gear"></i><span>General Setting</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={(clickGeneralsettingshow || compayprofile || Rolecreationgrid || Departmentcreate || Staffgrade || Officegrade || Officecreation || Divisional) ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>
                    {Api.CheckView(2) == 1 ?
                      <li class={compayprofile ? "list" : ""}>
                        <a href="/companyprofiletable">
                          <i class="bi bi-circle"></i><span>Profile</span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(3) == 1 ?
                      <li>
                        <a href="/tax">
                          <i class="bi bi-circle"></i><span>Taxes Setting</span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(4) == 1 ?
                      <li class={Rolecreationgrid ? "list" : ""}>
                        <a href="/Rolecreationgrid">
                          <i class="bi bi-circle"></i><span>Role</span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(5) == 1 ?
                      <li class={Departmentcreate ? "list" : ""}>
                        <a href='/Departmentcreationtable'>
                          <i class="bi bi-circle"></i><span>Department  Creation </span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(6) == 1 ?
                      <li class={Staffgrade ? "list" : ""}>
                        <a href='/staffgradetypetable'>
                          <i class="bi bi-circle"></i><span>Staff Grade</span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(7) == 1 ?
                      <li class={Officegrade ? "list" : ""}>
                        <a href='/officegradecreationtable'>
                          <i class="bi bi-circle"></i><span>Office Grade </span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(8) == 1 ?
                      <li class={Officecreation ? "list" : ""}>
                        <a href='/officetypecreationtable'>
                          <i class="bi bi-circle"></i><span>Office Creation  </span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(9) == 1 ?
                      <li class={Divisional ? "list" : ""}>
                        <a href='/divisionalofficedistlisttable'>
                          <i class="bi bi-circle"></i><span>Divisional Office Dist List  </span>
                        </a>
                      </li>
                      : null}
                    {sessionStorage.getItem('AccessType') == 'User' ?
                      <li >
                        <a href='/Email_edit_table'>
                          <i class="bi bi-circle"></i><span>Email Edit Table </span>
                        </a>
                      </li>
                      : null}
                  </ul>
                </ul>
              </li>
              : null}
            {(Api.CheckView(10) == 1 || Api.CheckView(11) == 1 || Api.CheckView(12) == 1 || Api.CheckView(13) == 1) ?
              <li class="nav-item">
                <a class={clickRegistrationshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickRegistrationshow(!clickRegistrationshow)} data-bs-toggle="collapse" >
                  <i class="bi bi-stickies"></i><span>Registration</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={(clickRegistrationshow || Employeecreate || Agentcreate || Employercreate || Jobseekercreate) ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>
                    {Api.CheckView(10) == 1 ?
                      <li class={Employeecreate ? "list" : ""}>
                        <a href="/empcreationtable">
                          <i class="bi bi-circle"></i><span>Employee Creation</span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(11) == 1 ?
                      <li class={Agentcreate ? "list" : ""}>
                        <a href="/AgentCreation">
                          <i class="bi bi-circle"></i><span>Agent Creation</span>
                        </a>
                      </li>
                      : null}
                    {sessionStorage.getItem('AccessType') == 'User' ?
                      <li class="">
                        <a href="/guest_table">
                          <i class="bi bi-circle"></i><span>Super Admin Creation</span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(12) == 1 ?
                      <li class={Employercreate ? "list" : ""}>
                        <a href="/EmployerCreation_table">
                          <i class="bi bi-circle"></i><span>Employer Creation</span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(13) == 1 ?
                      <li class={Jobseekercreate ? "list" : ""}>
                        <a href="/Jobseeker_table">
                          <i class="bi bi-circle"></i><span>JobSeeker Creation</span>
                        </a>
                      </li>
                      : null}
                  </ul>
                </ul>
              </li>
              : null}
            {(Api.CheckView(14) == 1 || Api.CheckView(15) == 1 || Api.CheckView(16) == 1) ?
              <li class="nav-item">
                <a class={clickTranscationshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickTranscationshow(!clickTranscationshow)} data-bs-toggle="collapse" >
                  <i class="bi bi-bank"></i><span>Bank/Cash</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={clickTranscationshow || Receipt || PaymentExpence || Bankaccount || Deposit ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>
                    {Api.CheckView(15) == 1 ?
                      <li class={Bankaccount ? "list" : ""}>
                        <a href='/bankaccounttable'>
                          <i class="bi bi-circle"></i><span>Bank Account   </span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(16) == 1 ?
                      <li class={Deposit ? "list" : ""}>
                        <a href='/deposittable'>
                          <i class="bi bi-circle"></i><span>Deposit</span>
                        </a>
                      </li>
                      : null}
                  </ul>
                </ul>
              </li>
              : null}
            {sessionStorage.getItem('AccessType') == 'Agent' ?
              <>
                {JSON.parse(sessionStorage.getItem('userdetails'))[0]['Subscribe'] == "Paid" ?
                  null :
                  <li class="nav-item">
                    <a class="nav-link " href='/Subscribeform'
                      state={{ ID: JSON.parse(sessionStorage.getItem('userdetails'))[0]['Mstuserregid'], Flag: 'S', Code: JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode'] }}>
                      <i class="bi bi-grid"></i>
                      <span>Subscribe</span>
                    </a>
                  </li>
                }
              </>
              : null}
            {(Api.CheckView(17) == 1 || Api.CheckView(18) == 1) ?
              <li class="nav-item">
                <a class={clickPlancreationshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickPlancreationshow(!clickPlancreationshow)} data-bs-toggle="collapse" >
                  <i class="bi bi-lightbulb"></i><span>Plan Creation</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={(clickPlancreationshow || Jobseekerplan || Employerplan) ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>
                    {Api.CheckView(17) == 1 ?
                      <li class={Jobseekerplan ? "list" : ""}>
                        <a href='/Agentplancreationtable'>
                          <i class="bi bi-circle"></i><span> Agent  Plan </span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(18) == 1 ?
                      <li class={Employerplan ? "list" : ""}>
                        <a href='/employerplancreationtable'>
                          <i class="bi bi-circle"></i><span> Employer Jobseeker Plan  </span>
                        </a>
                      </li>
                      : null}
                  </ul>
                </ul>
              </li>
              : null}
            {sessionStorage.getItem('AccessType') == 'User' || Api.CheckView(30) == 1 ?
              <li class="nav-item">
                <a class={clickMastercreationshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickMastercreationshow(!clickMastercreationshow)} data-bs-toggle="collapse" >
                  <i class="bi bi-gear"></i><span>Master Creation</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={(clickMastercreationshow || Master || Employent) ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>
                    <li class={Master ? "list" : ""}>
                      <a href='/Masterconfiguration'>
                        <i class="bi bi-circle"></i><span> Master Configuration </span>
                      </a>
                    </li>
                    {Api.CheckView(30) == 1 ?
                      <li class={Employent ? "list" : ""}>
                        <a
                          class="nav-link " href='/Employmenttypetable' state={{ ID: 0, Flag: 'ET' }}>
                          <i class="bi bi-grid"></i>
                          <span>Employment Type</span>
                        </a>
                      </li>
                      : null}
                  </ul>
                </ul>
              </li>
              : null}
            <li class="nav-item">
              <a class="nav-link " href='/PayoutTable'
                state={{ ID: 0, Flag: 'I' }}>
                <i class="bi bi-grid"></i>
                <span>Payout Request</span>
              </a>
            </li>
            {(Api.CheckView(20) == 1 || Api.CheckView(21) == 1 || Api.CheckView(22) == 1 || Api.CheckView(23) == 1 || Api.CheckView(24) == 1) ?
              <li class="nav-item">
                <a class={clickReportshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickReportshow(!clickReportshow)} data-bs-toggle="collapse" >
                  <i class="bi bi-file-earmark-pdf"></i><span>Reports</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={clickReportshow || Agentcommission || Branchperformance || Employeeperformance || Agentbrokerperformance || EmploeePaymentReport || OnlinePaymentReport ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>
                    {Api.CheckView(22) == 1 ?
                      <li>
                        <a href='/OfficeCashReport'>
                          <i class="bi bi-circle"></i><span> Cash Payment Report </span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(23) == 1 ?
                      <li>
                        <a href='/TotalPayments'>
                          <i class="bi bi-circle"></i><span>Total Payment Lists </span>
                        </a>
                      </li>
                      : null}
                    {sessionStorage.getItem('AccessType') == 'Agent' ?
                      <li>
                        <a href='/TotalAgentPayments'>
                          <i class="bi bi-circle"></i><span>Total Payment Lists </span>
                        </a>
                      </li>
                      : null}
                    {Api.CheckView(24) == 1 ?
                      <li class={OnlinePaymentReport ? "list" : ""}>
                        <a href='/OnlinePaymentReport'>
                          <i class="bi bi-circle"></i><span> Online Payment Report </span>
                        </a>
                      </li>
                      : null}
                  </ul>
                </ul>
              </li>
              : null}
            <li class="nav-item">
              <a href='/Customersupporttable'
                class="nav-link " state={{ ID: 0, Flag: 'CS' }}>
                <i class="bi bi-telephone-x"></i>
                <span>Customer Support</span>
              </a>
            </li>
            {Api.CheckView(31) == 1 ?
              <li class="nav-item">
                <a
                  class="nav-link " href='/Postjobstable' state={{ ID: 0, Flag: 'JD' }}>
                  <i class="bi bi-grid"></i>
                  <span>Post Jobs</span>
                </a>
              </li>
              : null}
            {sessionStorage.getItem('AccessType') == 'User' ?
              <li class="nav-item">
                <a class={clickCommisionshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickCommisionshow(!clickCommisionshow)} data-bs-toggle="collapse" >
                  <i class="bi bi-cash-coin"></i><span>Back Office</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={(clickCommisionshow || BoJobseekerApproval || BoEmployerApproval) ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>
                    <li class={BoEmployerApproval ? "list" : ""}>
                      <a href='/BoEmployerApproval' state={{ ID: 0, Flag: 'DC' }}>
                        <i class="bi bi-circle"></i><span>Employer Approval</span>
                      </a>
                    </li>
                    <li class={BoJobseekerApproval ? "list" : ""}>
                      <a href='/BoJobseekerApproval' state={{ ID: 0, Flag: 'DC' }}>
                        <i class="bi bi-circle"></i><span>Jobseeker Approval</span>
                      </a>
                    </li>
                  </ul>
                </ul>
              </li>
              : null}
          </ul>
        </div>
      </body>

    </>

  )
}

export default Base