import React, { useEffect, useState } from 'react'
import Base from '../../Base';
import { Link,useLocation ,useNavigate} from "react-router-dom";
import Api from '../../Services/ApiCall'
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import {
    validPhoneNumberRegex,
    validPasswordRegex,
    validEmailRegex,
    validOnlyEmailRegex,
    maxLength,
    numberMethod,
}from '../../CommanRules'
import '../../../App.css'

export default function Officetypecreationform() {

   
   
    const location = useLocation();
    const { ID, Flag } = location.state;

    const navigate=useNavigate();
    const [Listemployee, setListemployee] = useState([]);
    const [ListemployeeGrade, setListemployeeGrade] = useState([]);
    const [ListemployeeCode, setListemployeeCode] = useState([]);
    const [ListemployeeDesignation, setListemployeeDesignation] = useState("");

    const [SelectOfficegrade,setSelectOfficegrade]=useState("");
    const [SelectOFficeEmpCode,setSelectOFficeEmpCode]=useState("");
    const [SelectOFficeDesignation,setSelectOFficeDesignation]=useState("");
    const[grade,setGrade] =useState("");
    const[autograde,setAutograde] =useState("");
    const[autograde1,setAutograde1] =useState("");
    const[OfficeLocation,setOfficeLocation] =useState("");
    const[selectOfficeGrade,setselectOfficeGrade] =useState("");
    const[OfficeGradeType,setOfficeGradeType] =useState("");
    const[phonenumber,setPhonenumber] =useState("");
    const[address,setAddress] =useState("");
    const[pincode,setPincode] =useState("");
    const[emailId,setEmailId] =useState("");
    const[empgrade,setEMPgrade] =useState("");
    const [designation, setDesignation] = useState("");
    const[selectedOfficelocation,setselectedOfficelocation]=useState("");
    const [offGrade, setoffGrade] = useState("");
    const [offGradeType, setOffGradeType] = useState("");
    const [selectedOffGradeType, setselectedOffGradeType] = useState("");
    const  [Inchargename,setSelectinchargename]=useState("");
    const[selectedreportingoffice,setselectedReportingoffice]=useState("");
    const[reportingoffice,setReportingoffice]=useState("");
  
    useEffect(()  => {
       
         bindOffGradeDdl(0);
          bindEmpGradeDdl(0);
         bindStaffGradeType(0)
          BindGrid()
          BindGrid1()
         if (ID != 0) {
             Get(ID)
                 }
     }, []);

    async function BindGrid() {
        let _a=[];
        _a.push("Employee")
        var Param = { mstuserregid: 0 ,rolecab:JSON.stringify(_a)}
        var url = "/UserReg/GetUserRegtable";
        let _response1 = await Api.BindGrid(Param, url)
        setListemployee(_response1)
    }

    async function BindGrid1(){
         var url = "/DropDown/GetAdminDeptStaff";
         let _response = await Api.BindDropdown(url)
         setListemployeeGrade(_response)
     }
     

    function SetOfficeGrade(id,Flag)
        {
         try{

         
            if(Flag ===1){
                setSelectOfficegrade(id)
                let _x=Listemployee.filter(function (e) {
                    return e.AgGrade === id;
                })
                setListemployeeCode(([...new Set(_x.map(value => value.Msusertregcode))]))
            }
            if(Flag ===2){
                setSelectOFficeEmpCode(id)
                let _x=Listemployee.filter(function (e) {
                    return e.Msusertregcode === id;
                })
                setSelectOFficeDesignation(_x[0].AgDesignation)
                setSelectinchargename(_x[0].Name)
            }
        }catch{
            
        } 
    }
    
    const [offGradeTypeId, setoffGradeTypeId] = useState("");
    const [empGrade, setEmpGrade] = useState("");
    const [selectedEmpGrade, setselectedEmpGrade] = useState("");
    const [staffGrade, setStaffGrade] = useState("");



    async function bindOffGradeDdl(id) {
        var Param = { Id: id }
        var url = "/OfficeGradeCreation/BindOfficeGradeCreation";
        let _response = await Api.BindGrid(Param, url)
        setoffGrade(_response);
    }
    async function bindEmpGradeDdl(id) {
        var Param = { Rolecab: 'Employee' }
        var url = "/UserReg/GetUserRegtable";
        let _response = await Api.BindGrid(Param, url)
        setEmpGrade(_response);
    }
    async function bindStaffGradeType(id) {
        var Param = { mststaffgradedesignationid : id }
        var url = "/StaffGradeType/BindStaffGradeType";
        try{
    
            let _response1 = await Api.BindGrid(Param, url);
            setStaffGrade(_response1);
        }catch{
        }
    }

    async function Getreportingcode(e){
         var id = (e.target.value)
       
         var url = "/OfficeTypeCreation/RepotingOffice?id="+id;
 
         let _response = await Api.BindDropdown(url)
         let Arry_Title1 = [];
         setReportingoffice(_response)
     }
     
    
    async function getSelectedoffgiceGradeDdl(e) {
        setGrade(e.target.value);
        setoffGradeTypeId(e.target.value);
        var offGrade = e.target.options[e.target.selectedIndex].text;
        setGrade(offGrade)
        var Param = { Selectofficegrade: offGrade }
        var url = "/OfficeGradeCreation/BindOfficeGradeCreation";
        let _response = await Api.BindGrid(Param, url)
        setOffGradeType(_response);
    }
    async function OFficefunction(id) {
        var Param = { Selectofficegrade: id }
        var url = "/OfficeGradeCreation/BindOfficeGradeCreation";
        let _response = await Api.BindGrid(Param, url)
        setOffGradeType(_response);
    }
    async function IUDOperation()
    {       
        var Grade = grade;
        var Autograde = selectedOffGradeType;
        var Officelocation= selectedOfficelocation;
        var Phonenumber = phonenumber;
        var Address = address;
        var Pincode = pincode;
        var EmailId = emailId;
        var Selectofficegrade= SelectOfficegrade;
        var InchargeName=Inchargename;
        var SelectofficeEmpCode  = SelectOFficeEmpCode;
        var EMPgrade = empgrade;
        var Designation = designation;
        var Selectedreportingoffice=selectedreportingoffice;
 
        if (Grade == "") {
            toast.error('Please select a Grade')
            return false;
        }
        if (Autograde == "") {
            toast.error('Please select a Grade type')
            return false;
        }
        if (Selectedreportingoffice == "") {
            toast.error('Please select Reporting Office')
            return false;
        }
 
        if (Officelocation == "") {
         toast.error('Please select Office location')
         return false;
     }
   
     if (Address == "") {
         toast.error('Please enter Address')
         return false;
     }
     if (Pincode == "") {
         toast.error('Please enter Pincode ')
         return false;
     }else {if(!(Pincode.length==6)){
         toast.error('Please enter valid Pincode ')
          return false;
          }
     } 
     if (EmailId == "") {
         toast.error('PLease enter Email Id')
         return false;
     }
     else if (!  validOnlyEmailRegex.test(EmailId)) {
         toast.error('PLease enter a valid Email id')
         return false;
     }
   
    var Param = {  
    "mstofficetypecreationid": ID,
    "officetypecreationcode": "",
    "location": "string",
    "selectOfficeGrade": "string",
    "selectOfficeType": "string",
   "contactnumber": Phonenumber,
   "location": Officelocation,
   "selectOfficeGrade": selectedOffGradeType,
   "selectOfficeType": grade,
   "email": emailId,
   "address": Address,
   "pincode": Pincode, 
   "empcode": SelectOFficeEmpCode,
   "empgrade": SelectOfficegrade,
   "reportingOffice": Selectedreportingoffice,
   "designation": SelectOFficeDesignation, 
   "flag": Flag,
   "name": InchargeName,


   };
    var url = "/OfficeTypeCreation/IUDOfficeTypeCreation";
    let _response = await Api.IUD(Param, url);
    showAlert(_response);
    setTimeout(()=>{
     navigate('/officetypecreationtable')
    },1000)
   
 }
 const showAlert = (_response) => {
    Swal.fire({
        title: "Success",
        text: _response,
        icon: "success",
        confirmButtonText: "OK",
    });
};

async function Get(id) {
    var Param = { mstofficetypecreationid: id }
    var url = "/OfficeTypeCreation/BindOfficeTypeCreation";
   let _response = await Api.BindGrid(Param, url)


    setselectedOffGradeType(_response[0].SelectOfficeGrade)
    await OFficefunction(_response[0].SelectOfficeType)
    var url1 = "/OfficeTypeCreation/RepotingOffice?id="+_response[0].SelectOfficeType;
    let _response1 = await Api.BindDropdown(url1)
    setReportingoffice(_response1)

    setTimeout(function() {

   setselectedReportingoffice(_response[0].Reportoffice)
    setSelectOFficeDesignation(_response[0].Designation)});
    setSelectOFficeEmpCode(_response[0].Empcode)
    setSelectOFficeEmpCode(_response[0].Msusertregcode)

   
    setSelectOfficegrade(_response[0].Empgrade)
    SetOfficeGrade(_response[0].Empgrade)

    setPincode(_response[0].Pincode)
    setEmailId(_response[0].Email)
    setAddress(_response[0].Address)
    setPhonenumber(_response[0].Contactnumber) 
  
    setGrade(_response[0].SelectOfficeType) 
    setSelectinchargename(_response[0].Name) 

    setselectedOfficelocation(_response[0].Location) 
    }

  return (
    <div>
        <Toaster toastOptions={{ position: "top-right" }} />
          <Base Officecreation={true}/>
          <div id="main" class="main">
              <div class="pagetitle">
                  
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          <li class="breadcrumb-item">General Setting</li>
                          <li class="breadcrumb-item active">Office Creation</li>
                      </ol>
                  </nav>
              </div>
             
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Office  Creation</h5>

              
                      <div class="row g-3">
                          <div class="col-md-6">
                          <label for="officegrade">Office Grade</label>
                              <select id="inputState" class="form-select"  disabled={Flag == "D" || Flag == "null" ? true : false} value={grade} onChange={(e) => {getSelectedoffgiceGradeDdl(e);Getreportingcode(e)}}>
                                  <option selected>Select Office Grade</option>
                                  { offGrade ?(
                                offGrade.map((i,index) =>(
                                    <option value={i.Selectofficegrade}>{i.Selectofficegrade}</option>
                                ))):(null)}
                                                            
                              </select>
                          </div>
                          
                          <div class="col-md-6">
                          <label for="gradetype">Grade Type</label>
                          <select id="inputState" class="form-select"  disabled={Flag == "D" || Flag == "null" ? true : false}value={selectedOffGradeType} onChange={(e) => setselectedOffGradeType(e.target.value)}>
                                  <option selected>Select Office Type</option>
                                  { offGradeType ?(
                                offGradeType.map((i,index) =>(
                                    <option value={i.Mstofficegradecreationid}>{i.Ogc}</option>
                                ))):(null)}
                                                            
                              </select>
                              </div>
                          <div class="col-md-6">
                          <label for="reportingcode">Reporting Office</label>
                          <select id="inputState" class="form-select"  disabled={Flag == "D" || Flag == "null" ? true : false}value={selectedreportingoffice} onChange={(e) => setselectedReportingoffice(e.target.value)}>
                                  <option selected>Select Reporting Office</option>
                                  { reportingoffice ?(
                                reportingoffice.map((i,index) =>(
                                    <option value={i.id}>{i.Text}</option>
                                ))):(null)}
                                                            
                              </select>
                               </div>
                          <div class="col-md-6">
                          <label for="location">Location</label>
                              <input type="text" class="form-control" placeholder="Location"  disabled={Flag == "D" || Flag == "null" ? true : false}value={selectedOfficelocation} onChange={(e) => setselectedOfficelocation(e.target.value)}/>
                          </div>
                          <div class="col-md-6">
                          <label for="conactnumber">Contact Number</label>
                              <input type="number" class="form-control no-spinner" placeholder="Contact Number"  disabled={Flag == "D" || Flag == "null" ? true : false}value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)}/>
                          </div>
                          <div class="col-6">
                          <label for="address">Address</label>
                              <input type="text" class="form-control" placeholder="Address"value={address} disabled={Flag == "D" || Flag == "null" ? true : false} onChange={(e) => setAddress(e.target.value)}/>
                          </div>
                          <div class="col-md-6">
                          <label for="pincode">Pincode</label>
                              <input type="text" class="form-control" placeholder="PinCode" value={pincode} disabled={Flag == "D" || Flag == "null" ? true : false} onChange={(e) => setPincode(e.target.value)}/>
                          </div>
                          <div class="col-md-6">
                          <label for="email">Email Id</label>
                              <input type="text" class="form-control" placeholder="Email Id" value={emailId} disabled={Flag == "D"  || Flag == "null" ? true : false} onChange={(e) => setEmailId(e.target.value)}/>
                          </div>
                          <h5 class="card-title">Office Incharge Details</h5>
                          {/* <div class="col-md-6">
                          <label for="officetype">Staff Type</label>
                          <select class="form-select" value={SelectOfficegrade} placeholder="Grade" disabled={Flag == "D" || Flag == "null" ? true : false} onChange={(e) => SetOfficeGrade(e.target.value,1)} >
                                <option selected>Select Staff Type</option>
                                  { ListemployeeGrade ?(
                                ListemployeeGrade.map((i,index) =>(
                                    <option value={i.Text}>{i.Text}</option>
                                ))):(null)}
                                </select>
                           
                          </div> */}
                          <div class="col-md-6">
                          <label for="employeecode">Employee Code</label>
                          <select class="form-select" value={SelectOFficeEmpCode} disabled={Flag == "D" || Flag == "null" ? true : false} onChange={(e) => SetOfficeGrade(e.target.value,2)} >
                                <option selected>Select Employee Code </option>
                                  { ListemployeeGrade ?(
                                ListemployeeGrade.map((i,index) =>(
                                    <option value={i.Text}>{i.Text}</option>
                                ))):(null)}
                                </select>
                           
                          </div> 
                          <div class="col-md-6">
                          <label for="designation">Designation</label>
                              <input type="text" class="form-control" disabled={true}  placeholder="Designation"value={SelectOFficeDesignation} />
                          </div>
                          <div class="col-md-6">
                          <label for="designation">Incharge Name</label>
                              <input type="text" class="form-control"  disabled={true}  placeholder="Name" value={Inchargename} />
                          </div>
                          
                          <div class="text-center">
                              <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                              <button type="reset" class="btn btn-info">
                              <Link to='/officetypecreationtable'>
                                Back
                                </Link>
                                </button>
                          </div>
                      </div>

                  </div>
              </div>

           
          </div>
    </div>
  )
}
