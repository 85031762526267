import React, { useRef, useState, useEffect } from 'react';
import Base from './Base';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Api from './Services/ApiCall';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';
import css from '../App.css';
import { Images } from '../Images/Index';
import invoicebill from './invoicebill.css';
import $ from 'jquery';
import JobseekerBase from './Jobseeker/JobseekerBase';
import EmployerBase from './Employer/EmployerBase';
import {
    MDBCard,
    MDBCardBody,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBIcon,
    MDBTypography,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
} from "mdb-react-ui-kit";


export default function Reinvoice() {
    const location = useLocation();
    const { Invoice_number } = location.state; 


    const [setPlan] = useState("");
    const [setPlanperiod] = useState("");
    const [billingaddress, setBillingaddress] = useState("");
    const [paymenttype, setPaymenttype] = useState("");
    const [GSTnumber, setGstnumber] = useState("");
    const [created_date, setCreated_date] = useState("");
    
    
    const [description, setDesription] = useState("");
    const [amount, setAmount] = useState("");
    const [user_type, setUser_type] = useState("");
    const [user_id, setUser_id] = useState("");

    const [userName, setUserName] = useState("");
    const [userPhonenum, setUserPhonenum] = useState("");
    const [user_code, setUser_code] = useState("");
    const [userAddress, setUserAddress] = useState("");

    const Amount = parseFloat(amount);
    const Tax = 18;
    const Tax_percent_amount = (Amount * Tax) / 100;
    const CGST = Tax_percent_amount / 2;
    const SGST = Tax_percent_amount / 2;
    const Finel_amount = Amount + Tax_percent_amount



    useEffect(() => {
        CompanyGet()
        Get(Invoice_number)
        
    }, []);




    const [place, setLocation] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [emailid, setEmailid] = useState("");
    const [contactnumber, setContactnumber] = useState("");
    const [website, setWebsite] = useState("");
    const [gstnumber, setGstNumber] = useState("");
    const [logo, setLogo] = useState("");
    const { base64Data } = logo;

    async function CompanyGet(id) {
        var Param = { Mstcompanyprofileid: 3 }
        var url = "/Company/BindCompany";
        let _response = await Api.BindGrid(Param, url)

        setCompanyname(_response[0].Companyname);
        setLocation(_response[0].Location);
        setEmailid(_response[0].Emailid);
        setContactnumber(_response[0].Phonenumber);
        setWebsite(_response[0].Website);
        setGstNumber(_response[0].SearchField);
        setLogo(JSON.parse(_response[0].Logo));
    }



    var OrderRef = ""
    if (sessionStorage.getItem('AccessType') === "Agent") {
        OrderRef = JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode'];
    }
    else {
        OrderRef = "";

    }
    var Dispatchfrom = ""
    if (sessionStorage.getItem('AccessType') === "Agent") {
        Dispatchfrom = JSON.parse(sessionStorage.getItem('userdetails'))[0]['Address'];
    }
    else {
        Dispatchfrom = companyname;

    }
    

    async function IUD() {

        IUDOperation();
    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };



    var d = new Date();

    const pdfRef = useRef(null);

    async function GetAddress(value) {
        var id = value;
        var url = "/DropDown/GetAddress?id=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setBillingaddress(_response)
    }
    async function IUDOperation() {
        const content = pdfRef.current;
        const pdf = new jsPDF('pt', 'mm', [1500, 1000]);
        pdf.html(content, {
            callback: function (pdf) {
                pdf.setFontSize('8');
                pdf.save('Invoice.pdf');
            }
        })
    }

    async function Get(id) {
        var Param = { Paymentcode: id }
        var url = "/Payment/BindPayment";

        let _response = await Api.BindGrid(Param, url)

        setCreated_date(_response[0].CreatedDate);
        setUser_id(_response[0].Userid);
        setUser_type(_response[0].Usertype);
        setDesription(_response[0].Plan);
        setAmount(_response[0].Amount);
        to_address()
    }

    async function to_address() {
        if (user_type == 'Employer') {
            var Param = { Mstuserregid: user_id }
            var url = "/UserReg/GetUserRegtable";
            let _response = await Api.BindGrid(Param, url)
            setUserName(_response[0].Name)
            setUserPhonenum(_response[0].Mobilenumber)
            setUser_code(_response[0].Msusertregcode)
            setUserAddress(_response[0].Address)
        } 
        else if(user_type == 'Jobseeker') {
            var Param = { Mstjobseekerid: user_id }
            var url = "/JobSeekerTable/BindJobSeekerTable";
            let _response = await Api.BindGrid(Param, url)
            setUserName(_response[0].Name)
            setUserPhonenum(_response[0].Mobilenumber)
            setUser_code(_response[0].Mstjobseekercode)
            setUserAddress(_response[0].Address)
        }
    }
  

    return (
        <div>
            {sessionStorage.getItem('AccessType') === "Employer" ?
                <EmployerBase />
                : null}
            {sessionStorage.getItem('AccessType') === "jobseeker" ?
                <JobseekerBase />
                : null}
            {sessionStorage.getItem('AccessType') === "User" || sessionStorage.getItem('AccessType') === "Agent" || sessionStorage.getItem('AccessType') === "Employee" ?
                <Base />
                : null}
            <div id="main" class="main">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Invoice Details</h5>


                        <div class="text-center" style={{ marginRight: '100px' }}>
                            <button type="button" class="btn btn-primary" onClick={IUD}>Download Invoice</button>
                            {sessionStorage.getItem('AccessType') === "Employer" ?
                                <button type="reset" class="btn btn-info">
                                    <Link to='/Employerdashboard'>
                                        Back
                                    </Link>
                                </button>
                                : null}
                            {sessionStorage.getItem('AccessType') === "jobseeker" ?
                                <button type="reset" class="btn btn-info">
                                    <Link to='/Jobseekerdashboard'>
                                        Back
                                    </Link>
                                </button>
                                : null}
                            {sessionStorage.getItem('AccessType') === "User" || sessionStorage.getItem('AccessType') === "Agent" || sessionStorage.getItem('AccessType') === "Employee" ?
                                <button type="reset" class="btn btn-info">
                                    <Link to='/dash'>
                                        Back
                                    </Link>
                                </button>
                                : null}
                        </div>

                    </div>

                    <div ref={pdfRef} style={{ width: '100%' }}>
                        <MDBContainer className="py-5">
                            <MDBCard className="p-4">
                                <MDBCardBody>
                                    <MDBContainer className="mb-2 mt-3">
                                        <MDBRow className="d-flex align-items-baseline">
                                            <MDBCol xl="6">
                                                <img src={`data:image;base64,${base64Data}`}
                                                    style={{ width: '50%', marginLeft: '20%', marginTop: '20px' }} />

                                                <p><span style={{ fontWeight: 'bold' }}>Dispatch From:</span>{companyname}</p>
                                            </MDBCol>
                                            <MDBCol xl="6" className="float-end">
                                                <>
                                                    <div style={{ marginRight: '50%' }}><p style={{ fontWeight: '700' }}>Dispatch From:</p></div>
                                                    <p>{companyname}</p>
                                                    <p>{place}</p>
                                                    <p>{contactnumber}</p>
                                                </>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                    <MDBContainer>
                                        <MDBCol md="12" className="text-center">
                                            <MDBIcon
                                                fab
                                                icon="mdb"
                                                size="4x"
                                                className="ms-0 "
                                                style={{ color: "#5d9fc5" }}
                                            />
                                        </MDBCol>
                                    </MDBContainer>
                                    <MDBRow>
                                        <MDBCol xl="8">
                                            <MDBTypography listUnStyled>
                                                <li className="text-muted">
                                                    <span className="fw-bold ms-1">To:</span> <span style={{ color: "#5d9fc5" }}>{userName}</span>
                                                </li>
                                                <li className="text-muted">{userAddress}</li>
                                                <li className="text-muted">{user_code}</li>
                                                <li className="text-muted">
                                                    <MDBIcon fas icon="phone-alt" /> {userPhonenum}
                                                </li>
                                            </MDBTypography>
                                        </MDBCol>
                                        <MDBCol xl="4">
                                            <MDBTypography listUnStyled>
                                                <li className="text-muted">
                                                    <MDBIcon fas icon="circle" style={{ color: "#84B0CA" }} />
                                                    <span className="fw-bold ms-1">ID:</span>{Invoice_number}
                                                </li>
                                                <li className="text-muted">
                                                    <MDBIcon fas icon="circle" style={{ color: "#84B0CA" }} />
                                                    <span className="fw-bold ms-1">Creation Date: </span>{created_date}
                                                </li>
                                                <li className="text-muted">
                                                    <MDBIcon fas icon="circle" style={{ color: "#84B0CA" }} />
                                                    <span className="fw-bold ms-1">Status:</span>
                                                    <span className="badge bg-warning text-black fw-bold ms-1">
                                                        Paid
                                                    </span>
                                                </li>
                                            </MDBTypography>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="my-2 mx-1 justify-content-center">
                                        <MDBTable striped borderless>
                                            <MDBTableHead
                                                className="text-white"
                                                style={{ backgroundColor: "#84B0CA" }}
                                            >
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">Unit Price</th>
                                                    <th scope="col">GST</th>
                                                    <th scope="col">Amount</th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td style={{ textAlign: "initial" }}>{description}</td>
                                                    <td style={{ textAlign: "initial" }}>1</td>
                                                    <td style={{ textAlign: "initial" }}>{amount}</td>
                                                    <td style={{ textAlign: "initial" }}>{Tax_percent_amount}</td>
                                                    <td style={{ textAlign: "initial" }}>{Finel_amount}</td>
                                                </tr>
                                            </MDBTableBody>
                                        </MDBTable>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol xl="8">
                                        </MDBCol>
                                        <MDBCol xl="3">
                                            <p className="text-black float-start">
                                                <span className="text-black me-3"> Amount:</span>
                                                <span style={{ fontSize: "25px" }}>{amount}</span>
                                            </p>
                                            <p className="text-black float-start">
                                                <span className="text-black me-3"> CGST:</span>
                                                <span style={{ fontSize: "25px" }}>{CGST}</span>
                                            </p>
                                            <p className="text-black float-start">
                                                <span className="text-black me-3"> SGST:</span>
                                                <span style={{ fontSize: "25px" }}>{SGST}</span>
                                            </p>
                                            <p className="text-black float-start">
                                                <span className="text-black me-3"> Total Amount:</span>
                                                <span style={{ fontSize: "25px" }}>{Finel_amount}</span>
                                            </p>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol xl="8">
                                        </MDBCol>
                                        <MDBCol xl="4">
                                            {sessionStorage.getItem('AccessType') === "Agent" || sessionStorage.getItem('AccessType') === "Employee" || sessionStorage.getItem('AccessType') === "User" ?
                                                <>
                                                    <div style={{ marginTop: '20%' }}>

                                                        <p>Authorized Signatory</p>
                                                    </div>

                                                    <div >
                                                        <p style={{ fontWeight: 'bold' }}>
                                                            <span>{sessionStorage.getItem('username')}</span></p>
                                                    </div>
                                                </>
                                                :
                                                <p>Your Payment Is Successfull.Thanks for Subscribe</p>}
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}