import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import Base from './Base';
import Api from './Services/ApiCall';
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';

const OfficeCashReport = () => {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [Ho_data, setHo_data] = useState([]);
  const [Ho_office_data, setHo_office_data] = useState([]);
  const [selectHooffice, setSelectHooffice] = useState(null);
  const [selectRooffice, setSelectRooffice] = useState(null);
  const [Do_data, setDo_data] = useState([]);
  const [selectDooffice, setSelectDooffice] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [Dooffice, setDooffice] = useState("");

  useEffect(() => {
    GetRo_count()
    GetRo_count1()
  }, []);

  useEffect(() => {
    GetDo_count()
  }, [selectRooffice]);

  useEffect(() => {
    GetRO_office_using_HO()
  }, [selectHooffice]);

  useEffect(() => {
    BindGrid1()
  }, [selectDooffice]);


  async function GetRo_count() {
    var Param = { Mstuserregid: sessionStorage.getItem("MUserID") ,Flag:sessionStorage.getItem('AccessType')};
    // var Param = { Mstuserregid: sessionStorage.getItem("MUserID") };
    var url = "/Report/GetAllOffice";
    let _response = await Api.BindGrid(Param, url);
    setDooffice(_response.Text1)

    const dataArray = _response.Text.split(',');

    const optionsArray = dataArray.map((item) => ({ Text: item }));
    if (_response.Text1 == "DO") {
      setDo_data(optionsArray);
    }
    else {
      setHo_data(optionsArray);
    }
  }
  async function GetRO_office_using_HO() {
    var Param = { Msusertregcode: selectHooffice };
    // var Param = { Mstuserregid: sessionStorage.getItem("MUserID") };
    var url = "/Report/GetRO_office_using_HO";
    let _response = await Api.BindGrid(Param, url);

    const dataArray = _response.Text.split(',');

    const optionsArray = dataArray.map((item) => ({ Text: item }));

    setHo_data(optionsArray);
  }

  async function GetRo_count1() {
    var Param = { Mstuserregid: sessionStorage.getItem("MUserID") ,Flag:sessionStorage.getItem('AccessType')};
    // var Param = { Mstuserregid: sessionStorage.getItem("MUserID") };

    var url = "/Report/GetAllOffice";
    let _response = await Api.BindGrid(Param, url);

    const dataArray = _response.Text.split(',');

    const optionsArray = dataArray.map((item) => ({ Text: item }));

    setHo_office_data(optionsArray);
  }

  async function GetDo_count() {
    var Param = { Msusertregcode: selectRooffice };
    var url = "/Report/GetROOfficeReport_count_with_HO";
    let _response = await Api.BindGrid(Param, url);

    const dataArray = _response.Text.split(',');

    const optionsArray = dataArray.map((item) => ({ Text: item }));

    setDo_data(optionsArray);
  }


  async function BindGrid1() {
    var Param = {
      Mstuserregid: sessionStorage.getItem("MUserID"),
      Msusertregcode: selectDooffice,
      Name: "Employee"
    }
    var url = "/Report/GetROOfficeReport";
    let _response = await Api.BindGrid(Param, url)
    setRowData(_response)
    const total = _response.reduce((sum, row) => sum + parseFloat(row.BankImage), 0);
    setTotalAmount(total);
  }

  const openPopup = (rowData) => {
    setSelectedRowData(rowData);
    setShowPopup(true);
  };
  const onCellClicked = (params) => {
    openPopup({ id: params.data.Mstuserregid, code: params.data.Msusertregcode });
  };
  const columnDefs = [
    { headerName: 'Employee Code', field: 'Msusertregcode', filter: true, enableFilter: true },
    { headerName: 'Name', field: 'Name', filter: true, enableFilter: true },
    { headerName: 'Amount', field: 'BankImage', filter: true, enableFilter: true },
    { headerName: 'DO Office Code', field: 'DO_code', filter: true, enableFilter: true },
  ];
  const [quickFilterText, setQuickFilterText] = useState('');
  const onQuickFilterChange = (event) => {
    setQuickFilterText(event.target.value);
  };


  return (
    <div>
      <Base />
      <div id="main" className="main">
        <div class="pagetitle">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/dash">Home</Link>
              </li>

              <li class="breadcrumb-item">Report</li>
              <li class="breadcrumb-item active">HO Office Report</li>
            </ol>
          </nav>
        </div>

        <input
          type="text"
          placeholder="Search..."
          onChange={onQuickFilterChange}
          style={{ marginBottom: '10px' }}
        />
        <div class='row mb-5'>
          {Ho_office_data.some(item => item.Text.startsWith("HO")) &&
            <div class="col-md-3">
              <>
                <label for="mainoffice">HO Offices</label>
                <select
                  id="inputState"
                  class="form-select"
                  value={selectHooffice}
                  onChange={(e) => {
                    setSelectHooffice(e.target.value);
                  }}
                >
                  <option selected>HO Offices</option>
                  {Ho_office_data &&
                    Ho_office_data.map((i, index) => (
                      <option key={index} value={i.Text}>
                        {i.Text}
                      </option>
                    ))}
                </select>
              </>
            </div>
          }
          {Dooffice == "DO" ?
            null :
            <div class="col-md-3">
              <label for="mainoffice">RO Offices</label>
              <select
                id="inputState"
                class="form-select"
                value={selectRooffice}
                onChange={(e) => {
                  setSelectRooffice(e.target.value);
                }}
              >
                <option selected>RO Offices</option>
                {Ho_data &&
                  Ho_data.map((i, index) => (
                    <option key={index} value={i.Text}>
                      {i.Text}
                    </option>
                  ))}
              </select>
            </div>
          }

          {selectRooffice && (
            <div class="col-md-3">
              <label for="mainoffice">DO Offices</label>
              <select
                id="inputState"
                class="form-select"
                value={selectDooffice}
                onChange={(e) => setSelectDooffice(e.target.value)}
              >
                <option selected>DO Offices</option>
                {Do_data &&
                  Do_data.map((i, index) => (
                    <option key={index} value={i.Text}>
                      {i.Text}
                    </option>
                  ))}
              </select>
            </div>
          )}
          {Dooffice == "DO" ?
            <>
              <div class="col-md-3">
                <label for="mainoffice">DO Offices</label>
                <select
                  id="inputState"
                  class="form-select"
                  value={selectDooffice}
                  onChange={(e) => setSelectDooffice(e.target.value)}
                >
                  <option selected>DO Offices</option>
                  {Do_data &&
                    Do_data.map((i, index) => (
                      <option key={index} value={i.Text}>
                        {i.Text}
                      </option>
                    ))}
                </select>
              </div>
            </>
            : null}

        </div>

        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={10}
            domLayout='autoHeight'
            floatingFilter={true}
            enableBrowserTooltips={true}
            onCellClicked={onCellClicked}
            quickFilterText={quickFilterText}
          />
        </div>
        <h4>Total Amount: {totalAmount}</h4>
      </div>
    </div >
  );
};

export default OfficeCashReport;