import React from 'react'; 

function PreLoader() {
  return (
    <div className="preloader">
      <div className="dot-loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
      <p>ELAKIYAA SOLUTION</p>
    </div>
  );
}

export default PreLoader;
