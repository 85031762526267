import React from 'react';
import '../Css/dashboard.css';
import '../Css/vendor/bootstrap/css/bootstrap.min.css';
import '../Css/vendor/bootstrap-icons/bootstrap-icons.css';
import Base from './Base';
import MainDashboard from './MainDashboard';
import AgentDashboard from './Dashboard/AgentDashboard';
import EmployeeDashboard from './Dashboard/EmployeeDashboard';


function Dashboard() {
  return (
    <>
    <Base/>
    {sessionStorage.getItem("AccessType") == 'Agent'?
        <AgentDashboard/>:<EmployeeDashboard />
        // <AgentDashboard/>:<MainDashboard />
    }
    {/* <MainDashboard/> */}
    </>
  )
}

export default Dashboard