import React, { useState, useEffect } from 'react'
import Base from '../Base'
import Api from '../Services/ApiCall';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "./Admin_form/Filter/Globalfilter";
import '../../Css/Pagination.css'
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';



export default function Jobseekerplancreation(any, i) {
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup1, setShowPopup1] = useState(false);

    useEffect(() => {
        fetchDataAndSetState();
    }, []);

    const fetchDataAndSetState = async () => {
        try {
            var Param = { mstjobseekerplancreationid: 0 }
            var url = "/JobSeekerPlanCreation/BindJobSeekerPlan";
            let _response1 = await Api.BindGrid(Param, url);
            setRowData(_response1);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const openPopup = (rowData) => {
        setSelectedRowData(rowData);
        Get(rowData.id)
        setShowPopup(true);
    };
    const openPopup1 = (rowData) => {
        setShowPopup(false);
        setShowPopup1(true);
    };

    const closePopup1 = () => {
        setSelectedRowData(null);
        setShowPopup1(false);
    };
    const closePopup = () => {
        setSelectedRowData(null);
        setShowPopup(false);
    };

    const onCellClicked = (params) => {
        openPopup({ id: params.data.Mstjobseekerplancreationid, code: params.data.Plantype });
    };

    const columnDefs = [
        { headerName: 'Plan Type', field: 'Plantype', filter: true, enableFilter: true },
        { headerName: 'Partner Registration', field: 'Plandetail', filter: true, enableFilter: true },
        { headerName: 'Partner Plan Activation', field: 'Amount', filter: true, enableFilter: true },
        { headerName: 'Jobseeker Registration', field: 'SearchField', filter: true, enableFilter: true },
        { headerName: 'Employer Registration', field: 'Jobseekerplancreationcode', filter: true, enableFilter: true },
        // { headerName: 'Self Registered Jobseeker&Employer Wallet', field: 'SearchField', filter: true, enableFilter: true },
    ];

    const [quickFilterText, setQuickFilterText] = useState("");

    const onQuickFilterChange = (event) => {
        setQuickFilterText(event.target.value);
    };

    async function IUD_Delete() {
        openPopup1();
    }
    const [plantype, setPlantype] = useState("");
    const [partner_reg, setPartner_reg] = useState("");
    const [partner_plan, setPartner_plan] = useState("");
    const [jobseeker_reg, setJobseeker_reg] = useState("");
    const [employer_reg, setEmployer_reg] = useState("");
    const [wallet, setWallet] = useState("");
    const [coupon_percent, setCoupon_percent] = useState("");
    const [coupon, setCoupon] = useState("");


    async function IUDOperation() {
        var Plantype = plantype;
        var Partner_reg = partner_reg;
        var Partner_plan = partner_plan;
        var Jobseeker_reg = jobseeker_reg;
        var Employer_reg = employer_reg;
        var Wallet = wallet;


        var ID = selectedRowData.id;

        var Param = {
            "mstjobseekerplancreationid": ID,
            "jobseekerplancreationcode": Employer_reg,
            "plantype": Plantype,
            "plandetail": Partner_reg,
            "amount": Partner_plan,
            "coupon": coupon,
            "coupon_Percent": coupon_percent,
            "active": "string",
            "searchField": Jobseeker_reg,
            "flag": "U",
        };
        var url = "/JobSeekerPlanCreation/IUDJobSeekerPlan";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup(false);

    }
    async function IUDOperationDelete() {
        var Plantype = plantype;
        var Partner_reg = partner_reg;
        var Partner_plan = partner_plan;
        var Jobseeker_reg = jobseeker_reg;
        var Employer_reg = employer_reg;
        var Wallet = wallet;

        var ID = selectedRowData.id;

        var Param = {
            "mstjobseekerplancreationid": ID,
            "jobseekerplancreationcode": Employer_reg,
            "plantype": Plantype,
            "plandetail": Partner_reg,
            "coupon": coupon,
            "coupon_Percent": coupon_percent,
            "amount": Partner_plan,
            "active": "string",
            "searchField": Jobseeker_reg,
            "flag": "D",
        };
        var url = "/JobSeekerPlanCreation/IUDJobSeekerPlan";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup1(false);

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function Get(id) {
        var Param = { mstjobseekerplancreationid: id }
        var url = "/JobSeekerPlanCreation/BindJobSeekerPlan";
        let _response = await Api.BindGrid(Param, url)
        setPlantype(_response[0].Plantype);
        setPartner_reg(_response[0].Plandetail);
        setPartner_plan(_response[0].Amount);
        setJobseeker_reg(_response[0].SearchField);
        setEmployer_reg(_response[0].Jobseekerplancreationcode);
        setCoupon(_response[0].Coupon);
        setCoupon_percent(_response[0].Coupon_Percent);
    }

    return (
        <div>
            <Base Jobseekerplan={true} />
            <div id="main" class="main">
                <div class="pagetitle">
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>
                            <li class="breadcrumb-item">Plan Creation</li>
                            <li class="breadcrumb-item active">Agentplan</li>
                        </ol>
                    </nav>
                </div>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={onQuickFilterChange}
                    style={{ marginBottom: '10px' }}
                />
                {Api.CheckAdd(17) == 1 ?
                    <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed' }}>
                        <Link to={'/plancreationform'}
                            state={{ ID: 0, Flag: 'I' }}
                            style={{ color: "white" }}
                        >
                            Add
                        </Link>
                    </button>
                    : null}
                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={10}
                        domLayout='autoHeight'
                        floatingFilter={true}
                        enableBrowserTooltips={true}
                        onCellClicked={onCellClicked}
                        quickFilterText={quickFilterText}
                    />
                </div>
                {showPopup && (
                    <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">

                                <div class="col-md-6">
                                    <label for="plantype">Plan Type</label>
                                    <select id="inputstate" class="form-select" value={plantype} onChange={(e) => setPlantype(e.target.value)}>
                                        <option>Plan Type</option>
                                        <option>Diamond Partner</option>
                                        <option>Gold Partner</option>
                                        <option>Silver Partner</option>
                                    </select>
                                </div>

                                <div class="col-md-6">
                                    <label for="plandetails">Partner Registration</label>
                                    <input type="number" class="form-control no-spinner" value={partner_reg} placeholder="Amount" onChange={(e) => setPartner_reg(e.target.value)} />
                                </div>

                                <div class="col-md-6">
                                    <label for="amount">Partner Plan Activation</label>
                                    <input type="text" class="form-control no-spinner" value={partner_plan} placeholder="Amount" onChange={(e) => setPartner_plan(e.target.value)} />
                                </div>


                                <div class="col-md-6">
                                    <label for="plandetails">Jobseeker Registration</label>
                                    <input type="number" class="form-control no-spinner" value={jobseeker_reg} placeholder="Amount" onChange={(e) => setJobseeker_reg(e.target.value)} />
                                </div>

                                <div class="col-md-6">
                                    <label for="plandetails">Employer Registration</label>
                                    <input type="number" class="form-control no-spinner" value={employer_reg} placeholder="Amount" onChange={(e) => setEmployer_reg(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="coupon">Coupon Code</label>
                                    <input type="text" class="form-control no-spinner" value={coupon} placeholder="Coupon Code" onChange={(e) => setCoupon(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="coupon">Coupon Percent</label>
                                    <input type="text" class="form-control no-spinner" value={coupon_percent} placeholder="Coupon Percent" onChange={(e) => setCoupon_percent(e.target.value)} />
                                </div>


                                <div class="text-center">
                                    {Api.CheckUpdate(17) == 1 ?
                                        <button type="button" class="btn btn-primary" onClick={IUDOperation}>Update</button>
                                        : null}
                                    {Api.CheckDelete(17) == 1 ?
                                        <button type="reset" class="btn btn-info" onClick={IUD_Delete} >Delete</button>
                                        : null}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
                {showPopup1 && (
                    <Modal show={true} onHide={closePopup1} >
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are You sure want to Delete?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closePopup1}>
                                Close
                            </Button>
                            <Button variant="secondary" onClick={IUDOperationDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>


        </div>
    )
}
