import React, { useState,useEffect } from 'react';
import { Link,useLocation,useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Api from '../Services/ApiCall';
import { Images } from '../../Images/Index';


export default function CandidateDescription() {


    const[listdata,setlistdata]=useState("");


    const location = useLocation();
    const { ID,Flag } = location.state;

    useEffect(() => {

        BindGrid(ID)
    }, []);

    async function BindGrid(id) {
        var Param = { mstjobseekerid: id }
        var url = "/JobSeekerTable/BindCandidateDescription";
        let _response1 = await Api.BindGrid(Param, url)
        setlistdata(_response1)

    }

  return (
    <>
     <div className="Auth-form jobdescimg">
            <div className="Auth-form-content">
                <h3 className="Auth-form-title">   
                <img src={Images.mainlogo} alt="pelago" className="loginlg_img" /></h3>
            </div>
        </div>

        <div>
        {listdata && listdata.map((i, index) => (
               <>  
        <div class="job-tittle jobdescimg jobdesc">                             
        <h3>Jobseeker Details</h3>
        <table>
          
            <div class="jobdescleft">
            <p>Name:{i.Name}</p>
            </div>
           
            <div class="jobdescleft">
            <p>DateOfBirth:{i.Dataofbirth}</p>
            </div>
            <div class="jobdescleft">
            <p>Mobile Number:{i.Mobilenumber}</p>
            </div>
            <div class="jobdescleft">
            <p>Email:{i.Email}</p>
            </div>
            <div class="jobdescleft">
            <p>Country:{i.Country}</p>
            </div>
            <div class="jobdescleft">
            <p>State:{i.State}</p>
            </div>
            <div class="jobdescleft">
            <p>City:{i.City}</p>
            </div>
            <div class="jobdescleft">
            <p>Address:{i.Address}</p>
            </div>
           
        </table>
        </div>
        </>
        ))}
            <div class="text-center">
                       
                              <button type="reset" class="btn btn-info">
                              <Link to='/Employerdashboard'state={{ ID:"0" ,Flag:"Back"}} >
                                Back
                                </Link>
                                </button>
                          </div>
                          </div> 
    </>
  )
}
