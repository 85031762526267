import React, { useState, useEffect } from 'react'
import Base from '../Base'
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Api from '../Services/ApiCall';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "./Admin_form/Filter/Globalfilter";
import '../../Css/Pagination.css'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { Modal, Button } from 'react-bootstrap';


export default function Officegradecreation(any, i) {

    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup1, setShowPopup1] = useState(false);

    useEffect(() => {
        fetchDataAndSetState();
    }, []);

    const fetchDataAndSetState = async () => {
        try {
            var Param = { mstofficegradecreationid: 0 }
            var url = "/OfficeGradeCreation/BindOfficeGradeCreation";
            let _response1 = await Api.BindGrid(Param, url);
            setRowData(_response1);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const openPopup = (rowData) => {
        setSelectedRowData(rowData);
        Get(rowData.id)
        setShowPopup(true);
    };
    const openPopup1 = (rowData) => {
        setShowPopup(false);
        setShowPopup1(true);
    };

    const closePopup1 = () => {
        setSelectedRowData(null);
        setShowPopup1(false);
    };
    const closePopup = () => {
        setSelectedRowData(null);
        setShowPopup(false);
    };

    const onCellClicked = (params) => {
        openPopup({ id: params.data.Mstofficegradecreationid, code: params.data.Selectofficegrade });
    };

    const columnDefs = [
        // { headerName: 'Id', field: 'Mstofficegradecreationid', filter: 'agNumberColumnFilter', enableFilter: true },
        { headerName: 'Grade', field: 'Selectofficegrade', filter: true, enableFilter: true },
        { headerName: 'Grade Type', field: 'Ogc', filter: true, enableFilter: true },
        { headerName: 'Remarks', field: 'Remarks', filter: true, enableFilter: true },
    ];

    const [quickFilterText, setQuickFilterText] = useState("");

    const onQuickFilterChange = (event) => {
        setQuickFilterText(event.target.value);
    };



    async function IUD_Delete() {
        openPopup1();
    }


    const [grade, setGrade] = useState("");
    const [gradetype, setGradetype] = useState("");
    const [remark, setRemark] = useState("");




    async function IUDOperation() {
        var Grade = grade;
        var Gradetype = gradetype;
        var Remark = remark;
        var ID = selectedRowData.id;

        var Param = {
            "mstofficegradecreationid": ID,
            "officegradecreationcode": "string",
            "selectofficegrade": Grade,
            "ogc": Gradetype,
            "active": "string",
            "flag": "U",
            "searchField": "string",
            "remarks": Remark
        };
        var url = "/OfficeGradeCreation/IUDOfficeGradeCreation";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup(false);

    }
    async function IUDOperationDelete() {
        var Grade = grade;
        var Gradetype = gradetype;
        var Remark = remark;
        var ID = selectedRowData.id;

        var Param = {
            "mstofficegradecreationid": ID,
            "officegradecreationcode": "string",
            "selectofficegrade": Grade,
            "ogc": Gradetype,
            "active": "string",
            "flag": "D",
            "searchField": "string",
            "remarks": Remark
        };
        var url = "/OfficeGradeCreation/IUDOfficeGradeCreation";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup1(false);
    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function Get(id) {
        var Param = { mstofficegradecreationid: id }
        var url = "/OfficeGradeCreation/BindOfficeGradeCreation";
        let _response = await Api.BindGrid(Param, url)
        setGrade(_response[0].Selectofficegrade);
        setGradetype(_response[0].Ogc);
        setRemark(_response[0].Remarks);
    }




    return (
        <div>
            <Base Officegrade={true} />
            <div id="main" class="main">
                <div class="pagetitle">
                    {/* <h1>Tables</h1> */}
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>

                            <li class="breadcrumb-item">General Setting</li>
                            <li class="breadcrumb-item active">Office Grade</li>
                        </ol>
                    </nav>
                </div>

                <input
                    type="text"
                    placeholder="Search..."
                    onChange={onQuickFilterChange}
                    style={{ marginBottom: '10px' }}
                />
                {Api.CheckAdd(7) == 1 ?
                    <>
                        <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed' }}>
                            <Link to={'/officegradecreationform'}
                                state={{ ID: 0, Flag: 'I' }}
                                style={{ color: "white" }}
                            >
                                Add
                            </Link>
                        </button>
                    </>
                    : null}
                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={10}
                        domLayout='autoHeight'
                        floatingFilter={true}
                        enableBrowserTooltips={true}
                        onCellClicked={onCellClicked}
                        quickFilterText={quickFilterText}
                    />
                </div>
                {showPopup && (
                    <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">

                                <div class="col-md-6">
                                    <label for="grade">Grade</label>
                                    <input type="text" class="form-control" value={grade} placeholder="Office Grade" onChange={(e) => setGrade(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="gradetype">Grade Type</label>
                                    <input type="text" class="form-control" value={gradetype} placeholder="Office grade type" onChange={(e) => setGradetype(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="remark">Remark</label>
                                    <input type="text" class="form-control" value={remark} placeholder="Remark" onChange={(e) => setRemark(e.target.value)} />
                                </div>

                                <div class="text-center">
                                    {Api.CheckUpdate(7) == 1 ?
                                        <button type="button" class="btn btn-primary" onClick={IUDOperation}>Update</button>
                                        : null}
                                    {Api.CheckDelete(7) == 1 ?
                                        <button type="reset" class="btn btn-info" onClick={IUD_Delete} >Delete</button>
                                        : null}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
                {showPopup1 && (
                    <Modal show={true} onHide={closePopup1} >
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are You sure want to Delete?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closePopup1}>
                                Close
                            </Button>
                            <Button variant="secondary" onClick={IUDOperationDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}

            </div>
        </div >
    )
}