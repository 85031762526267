import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Api from './Services/ApiCall';
import toast, { Toaster } from 'react-hot-toast';
import JobseekerBase from './Jobseeker/JobseekerBase'
import { MDBDataTable } from 'mdbreact';

export default function Jobseekerdashboard() {
  const [listdata, setlistdata] = useState("");
  const navigate = useNavigate()
 
  const [walletAmount, setWalletAmount] = useState("");
  
  useEffect(() => {
    BindGrid();

  }, []);

  async function BindGrid() {
    var Id = sessionStorage.getItem("MUserID")
    var Param = { Mstjobseekerid: Id, AccessType: 'Jobseeker' }
    var url = "/JobSeekerTable/BindJobSeekerTable";

    let _response = await Api.BindGrid(Param, url)
    setWalletAmount(_response[0].Recharge_amount)
  }



  return (

    <>
      <JobseekerBase />
      <div id="main" class="main">
        <Toaster toastOptions={{ position: "top-right" }} />
        <h1>Jobseeker Dashboard</h1>
        <div></div>
        <div className='row'>
          <div className="col-xl-4">
            <div className="card">
              <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                <h2>Your Wallet Amount is</h2>
                <h3>{walletAmount}</h3>
              </div>
            </div>
          </div>
        </div>
         
      </div>
    </>


  )
}
