import React, { useState } from 'react'
import Base from '../Base';
import { Link } from "react-router-dom";

const viewbtn = {
    borderRadius: "10px",
    float: "right",
    backgroundColor: "#222a44",
    color: "#fff",
    borderColor: "none",
    height: "35px",
    width: "60px",
}

const doublebtn ={
    marginTop: "54px"

}

export default function ResumeForm() {
    const [formValues, setFormValues] = useState([{ responses: "" }])
    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }
    let addFormFields = () => {
        setFormValues([...formValues, { prospect_statement: "" }])
    }
const [add , setAdd ] =useState("")

  return (
      <div>
          <Base />
          <div id="main" class="main">
              <div class="pagetitle">
                  <h1>Tables</h1>
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          {/* <li class="breadcrumb-item"><Link to="/table">Resume</Link></li> */}
                          <li class="breadcrumb-item active">Form</li>
                      </ol>
                  </nav>
              </div>

              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Resume </h5>


                      <form class="row g-3">

                          <div class="col-md-6">
                              <input type="text" class="form-control" placeholder="Name" />
                          </div>
                          <div class="col-md-6">
                              <input type="text" class="form-control" placeholder="Mobile number" />
                          </div>
                          <div class="col-md-6">
                              <input type="email" class="form-control" placeholder="Email" />
                          </div>
                          <div class="col-md-6">
                              <select id="inputState" class="form-select">
                                  <option selected>Qualification</option>
                                  <option>10th</option>
                                  <option>12th</option>
                                  <option>UG</option>
                                  <option>PG</option>
                              </select>
                          </div>
                      </form>
                          <h6>Experience Details</h6>
                  

                    

                      <form class="row g-3">
                              {formValues.map((element, index) => (
                                 
                                      <><div class="col-md-4" onChange={e => handleChange(index, e)}>
                                      <input type="text" class="form-control" placeholder="Company Name" />
                                  </div><div class="col-md-3">
                                          <input type="text" class="form-control" placeholder="Designation" />
                                      </div><div class="col-md-2">
                                          <input type="text" class="form-control" placeholder="Years" />
                                      </div><div class="col-md-2">
                                          <input type="text" class="form-control" placeholder="Months" />
                                      </div></>
                                
                              ))}
                              </form>

                          
                          <div class="col-md-12 text-center">
                          <button type="button"  style={viewbtn} onClick={() => addFormFields()}>Add</button>
                                  </div>
                                  <div class="text-center" style={doublebtn}>
                                      <button type="button" class="btn btn-primary" >Submit</button><span>
                                        
                                          <button type="reset" class="btn btn-info">
                                              <Link to='/dash'>
                                                  Back
                                              </Link>
                                          </button></span>
                                  </div>
                       
      

                  </div>
              </div>


          </div>


      </div>
  )
}
