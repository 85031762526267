import React, { useState, useEffect } from "react";

const EmployerCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const images = [
    
    "https://images.unsplash.com/photo-1455849318743-b2233052fcff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80",
    "https://images.unsplash.com/photo-1488190211105-8b0e65b80b4e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    "https://images.unsplash.com/photo-1483058712412-4245e9b90334?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    "https://images.unsplash.com/photo-1508780709619-79562169bc64?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((activeIndex + 1) % images.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  const activeImageStyle = {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 2,
    opacity: 1,
    transition: "opacity 0.5s ease-in-out",
  };

  const inactiveImageStyle = {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%) scale(0.8)",
    zIndex: 1,
    opacity: 0.5,
    transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
  };

  return (
    <div className="carousel" style={{ position: "relative" }}>
      {images.map((image, index) => (
        <img
          key={index}
          style={index === activeIndex ? activeImageStyle : inactiveImageStyle}
          src={image}
          alt={`image ${index}`}
        />
      ))}
    </div>
  );
};

export default EmployerCarousel;
