import React, { useState, useEffect } from "react";
import Api from '../Services/ApiCall';
import Base from '../Base';
import '../../App.css'
import '../../Css/Pagination.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { Button, Modal } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "../Admin/Admin_form/Filter/Globalfilter";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import Swal from 'sweetalert2';
import { BeatLoader } from "react-spinners";


export default function JobSeeker_table(any, i) {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);

  useEffect(() => {
    fetchDataAndSetState();
  }, []);

  const fetchDataAndSetState = async () => {
    try {
      var Param = { Mstuserregid: 0 };
      var url = "/UserReg/GetJobseekerBOpendingData";
      let _response1 = await Api.BindGrid(Param, url);
      setRowData(_response1);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const openPopup = (rowData) => {
    setSelectedRowData(rowData);
    Get(rowData.id)
    setShowPopup(true);
  };
  const openPopup1 = (rowData) => {
    setShowPopup(false);
    setShowPopup1(true);
  };

  const closePopup1 = () => {
    setSelectedRowData(null);
    setShowPopup1(false);
  };
  const closePopup = () => {
    setSelectedRowData(null);
    setShowPopup(false);
  };

  const onCellClicked = (params) => {
    openPopup({ id: params.data.Mstjobseekerid, code: params.data.Mstjobseekercode, subscribe: params.data.Subscribe });
  };

  const columnDefs = [
    // { headerName: 'Id', field: 'Mstjobseekerid', filter: 'agNumberColumnFilter', enableFilter: true },
    { headerName: 'Code', field: 'Mstjobseekercode', filter: true, enableFilter: true },
    { headerName: 'Name', field: 'Name', filter: true, enableFilter: true },
    { headerName: 'Aadhaar Number', field: 'Aadhaarnumber', filter: true, enableFilter: true },
    { headerName: 'Emailid', field: 'Email', filter: true, enableFilter: true },
    { headerName: 'District', field: 'District', filter: true, enableFilter: true },
    { headerName: 'Wallet Amount', field: 'Recharge_amount', filter: true, enableFilter: true },
    { headerName: 'CreatedStatus', field: 'CreatedStatus', filter: true, enableFilter: true },
    { headerName: 'CreatedBy', field: 'CreatedBy1', filter: true, enableFilter: true },
    { headerName: 'CreatedDate', field: 'CreatedDate' },
    { headerName: 'Status', field: 'Activestatus', filter: true, enableFilter: true },
  ];

  const [quickFilterText, setQuickFilterText] = useState('');

  const onQuickFilterChange = (event) => {
    setQuickFilterText(event.target.value);
  };

  const [aadharnumber, setAadharno] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [uploadFile, setuploadFile] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  const [email, setEmail] = useState("");
  const [qualification, setQualification] = useState([]);
  const [filename, setfilename] = useState("");
  const [plan, setPlan] = useState("");
  const [amount, setAmount] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [CountryList, setCountryList] = useState();
  const [StateList, setStateList] = useState();
  const [CityList, setCityList] = useState();
  const [selectedPlan, setselectedPlan] = useState("");
  const [selectedQualiify, setselectedQualiify] = useState([]);
  const [formFields12, setFormFields] = useState([{ companyname: '', designation: '', years: '', month: '' },])
  const [formFields, setFormField] = useState([{ qualify: '', course: '', institution: '', passedout: '' },])
  useEffect(() => {
    GetQualification()
    // GetPlandetail()
    // GetAmount()
    GetCountryList()
    GetCityList()
    GetDistrict()
  }, []);
  const handleFormChange = (event, index, F) => {

    let data = [...formFields12];
    if (F == 1) {
      data[index].companyname = event.target.value;
    } if (F == 2) {
      data[index].designation = event.target.value;
    } if (F == 3) {
      data[index].years = event.target.value;
    } if (F == 4) {
      data[index].month = event.target.value;
    }
    setFormFields(data);
  }
  const handleFormChange1 = (event, index, F) => {

    let data = [...formFields];
    if (F == 1) {
      data[index].qualify = event.target.value;
    } if (F == 2) {
      data[index].course = event.target.value;
    } if (F == 3) {
      data[index].institution = event.target.value;
    } if (F == 4) {
      data[index].passedout = event.target.value;
    }
    setFormField(data);
  }
  const submit = (e) => {
    e.preventDefault();
  }
  const addFields = () => {
    let object = { companyname: '', designation: '', years: '', month: '' }

    setFormFields([...formFields12, object])
  }
  const removeFields = (index) => {
    let data = [...formFields12];
    data.splice(index, 1)
    setFormFields(data)
  }
  const addFields1 = () => {
    let object = { qualify: '', course: '', institution: '', passedout: '' }

    setFormField([...formFields, object])
  }
  const removeFields1 = (index) => {
    let data = [...formFields];
    data.splice(index, 1)
    setFormField(data)
  }
  async function GetCountryList() {
    let _response = await Api.DirectApiCall('country', "", "")
    setCountryList(_response)
  }
  async function GetStateList(value) {
    setCountry(value)
    var Param = { country: value }
    let _response = await Api.DirectApiCall('state', value)
    setStateList(_response.states)
  }
  async function GetCityList(value) {
    setState(value)
    // var url = "/DropDown/GetCities";
    // let _response = await Api.BindDropdown(url)
    // let Arry_Title1 = [];
    // setCityList(_response)
  }
  const [district, setDistrict] = useState("");
  const [districtlist, setDistrictlist] = useState("")
  async function GetDistrict() {
    var url = "/DropDown/GetDistrict";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setDistrictlist(_response)
  }
  async function GetPlandetail() {

    var url = "/DropDown/GetPlandetail";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setPlan(_response)
  }
  async function GetAmount(e) {
    setselectedPlan(e.target.value)
    var id = (e.target.value)
    var url = "/DropDown/GetPlandetailamount?id=" + id;
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setAmount(_response)
  }
  async function GetQualification() {
    var url = "/DropDown/GetQualification";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setQualification(_response)
  }
  const uploadImage = async (e) => {
    const file = e.target.files[0];
    var filename = file.name;
    setfilename(filename)
    const base64 = await convertBase64(file);
    setuploadFile(base64);
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  async function IUD_Delete() {
    openPopup1();
  }
  async function IUDOperation() {
    var AadharNo = aadharnumber;
    var Name = name;
    var Dateofbirth = dob;
    var Mobilenumber = mobilenumber;
    var Email = email;
    var Qualification = qualification;
    var Aadhar = aadharnumber;
    var UploadFile = uploadFile;
    var SelectedQualiify = selectedQualiify;
    var Country = country;
    var State = state;
    var City = city;
    var Address = address;
    var Pincode = pincode;
    var Plan = selectedPlan;
    var Amount = amount;
    var Flag = "U"
    var ID = selectedRowData.id

    var Param = {

      "mstjobseekerid": ID,
      "name": Name,
      "dataofbirth": Dateofbirth,
      "mobilenumber": Mobilenumber,
      "qualifcation": JSON.stringify(formFields12),
      "education": JSON.stringify(formFields),
      "usertype": "string",
      "email": Email,
      "aadhaarnumber": Aadhar,
      "filedocument": UploadFile,
      "filename": "string",
      "active": "string",
      "searchField": "string",
      "flag": Flag,
      "createdBy": 0,
      "createdDate": "2022-11-28T10:02:17.804Z",
      "subscribe": "string",
      "plantype": "string",
      "planperiod": "string",
      "activestatus": "string",
      "planamount": 0,
      "mstqualifcation": SelectedQualiify,
      "country": Country,
      "state": State,
      "city": City,
      "pincode": Pincode,
      "address": Address,
      "plan": Plan,
      "district": district,


    };

    var url = "/JobSeekerTable/IUDJobSeekerTable";
    let _response = await Api.IUD(Param, url);

    showAlert(_response);
    setShowPopup(false);

  }
  async function IUDOperationDelete() {
    var AadharNo = aadharnumber;
    var Name = name;
    var Dateofbirth = dob;
    var Mobilenumber = mobilenumber;
    var Email = email;
    var Qualification = qualification;
    var Aadhar = aadharnumber;
    var UploadFile = uploadFile;
    var SelectedQualiify = selectedQualiify;
    var Country = country;
    var State = state;
    var City = city;
    var Address = address;
    var Pincode = pincode;
    var Plan = selectedPlan;
    var Amount = amount;
    var Flag = "D"
    var ID = selectedRowData.id

    var Param = {

      "mstjobseekerid": ID,
      "name": Name,
      "dataofbirth": Dateofbirth,
      "mobilenumber": Mobilenumber,
      "qualifcation": JSON.stringify(formFields12),
      "education": JSON.stringify(formFields),
      "usertype": "string",
      "email": Email,
      "aadhaarnumber": Aadhar,
      "filedocument": UploadFile,
      "filename": "string",
      "active": "string",
      "searchField": "string",
      "flag": Flag,
      "createdBy": 0,
      "createdDate": "2022-11-28T10:02:17.804Z",
      "subscribe": "string",
      "plantype": "string",
      "planperiod": "string",
      "activestatus": "string",
      "planamount": 0,
      "mstqualifcation": SelectedQualiify,
      "country": Country,
      "state": State,
      "city": City,
      "pincode": Pincode,
      "address": Address,
      "plan": Plan,
      "district": district,


    };

    var url = "/JobSeekerTable/IUDJobSeekerTable";
    let _response = await Api.IUD(Param, url);

    showAlert(_response);
    setShowPopup1(false);

  }
  const showAlert = (_response) => {
    Swal.fire({
      title: "Success",
      text: _response,
      icon: "success",
      confirmButtonText: "OK",
    });
  };
  const [file, setfile] = useState()
  const [filetype, setfiletype] = useState()
  function base64toPDF(data, types) {
    var bufferArray = base64ToArrayBuffer(data);
    var blobStore = new Blob([bufferArray]);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    var data = window.URL.createObjectURL(blobStore);
    var link = document.createElement('a');
    document.body.appendChild(link)
      ;
    link.href = data;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
  }
  function base64ToArrayBuffer(data) {
    var data1 = data.replace('data:documents/pdf;base64,', '');
    var bString = window.atob(data1);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  async function Get(id) {

    var Param = { Mstuserregid: id }
    var url = "/UserReg/GetJobseekerBOpendingData";
    let _response = await Api.BindGrid(Param, url)
    setAadharno(_response[0].Aadhaarnumber);
    setName(_response[0].Name);
    setDob(_response[0].Dataofbirth);
    setMobilenumber(_response[0].Mobilenumber);
    setEmail(_response[0].Email);
    setFormFields(JSON.parse(_response[0].Qualifcation));
    setselectedQualiify(_response[0].Mstqualifcation);
    setCountry(_response[0].Country);
    setState(_response[0].State);
    setCity(_response[0].City);
    setAddress(_response[0].Address);
    setPincode(_response[0].Pincode);
    setselectedPlan(_response[0].Plan);
    setAmount(_response[0].Planamount);
    setDistrict(_response[0].District);
    setfilename(_response[0].Filename)
    var Files = _response[0].Filedocument;
    var type = Files.split(";")[0].split('/')[1];
    var test = Files.split(",")[1];
    setfiletype(type)
    setfile(test);
    setFormField(JSON.parse(_response[0].Education));
  }

  async function GetCityValue(value) {
    setDistrict(value)
    var Param = { districtName: value, cityName: "" }
    var url = "/CommanAPI/GetCity";
    let _response = await Api.BindGrid(Param, url)
    setCityList(_response)
  }
  const [isLoading, setIsLoading] = useState(false);
  async function Sync() {
    setIsLoading(true);
    var Param =
    {
      "flag": "Jobseeker"
    }
    var url = "/UserReg/AproveBOpendingData";
    let _response = await Api.IUD(Param, url);
    setIsLoading(false);
    showAlert(_response);
  }


  return (
    <div>
      <div>

        <Base BoJobseekerApproval={true} />
        <div id="main" class="main">
          <div class="pagetitle">
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/dash">Home</Link>
                </li>

                <li class="breadcrumb-item">Services</li>
                <li class="breadcrumb-item active">Jobseeker Approval</li>
              </ol>
            </nav>
          </div>
          <input
            type="text"
            placeholder="Search..."
            onChange={onQuickFilterChange}
            style={{ marginBottom: '10px' }}
          />
          <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed' }} onClick={() => Sync()}>
            Sync
          </button>
          {isLoading ? (
            <div className="d-flex justify-content-center my-5 pt-5">
              <div className='pt-5 mt-5'>
                <BeatLoader className='mt-5' />
              </div>
            </div>
          ) : (
            <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={10}
                domLayout='autoHeight'
                floatingFilter={true}
                enableBrowserTooltips={true}
                onCellClicked={onCellClicked}
                quickFilterText={quickFilterText}
              />
            </div>
          )}
          {showPopup && (
            <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
              <Modal.Header closeButton>
                <Modal.Title> {selectedRowData.code}</Modal.Title>
                <Link to={'/Rechargeform'} state={{
                  Code: selectedRowData.code
                }}>
                  <button type="button"
                    class={selectedRowData.subscribe == "Not Paid" ? "btn btn-danger mx-5" : "btn btn-success mx-5"}
                  >Subscribe</button>
                </Link>
              </Modal.Header>
              <Modal.Body>
                <div class="row g-3">

                  <div class="col-md-6">
                    <label for="name"> Name</label>
                    <input type="text" class="form-control" value={name} placeholder="Name" onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div class="col-md-6">
                    <label for="aadharnumber">Aadhar Number</label>
                    <input type="text" class="form-control no-spinner" value={aadharnumber} placeholder="Aadhar no" onChange={(e) => setAadharno(e.target.value)} />
                  </div>

                  <div class="col-md-6">
                    <label for="dob"> Date of Birth</label>
                    <input type="date" max={new Date().toISOString().split("T")[0]} class="form-control" value={dob} placeholder="Date of birth" onChange={(e) => setDob(e.target.value)} />
                  </div>
                  <div class="col-md-6">
                    <label for="mobilenumber"> Mobile Number</label>
                    <input type="number" class="form-control no-spinner" placeholder="Mobile number" value={mobilenumber} onChange={(e) => setMobilenumber(e.target.value)} />
                  </div>

                  <div class="col-md-6">
                    <label for="email">Email Id</label>
                    <input type="email" class="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div class="col-md-6">
                    <label for="qualification"> Qualifcation</label>
                    <select id="inputState" class="form-select" value={selectedQualiify} onChange={(e) => setselectedQualiify(e.target.value)}  >
                      <option selected>Qualification</option>
                      {qualification &&
                        qualification.map((i, index) => (
                          <option value={i.id}>{i.Text}</option>
                        ))}
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label for="country">Country</label>
                    <select id="inputState" class="form-select" value={country} onChange={(e) => GetStateList(e.target.value)}>
                      <option selected>Country</option>
                      {CountryList?.map((i, index) => (
                        <option value={i.country.toLowerCase()}>{i.country}</option>
                      ))}

                    </select>
                  </div>


                  <div class="col-md-6">
                    <label for="state">State</label>
                    <select id="inputState" class="form-select" value={state} onChange={(e) => GetCityList(e.target.value)}>
                      <option selected >State</option>
                      {StateList?.map((i, index) => (
                        <option value={i.name.toLowerCase()}>{i.name}</option>
                      ))}

                    </select>
                  </div>

                  <div class="col-md-6">
                    <label for="district">District</label>
                    <select id="inputState" class="form-select" value={district} onChange={(e) => GetCityValue(e.target.value)}>
                      <option selected >District</option>
                      {districtlist &&
                        districtlist.map((i, index) => (
                          <option value={i.Text}>{i.Text}</option>
                        ))}
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label for="city">City</label>
                    <select id="inputState" class="form-select" value={city} onChange={(e) => setCity(e.target.value)}>

                      <option >City</option>
                      {CityList?.map((i, index) => (
                        <option value={i.CityName}>{i.CityName}</option>
                      ))}
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label for="address">Address</label>
                    <textarea class="form-control" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} ></textarea>
                  </div>
                  <div class="col-md-6">
                    <label for="pincode">Pincode</label>
                    <input type="number" class="form-control no-spinner" maxLength={6} placeholder="Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                  </div>

                  <div class="col-md-6">
                    <label for="attachresume">Attach Resume</label>
                    <input type="file" name="file" class="form-control" onChange={(e) => uploadImage(e)} />
                  </div>

                  {/* <div class="text-center">
                    {Api.CheckUpdate(13) == 1 ?
                      <button type="button" class="btn btn-primary" style={{ background: 'green' }} onClick={IUDOperation}>Update</button>
                      : null}
                    {Api.CheckDelete(13) == 1 ?
                      <button type="reset" class="btn btn-info" style={{ background: 'red', color: '#ffffff' }} onClick={IUD_Delete} >Delete</button>
                      : null}
                  </div> */}

                </div>
              </Modal.Body>
            </Modal>
          )}
          {showPopup1 && (
            <Modal show={true} onHide={closePopup1} >
              <Modal.Header closeButton>
                <Modal.Title> {selectedRowData.code}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Are You sure want to Delete?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closePopup1}>
                  Close
                </Button>
                <Button variant="secondary" onClick={IUDOperationDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>

      </div>
    </div>
  )
}
