import React from 'react'
import User_profile from '../../Profile/User_profile'
import Employee_profile from '../../Profile/Employee_profile'
import Employer_profile from '../../Profile/Employer_profile'
import Agent_profile from '../../Profile/Agent_profile'
import Jobseeker_profile from '../../Profile/Jobseeker_profile'

function Myprofile() {

  const checkfunction=()=>{
  if(sessionStorage.getItem('AccessType')=='User'){
    <User_profile />
  }
  else if(sessionStorage.getItem('AccessType')=='Employee'){
    <Employee_profile />
  }
  
  else if(sessionStorage.getItem('AccessType')=='Employer'){
    <Employer_profile />
  }
  else if(sessionStorage.getItem('AccessType')=='Agent'){
    <Agent_profile />
  }
  else if(sessionStorage.getItem('AccessType')=='jobseeker'){
    <Jobseeker_profile />
  }
  else{
    return false;
  }
  }

  return (
    <div>
      {/* {checkfunction()} */}
      {sessionStorage.getItem('AccessType')=='User'?
      <User_profile />:null}
      {sessionStorage.getItem('AccessType')=='Employee'?
      <Employee_profile />:null}
      {sessionStorage.getItem('AccessType')=='Employer'?
      <Employer_profile />:null}
      {sessionStorage.getItem('AccessType')=='Agent'?
      <Agent_profile />:null}
      {sessionStorage.getItem('AccessType')=='jobseeker'?
      <Jobseeker_profile />:null}
    </div>
  )
}

export default Myprofile
