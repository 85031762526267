import React, {useState, useEffect}  from 'react'
//import  ''
import Base from '../Base'
import { Link } from "react-router-dom";
import Api from '../Services/ApiCall';
// import ReactPaginate from "react-paginate";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "./Admin_form/Filter/Globalfilter";
import '../../Css/Pagination.css'
function Table({ columns, data }) {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        useGlobalFilter, useSortBy, usePagination
    );
    const { globalFilter } = state

    
    // useEffect(() => {
    //     BindGrid()
    // }, []);

    // const [listdata, setlistdata] = useState([]);

    // async function BindGrid(id) {
    //     var Param = {Mstbrokercommissioncreationid: id }
    //     var url = "/Broker/BindBroker";
    //     let _response = await Api.BindGrid(Param, url)
    //    // //console.log(_response,"res")
    //     setlistdata(_response)
    //   }
    return (
        <div>
            <Globalfilter className="form-control" filter={globalFilter} setFilter={setGlobalFilter} />
            <table class="table table-striped" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}  >
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="fa-fa-sort-asc"></i>
                                                : <i className="fa-fa-sort-desc"></i>
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td  {...cell.getCellProps()} className={` ${cell.column.className ?? ""}`}>
                                            {cell.render("Cell")}</td>

                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <br></br>
            <div className="pagination">
            <nav class="paginate page_numbers" aria-label="Page navigation">
            </nav>
            <ul class="pagination first_last">
    
    <li class="page-item"><a class="page-link"  onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
         {"First"}</a>
     </li>{" "}
     <li class="page-item"><a class="page-link"  onClick={() => previousPage()} disabled={!canPreviousPage}>
         {"<"}</a>
     </li>{" "}
     <li class="page-item"><a class="page-link"  onClick={() => nextPage()} disabled={!canNextPage}>
         {">"}</a>
     </li>{" "}
     <li class="page-item"><a class="page-link"  onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
         {"Last"}</a>
     </li>{" "}</ul>
     
     <span className="pageno">
         Page{" "}
         <strong>
             {pageIndex + 1} of {pageOptions.length}
         </strong>{" "}
     </span>
     <div className='showpagesize' >
                Show:{" "}
                <select
                className='showpagecss'
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[2, 10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select></div>
            </div>
        </div>
    );
}
export default function Brokercommission(any, i) {

    const [listdata, setlistdata] = useState([]);
    const [columns,setcolumns] = useState([

        {
            Header: "ID",  Cell: (row) => {
                return <div>{Number(row.row.id) + 1}</div>;
              },
        },
        {
            Header: "Creation Detail", accessor: "Creationdetail",
        },
        {
            Header: "Commision", accessor: "Commission",
        },
        {
            Header: "Package update Commision", accessor: "Packageupdatecommission",
        },
        {
            Header: "BrokerCommission", accessor: "Brokercommissioncreationcode",
        },
        {
            Header: "Actions", Cell: (row) => {
                return <div>{<nav>

                    <ol class="breadcrumb">
                          {Api.CheckUpdate(22) == 1 ?
                        <li class="breadcrumb-item"> 
                        <Link to={'/brokercommisioncreationform'} state={{
                                ID: row.data[row.row.id].Mstbrokercommissioncreationid, Flag:'U' }}>
                           <i class="bi bi-pencil-square" />
                           </Link>
                           </li>
                           :null}
          {Api.CheckDelete(22) == 1 ?
                        <li class="breadcrumb-item active">
                        <Link to={'/brokercommisioncreationform'} state={{
                                ID: row.data[row.row.id].Mstbrokercommissioncreationid, Flag:'D' }}>
                            <i class="bi bi-trash" />
                            </Link>
                            </li>
                            :null}
                    </ol>
                </nav>}</div>;
              },
            }

        ])
    
        useEffect(() => {
            bindGrid(0)
        }, []);
    
        async function bindGrid(id) {
            var Param = { mstbrokercommisioncreationid: id }
            var url = "/Broker/BindBroker";
            let _response = await Api.BindGrid(Param, url)
           // //console.log(_response)
            setlistdata(_response)
        }
    
      return (
        <div>
            <Base Brokercommission={true} />
              <div id="main" class="main">
                  <div class="pagetitle">
                      {/* <h1>Tables</h1> */}
                      <nav>
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                                </li>
                                
                              <li class="breadcrumb-item">Commision</li>
                               <li class="breadcrumb-item active">Broker Commission</li> 
                          </ol>
                      </nav>
                  </div>
              <div class="col-lg-12">
    
                  <div class="card">
                      <div class="card-body">
                          <h5 class="card-title">Brokercommission</h5>
                          {Api.CheckAdd(22) == 1 ?
                              <button type="button" class="btn btn-primary Add">
                              <Link to={'/brokercommisioncreationform'}
                                       state={{ ID: 0,Flag:'I' }}>
                                   Add
                                  </Link>
                              </button>
                              :null}
                              
                          <Table columns={columns} data={listdata} />
                          
                  </div>
              </div>
</div>
    </div>


    </div>
  )
}


//   return (
//     <div>
//          <Base Brokercommission={true}/>
//           <div id="main" class="main">
//               <div class="pagetitle">
//                   <h1>Tables</h1>
//                   <nav>
//                       <ol class="breadcrumb">
//                           <li class="breadcrumb-item">
//                             <Link to="/dash">Home</Link>
//                             </li>
                            
//                           <li class="breadcrumb-item">Admin</li>
//                           {/* <li class="breadcrumb-item active">General</li> */}
//                       </ol>
//                   </nav>
//               </div>
//           <div class="col-lg-12">

//               <div class="card">
//                   <div class="card-body">
//                       <h5 class="card-title">Broker Commision Creation Table</h5>
//                        {/* {Api.checkmenu(13)[0]['Add']==1? */}
//                           <button type="button" class="btn btn-primary Add">
//                               <Link to={'/brokercommisioncreationform'}
//                                    state={{ ID: 0,Flag:'I' }}>
//                                Add
//                               </Link>
//                           </button>
//                           {/* :null} */}

                     
//                       <table class="table table-striped">
//                           <thead>
//                               <tr>
//                                   <th scope="col">SNo</th>
//                                   <th scope="col">Creation Details</th>
//                                   <th scope="col">Commission</th>
//                                   <th scope="col">Package update commission</th>
//                                   <th scope="col">Broker Commission</th>
//                                   <th scope="col">Action</th>
//                               </tr>
//                           </thead>
//                           <tbody>
//                          {listdata   &&
//                           listdata.map((i, index) => (                            
//                                   <tr key={index}>
//                                       <th scope="row">{index+1}</th>
                            
//                                       <td>{i.Creationdetail}</td>  
//                                       <td>{i.Commission}</td>                                  
//                                       <td>{i.Packageupdatecommission}</td>
//                                       <td>{i.Brokercommissioncreationcode}</td>
                                    
                                      
                                      

//                                        <td>
//                                          <nav>

//                                               <ol class="breadcrumb">
//                                                   <li class="breadcrumb-item">
//                                                       <Link to={'/brokercommisioncreationform'} state={{
//                                                           ID: i.Mstbrokercommissioncreationid, Flag: 'U' }}>
//                                                           <i class="bi bi-pencil-square" /> </Link> </li>
//                                                   <li class="breadcrumb-item active">
//                                                       <Link to={'/brokercommisioncreationform'} state={{
//                                                           ID: i.Mstbrokercommissioncreationid, Flag: 'D' }}>
//                                                           <i class="bi bi-trash" /></Link></li>
//                                               </ol>
//                                           </nav>
//                                       </td>
                                  
                               
//                                   </tr>
//                               ))} 
                              
//                             </tbody>
//                                                </table>  

//                   </div>
//               </div>
// </div>
//     </div>



//     </div>
//   )
// }
