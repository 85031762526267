import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { Images } from '../Images/Index';
import { useNavigate } from "react-router-dom";
import Api from "./Services/ApiCall";
import "../Css/Signin.css"
import LoginBase from './SigninBase';
import Footer from './Footer';

export default function Newlogin() {
    sessionStorage.clear()
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [Jobseeker, setJobseeker] = useState(false)
    const [place, setLocation] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [emailid1, setEmailid] = useState("");
    const [contactnumber, setContactnumber] = useState("");
    useEffect(() => {
        CompanyGet()
    }, []);
    async function CompanyGet(id) {
        var Param = { Mstcompanyprofileid: 3 }
        var url = "/Company/BindCompany";
        let _response = await Api.BindGrid(Param, url)
        setCompanyname(_response[0].Companyname);
        setLocation(_response[0].Location);
        setEmailid(_response[0].Emailid);
        setContactnumber(_response[0].Phonenumber);
    }
    async function Login() {
        if (email == "" || password == "") {
            toast.error('Enter Email or Password');
            return false;
        }
        var _Jobseeker = Jobseeker ? "jobseeker" : "";
        sessionStorage.clear()
        var Param = { "mstuserregid": 0, "emailid": email, "password": password, "AccessType": _Jobseeker }
        var url = "/CommanAPI/LoginCheck";
        let _response = await Api.CheckLogin(Param, url)
        if (_response == "[]") {
            toast.error('Invalid Email or Password');
            return false;
        }
        if (_Jobseeker == 'jojbseeker') {
            if (_response.length > 0) {
                const myJSON = JSON.parse(_response);
                sessionStorage.setItem("userdetails", _response)
                sessionStorage.setItem("username", myJSON[0].Name)
                sessionStorage.setItem("MUserID", myJSON[0].Mstjobseekerid)
                sessionStorage.setItem("roleaUsertypeccess", 'jobseeker')
                sessionStorage.setItem("AccessType", 'jobseeker')
                sessionStorage.setItem("Email", myJSON[0].Email)
                navigate('/Jobseekerdashboard')
            }
            else {
                toast.error('Invalid Email or Password');
                return false;
            }
        } else {
            if (_response.length > 0) {
                const myJSON = JSON.parse(_response);
                sessionStorage.setItem("userdetails", _response)
                try {
                    let str = myJSON[0].Rolevscab;
                    str = str.replaceAll("Mstmenuid", "MenuID")
                    sessionStorage.setItem("roleaccess", str)
                } catch {
                    sessionStorage.setItem("roleaccess", myJSON[0].Rolevscab)
                }
                if (myJSON[0].Rolecab == "Employer") {
                    sessionStorage.setItem("username", myJSON[0].Name)
                    sessionStorage.setItem("MUserID", myJSON[0].Mstuserregid)
                    sessionStorage.setItem("roleaUsertypeccess", myJSON[0].UsertypeName)
                    sessionStorage.setItem("AccessType", myJSON[0].Rolecab)
                    sessionStorage.setItem("Email", myJSON[0].Email)
                    navigate('/Employerdashboard')
                }
                else if (myJSON[0].Rolevscab == "jobseeker") {
                    sessionStorage.setItem("username", myJSON[0].Name)
                    sessionStorage.setItem("MUserID", myJSON[0].Mstjobseekerid)
                    sessionStorage.setItem("roleaUsertypeccess", 'jobseeker')
                    sessionStorage.setItem("AccessType", 'jobseeker')
                    sessionStorage.setItem("Email", myJSON[0].Email)
                    navigate('/Jobseekerdashboard')
                }
                else {
                    sessionStorage.setItem("username", myJSON[0].Name)
                    sessionStorage.setItem("MUserID", myJSON[0].Mstuserregid)
                    sessionStorage.setItem("roleaUsertypeccess", myJSON[0].UsertypeName)
                    sessionStorage.setItem("AccessType", myJSON[0].Rolecab)
                    sessionStorage.setItem("Email", myJSON[0].Email)
                    navigate('/dash')
                }
            }
            else {
                toast.error('Invalid Email or Password');
                return false;
            }
        }
    }
    const [showPassword, setShowPassword] = useState(false);
    return (
        <div>
            <LoginBase />
            <Toaster toastOptions={{ position: "top-right" }} />
            <section class="login-block">
                <div class="container">
                    <div class="empcard1">
                        <div style={{ marginTop: "20px" }}>
                            <div class="row loginrow">
                                <div class="col-md-4 login-sec ">
                                    <div >
                                        <div className="Auth-form-content">
                                            <h3 className="Auth-form-title">
                                                <h4 class="login_letter">Login</h4>
                                            </h3>
                                            <div className="form-group mt-3">
                                                <label>User Code</label>
                                                <input
                                                    type="text"
                                                    className="form-control mt-1"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Enter User Code" />
                                            </div>
                                            <div className="form-group mt-3">
                                                <label>Password</label>
                                                <input
                                                    required
                                                    type={showPassword ? "text" : "password"}
                                                    className="form-control mt-1"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Enter password" />
                                                <i className={showPassword ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"} onClick={() => setShowPassword(!showPassword)}></i>
                                            </div>
                                            <div class="row mb-4 mt-4 gy-4">
                                                <div class="col-md-6">
                                                    <button type="button" className="btn btn-primary loginbtn" onClick={Login}>
                                                        Submit
                                                    </button>
                                                </div>
                                                <div class="col-lg-6">
                                                    <button type="button" class="btn btn-primary btnsignin loginbtn">
                                                        <Link to={'/Signup'}
                                                            state={{ ID: 0, Flag: 'I' }} style={{ textDecoration: 'none' }}>
                                                            Register
                                                        </Link>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row mb-4">
                                                <div class="col-lg-6">
                                                    <a class="a-forgot">
                                                        <Link to='/forgotpassword'>
                                                            Forgot Password?
                                                        </Link></a>
                                                </div>
                                                <div class="col-lg-6">
                                                    <a class="a-forgot" style={{ float: 'right' }}>
                                                        <Link to='/Agentrequestform' state={{ ID: 0, Flag: 'I' }}>
                                                            Partner Request
                                                        </Link>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="emplogbtn" style={{ textAlign: 'center' }}>
                                            <a >
                                                <Link to='/Employeelogin' state={{ ID: 0, Flag: 'I' }}>
                                                    Employee Login
                                                </Link></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8 banner-sec">
                                    <div class="signin-img">
                                        <img class="d-block sign-img-1" src="https://static.pexels.com/photos/33972/pexels-photo.jpg" alt="First slide" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '30px' }}></div>
                        <div></div>
                    </div>
                </div>
                <div class="mt-4">
                    <Footer />
                </div>
            </section>
        </div>
    )
}
