import React, { useState, useEffect } from 'react';
import Base from './Base';
import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import Api from './Services/ApiCall';

export default function Agent_Total_payment(any, i) {
    const [rowData, setRowData] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        fetchDataAndSetState();
    }, []);

    const fetchDataAndSetState = async () => {
        try {
            var Param = {
                
                    "mstpaymentid": sessionStorage.getItem("MUserID"),
                    "paymentcode": "string",
                    "paymentid": "string",
                    "paymenttype": "string",
                    "usertype": "string",
                    "userid": "string",
                    "approveusertype": "string",
                    "approveuserid": "string",
                    "plan": "string",
                    "months": "string",
                    "expiremonth": "string",
                    "amount": "string",
                    "active": "string",
                    "searchField": "string",
                    "createdBy": 0,
                    "createdDate": "2024-01-26T09:51:27.958Z",
                    "flag": "string"
                  
              }
            let url = "/Payment/BindTotalPayment_type";
            let _response1 = await Api.BindGrid(Param, url)
            setRowData(_response1);
            
            const total = _response1.reduce((acc, item) => acc + parseFloat(item.Amount || 0), 0);
            setTotalAmount(total);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const columnDefs = [
        // { headerName: 'Id', field: 'Mstpaymentid', filter: 'agNumberColumnFilter', enableFilter: true },
        { headerName: 'Bill Number', field: 'Paymentcode', filter: true, enableFilter: true },
        { headerName: 'Payment Mode', field: 'Paymenttype', filter: true, enableFilter: true },
        { headerName: 'Userid', field: 'Userid', filter: true, enableFilter: true },
        { headerName: 'Plan Type', field: 'Plan', filter: true, enableFilter: true },
        { headerName: 'Amount', field: 'Amount', filter: true, enableFilter: true },
    ];

    return (
        <div>
            <Base/>
            <div id="main" class="main">
                <div class="pagetitle">
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>
                            <li class="breadcrumb-item active">Total payment list</li>
                        </ol>
                    </nav>
                </div>
                <h4>Total Amount: {totalAmount}</h4>
                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={10}
                        domLayout='autoHeight'
                        floatingFilter={true}
                        enableBrowserTooltips={true}
                    />
                </div>
                
            </div>
        </div>
    );
}
