import React, { useState, useEffect } from 'react'
import Base from '../../Base';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Api from '../../Services/ApiCall';
import Swal from 'sweetalert2';


export default function Branchperformanceform() {
    const location = useLocation();
    const { ID, Flag } = location.state;
    ////console.log('%%%', ID, Flag);
    const navigate = useNavigate();

    useEffect(() => {

        if (ID != 0) {
            Get(ID);
        }

    }, []);



    const [branchgrade, setBranchgrade] = useState("");
    const [locate, setLocation] = useState("");
    const [register, setRegister] = useState("");
    const [processing, setProcessing] = useState("");
    const [approved, setApproved] = useState("");
    const [reject, setReject] = useState("");

    async function IUDOperation() {


        var Branchgrade = branchgrade;
        var Locate = locate;
        var Register = register;
        var Processing = processing;
        var Approved = approved;
        var Reject = reject;

        if (Branchgrade == "") {
            toast.error('Please Choose Branch Grade')
            return false;
        }
        if (Locate == "") {
            toast.error('Please Enter Branch Location')
            return false;
        }
        if (Register == "") {
            toast.error('Please Enter Register')
            return false;
        }
        if (Processing == "") {
            toast.error('Please Enter Processing')
            return false;
        }
        if (Approved == "") {
            toast.error('Please Enter Approved')
            return false;
        }
        if (Reject == "") {
            toast.error('Please Enter Reject')
            return false;
        }

        var Param = {
            "mstbranchperformancereportid": ID,
            "ebranchperformancereportcode": "string",
            "selectbranchgrade": Branchgrade[0].selectbranchgrade,
            "branchlocation": Locate,
            "register": Register,
            "approved": Approved,
            "processing": Processing,
            "reject": Reject,
            "active": "string",
            "searchField": "string",
            "flag": Flag,
            "createdBy": 0,
            "createdDate": "2022-11-29T11:23:47.330Z"
        };
        var url = "/BranchPerformance/IUDBranch";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setTimeout(() => {
            navigate('/branchperformancetable')
        }, 2000)

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function Get(id) {
        var Param = { mstbranchperformancereportid: ID }
        var url = "/BranchPerformance/BindBranch";
        let _response = await Api.BindGrid(Param, url)

        setBranchgrade(_response[0].Selectbranchgrade)
        setLocation(_response[0].Branchlocation)
        setRegister(_response[0].Register)
        setApproved(_response[0].Approved)
        setProcessing(_response[0].Processing)
        setReject(_response[0].Reject)


    }




    return (
        <div>
            <Base Branchperformance={true} />
            <div id="main" class="main">
                <div class="pagetitle">

                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">Report</li>
                            <li class="breadcrumb-item active">Branch Performance</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Branch Performance Report</h5>


                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="branchgrade">Branch Grade</label>
                                <select id="inputState" class="form-select" value={branchgrade} onChange={(e) => setBranchgrade(e.target.value)}>
                                    <option selected>Select Branch Grade</option>
                                    <option>BO/01</option>
                                    <option>BO/02</option>
                                    <option>BO/03</option>
                                    <option>BO/04</option>
                                    <option>BO/05</option>
                                    <option>BO/06</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="location">Branch Location</label>
                                <input type="text" class="form-control" placeholder="Branch Location" value={locate} onChange={(e) => setLocation(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="register">Register</label>
                                <input type="text" class="form-control" placeholder="Register" value={register} onChange={(e) => setRegister(e.target.value)} />
                            </div>

                            <div class="col-6">
                                <label for="processing">Processing</label>
                                <input type="text" class="form-control" placeholder="Processing" value={processing} onChange={(e) => setProcessing(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="appproved">Approved</label>
                                <input type="text" class="form-control" placeholder="Approved" value={approved} onChange={(e) => setApproved(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="reject">Reject</label>
                                <input type="text" class="form-control" placeholder="Reject" value={reject} onChange={(e) => setReject(e.target.value)} />
                            </div>


                            <div class="text-center">
                                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                                <button type="reset" class="btn btn-info">
                                    <Link to='/branchperformancetable'>
                                        Back
                                    </Link>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>


            </div>

        </div>
    )
}
