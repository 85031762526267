import React, { useEffect, useState } from 'react'
import Base from '../Base';
import Api from '../Services/ApiCall';
import ReactPaginate from "react-paginate";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "../Admin/Admin_form/Filter/Globalfilter";
import '../../Css/Pagination.css'
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';


export default function Rolecreationgrid() {
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup1, setShowPopup1] = useState(false);
    const [role_for, setRole_For] = useState("");

    useEffect(() => {
        fetchDataAndSetState();
    }, []);

    const fetchDataAndSetState = async () => {
        try {
            var Param = { mstroleid: 0 }
            var url = "/Role/BindRole";
            let _response1 = await Api.BindGrid(Param, url);
            setRowData(_response1);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const openPopup = (rowData) => {
        setSelectedRowData(rowData);
        Get(rowData.id)
        setShowPopup(true);
    };
    const openPopup1 = (rowData) => {
        setShowPopup(false);
        setShowPopup1(true);
    };

    const closePopup1 = () => {
        setSelectedRowData(null);
        setShowPopup1(false);
    };
    const closePopup = () => {
        setSelectedRowData(null);
        setShowPopup(false);
    };

    const onCellClicked = (params) => {
        openPopup({ id: params.data.Mstroleid, code: params.data.Rolename });
    };

    const columnDefs = [
        // { headerName: 'Id', field: 'Mstroleid', filter: 'agNumberColumnFilter', enableFilter: true },
        { headerName: 'Role for', field: 'Rolecode', filter: true, enableFilter: true },
        { headerName: 'Role', field: 'Rolename', filter: true, enableFilter: true },
    ];

    const [quickFilterText, setQuickFilterText] = useState("");

    const onQuickFilterChange = (event) => {
        setQuickFilterText(event.target.value);
    };



    async function IUD_Delete() {
        openPopup1();
    }


    const [role, setRole] = useState("");

    async function IUDOperation() {
        var Role = role;

        var ID = selectedRowData.id;

        var Param = {
            "mstroleid": ID,
            "rolename": Role,
            "rolecode": role_for,
            "roleList": JSON.stringify(ListMenu),
            "flag": "U"
        };
        var url = "/Role/IUDRole";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup(false);
        fetchDataAndSetState();
        // setTimeout(() => {
        //     navigate('/Rolecreationgrid')
        // }, 1000)

    }
    async function openPopupDelete() {
        var Role = role;

        var ID = selectedRowData.id;

        var Param = {
            "mstroleid": ID,
            "rolename": Role,
            // "roleList": JSON.stringify(ListMenu),
            "flag": "D"
        };
        var url = "/Role/IUDRole";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup1(false);
        fetchDataAndSetState();
        // setTimeout(() => {
        //     navigate('/Rolecreationgrid')
        // }, 1000)

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function Get(id) {
        var Param = { Mstroleid: id }
        var url = "/Role/BindRole";
        let _response1 = await Api.BindGrid(Param, url);
        setRole_For(_response1[0].Rolecode);
        setRole(_response1[0].Rolename);
        setListMenu(JSON.parse(_response1[0].RoleList));
    }

    const [ListMenu, setListMenu] = useState([]);
    async function GetMenu(id) {
        var Param = { mstmenuid: id }
        var url = "/Menu/BindMenu";
        let _response = await Api.BindGrid(Param, url)
        setListMenu(_response);
    }

    const handleOnChange = (event, option, index, F) => {

        const values = [...ListMenu];
        if (event.target.checked) {
            if (F === "V") {
                values[index].View = 1;
            } if (F === "A") {
                values[index].Add = 1;
            } if (F === "E") {
                values[index].Edit = 1;
            } if (F === "D") {
                values[index].Delete = 1;
            } if (F === "P") {
                values[index].Print = 1;
            }
        }
        else {
            if (F === "V") {
                values[index].View = 0;
            } if (F === "A") {
                values[index].Add = 0;
            } if (F === "E") {
                values[index].Edit = 0;
            } if (F === "D") {
                values[index].Delete = 0;
            } if (F === "P") {
                values[index].Print = 0;
            }
        }
        setListMenu(values);
    };

    return (
        <div>
            <div>
                <Base Rolecreationgrid={true} />
                <div id="main" class="main">
                    <div class="pagetitle">
                        {/* <h1>Tables</h1> */}
                        <nav>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <Link to="/dash">Home</Link>
                                </li>

                                <li class="breadcrumb-item">General Setting</li>
                                <li class="breadcrumb-item">Role</li>
                                {/* <li class="breadcrumb-item active">General</li> */}
                            </ol>
                        </nav>
                    </div>
                    <input
                        type="text"
                        placeholder="Search..."
                        onChange={onQuickFilterChange}
                        style={{ marginBottom: '10px' }}
                    />
                    {Api.CheckAdd(4) == 1 ?
                        <>
                            <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed' }}>
                                <Link to={'/rolecreationform'}
                                    state={{ ID: 0, Flag: 'I' }}
                                    style={{ color: "white" }}
                                >
                                    Add
                                </Link>
                            </button>
                        </>
                        : null}
                    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            pagination={true}
                            paginationPageSize={10}
                            domLayout='autoHeight'
                            floatingFilter={true}
                            enableBrowserTooltips={true}
                            onCellClicked={onCellClicked}
                            quickFilterText={quickFilterText}
                        />
                    </div>
                    {showPopup && (
                        <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
                            <Modal.Header closeButton>
                                <Modal.Title> {selectedRowData.code}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div class="row g-3">

                                    <div class="col-md-6">
                                        <label for="role">Role For</label>
                                        <select class="form-select" value={role_for} onChange={(e) => setRole_For(e.target.value)}>
                                            <option>Select</option>
                                            <option>Agent</option>
                                            <option>Employee</option>
                                        </select>
                                    </div>

                                    <div class="col-md-6">
                                        <label for="role">Role</label>
                                        <input type="text" class="form-control" placeholder="Role" value={role} onChange={(e) => setRole(e.target.value)} />
                                    </div>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Menu Name</th>
                                                <th scope="col">Rights</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ListMenu.map((i, index) => (
                                                <tr>
                                                    <td>{i.Menuname}</td>
                                                    <td>
                                                        <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "V")} checked={i.View === 1} value="" id={"View_" + i.Mstmenuid + "_"} />View
                                                        <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "A")} checked={i.Add === 1} value="" id={"Add_" + i.Mstmenuid + "_"} />Add
                                                        <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "E")} checked={i.Edit === 1} value="" id={"Edit_" + i.Mstmenuid + "_"} />Edit
                                                        <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "P")} checked={i.Print === 1} value="" id={"Print_" + i.Mstmenuid + "_"} />Print
                                                        <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "D")} checked={i.Delete === 1} value="" id={"Delete_" + i.Mstmenuid + "_"} /> Delete
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody >
                                    </table >
                                    <div class="text-center">
                                        {Api.CheckUpdate(4) == 1 ?
                                            <button type="button" class="btn btn-primary" onClick={IUDOperation}>Update</button>
                                            : null}
                                        {Api.CheckDelete(4) == 1 ?
                                            <button type="reset" class="btn btn-info" onClick={IUD_Delete} >Delete</button>
                                            : null}
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    )}
                    {showPopup1 && (
                        <Modal show={true} onHide={closePopup1} >
                            <Modal.Header closeButton>
                                <Modal.Title> {selectedRowData.code}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are You sure want to Delete?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={closePopup1}>
                                    Close
                                </Button>
                                <Button variant="secondary" onClick={openPopupDelete}>
                                    Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}
                </div>
            </div>
        </div>
    )
}
