import React, { useState, useEffect } from 'react';
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import { Link } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

export default function PayoutTable() {
    const [rowData, setRowData] = useState([]);
    const [quickFilterText, setQuickFilterText] = useState("");

    useEffect(() => {
        fetchDataAndSetState();
    }, []);

    const fetchDataAndSetState = async () => {
        try {
            var Param = { mstpayoutid: 0, flag: sessionStorage.getItem('AccessType') };
            var url = "/CommanAPI/BindPayout";
            let _response1 = await Api.BindGrid(Param, url);
            setRowData(_response1);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const columnDefs = [
        { headerName: 'Agent Code', field: 'AgentCode', filter: true, enableFilter: true },
        { headerName: 'Amount', field: 'Amount', filter: true, enableFilter: true },
        { headerName: 'Status', field: 'Status', filter: true, enableFilter: true },
        { headerName: 'Created Date', field: 'CreatedDate', filter: true, enableFilter: true },
    ];

    const onQuickFilterChange = (event) => {
        setQuickFilterText(event.target.value);
    };

    const isAddButtonEnabled = () => {
        const currentDay = new Date().getDate();
        return (currentDay >= 1 && currentDay <= 3) || (currentDay >= 15 && currentDay <= 17);
    };

    return (
        <div>
            <Base />
            <div id="main" className="main">
                <div className="pagetitle">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>
                            <li className="breadcrumb-item">Payout</li>
                            <li className="breadcrumb-item active">Payout Table</li>
                        </ol>
                    </nav>
                </div>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={onQuickFilterChange}
                    style={{ marginBottom: '10px' }}
                />
                <button type="button" className="btn btn-primary Add" 
                        style={{ background: '#0782d0ed' }} 
                        disabled={!isAddButtonEnabled()}>
                    <Link to={'/PayoutForm'}
                          state={{ ID: 0, Flag: 'I' }}
                          style={{ color: "white", textDecoration: 'none' }}>
                        Add
                    </Link>
                </button>
                <h6 style={{ color: 'red', marginTop: '10px' }}>
                    Note: The Add option is enabled only on the 1st to 3rd and 15th to 17th of each month.
                </h6>

                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={10}
                        domLayout='autoHeight'
                        floatingFilter={true}
                        enableBrowserTooltips={true}
                        quickFilterText={quickFilterText}
                    />
                </div>
            </div>
        </div>
    );
}
