import React,{useState,useEffect} from 'react'
import Base from '../../Base';
import { Link , useLocation,useNavigate} from "react-router-dom";
import Api from '../../Services/ApiCall';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';

export default function Email_edit_form() {

    const navigate=useNavigate();
    const location = useLocation();
    const { ID,Flag ,Usertype} = location.state;
 
    useEffect(() => {
        if (ID != 0) {
        //    Get(ID)
                }
    }, []);

    const [code, setCode] = useState("");
    const [email, setEmail] = useState("");


   

   async function IUDOperation(){
       setTimeout(()=>{
        navigate('/Expenses_table')
       },1000)
    
   }
   const showAlert = (_response) => {
    Swal.fire({
        title: "Success",
        text: _response,
        icon: "success",
        confirmButtonText: "OK",
    });
};





  return (
    <div>
          <div>
          <Toaster toastOptions={{ position: "top-right" }} />
              <Base  />
              <div id="main" class="main">
                  <div class="pagetitle">
                      {/* <h1>Form</h1> */}
                      <nav>
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                              <li class="breadcrumb-item">Email Edit</li>
                          </ol>
                      </nav>
                  </div>

                  <div class="card">
                      <div class="card-body">
                          <h5 class="card-title">Email Edit</h5>


                          <div class="row g-3">
                         
                          <div class="col-md-6">
                          <label for="code">Code</label>
                              <input type="text" class="form-control " value={code} placeholder="Code" onChange={(e) => setCode(e)} />
                          </div>
                          <div class="col-md-6">
                          <label for="email">Email</label>
                              <input type="text" class="form-control"  value={email} placeholder="Email"  onChange={(e) => setEmail(e.target.value)}/>
                          </div>
                         
                       
                              <div class="text-center">
                                  <button type="button" class="btn btn-primary"onClick={()=>IUDOperation()}>Submit</button>
                                  <button type="reset" class="btn btn-info"><Link to="/Email_edit_table">Back</Link></button>
                              </div>
                          </div>

                      </div>
                  </div>


              </div>
          </div>

    </div>
  )
}
