import React, { useState, useEffect } from 'react'
import { Images } from '../../../Images/Index'
import Api from '../../Services/ApiCall';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import CSS from "../../../App.css";
import LoginBase from '../../SigninBase';

export default function Employeelogin() {

    const navigate = useNavigate();
    const location = useLocation();
    const { ID, Flag } = location.state;

    const [employeeid, setEmployeeID] = useState("")
    const [password, setPassword] = useState("")

    async function IUDOperation() {

        var Employeeid = employeeid;
        var Password = password;

        if (Employeeid == "") {
            toast.error("Please Enter Employee Id");
            return false;
        }
        if (Password == "") {
            toast.error("Please Enter Password");
            return false;
        }
        var Param = {
            "mstuserregid": 0,
            "msusertregcode": Employeeid,
            "password": Password,
            "rolecab": "Employee",
        }
        var url = "/CommanAPI/EmployeeLogin";
        let _response = await Api.CheckLogin(Param, url)

        if (_response.length > 0) {
            const myJSON = JSON.parse(_response);
            sessionStorage.setItem("userdetails", _response)
            try {

                let str = myJSON[0].Rolevscab;
                str = str.replaceAll("Mstmenuid", "MenuID")
                sessionStorage.setItem("roleaccess", str)
            } catch {

                sessionStorage.setItem("roleaccess", myJSON[0].Rolevscab)
            }

            sessionStorage.setItem("username", myJSON[0].Name)
            sessionStorage.setItem("MUserID", myJSON[0].Mstuserregid)
            sessionStorage.setItem("roleaUsertypeccess", myJSON[0].UsertypeName)
            sessionStorage.setItem("AccessType", myJSON[0].Rolecab)
            sessionStorage.setItem("Email", myJSON[0].Email)

            navigate('/dash')
        }
        if (_response == "[]") {
            toast.error('Invalid ID');
            return false;
        }
    }



    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            {/* <div className="Auth-form-container backgroundimg"
                style={{ backgroundImage: `url(${Images.backgroundimg})` }}>
                <Toaster toastOptions={{ position: "top-right" }} />
                <div className="Auth-form">
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title">
                            <img src={Images.mainlogo} alt="pelago" className="loginlg_img" /></h3>
                        <div class="col-md-12">
                            <label for="name">Employee ID</label>
                            <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Employee ID" value={employeeid} onChange={(e) => setEmployeeID(e.target.value)} />
                        </div>
                        <div className="form-group mt-3">
                            <label>Password</label>
                            <input required type="password" className="form-control mt-1" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Enter password" />
                        </div>

                        <div class="text-center agentrequestbtn">
                            <button type="submit" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                            <button type="reset" class="btn btn-info">
                                <Link to='/'>
                                    Back
                                </Link>
                            </button>
                        </div>
                    </div>

                </div>
            </div> */}
            <div>
                <LoginBase />
                <Toaster toastOptions={{ position: "top-right" }} />
                <section class="login-block">
                    <div class="container">
                        <div class="empcard1">
                            <div style={{ marginTop: "20px" }}>
                                <div class="row loginrow">
                                    <div class="col-md-4 login-sec ">
                                        <div className="Auth-form-content forget">
                                            <h3 className="Auth-form-title">
                                                <h4 class="login_letter">Employee Login</h4>
                                            </h3>
                                            {/* <img src={Images.mainlogo} alt="pelago" className="loginlg_img" /></h3> */}
                                            <div style={{marginTop: "30%"}}>
                                                <div className="form-group mt-3">
                                                    <label for="name">Employee ID</label>
                                                    <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Employee ID" value={employeeid} onChange={(e) => setEmployeeID(e.target.value)} />
                                                </div>
                                                <div className="form-group mt-3">
                                                    <label>Password</label>
                                                    <input required type={showPassword ? "text" : "password"} className="form-control mt-1" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Enter password" />
                                                    <i className={showPassword ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"} onClick={() => setShowPassword(!showPassword)}></i>
                                                </div>

                                                <div className="form-group mt-3">
                                                    <button type="button" className="btn btn-primary" onClick={IUDOperation} >
                                                        Submit
                                                    </button>
                                                    <button type="button" class="btn btn-info">
                                                        <Link to='/' class="backbuttonclr">
                                                            Back
                                                        </Link>
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-8 banner-sec">
                                        <div class="signin-img">
                                            <img class="d-block sign-img-1" src="https://images.pexels.com/photos/3182773/pexels-photo-3182773.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="First slide" />
                                            {/* <img class="d-block sign-img-1" src="https://images.pexels.com/photos/3760071/pexels-photo-3760072.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="First slide" /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
