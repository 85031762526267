import React,{useState, useEffect} from 'react'
import Base from '../Base'
import { Link } from "react-router-dom";
import Api from '../Services/ApiCall';
import ReactPaginate from "react-paginate";

export default function Role() {

    const [listdata, setlistdata] = useState([]);
    const [number, setNumber] = useState(1); // No of pages
    const [postPerPage] = useState(10);
  
  
    const lastPost = number * postPerPage;
    const firstPost = lastPost - postPerPage;
    const listdata1 = listdata.slice(firstPost, lastPost);
    const PageCount = Math.ceil(listdata.length / postPerPage);
    const ChangePage = ({ selected }) => {
       // //console.log(selected);
        setNumber(selected);
    };

    useEffect(() => {
        BindGrid(0)
    }, []);

    async function BindGrid(id) {
        var Param = { mstuserid: 0 }
        var url = "/User/BindUser";
        let _response1 = await Api.BindGrid(Param, url)
       // //console.log(_response1)
        setlistdata(_response1)
    }
    return (
    
        <div>
        <Base />
        <div id="main" class="main">
            <div class="pagetitle">
                {/* <h1>Tables</h1> */}
                <nav>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <Link to="/dash">Home</Link>
                        </li>

                        <li class="breadcrumb-item">Admin</li>
                        {/* <li class="breadcrumb-item active">General</li> */}
                    </ol>
                </nav>
            </div>
   
            <div class="col-lg-12">

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">User</h5>
                        <button type="button" class="btn btn-primary Add">
                            <Link to={'/userformadmin'} state={{ ID: 0, Flag: 'I' }}>
                             Add
                            </Link>
                        </button>


                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">SNo</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">MobileNo</th>
                                    <th scope="col">EmailID</th> 
                                    <th scope="col">AadharNo</th>
                                    <th scope="col">PanNo</th>
                                    <th scope="col">EmploeeType</th>
                                

                                    <th scope="col">Action </th>

                                </tr>
                            </thead>
                            <tbody>
                                {listdata1.map((i, index) => (
                                     
                                        <><tr key={index}>
                                        <th scope="row">{index+1}</th>
                                        <td>{i.Name}</td>
                                        <td>{i.Mobilenumber}</td>
                                        <td>{i.Email}</td> 
                                        <td>0000 0000</td>
                                        <td>{i.Pannumber}</td>
                                        <td>{i.UsertypeName}</td>

                                    <td>
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"> 
                                            <Link to={'/userformadmin'} state={{ ID: i.Mstuserid, Flag: 'U' }}>
                                                    <i class="bi bi-pencil-square" /> </Link> </li>
                                            <li class="breadcrumb-item active">
                                                 <Link to={'/userformadmin'} state={{ ID: i.Mstuserid, Flag: 'D' }}>
                                                    <i class="bi bi-trash" /></Link></li>
                                        </ol>
                                        </td>

                                    </tr><td>
                                        </td></>
                                ))}
                            </tbody>
                        </table>
                        <ReactPaginate
                                // previousLabel={"Previous"}
                                // nextLabel={"Next"}
                                pageCount={PageCount}
                                onPageChange={ChangePage}
                                containerClassName={"paginationBttns"}
                                activeClassName={"paginationActive"}
                                disableInitialCallback={true}
                                initialPage={1}
                            ></ReactPaginate>
                    </div>
                </div>
            </div>
        </div>


    </div>
)
}
