import React, { useState, useEffect } from 'react'
import Base from '../../Base';
import EmployerBase from '../../Employer/EmployerBase';
import JobseekerBase from '../../Jobseeker/JobseekerBase';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import CSS from '../../../App.css';
import Swal from 'sweetalert2';
import Api from '../../Services/ApiCall';
// import { man } from '../../../Images/Index';

import { Images } from '../../../Images/Index';

export default function Editprofile() {

  const location = useLocation();
  const { ID, Flag } = location.state;
  const navigate = useNavigate();

  useEffect(() => {
    GetCountryList()
    GetCityList()
    if (ID != 0) {
      Get(ID)
    }
    else {
    }

  }, []);

  const [fullname, setFullname] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Name']);
  const [dateofbirth, setDateofbirth] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Name']);
  const [phonenumber, setPhonenumber] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Mobilenumber']);
  const [email, setEmail] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Emailid']);
  const [email1, setEmail1] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Email']);
  const [aadharnumber, setAadharnumber] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Aadhaarnumber']);
  const [pincode, setPincode] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Pincode']);
  const [pannumber, setPannumber] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Pannumber']);
  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [country, setCountry] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Country']);
  const [state, setState] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['State']);
  const [city, setCity] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['City']);
  const [address, setAddress] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Address']);


  async function GetCountryList() {
    let _response = await Api.DirectApiCall('country', "", "")
    setCountryList(_response)
  }
  async function GetStateList(value) {
    setCountry(value)
    var Param = { country: value }
    let _response = await Api.DirectApiCall('state', value)
    setStateList(_response.states)
  }
  async function GetCityList(value) {
    setState(value)
    // let _response = await Api.DirectApiCall('city', country, value)
    // setCityList(_response)
    var url = "/DropDown/GetCities";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setCityList(_response)
  }


  async function IUDOperation1() {
    var Fullname = fullname;
    var Dateofbirth = dateofbirth;
    var Phonenumber = phonenumber;
    var Email = email;
    var Aadharnumber = aadharnumber;
    var Pincode = pincode;
    var Address = address;
    var Pannumber = pannumber;
    var Country = country;
    var State = state;
    var City = city;




    var Param = {
      "mstuserregid": sessionStorage.getItem('MUserID'),
      "name": Fullname,
      "mobilenumber": Phonenumber,
      "pannumber": Pannumber,
      "emailid": Email,
      "aadhaarnumber": Aadharnumber,
      "address": Address,
      "pincode": Pincode,
      "flag": "USUPD",
    }
    var url = "/UserReg/IUDUserRegtableResetPassword";
    let _response = await Api.IUD(Param, url);
    showAlert(_response);

  }


  async function IUDOperation() {
    var Fullname = fullname;
    var Dateofbirth = dateofbirth;
    var Phonenumber = phonenumber;
    var Aadharnumber = aadharnumber;
    var Pincode = pincode;
    var Address = address;
    var Country = country;
    var State = state;
    var City = city;

    var Param = {
      "mstjobseekerid": sessionStorage.getItem('MUserID'),
      "name": Fullname,
      "mobilenumber": Phonenumber,
      "aadhaarnumber": Aadharnumber,
      "flag": "USUPD",
      "pincode": Pincode,
      "address": Address,
    };
    var url = "/JobSeekerTable/IUDJobSeekerTable";
    let _response = await Api.IUD(Param, url);
    showAlert(_response);

  }
  const showAlert = (_response) => {
    Swal.fire({
      title: "Success",
      text: _response,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  async function Get(id) {
    var Param = { mstmyprofileid: ID }
    var url = "/MyProfileControler/BindMyProfile";
    let _response = await Api.BindGrid(Param, url)

    setFullname(_response[0].Name)
    setDateofbirth(_response[0].Dateofbirth)
    setPhonenumber(_response[0].Mobilenumber)
    setEmail(_response[0].Email)
    setAadharnumber(_response[0].Aadharnumber)
    setCountry(_response[0].Country)
    setState(_response[0].State)
    setCity(_response[0].City)
    setPincode(_response[0].Pincode)
    setAddress(_response[0].Address)
    setPannumber(_response[0].Pannumber)

  }





  return (
    <>
      <div>
        {sessionStorage.getItem('AccessType') == "Employer" ?
          <EmployerBase /> : null}
        {sessionStorage.getItem('AccessType') == "jobseeker" ?
          <JobseekerBase /> : null}
        {sessionStorage.getItem('AccessType') == "User"
          || sessionStorage.getItem('AccessType') == "Employee"
          || sessionStorage.getItem('AccessType') == "Agent" ?
          <Base /> : null}
        <div id="main" class="main">
          <div class="pagetitle">
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/dash">Home</Link>
                </li> 
                <li class="breadcrumb-item">My Profile</li> 
              </ol>
            </nav>
          </div> 
          <section class="section profile">
            <div class="row">
              <div class="col-xl-4"> 
                <div class="card">
                  <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">
                    <h2>{sessionStorage.getItem('username')}</h2>
                    <h3>{sessionStorage.getItem('AccessType')}</h3>
                    <div class="social-links mt-2">
                      <a class="twitter"><i class="bi bi-twitter"></i></a>
                      <a class="facebook"><i class="bi bi-facebook"></i></a>
                      <a class="instagram"><i class="bi bi-instagram"></i></a>
                      <a class="linkedin"><i class="bi bi-linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-8">
                <div class="card">
                  <div class="card-body pt-3">
                    <h5 class="card-title">Edit Profile</h5>
                    <div class="row mb-3">
                      <div class="col-md-8 col-lg-9">
                      </div>
                    </div>
                    <div class="row g-3">
                      <div class="col-6">
                        <label for="fullname">Full Name</label>
                        <input type="text" class="form-control" disabled={true} placeholder="Name" value={fullname} onChange={(e) => setFullname(e.target.value)} />
                      </div>

                      <div class="col-6">
                        <label for="aadhar">Aadhaar Number</label>
                        <input type="number" class="form-control" disabled={true} placeholder="Aadhar" value={aadharnumber} onChange={(e) => setAadharnumber(e.target.value)} />
                      </div>
                      <div class="col-md-6">
                        <label for="pincode">Pan Number</label>
                        <input type="text" class="form-control" placeholder="Pan Number" disabled={true} value={pannumber} onChange={(e) => setPannumber(e.target.value)} />
                      </div>
                      <div class="col-6">
                        <label for="phonenumber">Phone Number</label>
                        <input type="number no-spinner" class="form-control" placeholder="Phone Number" value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} />
                      </div>
                      {sessionStorage.getItem('AccessType') == "jobseeker" ?
                        <div class="col-6">
                          <label for="email">Email</label>
                          <input type="email" class="form-control" placeholder="Email" value={email1} onChange={(e) => setEmail1(e.target.value)} />
                        </div>
                        :
                        <div class="col-6">
                          <label for="email">Email</label>
                          <input type="email" class="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                      }

                      <div class="col-md-6">
                        <label for="pincode">Pincode</label>
                        <input type="number" class="form-control no-spinner" placeholder="Pincode" disabled={Flag == "D" || Flag == "null" ? true : false} value={pincode} onChange={(e) => setPincode(e.target.value)} />
                      </div>
                      <div class="col-md-6">
                        <label for="address">Address</label>
                        <textarea class="form-control" placeholder="Address" value={address} disabled={Flag == "D" || Flag == "null" ? true : false} onChange={(e) => setAddress(e.target.value)} ></textarea>
                      </div>
                      <div class="text-center">
                        {sessionStorage.getItem('AccessType') == "jobseeker" ?
                          <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                          :
                          <button type="button" class="btn btn-primary" onClick={IUDOperation1}>Submit</button>
                        }

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
