import React from 'react'
import Base from '../Base'
import { Link } from "react-router-dom";
import Api from '../Services/ApiCall'
export default function Staffgradedesignation() {

    async function bindgrid(id) {
        var Param = { Id: id }
        var url = "/Demo";
        let _response = await Api.BindGrid(Param, url)
       // //console.log(_response)
    }

  return (
    <div>
         <Base />
          <div id="main" class="main">
              <div class="pagetitle">
                  <h1>Tables</h1>
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                            <Link to="/dash">Home</Link>
                            </li>
                            
                          <li class="breadcrumb-item">Admin</li>
                           {/* <li class="breadcrumb-item active">General</li>  */}
                      </ol>
                  </nav>
              </div>
          <div class="col-lg-12">

              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Staff Grade Designation Table</h5>
                       {/* {Api.checkmenu(7)[0]['Add']==1? */}
                          <button type="button" class="btn btn-primary Add">
                              <Link to={'/staffgradedesignationform'}
                                   state={{ ID: 0,Flag:'I' }}
                                   >
                               Add
                              </Link>
                          </button>
                          {/* :null} */}

                     
                      <table class="table table-striped">
                          <thead>
                          <tr>
                                  <th scope="col">SNo</th>
                                  <th scope="col">Grade Type</th>
                                  <th scope="col">Grade</th>
                                  <th scope="col">Action</th>
                                 
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <th scope="row">1</th>
                                  <td>D</td>
                                  <td>D1,D2,D3...</td>
                                  <td>
                                          <nav>
                                              <ol class="breadcrumb">
                                                  <li class="breadcrumb-item"> 
                                                  {/* {Api.checkmenu(7)[0]['Edit']==1? */}
                                                     <i class="bi bi-pencil-square" />
                                                     {/* :null} */}
                                                     </li>
                                                  <li class="breadcrumb-item active">
                                                  {/* {Api.checkmenu(7)[0]['Delete']==1? */}
                                                      <i class="bi bi-trash" />
                                                     {/* :null} */}
                                                      </li>
                                              </ol>
                                          </nav>
                                       
                                      </td>
                                
                              </tr>
                              <tr>
                                  <th scope="row">2</th>
                                  <td>T</td>
                                  <td>T1,T2,T3....</td>
                                  <td>
                                          <nav>
                                              <ol class="breadcrumb">
                                                  <li class="breadcrumb-item">    <i class="bi bi-pencil-square" /></li>
                                                  <li class="breadcrumb-item active">
                                                      <i class="bi bi-trash" /></li>
                                              </ol>
                                          </nav>
                                       
                                      </td>
                                
                              </tr>
                              <tr>
                                  <th scope="row">3</th>
                                  <td>M</td>
                                  <td>M1,M2,M3....</td>
                                  <td>
                                          <nav>
                                              <ol class="breadcrumb">
                                                  <li class="breadcrumb-item">    <i class="bi bi-pencil-square" /></li>
                                                  <li class="breadcrumb-item active">
                                                      <i class="bi bi-trash" /></li>
                                              </ol>
                                          </nav>
                                       
                                      </td>
                                  
                              </tr>
                              <tr>
                                  <th scope="row">4</th>
                                  <td>E</td>
                                  <td>E1,E2,E3...</td>
                                  <td>
                                          <nav>
                                              <ol class="breadcrumb">
                                                  <li class="breadcrumb-item">    <i class="bi bi-pencil-square" /></li>
                                                  <li class="breadcrumb-item active">
                                                      <i class="bi bi-trash" /></li>
                                              </ol>
                                          </nav>
                                       
                                      </td>
                                 
                              </tr>
                              <tr>
                                  <th scope="row">5</th>
                                 
                                  <td>A</td>
                                  <td>A1,A2,A3...</td>
                                  <td>
                                          <nav>
                                              <ol class="breadcrumb">
                                                  <li class="breadcrumb-item">    <i class="bi bi-pencil-square" /></li>
                                                  <li class="breadcrumb-item active">
                                                      <i class="bi bi-trash" /></li>
                                              </ol>
                                          </nav>
                                       
                                      </td>
                               
                              </tr>                          </tbody>
                      </table>  

                  </div>
              </div>
</div>
    </div>

    </div>
  )
}
