import React,{useState,useEffect} from 'react'
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import { Link ,Navigate,useNavigate,useLocation} from "react-router-dom";
import toast,{Toaster} from 'react-hot-toast';
import Swal from 'sweetalert2';


export default function Departmentcreationform() {
   
    const navigate= useNavigate();
    const [departmentcode, setDepcode] = useState("");
    ////console.log(departmentcode,"kkkk");
    const [departmentname, setDepname] = useState("");
    const location = useLocation();
    const { ID,Flag } = location.state;
    ////console.log('%%%', ID, Flag);

    useEffect(() => {
        if (ID != 0){
        Get(ID);
    }else { 
        //Get(0) 
    }
}, []);


  

   
   
    
//     async function BindGrid(id){
//         var Param ={Id:id}
//         var url = "/Demo";
//         let _response = await Api.BindGrid(Param,url)
//         //console.log(_response)
//    }

   async function IUDOperation (flag,id){

       var Depcode = departmentcode;
       var Depname = departmentname;
      
       
    //    if(Depcode ==""){
    //        toast.error('Please enter a department code ')
    //        return false;
    //    }   
       if(Depname ==""){
           toast.error('Please enter a department name')
           return false;
       }
       var Param =
       {
        "departmentcreationcode":Depcode,
       "departmentname":Depname,
       "mstdepartmentcreationid":ID,
       "flag":Flag,
       "createdBy": 0,
       "createdDate": "2022-10-12T07:13:51.208Z"

    };
       var url = "/DepartMent/IUDDepartment";
       let _response = await Api.IUD(Param,url);
      // //console.log(_response);
      showAlert(_response);
       setTimeout(()=>{
        navigate('/Departmentcreationtable')
       },2000)
       
   }
   const showAlert = (_response) => {
    Swal.fire({
        title: "Success",
        text: _response,
        icon: "success",
        confirmButtonText: "OK",
    });
};

async function Get(id){
    var Param ={mstdepartmentcreationid:ID}
    var url = "/DepartMent/BindDepartment";
    ////console.log("Param",Param,"URL",url)
    let _response1 = await Api.BindGrid(Param,url) 
    // var _response ={
    //     Depcode:"",Depname:""
    // }
    ////console.log("+++++",_response1);

    setDepcode(_response1[0].Departmentcreationcode);
    ////console.log(_response.Name,"+++++++++++++")
    setDepname(_response1[0].Departmentname);
   

    ////console.log(_response1);

 }


  return (
  
     <div>
        <Toaster toastOptions={{ position: "top-right" }} />
         <Base  Departmentcreate={true}/>
          <div id="main" class="main">
              <div class="pagetitle">
                  <h1>Tables</h1>
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          <li class="breadcrumb-item">General Setting</li>
                          <li class="breadcrumb-item active">Department Creation</li>
                      </ol>
                  </nav>
              </div>
             
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Department Creation Creation</h5>

              
                      <div class="row g-3">
                          {/* <div class="col-md-6">
                          <label for="departmentcode">Department Code</label>
                              <input type="text" class="form-control"  disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Department Code" value={departmentcode}  onChange={(e) => setDepcode(e.target.value)}/>
                          </div> */}
                          <div class="col-md-6">
                          <label for="departmentname">Department Name</label>
                              <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false}  placeholder="Department Name"value={departmentname} onChange={(e) => setDepname(e.target.value)}/>
                          </div>
                          
                          <div class="text-center">
                              <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                              <button type="reset" class="btn btn-info">
                              <Link to='/Departmentcreationtable'>
                                Back
                                </Link>
                                </button>
                          </div>
                      </div>

                  </div>
              </div>

           
          </div>

    </div>
   
  )
}
