import React, { useState } from 'react'
import Base from '../../Base'
import Api from '../../Services/ApiCall';
import { Link,useLocation ,useNavigate} from "react-router-dom";
import { toast } from 'react-hot-toast';
import Swal from 'sweetalert2';

export default function Employeeperformanceform() {

    const navigate=useNavigate();
    const location = useLocation();
    const { ID,Flag } = location.state;

    const [employeecode, setEmployeecode] = useState("");
    const [performance, setPerformance] = useState("");
    const [date, setDate] = useState("");
    const [remark, setRemark] = useState("");
    const [file, setFile] = useState("");
    
    async function IUDOperation()
    {
        var Employeecode=employeecode;
        var Performance=performance;
        var Date=date;
        var Remark = remark;
        var File = file;

        if(Employeecode ==""){
            toast.error('Please Enter Employee Code')
            return false;
        }
        if(Performance ==""){
            toast.error('Please Enter Performance')
            return false;
        }
        if(Date ==""){
            toast.error('Please Enter Date')
            return false;
        }
        if(Remark ==""){
            toast.error('Please Enter Performance Remark')
            return false;
        }
        var Param = { 
           "mstemployeeperformancetableid" :ID,
           "Employeeperformancetablecode" :"string",
           "Date":Date,
            "Custname" :Employeecode,
            "Location" :Performance,
            "Status" :Remark,
            "Meetingwithownerorstaff" :"string",
            "Nextschedule":"string",
            "Status" :"string",
            "Flag":Flag,
            "Photoupdating" : File

        };
        var url = "/EmployeePerformanceTable/IUDEmployeePerformance";
        let _response = await Api.IUD(Param,url);
        showAlert(_response);
        setTimeout(()=>{
        navigate('/EmployeePerformanceReport')
        },1000)        
    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };


    return (
        <div>
            <Base />
            <div id="main" class="main">
                <div class="pagetitle">
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">Employee Performance</li>
                            <li class="breadcrumb-item active">Employee Performance Form</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Employee Performance Report</h5>


                        <form class="row g-3">
                            <div class="col-md-6">
                                <label>Employee Code</label>
                                <input type="text" class="form-control" placeholder="Employee Code" value={employeecode} onChange={(e) => setEmployeecode(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label>Performance</label>
                                <input type="text" class="form-control" placeholder="Enter Your Today Performance" value={performance} onChange={(e) => setPerformance(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label>Date</label>
                                <input type="Date" max={new Date().toISOString().split("T")[0]} class="form-control" placeholder="Choose Date" value={date} onChange={(e) => setDate(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label>Attach File</label>
                                <input type="file" class="form-control" value={file} onChange={(e) => setFile(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label>Remark</label>
                                <textarea placeholder="Enter the Today Performance" value={remark} class="form-control" onChange={(e) => setRemark(e.target.value)}>
                                </textarea>
                            </div>


                            <div class="text-center">
                                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                                <button type="reset" class="btn btn-info">
                                    <Link to='/EmployeePerformanceReport'>
                                        Back
                                    </Link>
                                </button>
                            </div>
                        </form>

                    </div>
                </div>


            </div>
        </div>
    )
}
