import React, { useState, useEffect } from 'react'
import Base from '../Base'
import { Link } from "react-router-dom";
import Api from '../Services/ApiCall';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "./Admin_form/Filter/Globalfilter";
import '../../Css/Pagination.css'
import { Button, Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';

function Table({ columns, data }) {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        useGlobalFilter, useSortBy, usePagination
    );
    const { globalFilter } = state

    return (
        <div>
            <Globalfilter className="form-control" filter={globalFilter} setFilter={setGlobalFilter} />
            <table class="table table-striped" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}  >
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="fa-fa-sort-asc"></i>
                                                : <i className="fa-fa-sort-desc"></i>
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td  {...cell.getCellProps()} className={` ${cell.column.className ?? ""}`}>
                                            {cell.render("Cell")}</td>

                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <br></br>
            <div className="pagination">
                <nav class="paginate page_numbers" aria-label="Page navigation">

                    {/* <ul class="pagination page_numbers">
            <li class="page-item">
                <a  class="page-link" aria-label="Previous">
                    <span aria-hidden="true">«</span>
                </a>
            </li>
            <li class="page-item"><a class="page-link" >1</a></li>
            <li class="page-item"><a class="page-link" >2</a></li> 
            <li class="page-item"><a class="page-link" >3</a></li>
            <li class="page-item"><a class="page-link" >4</a></li>
            <li class="page-item"><a class="page-link" >5</a></li>
            <li class="page-item">
                <a  class="page-link" aria-label="Next">
                    <span aria-hidden="true">»</span>
                </a>
            </li>
        </ul> */}

                </nav>
                <ul class="pagination first_last">

                    <li class="page-item"><a class="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {"First"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {"<"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                        {">"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {"Last"}</a>
                    </li>{" "}</ul>

                <span className="pageno">
                    Page{" "}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                </span>


                {/* <div  className="goto_page">   
                <span className="pageno_box">
                    Go to page:{" "}
                    <input className='gotopage_number'
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                        style={{ width: "100px" }}
                    />
                </span>{" "}</div> */}

                <div className='showpagesize' >
                    Show:{" "}
                    <select
                        className='showpagecss'
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[2, 10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select></div>
            </div>
        </div>
    );
}





export default function Deposittable(i, any) {

    const [popup, setpopup] = useState(false);
    const [AppUserid, setAppUserid] = useState('');
    const [Amount, setAmountt] = useState('');
    const [Deptid, setDeptid] = useState('');

    const openPopup = (id, amount, depositid) => {
        setAppUserid(id)
        setAmountt(amount)
        setDeptid(depositid)
        setpopup(true);
    }
    function closeModal2() {
        setpopup(false);
    }

    async function Active() {
        var Param = {
            "mstcashrecieveid": 0,
            "recieverid": AppUserid.toString(),
            "amount": Amount.toString(),
            "active": "Approved",
            "searchField": Deptid.toString(),
            "flag": "I"
        };
        var url = "/Cashrechieve/IUDCashRecieve";
        let _response = await Api.IUD(Param, url);
        toast.success(_response);
        bindGrid(0)
        setpopup(false);
    }
    async function Inactive() {
        var Param = {
            "mstcashrecieveid": 0,
            "recieverid": AppUserid.toString(),
            "amount": Amount.toString(),
            "active": "Rejected",
            "searchField": Deptid.toString(),
            "flag": "I"
        };
        var url = "/Cashrechieve/IUDCashRecieve";
        let _response = await Api.IUD(Param, url);
        toast.success(_response);
        bindGrid(0)
        setpopup(false);
    }
    const [listdata, setlistdata] = useState([]);

    const [columns, setcolumns] = useState([

        {
            Header: "ID", Cell: (row) => {
                return <div>{Number(row.row.id) + 1}</div>;
            },
        },
        {
            Header: "Depositor Id", accessor: "Depositorid",
        },
        {
            Header: "Bank Name", accessor: "MasterBankname",
        },
        {
            Header: "Account Number", accessor: "MasterAccountno",
        },
        {
            Header: "Amount", accessor: "Amount",
        },
        {
            Header: "Status", accessor: "Searchfield",
        },
        {
            Header: "Actions", Cell: (row) => {

                return <div>{<nav>
                    <ol class="breadcrumb">
                        {Api.CheckUpdate(16) == 1 ?
                            <li class="breadcrumb-item">
                                <Link to={'/Depositform'} state={{
                                    ID: row.data[row.row.id].Mstdepositid, Flag: 'U'
                                }}>
                                    <i class="bi bi-pencil-square" />
                                </Link>
                            </li>
                            : null}
                        {Api.CheckDelete(16) == 1 ?
                            <li class="breadcrumb-item active">
                                <Link to={'/Depositform'} state={{
                                    ID: row.data[row.row.id].Mstdepositid, Flag: 'D'
                                }}>
                                    <i class="bi bi-trash" />
                                </Link>
                            </li>
                            : null}
                    </ol>
                </nav>}</div>;
            },



        },

        {
            Header: "Active", Cell: (row) => {
                return (
                    <div>
                        <nav>
                            <ol class="breadcrumb">
                            {JSON.parse(sessionStorage.getItem('userdetails'))[0]['SearchField'] == 1 ?
                            // {JSON.parse(sessionStorage.getItem('userdetails'))[0]['Usertype'] == 1 ?
                               <li class="breadcrumb-item active"
                                    onClick={() => openPopup(row.data[row.row.id].Depositorid, row.data[row.row.id].Amount, row.data[row.row.id].Mstdepositid)}>
                                    
                                        <i className="bi bi-check" /> 
                                     
                                </li>
                                :null}
                            </ol>
                        </nav>
                    </div>
                );
            }
        }

    ])



    useEffect(() => {

        bindGrid(0)

    }, []);


    async function bindGrid(id) {
        var Param = {
            mstdepositid: id,
            searchField: sessionStorage.getItem('AccessType')
        }
        var url = "/Deposit/BindDepostit";
        let _response1 = await Api.BindGrid(Param, url)
        setlistdata(_response1)
    }


    return (
        <div>
            <Base Deposit={true} />
            <div id="main" class="main">
                <div class="pagetitle">
                    {/* <h1>Tables</h1> */}
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>

                            <li class="breadcrumb-item">Bank/Cash</li>
                            <li class="breadcrumb-item active">Deposit</li>
                        </ol>
                    </nav>
                </div>

                <div class="col-lg-12">

                    <div class="card">
                        <div class="card-body alignleft">
                            <h5 class="card-title">Deposit</h5>
                            {Api.CheckAdd(16) == 1 ?
                                <button type="button" class="btn btn-primary Add">
                                    <Link to={'/Depositform'}
                                        state={{ ID: 0, Flag: 'I' }}>
                                        Add
                                    </Link>
                                </button>
                                : null}


                            <Table columns={columns} data={listdata} />


                        </div>
                    </div>
                </div>
            </div>
            <Modal show={popup} className='model-popup-design' style={{ marginBottom: "100px" }}>
                <Modal.Header classname="Userlgnmodalhead">
                    <Modal.Title>
                        Verify Status
                    </Modal.Title>
                    <button type="button" class="btn-close" data-dismis="modal" onClick={closeModal2} arial-label="Close"></button>
                    {/* <CloseButton variant="black" onClick={closeModal2} /> */}
                </Modal.Header>
                <div className='loginmodalbody' style={{ width: '785px' }}>
                    <Modal.Body className='testing-test' >
                        <div>
                            <h5>Do you want to update Status?</h5>
                        </div>

                    </Modal.Body>
                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => Active()}>
                        Approve
                    </Button>
                    <Button variant="secondary" onClick={() => Inactive()}>
                        Rejected
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}