import React ,{useState, useEffect}from 'react';
import Base from '../Base'
import { Link } from "react-router-dom";
import Api from '../Services/ApiCall';

export default function EmployeeDASCreation_table() {

    useEffect(() => {
        BindGrid()
    }, []);

    const [listdata, setlistdata] = useState([]);

    async function BindGrid(id) {
        var Param = {Mstemployeedascreationid: id }
        var url = "/EmployeeDASCreation/BindEmployeedasCreation";
        let _response = await Api.BindGrid(Param, url)
        ////console.log(_response,"res")
        setlistdata(_response)
      }

  return (
    <div>

          <Base />
          <div id="main" class="main">
              <div class="pagetitle">
                 
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                              <Link to="/dash">Home</Link>
                          </li>

                          <li class="breadcrumb-item">EmployeeDASCreation</li>
                          <li class="breadcrumb-item active">Agent</li>
                      </ol>
                  </nav>
              </div>
              <div class="col-lg-12">

                  <div class="card">
                      <div class="card-body">
                          <h5 class="card-title">EmployeeDASCreation</h5>
                          <button type="button" class="btn btn-primary Add">
                              <Link to={'/EmployeeDASCreationForm'}
                                   state={{ ID: 0,Flag:'I' }}>
                                  Add
                              </Link>
                          </button>


                          <table class="table table-striped">
                              <thead>
                                  <tr>
                                  <th scope="col">S.No</th>
                                      {/* <th scope="col">Date</th> */}
                                      <th scope="col">Cust Name</th>
                                      <th scope="col">Location</th>
                                      {/* <th scope="col">Meeting with Owner/Staff</th> */}
                                      <th scope="col">Next Schedule</th>
                                      <th scope="col">Status</th>
                                      {/* <th scope="col">Photo Updating</th> */}
                                      <th scope='col'>Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                         {listdata   &&
                          listdata.map((i, index) => (                            
                                  <tr key={index}>
                                      <th scope="row">{index+1}</th>
                            
                                      {/* <td>{i.Edasdate}</td> */}
                                      <td>{i.Custname}</td>
                                      <td>{i.Location}</td>
                                      <td>{i.Nestschedule}</td>
                                      <td>{i.Status}</td>
                                      {/* <td>{i.Chossefile}</td> */}
                                      
                                      

                                       <td>
                                         <nav>

                                              <ol class="breadcrumb">
                                                  <li class="breadcrumb-item">
                                                      <Link to={'/EmployeeDASCreationForm'} state={{
                                                          ID: i.Mstemployeedascreationid, Flag: 'U' }}>
                                                          <i class="bi bi-pencil-square" /> </Link> </li>
                                                  <li class="breadcrumb-item active">
                                                      <Link to={'/EmployeeDASCreationForm'} state={{
                                                          ID: i.Mstemployeedascreationid, Flag: 'D' }}>
                                                          <i class="bi bi-trash" /></Link></li>
                                              </ol>
                                          </nav>
                                      </td>
                                  
                               
                                  </tr>
                              ))} 
                              
                            </tbody>  
                             

                          </table>

                      </div>
                  </div>
              </div>
          </div>

    </div>
  )
}
