import React, { useState, useEffect } from 'react'
import Base from '../Base'
import Api from '../Services/ApiCall';
import '../../Css/Pagination.css'
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import EmployerBase from './EmployerBase';



export default function Postjobstable(any, i) {
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup1, setShowPopup1] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [brief, setBrief] = useState("");

    const [sexList, setSexList] = useState([]);
    const [experienceList, setExperienceList] = useState([]);
    const [salaryList, setSalaryList] = useState([]);
    const [jobcategoryList, setJobcategoryList] = useState([]);
    const [jobcategory, setJobcategory] = useState("");

    useEffect(() => {
        fetchDataAndSetState();
    }, []);

    const fetchDataAndSetState = async () => {
        try {
            var category = sessionStorage.getItem("AccessType");
            var Param = { mstjoblistid: 0, flag: category }
            var url = "/Postjobs/BindPostJobs";
            let _response1 = await Api.BindGrid(Param, url)
            setRowData(_response1);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const openPopup = (rowData) => {
        setSelectedRowData(rowData);
        Get(rowData.id)
        setShowPopup(true);
    };
    const openPopup1 = (rowData) => {
        setShowPopup(false);
        setShowPopup1(true);
    };
    const openPopup2 = (rowData) => {
        setShowPopup(false);
        setShowPopup1(false);
        setShowPopup2(true);
    };

    const closePopup1 = () => {
        setSelectedRowData(null);
        setShowPopup1(false);
        setShowPopup2(false);
    };
    const closePopup = () => {
        setSelectedRowData(null);
        setShowPopup(false);
    };

    const onCellClicked = (params) => {
        openPopup({ id: params.data.Mstjoblistid, code: params.data.Jobtitle });
    };

    const columnDefs = [
        { headerName: 'Job Title', field: 'Jobtitle', filter: true, enableFilter: true },
        { headerName: 'Company Name', field: 'Companyname', filter: true, enableFilter: true },
        { headerName: 'Vacancies', field: 'Vacancy', filter: true, enableFilter: true },
        { headerName: 'Location', field: 'Location', filter: true, enableFilter: true },
        { headerName: 'Disclosetodate', field: 'Disclosetodate', filter: true, enableFilter: true },
        { headerName: 'Status', field: 'Self', filter: true, enableFilter: true },
    ];

    const [quickFilterText, setQuickFilterText] = useState("");

    const onQuickFilterChange = (event) => {
        setQuickFilterText(event.target.value);
    };

    async function IUD_Delete() {
        openPopup1();
    }

    useEffect(() => {
        GetCountryList()
        GetEmployeetype()
        GetQualification()
        GetEmployerCode()
        GetCityList()
        GetExperience()
        GetSex()
        GetJobcategory()
        GetSalary()
    }, []);


    async function GetSex() {

        var url = "/DropDown/GetMaster_name?mastername=Sex";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setSexList(_response)
    }
    async function GetJobcategory() {

        var url = "/DropDown/GetMaster_name?mastername=Jobcategory";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setJobcategoryList(_response)
    }
    async function GetExperience() {

        var url = "/DropDown/GetMaster_name?mastername=Experience";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setExperienceList(_response)
    }
    async function GetSalary() {

        var url = "/DropDown/GetMaster_name?mastername=Salary";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setSalaryList(_response)
    }
    async function GetQualification() {

        var url = "/DropDown/GetQualification";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setQualification(_response)
    }
    async function GetEmployeetype() {

        var url = "/DropDown/GetEmploymentType";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setEmptype(_response)
    }

    const [jobtitle, setJobtitle] = useState("");
    const [companyname, setCompanyname] = useState(
        sessionStorage.getItem("AccessType") == "Employer" ?
            JSON.parse(sessionStorage.getItem('userdetails'))[0]['Companyname']
            : ""

    );
    const [employercode, setEmployercode] = useState(
        sessionStorage.getItem("AccessType") == "Employer" ?
            JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode']
            : ""

    );
    const [experiencedetails, setExperiencedetails] = useState("");
    const [vacancies, setVacancies] = useState("");
    const [disclosefromdate, setDisclosefromdate] = useState("");
    const [disclosetodate, setDisclosetodate] = useState("");
    const [locate, setLocation] = useState("");
    const [skills, setSkills] = useState("");
    const [jobdescription, setJobdescription] = useState("");
    const [industrytype, setIndustrytype] = useState("");
    const [functionalarea, setFunctionalarea] = useState("");
    const [role, setRole] = useState("");
    const [rolecategory, setRolecategory] = useState("");
    const [employmenttype, setEmploymenttype] = useState("");
    const [education, setEducation] = useState("");
    const [salary, setSalary] = useState("");
    const [CountryList, setCountryList] = useState([]);
    const [StateList, setStateList] = useState([]);
    const [CityList, setCityList] = useState([]);
    const [qualification, setQualification] = useState([]);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [emptype, setEmptype] = useState("");
    const [employercodelist, setEmployercodelist] = useState("");
    const [companynamelist, setCompanynamelist] = useState("");


    async function GetCountryList() {
        let _response = await Api.DirectApiCall('country', "", "")
        setCountryList(_response)
    }
    async function GetStateList(value) {
        setCountry(value)
        var Param = { country: value }
        let _response = await Api.DirectApiCall('state', value)
        setStateList(_response.states)
    }
    async function GetCityList(value) {
        setState(value)
        var url = "/DropDown/GetCities";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setCityList(_response)
    }
    async function GetEmployerCode() {
        let id = "";
        if (sessionStorage.getItem("AccessType") === 'Agent') {
            id = sessionStorage.getItem("MUserID");
        } else {
            id = "called";
        }
        var url = "/DropDown/GetEmployerCode?Agent=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setEmployercodelist(_response)
    }
    async function GetCompanyName(e) {
        var Employercode = e;
        var url = "/DropDown/GetEmployerName?code=" + Employercode;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setCompanyname(_response.Text)
    }

    async function handleEmployerCodeChange(e) {
        GetCompanyName(e);
    }


    async function IUDOperation() {
        var ID = selectedRowData.id;
        var Jobtitle = jobtitle;
        var Companyname = companyname;
        var Experiencedetails = experiencedetails;
        var Vacancis = vacancies;
        var Disclose = disclosefromdate;
        var Location = locate
        var Skills = skills;
        var Jobdescription = jobdescription;
        var Industrytype = industrytype;
        var Functionalarea = functionalarea;
        var Role = role;
        var Rolecategory = rolecategory;
        var Employmenttype = employmenttype;
        var Education = education;
        var Country = country;
        var State = state;
        var District = district;
        var City = city;
        var Salary = salary;
        var Disclosetodate = disclosetodate;
        var Employercode = employercode;


        var Param = {
            "mstjoblistid": ID,
            "joblistcode": Employercode,
            "jobtitle": Jobtitle,
            "companyname": Companyname,
            "experiencedetails": Experiencedetails,
            "vacancy": Vacancis,
            "disclosefromdate": Disclose,
            "location": Location,
            "skils": Skills,
            "jobdescription": Jobdescription,
            "industrytype": Industrytype,
            "functionalarea": Functionalarea,
            "role": Role,
            "rolecategory": Rolecategory,
            "employmenttype": Employmenttype,
            "education": Education,
            "active": "string",
            "searchField": Salary,
            "country":jobcategory,
            "createdDate": "2022-11-17T05:12:20.480Z",
            "flag": "U",
            "disclosetodate": Disclosetodate,
            "brief": brief
        };
        var url = "/Postjobs/IUDPostJobs";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup(false);
        fetchDataAndSetState();

    }
    async function IUDOperationDelete() {

        var ID = selectedRowData.id;
        var Jobtitle = jobtitle;
        var Companyname = companyname;
        var Experiencedetails = experiencedetails;
        var Vacancis = vacancies;
        var Disclose = disclosefromdate;
        var Location = locate
        var Skills = skills;
        var Jobdescription = jobdescription;
        var Industrytype = industrytype;
        var Functionalarea = functionalarea;
        var Role = role;
        var Rolecategory = rolecategory;
        var Employmenttype = employmenttype;
        var Education = education;
        var Country = country;
        var State = state;
        var District = district;
        var City = city;
        var Salary = salary;
        var Disclosetodate = disclosetodate;
        var Employercode = employercode;


        var Param = {
            "mstjoblistid": ID,
            "joblistcode": Employercode,
            "jobtitle": Jobtitle,
            "companyname": Companyname,
            "experiencedetails": Experiencedetails,
            "vacancy": Vacancis,
            "disclosefromdate": Disclose,
            "location": Location,
            "skils": Skills,
            "jobdescription": Jobdescription,
            "industrytype": Industrytype,
            "functionalarea": Functionalarea,
            "role": Role,
            "rolecategory": Rolecategory,
            "employmenttype": Employmenttype,
            "education": Education,
            "active": "string",
            "searchField": Salary,
            "createdBy": 0,
            "createdDate": "2022-11-17T05:12:20.480Z",
            "flag": "D",
            "disclosetodate": Disclosetodate,
            "brief": brief
        };
        var url = "/Postjobs/IUDPostJobs";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup1(false);
        fetchDataAndSetState();

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function Get(id) {
        debugger;
        var category = sessionStorage.getItem("AccessType");
        var Param = { mstjoblistid: id, flag: category }
        var url = "/Postjobs/BindPostJobs";
        let _response = await Api.BindGrid(Param, url)
        setJobtitle(_response[0].Jobtitle)
        setEmployercode(_response[0].Joblistcode)
        setCompanyname(_response[0].Companyname)
        setExperiencedetails(_response[0].Experiencedetails)
        setVacancies(_response[0].Vacancy)
        setDisclosefromdate(_response[0].Disclosefromdate)
        setLocation(_response[0].Location)
        setSkills(_response[0].Skils)
        setJobdescription(_response[0].Jobdescription)
        setIndustrytype(_response[0].Industrytype)
        setFunctionalarea(_response[0].Functionalarea)
        setRole(_response[0].Role)
        setRolecategory(_response[0].Rolecategory)
        setEmploymenttype(_response[0].Employmenttype)
        setEducation(_response[0].Education)
        setJobcategory(_response[0].Country)
        setState(_response[0].State)
        setDistrict(_response[0].District)
        setCity(_response[0].City)
        setDisclosetodate(_response[0].Disclosetodate)
        setSalary(_response[0].SearchField)
        setBrief(_response[0].Brief)
    }
    async function Inactive() {
        var ID = selectedRowData.id;
        var Param = {
            "mstjoblistid": ID,
            "flag": "INACT"
        };
        var url = "/Postjobs/JobInactive";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        fetchDataAndSetState(0)
        setShowPopup2(false);
    }
    async function Active() {
        var ID = selectedRowData.id;
        var Param = {
            "mstjoblistid": ID,
            "flag": "ACT"
        };
        var url = "/Postjobs/JobInactive";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        fetchDataAndSetState(0)
        setShowPopup2(false);
    }
    const [walletAmount, setWalletAmount] = useState("0");
    const [postamount, setPostamount] = useState("0");

    async function BindGrid() {
        var Id = sessionStorage.getItem("MUserID")
        var Param = { Mstuserregid: Id }
        var url = "/UserReg/GetUserRegtable1";
        let _response = await Api.BindGrid(Param, url)
        setWalletAmount(_response[0].Recharge_amount)
    }

    async function grid(id) {
        var Param = { mstemployerplancreationid: id }
        var url = "/EmployerPlanCreation/BindEmployerPlanCreation";
        let _response = await Api.BindGrid(Param, url);
        setPostamount(_response[0].Employerplancreationcode);
    }

    const handleButtonClick = () => {
        grid(6);
        BindGrid();
        const intwalletAmount = parseInt(walletAmount, 10);
        const intapplyamount = parseInt(postamount, 10);


        if (intwalletAmount >= intapplyamount) {
            navigate('/Jobtitleform', {
                state: {
                    ID: 0,
                    Flag: 'I'
                }
            });
        } else {
            Swal.fire({
                title: "Failed",
                text: "Insufficient Balance",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    return (
        <div>
            {sessionStorage.getItem("AccessType") == 'Employer' ?
                <EmployerBase /> : null}
            {sessionStorage.getItem("AccessType") == 'Employee' ||
                sessionStorage.getItem("AccessType") == 'Agent' ||
                sessionStorage.getItem("AccessType") == 'User'
                ?
                <Base /> : null}
            <div id="main" class="main">
                <div class="pagetitle">
                    {/* <h1>Tables</h1> */}
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>

                            <li class="breadcrumb-item">Post Job Form</li>
                        </ol>
                    </nav>
                </div>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={onQuickFilterChange}
                    style={{ marginBottom: '10px' }}
                />
                <Link to="/Jobtitleform" state={{ ID: 0, Flag: 'I' }}>
                    <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed', color: "white" }}>
                        Add
                    </button>
                </Link>
                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={10}
                        domLayout='autoHeight'
                        floatingFilter={true}
                        enableBrowserTooltips={true}
                        onCellClicked={onCellClicked}
                        quickFilterText={quickFilterText}
                    />
                </div>
                {showPopup && (
                    <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                            <button type="reset" class="btn btn-info" onClick={openPopup2} >Active</button>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">

                                <div class="row g-3">
                                    <div class="col-md-6">
                                        <label for="jobtitle">Job Title</label>
                                        <input type="text" class="form-control" placeholder="Job Title" value={jobtitle} onChange={(e) => setJobtitle(e.target.value)} />
                                    </div>

                                    {sessionStorage.getItem("AccessType") == "Employer" ?
                                        <div class="col-md-6">
                                            <label for="employercode">Employer Code</label>
                                            <input type="text" class="form-control" disabled="true" placeholder="Employer code" value={employercode} onChange={(e) => setEmployercode(e.target.value)} />
                                        </div>
                                        :
                                        <div class="col-md-6">
                                            <label for="employercode">Employer Code</label>
                                            <select id="inputState" class="form-select" value={employercode} onChange={(e) => {
                                                setEmployercode(e.target.value);
                                                handleEmployerCodeChange(e.target.value);
                                            }}>
                                                <option selected >Employer Code</option>
                                                {employercodelist &&
                                                    employercodelist.map((i, index) => (
                                                        <option value={i.Text}>{i.Text}</option>
                                                    ))}
                                            </select>
                                        </div>

                                    }

                                    {sessionStorage.getItem("AccessType") == "Employer" ?
                                        <div class="col-md-6">
                                            <label for="companyname">Company Name</label>
                                            <input type="text" class="form-control" disabled="true" placeholder="Company Name" value={companyname} onChange={(e) => setCompanyname(e.target.value)} />
                                        </div>
                                        :
                                        <div class="col-md-6">
                                            <label for="employercode">Company Name</label>
                                            <input type="text" class="form-control" disabled="true" placeholder="Company Name" value={companyname} onChange={(e) => setCompanyname(e.target.value)} />

                                        </div>
                                    }

                                    <div class="col-md-6">
                                        <label for="Jobcategory">Jobcategory</label>
                                        <select id="inputState" class="form-select" value={jobcategory} onChange={(e) => setJobcategory(e.target.value)}  >
                                            <option selected>Jobcategory</option>
                                            {jobcategoryList &&
                                                jobcategoryList.map((i, index) => (
                                                    <option value={i.Text1}>{i.Text1}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="experiencedetails">Experience Details</label>
                                        <select id="inputState" class="form-select" value={experiencedetails} onChange={(e) => setExperiencedetails(e.target.value)}  >
                                            <option selected>Experience Details</option>
                                            {experienceList &&
                                                experienceList.map((i, index) => (
                                                    <option value={i.Text1}>{i.Text1}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="vacancies">Vacancies</label>
                                        <input type="text" class="form-control" placeholder="Vacancies" value={vacancies} onChange={(e) => setVacancies(e.target.value)} />
                                    </div>
                                    <div class="col-md-3">
                                        <label for="disclose">Disclose From Date</label>
                                        <input type="date" max={new Date().toISOString().split("T")[0]} class="form-control" disabled={true} placeholder="From" value={disclosefromdate} onChange={(e) => setDisclosefromdate(e.target.value)} />
                                    </div>

                                    <div class="col-md-3">
                                        <label for="disclose">Disclose To Date</label>
                                        <input type="date" class="form-control" placeholder="To" value={disclosetodate} disabled={true} onChange={(e) => setDisclosetodate(e.target.value)} />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="location">Location</label>
                                        <input type="text" class="form-control" placeholder="Location" value={locate} onChange={(e) => setLocation(e.target.value)} />
                                    </div>

                                    <div class="col-md-6">
                                        <label for="jobdescription">Job Description</label>

                                        <input type="text" class="form-control" placeholder="Job Description" value={jobdescription} onChange={(e) => setJobdescription(e.target.value)} />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="skills">Skills</label>
                                        <input type="text" class="form-control" placeholder="Skills" value={skills} onChange={(e) => setSkills(e.target.value)} />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="industrytype">Industry Type</label>
                                        <input type="text" class="form-control" placeholder="Industry Type" value={industrytype} onChange={(e) => setIndustrytype(e.target.value)} />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="functionalarea">Functional Area</label>
                                        <input type="text" class="form-control" placeholder="Functional Area" value={functionalarea} onChange={(e) => setFunctionalarea(e.target.value)} />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="role">Role</label>
                                        <input type="text" class="form-control" placeholder="Role" value={role} onChange={(e) => setRole(e.target.value)} />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="rolecategory">Role Category</label>
                                        <input type="text" class="form-control" placeholder="Role Category" value={rolecategory} onChange={(e) => setRolecategory(e.target.value)} />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="employmenttype">Employment Type</label>
                                        <select id="inputState" class="form-select" value={employmenttype} onChange={(e) => setEmploymenttype(e.target.value)}>

                                            <option selected>Employment Type</option>
                                            {emptype &&
                                                emptype.map((i, index) => (
                                                    <option value={i.Text}>{i.Text}</option>
                                                ))}

                                        </select>
                                    </div>

                                    <div class="col-md-6">
                                        <label for="qualification"> Qualifcation</label>
                                        <select id="inputState" class="form-select" value={education} onChange={(e) => setEducation(e.target.value)}  >
                                            <option selected>Qualification</option>
                                            {qualification &&
                                                qualification.map((i, index) => (
                                                    <option value={i.Text}>{i.Text}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="qualification"> Salary</label>
                                        <select id="inputState" class="form-select" value={salary} onChange={(e) => setSalary(e.target.value)}  >
                                            <option selected>Salary</option>
                                            {salaryList &&
                                                salaryList.map((i, index) => (
                                                    <option value={i.Text1}>{i.Text1}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="role">Give the Brief of the Job Details</label>
                                        <textarea class="form-control" value={brief} onChange={(e) => setBrief(e.target.value)} ></textarea>
                                    </div>

                                </div>

                                <div class="text-center">
                                    <button type="button" class="btn btn-primary" onClick={IUDOperation}>Update</button>

                                    <button type="reset" class="btn btn-info" onClick={IUD_Delete} >Delete</button>

                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
                {showPopup1 && (
                    <Modal show={true} onHide={closePopup1} >
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are You sure want to Delete?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closePopup1}>
                                Close
                            </Button>
                            <Button variant="secondary" onClick={IUDOperationDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
                {showPopup2 && (
                    <Modal show={true} onHide={closePopup1} >
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Do you want to update Status?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={Active}>
                                Active
                            </Button>
                            <Button variant="secondary" onClick={Inactive}>
                                Inactive
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>


        </div>
    )
}
