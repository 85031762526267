import React, { useState, useEffect } from 'react';
import Api from '../Services/ApiCall';
import Base from '../Base';
import '../../App.css'
import { Button, Modal } from 'react-bootstrap';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "../Admin/Admin_form/Filter/Globalfilter";
import toast, { Toaster } from 'react-hot-toast';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { BeatLoader } from "react-spinners";


export default function Otherusers(i, any) {

  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [Griddata, setlGriddata] = useState([]);
  let _a = [];
  const menu = JSON.parse(sessionStorage.getItem("roleaccess"))
  useEffect(() => {

    Checkvalidation()

  }, []);

  async function Checkvalidation() {
    setIsLoading(true);
    try {
      if (checkmenu(2)[0]['View'] == 1) {
        set_Usertype("Employee")
        _a.push("Employee")
        let _a1 = [];
        _a1.push("Employee")
        var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a1) }
        var url = "/UserReg/GetUserRegtable";
        let _response1 = await Api.BindGrid(Param, url)
        setRowData(_response1)
        setIsLoading(false);
      }
    }
    catch {
      setIsLoading(false);
    }

  }
  function checkmenu(id) {
    return menu.filter(function (menu) {
      try {

        return menu.MenuID == id;
      }
      catch {

        return menu.MenuID == id;
      }

    });
  } 
  const UserT = "Employee"
  const [_Usertype, set_Usertype] = useState("Employee");
   


  const openPopup = (rowData) => {
    setSelectedRowData(rowData);
    Get(rowData.id)
    setShowPopup(true);
  };
  const openPopup1 = (rowData) => {
    setShowPopup(false);
    setShowPopup1(true);
  };

  const closePopup1 = () => {
    setSelectedRowData(null);
    setShowPopup1(false);
  };
  const closePopup = () => {
    setSelectedRowData(null);
    setShowPopup(false);
  };

  const onCellClicked = (params) => {
    openPopup({ id: params.data.Mstuserregid, code: params.data.Msusertregcode, Activestatus: params.data.Activestatus });
  };

  const columnDefs = [
    { headerName: 'Code', field: 'Msusertregcode', filter: true, enableFilter: true },
    { headerName: 'Name', field: 'Name', filter: true, enableFilter: true },
    { headerName: 'Mobile Number', field: 'Mobilenumber', filter: true, enableFilter: true },
    { headerName: 'Email', field: 'Emailid', filter: true, enableFilter: true },
    { headerName: 'Department Name', field: 'DepartmentName', filter: true, enableFilter: true },
    // { headerName: 'Branch', field: 'Accountnumber', filter: true, enableFilter: true },
    { headerName: 'CreatedBy', field: 'CreatedBy1', filter: true, enableFilter: true },
    { headerName: 'CreatedDate', field: 'CreatedDate' },
    { headerName: 'Status', field: 'Activestatus', filter: true, enableFilter: true },
  ];

  const [quickFilterText, setQuickFilterText] = useState('');

  const onQuickFilterChange = (event) => {
    setQuickFilterText(event.target.value);
  };

  useEffect(() => {
    set_Usertype(UserT)
    GetMenu(0)
    GetQualification()
    GetUserType()
    bindStaffGradeDdl(0)
    getEmployeeCode()
    GetPlandetail()
    GetAmount()
    GetDistrict()
    GetDepartment()
    GetCountryList()
    GetCityList()
    GetDepartment()
    getRepEmployeeCode()
  }, []);
  const [name, setName] = useState("");
  const [phone, setPhonenum] = useState("");
  const [pannumber, setPannum] = useState("");
  const [aadharnumber, setAadhaarnum] = useState("");
  const [email, setEmail] = useState("");
  const [qualification, setQualification] = useState([]);
  const [usertype, setUsertype] = useState([]);
  const [department, setDepartment] = useState([]);
  const [selectdepartment, setselectDepartment] = useState("");
  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [selectedUsertype, setselectedUsertype] = useState([]);
  const [selectedGradeType, setselectedGradeType] = useState("");
  const [selectedGrade, setselectedGrade] = useState("");
  const [user, setUser] = useState("");
  const [address, setAddress] = useState("");
  const [grade, setGrade] = useState("");
  const [designation, setDesignation] = useState("");
  const [agentcode, setAgentcode] = useState("");
  const [officecode, setOfficecode] = useState("");
  const [ListMenu, setListMenu] = useState([]);
  const [reportempcode, setReportempcode] = useState("")
  const [state, setState] = useState("")
  const [country, setCountry] = useState("")
  const [city, setCity] = useState("")
  const [pincode, setPincode] = useState("")
  const [acholder, setAcholder] = useState("")
  const [accountno, setAccountno] = useState("")
  const [ifsccode, setIfsccode] = useState("")
  const [branchname, setBranchname] = useState("")
  const [bankname, setbankname] = useState("")
  const [reportingempcode, setreportingempcode] = useState("")
  const [accountholdername, seaccountholdername] = useState("")
  const [selectedQualiify, setselectedQualiify] = useState([]);
  const [gradetype, setgradetype] = useState([]);
  const [staffGrade, setStaffGrade] = useState([]);
  const [selectedStaffGrade, setSelectedStaffGrade] = useState([]);
  const [staffGradeType, setStaffGradeType] = useState([]);
  const [staffDesignation, setStaffDesignation] = useState("");
  const [selectedPlan, setselectedPlan] = useState("");
  const [plan, setPlan] = useState("");
  const [amount, setAmount] = useState([]);
  const [employeebranch, setEmployeebranch] = useState("");
  const [incharge, setIncharge] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [companynumber, setCompanynumber] = useState("");
  const [companyaddress, setCompanyaddress] = useState("");
  const [companygst, setCompanygst] = useState("");
  const [ListemployeeGrade, setListemployeeGrade] = useState([]);
  const [ListemployeeCode, setListemployeeCode] = useState([]);
  const [ListemployeeDesignation, setListemployeeDesignation] = useState("");
  const [SelectOfficegrade, setSelectOfficegrade] = useState("");
  const [SelectOFficeEmpCode, setSelectOFficeEmpCode] = useState("");
  const [SelectOFficeDesignation, setSelectOFficeDesignation] = useState("");
  const [officeinchargename, setOfficeinchargename] = useState("");
  const [district, setDistrict] = useState("");
  const [districtlist, setDistrictlist] = useState("");
  const [ListRepemployeeCode, setListRepemployeeCode] = useState([]);
  const [SelectReportEmpCode, setSelectReportEmpCode] = useState("");
  const [SelectReportEmpCode1, setSelectReportEmpCode1] = useState("");
  const [officeEmployeename, setOfficeEmployeename] = useState("");

  async function GetDistrict() {
    var url = "/DropDown/GetDistrict";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setDistrictlist(_response)
  }
  async function getSelectedStaffGradeTypeDdl(e) {
    setselectedGradeType(e.target.value);
    var Param = { Mststaffgradetypeid: e.target.value }
    var url = "/StaffGradeType/BindStaffGradeType";
    let _response = await Api.BindGrid(Param, url);
    setStaffDesignation(_response[0].Designation);
  }
  async function getEmployeeCode() {
    var url = "/DropDown/GetOfficetypecode";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setListemployeeCode(_response)
  }
  async function GetReportemployeename(e) {
    setSelectReportEmpCode(e);
    var url = "/DropDown/GetEmployeeName_using_Code?code=" + e;
    let _response = await Api.BindDropdown(url)
    // //console.log(_response,"rrrrrr")
    setOfficeEmployeename(_response.Text);

  }
  async function GetUserType() {
    var url = "/DropDown/GetRole?code=Employee";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setUsertype(_response)
  }
  async function getSelectedStaffGradeDdl(e) {
    setGrade(e.target.value);
    setSelectedStaffGrade(e.target.value);
    var staffGrade = e.target.options[e.target.selectedIndex].text;
    var Param = { mststaffgradedesignationid: 0 }
    var url = "/StaffGradeType/BindStaffGradeType";
    let _response1 = await Api.BindGrid(Param, url)
    _response1 = _response1.filter(function (el) {
      return el.Staffgradetypecode == e.target.value;
    });
    var sGrade = [...new Set(_response1.map(q => {
      var x = {
        Automaticbaseedongradetype: q.Automaticbaseedongradetype,
        Mststaffgradetypeid: q.Mststaffgradetypeid
      }
      return x;
    }))]
    setStaffGradeType(sGrade);
  }
  async function GetStaffGrademannual(id) {
    try {
      var Param = { mststaffgradedesignationid: 0 }
      var url = "/StaffGradeType/BindStaffGradeType";
      let _response121 = await Api.BindGrid(Param, url);
      _response121 = _response121.filter(function (el) {
        return el.Role == id;
      });
      var sGrade = [...new Set(_response121.map(q => q.Staffgradetypecode))]
      setStaffGrade(sGrade);
    } catch {
    }
  }
  async function GetStaffGradeTypemannual(id) {
    var Param = { mststaffgradedesignationid: 0 }
    var url = "/StaffGradeType/BindStaffGradeType";
    let _response1 = await Api.BindGrid(Param, url)
    _response1 = _response1.filter(function (el) {
      return el.Staffgradetypecode == id;
    });
    var sGrade = [...new Set(_response1.map(q => {
      var x = {
        Automaticbaseedongradetype: q.Automaticbaseedongradetype,
        Mststaffgradetypeid: q.Mststaffgradetypeid
      }
      return x;
    }))]
    setStaffGradeType(sGrade);
  }
  async function GetPlandetail() {
    var url = "/DropDown/GetEmployerdetail";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setPlan(_response)
  }
  async function GetAmount(e) {
    setselectedPlan(e.target.value)
    var id = (e.target.value)
    var url = "/DropDown/GetEmployeramount?id=" + id;
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setAmount(_response)
  }
  async function GetQualification() {
    var url = "/DropDown/GetQualification";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setQualification(_response)
  }
  async function GetMenu(id) {
    var Param = { mstmenuid: id }
    var url = "/Menu/BindMenu";
    let _response = await Api.BindGrid(Param, url)
    setListMenu(_response);
  }
  async function GetRoleType(id) {
    var Param = { Mstroleid: id }
    var url = "/Role/BindRole";
    let _response1 = await Api.BindGrid(Param, url);
    setselectedUsertype(id)
    setListMenu(JSON.parse(_response1[0].RoleList));
    await bindStaffGradeDdl(id)
  }
  async function GetDepartment() {
    var url = "/DropDown/GetDepartment";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setDepartment(_response)
  }
  async function GetGradeType(id) {
    setselectedGrade(id)
    var url = "/DropDown/GetGradeType?GradeCode=" + id;
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    try {
      setgradetype(_response[0].Text.split(","))
    }
    catch { }
  }
  async function bindStaffGradeDdl(id) {
    var Param = { mststaffgradedesignationid: 0 }
    var url = "/StaffGradeType/BindStaffGradeType";
    try {
      let _response1 = await Api.BindGrid(Param, url);
      _response1 = _response1.filter(function (el) {
        return el.Role == id;
      });
      var sGrade = [...new Set(_response1.map(q => q.Staffgradetypecode))]
      setStaffGrade(sGrade);
    } catch {
    }
  }
  async function getRepEmployeeCode() {
    var url = "/DropDown/GetRole_with_management";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setListRepemployeeCode(_response)
  }

  async function GetCountryList() {
    let _response = await Api.DirectApiCall('country', "", "")
    setCountryList(_response)
  }
  async function GetStateList(value) {
    setCountry(value)
    var Param = { country: value }
    let _response = await Api.DirectApiCall('state', value)
    setStateList(_response.states)
  }
  async function GetCityList(value) {
    setState(value)
    // var url = "/DropDown/GetCities";
    // let _response = await Api.BindDropdown(url)
    // let Arry_Title1 = [];
    // setCityList(_response)
  }

  async function GetGradeTypesManual(ID) {
    var Param = { Staffgradetypecode: ID }
    var url = "/StaffGradeType/BindStaffGradeType";
    let _response = await Api.BindGrid(Param, url)
    setStaffGradeType(_response);
  }
  async function getSelectedStaffGradeTypeDdl(e) {
    setselectedGradeType(e.target.value);
    var Param = { Mststaffgradetypeid: e.target.value }
    var url = "/StaffGradeType/BindStaffGradeType";
    let _response = await Api.BindGrid(Param, url);
    setStaffDesignation(_response[0].Designation);
  }
  async function Getofficemployeename(e) {
    setSelectOFficeEmpCode(e);
    var Param = { Mstofficetypecreationid: e }
    var url = "/DropDown/GetInchargeNmae?id=" + e;
    let _response = await Api.BindGrid(Param, url);
    setOfficeinchargename(_response[0].Text);
    setSelectReportEmpCode1(_response[0].Text2);
  }

  async function IUDOperation(flag, id) {
    var Name = name;
    var Mobile = phone;
    var Pan = pannumber;
    var Qualify = selectedQualiify;
    var User = selectedUsertype;
    var Email = email;
    var Aadhar = aadharnumber;
    var Address = address;
    var State = state;
    var Country = country;
    var City = city;
    var Pincode = pincode;
    var Acholder = acholder;
    var Accountno = accountno;
    var Ifsccode = ifsccode;
    var Branchname = branchname;
    var SelectedStaffGrade = selectedStaffGrade;
    var StaffGradeType = staffGradeType;
    var Department = selectdepartment;
    var Reportempcode = reportingempcode;
    var Bankname = bankname;
    var ID = selectedRowData.id;
    var selectReportEmpCode = SelectReportEmpCode;

    var Param =
    {
      "mstuserregid": ID,
      "msusertregcode": "",
      "name": name,
      "mobilenumber": Mobile,
      "pannumber": pannumber,
      "qualification": "1",
      "usertype": selectedUsertype,
      "emailid": email,
      "aadhaarnumber": aadharnumber,
      "rolecab": _Usertype,
      "agGradetype": selectedGradeType,
      "agGrade": selectedStaffGrade.length == 0 ? "" : selectedStaffGrade,
      "agDesignation": staffDesignation,
      "department": Department,
      "agAgentcode": agentcode,
      "officecode": officecode,
      "reportingempcode": SelectOFficeEmpCode,
      "city": city,
      "state": state,
      "country": country,
      "address": address,
      "pincode": pincode,
      "accountholdername": acholder,
      "accountnumber": accountno,
      "ifsccode": ifsccode,
      "branchname": branchname,
      "bankname": bankname,
      "rolevscab": JSON.stringify(ListMenu),
      "flag": "U",
      "searchField": _Usertype,
      "district": district,
      "planperiod": SelectReportEmpCode,
    }
    var url = "/UserReg/IUDUserRegtable";
    let _response = await Api.IUD(Param, url);
    showAlert(_response);
    setShowPopup(false);
  }
  const showAlert = (_response) => {
    Swal.fire({
      title: "Success",
      text: _response,
      icon: "success",
      confirmButtonText: "OK",
    });
  };
  async function IUDOperationDelete(flag, id) {
    var Name = name;
    var Mobile = phone;
    var Pan = pannumber;
    var Qualify = selectedQualiify;
    var User = selectedUsertype;
    var Email = email;
    var Aadhar = aadharnumber;
    var Address = address;
    var State = state;
    var Country = country;
    var City = city;
    var Pincode = pincode;
    var Acholder = acholder;
    var Accountno = accountno;
    var Ifsccode = ifsccode;
    var Branchname = branchname;
    var SelectedStaffGrade = selectedStaffGrade;
    var StaffGradeType = staffGradeType;
    var Department = selectdepartment;
    var Reportempcode = reportingempcode;
    var Bankname = bankname;
    var ID = selectedRowData.id;

    var Param =
    {
      "mstuserregid": ID,
      "msusertregcode": "",
      "name": name,
      "mobilenumber": Mobile,
      "pannumber": pannumber,
      "qualification": "1",
      "usertype": selectedUsertype,
      "emailid": email,
      "aadhaarnumber": aadharnumber,
      "rolecab": _Usertype,
      "agGradetype": selectedGradeType,
      "agGrade": selectedStaffGrade.length == 0 ? "" : selectedStaffGrade,
      "agDesignation": staffDesignation,
      "department": Department,
      "agAgentcode": agentcode,
      "officecode": officecode,
      "reportingempcode": SelectOFficeEmpCode,
      "city": city,
      "state": state,
      "country": country,
      "address": address,
      "pincode": pincode,
      "accountholdername": acholder,
      "accountnumber": accountno,
      "ifsccode": ifsccode,
      "branchname": branchname,
      "bankname": bankname,
      "rolevscab": JSON.stringify(ListMenu),
      "flag": "D",
      "searchField": _Usertype,
      "district": district,
      "planperiod": SelectReportEmpCode ? SelectReportEmpCode : SelectReportEmpCode1,
    }
    var url = "/UserReg/IUDUserRegtable";
    let _response = await Api.IUD(Param, url);
    showAlert(_response);
    setShowPopup1(false);
  }


  async function Get(id) {
    var Param = { Mstuserregid: id }
    var url = "/UserReg/GetUserRegtable";
    let _response = await Api.BindGrid(Param, url)
    setName(_response[0].Name)
    setPhonenum(_response[0].Mobilenumber)
    setPannum(_response[0].Pannumber)
    setSelectOFficeEmpCode(_response[0].Reportingempcode)
    Getofficemployeename(_response[0].Reportingempcode)
    setselectedQualiify(_response[0].Qualification)
    setselectedUsertype(_response[0].Usertype)
    setSelectReportEmpCode1(_response[0].Planperiod)
    GetReportemployeename(_response[0].Planperiod)
    setEmail(_response[0].Emailid)
    setAadhaarnum(_response[0].Aadhaarnumber)
    set_Usertype(_response[0].Rolecab)
    setGrade(_response[0].selectedGradeType)
    setGrade(_response[0].AgGradetype)
    await GetStaffGrademannual(_response[0].Rolecab)
    setSelectedStaffGrade(_response[0].Staffgradetypecode)
    await GetGradeTypesManual(_response[0].AgGrade)
    await GetStaffGradeTypemannual(_response[0].AgGrade)
    setGrade(_response[0].AgGrade)
    setSelectedStaffGrade(_response[0].AgGrade)
    setselectedGradeType(_response[0].AgGradetype)
    setStaffDesignation(_response[0].AgDesignation)
    setselectDepartment(_response[0].Departmentname)
    setselectDepartment(_response[0].Department)
    setAgentcode(_response[0].AgAgentcode)
    setOfficecode(_response[0].Officecode)
    setCity(_response[0].City)
    setCountry(_response[0].Country)
    setState(_response[0].State)
    setAddress(_response[0].Address)
    setPincode(_response[0].Pincode)
    setAcholder(_response[0].Accountholdername)
    setAccountno(_response[0].Accountnumber)
    setIfsccode(_response[0].Ifsccode)
    setBranchname(_response[0].Branchname)
    setbankname(_response[0].Bankname)
    setIfsccode(_response[0].Ifsccode)
    setDistrict(_response[0].District)
    setOfficeinchargename(_response[0].Text)
    setOfficecode(_response[0].Officecode)
    setSelectOFficeEmpCode(_response[0].Reportingempcode)
    Getofficemployeename(_response[0].Reportingempcode)
    set_Usertype(_response[0].Rolecab)
    setBranchname(_response[0].Branchname)
    setListMenu(JSON.parse(_response[0].Rolevscab))
    setDistrict(_response[0].District)
  }
  const handleOnChange = (event, option, index, F) => {
    const values = [...ListMenu];
    if (event.target.checked) {
      if (F === "V") {
        values[index].View = 1;
      } if (F === "A") {
        values[index].Add = 1;
      } if (F === "E") {
        values[index].Edit = 1;
      } if (F === "D") {
        values[index].Delete = 1;
      } if (F === "P") {
        values[index].Print = 1;
      }
    }
    else {
      if (F === "V") {
        values[index].View = 0;
      } if (F === "A") {
        values[index].Add = 0;
      } if (F === "E") {
        values[index].Edit = 0;
      } if (F === "D") {
        values[index].Delete = 0;
      } if (F === "P") {
        values[index].Print = 0;
      }
    }
    setListMenu(values);
  };

  async function IUD_Delete() {
    openPopup1();
  }

  async function Active() {
    var ID = selectedRowData.id;
    var Activestatus = selectedRowData.Activestatus;
    if (Activestatus == "Active") {
      Inactive(ID)
    } else {
      Activate(ID)
    }
  }


  async function Inactive(ID) {
    var Param = {
      "mstuserregid": ID,
      "flag": "INACT"
    };
    var url = "/UserReg/IUDUserInactiveActive";
    let _response = await Api.IUD(Param, url);


    await Checkvalidation();
    setShowPopup1(false);
    setShowPopup(false);
    toast.success(_response);

  }
  async function Activate(ID) {
    var Param = {
      "mstuserregid": ID,
      "flag": "ACT"
    };
    var url = "/UserReg/IUDUserInactiveActive";
    let _response = await Api.IUD(Param, url);


    await Checkvalidation();
    setShowPopup1(false);
    setShowPopup(false);
    toast.success(_response);
  }

  async function GetCityValue(value) {
    setDistrict(value)
    var Param = { districtName: value,cityName:"" }
    var url = "/CommanAPI/GetCity";
    let _response = await Api.BindGrid(Param, url)
    setCityList(_response)
}

  return (
    <div>
      <Base Employeecreate={true} />
      <Toaster toastOptions={{ position: "top-right" }} />
      <div id="main" className="main">
        <div class="pagetitle">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/dash">Home</Link>
              </li>

              <li class="breadcrumb-item">Registration</li>
              <li class="breadcrumb-item active">Employee Registration</li>
            </ol>
          </nav>
        </div>
        <input
          type="text"
          placeholder="Search..."
          onChange={onQuickFilterChange}
          style={{ marginBottom: '10px' }}
        />
        {Api.CheckAdd(10) == 1 ?
          <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed' }}>
            <Link to={'/Employeecreationform'}
              state={{ ID: 0, Flag: 'I', UserT: _Usertype, Page: "age_emp" }}
              style={{ color: "white" }}
            >
              Add
            </Link>
          </button>
          : null}
        {isLoading ? (
          <div className="d-flex justify-content-center my-5 pt-5">
            <div className='pt-5 mt-5'>
              <BeatLoader className='mt-5' />
            </div>
          </div>
        ) : (
          <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              pagination={true}
              paginationPageSize={10}
              domLayout='autoHeight'
              floatingFilter={true}
              enableBrowserTooltips={true}
              onCellClicked={onCellClicked}
              quickFilterText={quickFilterText}
            />
          </div>
        )}
        {showPopup && (
          <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
            <Modal.Header closeButton>
              <Modal.Title> {selectedRowData.code}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="row g-3">

                <div class="col-md-6">
                  <label for="usertype">User Type</label>
                  <select id="inputState" class="form-select" value={selectedUsertype} onChange={(e) => GetRoleType(e.target.value)}>
                    <option selected>User Type</option>
                    {usertype.map((i, index) => (
                      <option value={i.id}>{i.Text}</option>
                    ))}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="staffgrade">Staff Grade</label>
                  <select class="form-select" value={selectedStaffGrade} placeholder="Grade" onChange={(e) => getSelectedStaffGradeDdl(e)} >
                    <option selected>Select Staff Grade</option>
                    {staffGrade ? (
                      staffGrade.map((i, index) => (
                        <option value={i}>{i}</option>
                      ))) : (null)}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="gradetype">Grade Type</label>
                  <select onChange={(e) => getSelectedStaffGradeTypeDdl(e)} class="form-select" value={selectedGradeType} placeholder="GradeType">
                    <option selected>Select Grade Type</option>
                    {staffGradeType.map((i, index) => (
                      <option value={i.Mststaffgradetypeid}>{i.Automaticbaseedongradetype}</option>
                    ))}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="designation">Designation</label>
                  <input type="text" class="form-control" disabled={true} placeholder="Designation" value={staffDesignation} onChange={(e) => setDesignation(e.target.value)} />
                </div>
                <div class="col-md-6">
                  <label for="department">Department</label>
                  <select class="form-select" value={selectdepartment} onChange={(e) => setselectDepartment(e.target.value)} >
                    <option selected>Select Department Name</option>
                    {department ? (
                      department.map((i, index) => (
                        <option value={i.id}>{i.Text}</option>
                      ))) : (null)}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="empcode">Reporting Office Code</label>
                  <select class="form-select" value={SelectOFficeEmpCode} onChange={(e) => Getofficemployeename(e.target.value)} >
                    <option selected>Select Office code</option>
                    {ListemployeeCode ? (
                      ListemployeeCode.map((i, index) => (
                        <option value={i.id}>{i.Text}</option>
                      ))) : (null)}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="inchargename">Incharge Name</label>
                  <input type="text" class="form-control" disabled={true} placeholder="Incharge Name" value={officeinchargename} onChange={(e) => setOfficeinchargename(e.target.value)} />
                </div>

                {sessionStorage.getItem('AccessType') == 'Agent' ?
                  <>
                    <div class="col-md-6">
                      <label for="empcode">Reporting Employee Code</label>
                      <input type="text" class="form-control" disabled={true} placeholder="Employee Code" value={SelectReportEmpCode1} onChange={(e) => setSelectReportEmpCode1(e.target.value)} />
                    </div>

                    <div class="col-md-6">
                      <label for="inchargename">Employee Name</label>
                      <input type="text" class="form-control" disabled={true} placeholder="Employee Name" value={officeinchargename} onChange={(e) => setOfficeinchargename(e.target.value)} />
                    </div>
                  </>
                  :
                  <>
                    <div class="col-md-6">
                      <label for="empcode">Reporting Employee Code</label>
                      <select class="form-select" value={SelectReportEmpCode} onChange={(e) => GetReportemployeename(e.target.value)}>
                        <option selected>Select employee code</option>
                        {ListRepemployeeCode ? (
                          ListRepemployeeCode.map((i, index) => (
                            <option value={i.Text}>{i.Text}</option>
                          ))) : (null)}

                      </select>
                    </div>

                    <div class="col-md-6">
                      <label for="inchargename">Employee Name</label>
                      <input type="text" class="form-control" disabled={true} placeholder="Employee Name" value={officeEmployeename} onChange={(e) => setOfficeEmployeename(e.target.value)} />
                    </div>
                  </>
                }

                <h5 class="card-title"> Personal Details</h5>
                <div class="col-md-6">
                  <label for="name">Name</label>
                  <input type="text" class="form-control" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div class="col-md-6">
                  <label for="phonenumber">Phone Number</label>
                  <input type="number" maxlength="15" minlength="10" class="form-control no-spinner" placeholder="Phone Number" value={phone} onChange={(e) => setPhonenum(e.target.value)} />
                </div>
                <div class="col-md-6">
                  <label for="pannumber">Pan Number</label>
                  <input type="text" class="form-control" placeholder="Pan Number" value={pannumber} onChange={(e) => setPannum(e.target.value)} />
                </div>
                <div class="col-md-6">
                  <label for="email">Email</label>
                  <input type="email" class="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div class="col-md-6">
                  <label for="aadhar">Aadhar Number</label>
                  <input type="text" class="form-control no-spinner" placeholder="Aadhar Number" value={aadharnumber} onChange={(e) => setAadhaarnum(e.target.value)} />
                </div>
                <div class="col-md-6">
                  <label for="country">Country</label>
                  <select id="inputState" class="form-select" value={country} onChange={(e) => GetStateList(e.target.value)}>
                    <option selected>Country</option>
                    {CountryList.map((i, index) => (
                      <option value={i.country.toLowerCase()}>{i.country}</option>
                    ))}

                  </select>
                </div>
                <div class="col-md-6">
                  <label for="state">State</label>
                  <select id="inputState" class="form-select" value={state} onChange={(e) => GetCityList(e.target.value)}>
                    <option selected >State</option>
                    {StateList.map((i, index) => (
                      <option value={i.name.toLowerCase()}>{i.name}</option>
                    ))}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="district">District</label>
                  <select id="inputState" class="form-select" value={district} onChange={(e) => GetCityValue(e.target.value)}>
                    <option selected >District</option>
                    {districtlist &&
                      districtlist.map((i, index) => (
                        <option value={i.Text}>{i.Text}</option>
                      ))}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="city">City</label>
                  <select id="inputState" class="form-select" value={city} onChange={(e) => setCity(e.target.value)}>

                    <option >City</option>
                    {CityList.map((i, index) => (
                      <option value={i.CityName}>{i.CityName}</option>
                    ))}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="pincode">Pincode</label>
                  <input type="number" class="form-control no-spinner" placeholder="Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                </div>
                <div class="col-md-6">
                  <label for="address">Address</label>
                  <textarea class="form-control" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} ></textarea>
                </div>
                <h5 class="card-title"> Bank Details</h5>
                <div class="col-md-6">
                  <label for="holdername">Account Holder Name</label>
                  <input type="text" class="form-control" placeholder="Account Holder Name" value={acholder} onChange={(e) => setAcholder(e.target.value)} />
                </div>
                <div class="col-md-6">
                  <label for="accountnum">Account Number</label>
                  <input type="number" class="form-control no-spinner" placeholder="Account No" value={accountno} onChange={(e) => setAccountno(e.target.value)} />
                </div>
                <div class="col-md-6">
                  <label for="ifsc">IFSC Code</label>
                  <input type="text" class="form-control" placeholder="IFSC Code" value={ifsccode} onChange={(e) => setIfsccode(e.target.value)} />
                </div>
                <div class="col-md-6">
                  <label for="branchname">Branch Name</label>
                  <input type="text" class="form-control" placeholder="Branch Name" value={branchname} onChange={(e) => setBranchname(e.target.value)} />
                </div>
                <div class="col-md-6">
                  <label for="bankname">Bank Name</label>
                  <input type="text" class="form-control" placeholder="Bank Name" value={bankname} onChange={(e) => setbankname(e.target.value)} />
                </div>

                <div class="text-center">
                  {Api.CheckUpdate(10) == 1 ?
                    <button type="button" class="btn btn-primary" style={{ background: 'green' }} onClick={IUDOperation}>Update</button>
                    : null}
                  {Api.CheckDelete(10) == 1 ?
                    <button type="reset" class="btn btn-info" style={{ background: 'red', color: '#ffffff' }} onClick={IUD_Delete} >Delete</button>
                    : null}
                  <button type="reset" class="btn btn-info mx-3" onClick={Active} style={{ background: '#012fff', color: '#ffffff' }} >Active/Inactive</button>
                </div>

              </div>
            </Modal.Body>
          </Modal>
        )}
        {showPopup1 && (
          <Modal show={true} onHide={closePopup1} >
            <Modal.Header closeButton>
              <Modal.Title> {selectedRowData.code}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are You sure want to Delete?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePopup1}>
                Close
              </Button>
              <Button variant="secondary" onClick={IUDOperationDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  )
}
