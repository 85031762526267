import React, { useState, useEffect } from "react";
import Api from '../Services/ApiCall';
import Base from '../Base';
import { Link } from "react-router-dom";
import '../../App.css'
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "../Admin/Admin_form/Filter/Globalfilter";
import toast, { Toaster } from 'react-hot-toast';
import { Button, Modal } from 'react-bootstrap';

function Table({ columns, data }) {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        useGlobalFilter, useSortBy, usePagination
    );
    const { globalFilter } = state

    return (
        <div>
            <Globalfilter className="form-control" filter={globalFilter} setFilter={setGlobalFilter} />
            <table class="table table-striped" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}  >
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="fa-fa-sort-asc"></i>
                                                : <i className="fa-fa-sort-desc"></i>
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td  {...cell.getCellProps()} className={` ${cell.column.className ?? ""}`}>
                                            {cell.render("Cell")}</td>

                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <br></br>
            <div className="pagination">
                <nav class="paginate page_numbers" aria-label="Page navigation">



                </nav>
                <ul class="pagination first_last">

                    <li class="page-item"><a class="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {"First"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {"<"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                        {">"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {"Last"}</a>
                    </li>{" "}</ul>

                <span className="pageno">
                    Page{" "}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                </span>




                <div className='showpagesize' >
                    Show:{" "}
                    <select
                        className='showpagecss'
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[2, 10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select></div>
            </div>
        </div>
    );
}



export default function GuestTable(i, any) {
    const [popup, setpopup] = useState(false);

    const [AppUserid, setAppUserid] = useState('');

    const _Usertype1 = "Agent";


    const openPopup = (id) => {

        setAppUserid(id)
        setpopup(true);
    }
    function closeModal2() {
        setpopup(false);
    }

    async function Inactive(ID) {
        var Param = {
            "mstuserregid": AppUserid,
            "flag": "INACT"
        };
        var url = "/UserReg/IUDUserInactiveActive";
        let _response = await Api.IUD(Param, url);
        toast.success(_response);
        BindGrid(0)
        setpopup(false);
    }
    async function Active(ID) {
        var Param = {
            "mstuserregid": AppUserid,
            "flag": "ACT"
        };
        var url = "/UserReg/IUDUserInactiveActive";
        let _response = await Api.IUD(Param, url);
        toast.success(_response);
        BindGrid(0)
        setpopup(false);
    }


    const [listdata, setlistdata] = useState([]);




    const [Griddata, setlGriddata] = useState([]);
    let _a = [];
    const menu = JSON.parse(sessionStorage.getItem("roleaccess"))
    useEffect(() => {
        set_Usertype(_Usertype1)
        Checkvalidation()

    }, []);

    async function Checkvalidation() {


        try {
            if (checkmenu(5)[0]['View'] == 1) {
                set_Usertype("User")
                _a.push("User")
                let _a1 = [];
                _a1.push("User")
                var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a1) }
                var url = "/UserReg/GetUserRegtable";
                let _response1 = await Api.BindGrid(Param, url)
                // //console.log(_response1)
                setlistdata(_response1)

            }
            else if (checkmenu(3)[0]['View'] == 1) {
                set_Usertype("Agent")
                _a.push("Agent")
                let _a1 = [];
                _a1.push("Agent")
                var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a1) }
                var url = "/UserReg/GetUserRegtable";
                let _response1 = await Api.BindGrid(Param, url)
                // //console.log(_response1)
                setlistdata(_response1)
            }
            else if (checkmenu(4)[0]['View'] == 1) {
                set_Usertype("Employer")
                _a.push("Employer")
                let _a1 = [];
                _a1.push("Employer")
                var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a1) }
                var url = "/UserReg/GetUserRegtable";
                let _response1 = await Api.BindGrid(Param, url)
                ////console.log(_response1)
                setlistdata(_response1)
            }
            else if (checkmenu(2)[0]['View'] == 1) {
                set_Usertype("Employee")
                _a.push("Employee")
                let _a1 = [];
                _a1.push("Employee")
                var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a1) }
                var url = "/UserReg/GetUserRegtable";
                let _response1 = await Api.BindGrid(Param, url)
                ////console.log(_response1)
                setlistdata(_response1)
            }
        }
        catch {

        }

    }
    function checkmenu(id) {
        return menu.filter(function (menu) {
            try {

                return menu.MenuID == id;
            }
            catch {

                return menu.MenuID == id;
            }

        });
    }
    async function BindGrid() {
        let _a1 = [];
        _a1.push(_Usertype)
        var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a1) }
        var url = "/UserReg/GetUserRegtable";
        let _response1 = await Api.BindGrid(Param, url)
        // //console.log(_response1)
        setlistdata(_response1)
    }
    const [_Usertype, set_Usertype] = useState("Agent");
    async function checkBoxMenuValidation(e) {
        set_Usertype(e.target.value);
        let _a1 = [];
        _a1.push(e.target.value)
        var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a1) }
        var url = "/UserReg/GetUserRegtable";
        let _response1 = await Api.BindGrid(Param, url)

        setlistdata(_response1)
    }

    const [columns, setcolumns] = useState([

        {
            Header: "ID", Cell: (row) => {
                return <div>{Number(row.row.id) + 1}</div>;
            },
        },
        {
            Header: "Code", accessor: "Msusertregcode",
        },
        {
            Header: "Name", accessor: "Name",
        },
        // {
        //     Header: "MobileNo", accessor: "Mobilenumber",
        // },
        {
            Header: "Emailid", accessor: "Emailid",
        },
        {
            Header: "CreatedBy", accessor: "CreatedBy1",
        },
        // {
        //     Header: "AadharNo", accessor: "Aadhaarnumber",
        // },

        {
            Header: "Actions", Cell: (row) => {

                return <div>{<nav>

                    <ol class="breadcrumb">
                        {Api.CheckUpdate(11) == 1 ?
                            <li class="breadcrumb-item">
                                <Link to={'/guestform'} state={{ ID: row.data[row.row.id].Mstuserregid, Flag: 'U', UserT: i.Rolecab, Page: "age_emp1" }}>
                                    <i class="bi bi-pencil-square" /> </Link> </li>
                            : null}
                        {Api.CheckDelete(11) == 1 ?
                            <li class="breadcrumb-item active">
                                <Link to={'/guestform'} state={{ ID: row.data[row.row.id].Mstuserregid, Flag: 'D', UserT: i.Rolecab, Page: "age_emp1" }}>
                                    <i class="bi bi-trash" /></Link></li>
                            : null}
                        <li class="breadcrumb-item active">
                            <Link to={'/guestform'} state={{ ID: row.data[row.row.id].Mstuserregid, Flag: 'V', UserT: i.Rolecab, Page: "age_emp1" }}>
                                <i class="bi bi-eye" /></Link></li>

                    </ol>
                </nav>}</div>;
            }

        },
        {
            Header: "Status", accessor: "Activestatus",
        },

        {
            Header: "Active", Cell: (row) => {

                return <div>{<nav>

                    <ol class="breadcrumb">

                        <li class="breadcrumb-item active"
                            onClick={() => openPopup(row.data[row.row.id].Mstuserregid)} >

                            <i class="bi bi-check" />
                        </li>

                    </ol>
                </nav>}</div>;
            }
        }




    ])



    return (
        <div>

            <Base />
            <div id="main" class="main">
                <div class="pagetitle">
                    {/* <h1>Tables</h1> */}
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>

                            <li class="breadcrumb-item">Registration</li>
                            <li class="breadcrumb-item active">Super Admin Registration</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-lg-12">

                    <div class="card">
                        <div class="card-body alignleft">
                            <div class="divGroup col-md-12 disnone" >

                                {sessionStorage.getItem('AccessType') == 'User' ?
                                    <>
                                        <input type="radio" id="User" value="User" checked={_Usertype == "User"} onChange={(e) => checkBoxMenuValidation(e)} />
                                        <label for="User">User</label>
                                    </>
                                    : null}

                                {/* {checkmenu(11)[0]['View'] == 1 ? <> <input type="radio" id="Agent" value="Agent" checked={_Usertype == "Agent"} onChange={(e) => checkBoxMenuValidation(e)} />
                                    <label for="Agent">Agent</label>
                                </> : null} */}

                            </div>


                            <h5 class="card-title"> {_Usertype} </h5>
                            {/* {Api.checkmenu(14)[0]['Add'] == 1 ? */}
                            {sessionStorage.getItem('AccessType') == 'User' && _Usertype == 'User' ?
                                <button type="button" class="btn btn-primary Add">
                                    <Link to={'/guestform'}
                                        state={{ ID: 0, Flag: 'I', UserT: _Usertype }}
                                    >
                                        Add
                                    </Link>
                                </button>
                                :
                                <button type="button" class="btn btn-primary Add">
                                    <Link to={'/guestform'}
                                        state={{ ID: 0, Flag: 'I', UserT: "Agent" }}
                                    >
                                        Add
                                    </Link>
                                </button>

                            }
                            <Table columns={columns} data={listdata} />
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={popup} className='model-popup-design' style={{ marginBottom: "100px" }}>
                <Modal.Header classname="Userlgnmodalhead">
                    <Modal.Title>
                        Verify Status
                    </Modal.Title>
                    <button type="button" class="btn-close" data-dismis="modal" onClick={closeModal2} arial-label="Close"></button>
                    {/* <CloseButton variant="black" onClick={closeModal2} /> */}
                </Modal.Header>
                <div className='loginmodalbody' style={{ width: '785px' }}>
                    <Modal.Body className='testing-test' >
                        <form>
                            <h5>Do you want to update Status?</h5>
                        </form>

                    </Modal.Body>
                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => Active()}>
                        Active
                    </Button>
                    <Button variant="secondary" onClick={() => Inactive()}>
                        Inactive
                    </Button>


                </Modal.Footer>

            </Modal>
        </div>
    )
}