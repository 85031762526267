import React, { useState, useEffect } from 'react'
import Base from '../../Base';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Api from '../../Services/ApiCall';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';

export default function EmployeeDASCreationForm() {

  const navigate = useNavigate();
  const location = useLocation();
  const { ID, Flag } = location.state;
  ////console.log('%%%', ID, Flag);

  useEffect(() => {
    if (ID != 0) {
      // GetMenu(0)  
      //  GetUserType()
      Get(ID)

    }
    else {
      //Get(0) 
      // GetUserType()
    }


  }, []);
  // useEffect(()=>{
  //   Get();
  // },[]);

  const [date, setDate] = useState("");
  const [cusname, setCusname] = useState("");
  const [locate, setLocation] = useState("");
  const [meatingwith, setMeatingwith] = useState("");
  const [nxtdate, setNxtdate] = useState("");
  const [status, setStatus] = useState("");
  const [upload, setUpload] = useState("");

  async function BindGrid(id) {
    var Param = { Mstemployeedascreationid: id }
    var url = "/EmployeeDASCreation/BindEmployeedasCreation";
    let _response = await Api.BindGrid(Param, url)
    ////console.log(_response)
  }
  async function IUDOperation(flag, id) {
    var Mdate = date;
    var Cname = cusname;
    var Place = locate;
    var Meatingwith = meatingwith;
    var Schedule = nxtdate;
    var Status = status;
    var Upload = upload;

    //   if(Mdate==""){
    // toast.error('Please Enter Date')
    // return false;
    //   }
    if (Cname == "") {
      toast.error('Please Enter Customer Name')
      return false;
    }
    else {
      if (!(Cname.length > 3)) {
        toast.error('Please Enter Valid Customer Name')
        return false;

      }
    }
    if (Place == "") {
      toast.error('Please Enter a Location')
      return false;
    }
    else {
      if (!(Place.length > 3)) {
        toast.error('Please Enter a valid Place')
      }

    }
    //       if(Meatingwith==""){
    //         toast.error('Please select Meating with')
    //         return false;
    //             }

    if (Schedule == "") {
      toast.error('Please Enter Next Schedule Date')
    }
    // if (Upload==""){
    //   toast.error('Please Upload the file')
    //   }

    var Param = {
      "mstemployeedascreationid": ID,
      "employeedascreationcode": "string",
      // "edasdate": Mdate,
      "custname": Cname,
      "location": Place,
      "edas": 0,
      "nestschedule": Schedule,
      "flag": Flag,
      "status": Status,
      "chossefile": "Upload",
      "active": "string",
      "searchField": "string",
      "createdBy": 0,
      "createdDate": "2022-10-12T08:02:48.194Z"

    }
    var url = "/EmployeeDASCreation/IUDEmployeedasCreation";
    let _response = await Api.IUD(Param, url);
    showAlert(_response);
    setTimeout(() => {
      navigate('/EmployeeDASCreation_table')
    }, 2000)
    // //console.log(_response, "++++++");

  }
  const showAlert = (_response) => {
    Swal.fire({
      title: "Success",
      text: _response,
      icon: "success",
      confirmButtonText: "OK",
    });
  };


  async function Get(id) {
    var Param = { Mstemployeedascreationid: id }
    var url = "/EmployeeDASCreation/BindEmployeedasCreation";
    let _response1 = await Api.BindGrid(Param, url)
    // //console.log("response",_response1)    
    // setDate(_response1[0].date);
    setCusname(_response1[0].Custname);
    setLocation(_response1[0].Location);
    setNxtdate(_response1[0].Nestschedule);
    setStatus(_response1[0].Status);
    // setUpload(_response1[0].Chossefile);
  }

  return (
    <div>
      <Toaster toastOptions={{ position: "top-right" }} />
      <Base />
      <div id="main" class="main">
        <div class="pagetitle">
          <h1>Form</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
              <li class="breadcrumb-item"><Link to="/EmployeeDASCreation_table">Table</Link></li>
              <li class="breadcrumb-item active">EmployeeDASCreationForm</li>
            </ol>
          </nav>
        </div>

        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Form</h5>


            <div class="row g-3">


              <div class="col-md-6">
                <label for="date">Date</label>
                <input type="date" max={new Date().toISOString().split("T")[0]} class="form-control" placeholder="Date" disabled={Flag == "D" || Flag == "null" ? true : false} value={date} onChange={(e) => setDate(e.target.value)} />
              </div>
              <div class="col-md-6">
                <label for="customername">Customer Name</label>
                <input type="text" class="form-control" placeholder="Customer name" disabled={Flag == "D" || Flag == "null" ? true : false} value={cusname} onChange={(e) => setCusname(e.target.value)} />
              </div>

              <div class="col-md-6">
                <label for="location">Location</label>
                <input type="text" class="form-control" placeholder="Location" disabled={Flag == "D" || Flag == "null" ? true : false} value={locate} onChange={(e) => setLocation(e.target.value)} />
              </div>
              <div class="col-md-6">
                <label for="meetingwith">Meeting With</label>
                <select id="inputState" class="form-select" placeholder='Meeting' disabled={Flag == "D" || Flag == "null" ? true : false} selected="Meeting" value={meatingwith} onChange={(e) => setMeatingwith(e.target.value)} >
                  <option selected>Meeting With</option>
                  <option >Owner</option>
                  <option>Staff </option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="nextschedule">Next Schedule</label>
                <input type="text" class="form-control" placeholder="Next Schedule" disabled={Flag == "D" || Flag == "null" ? true : false} value={nxtdate} onChange={(e) => setNxtdate(e.target.value)} />
              </div>
              <div class="col-md-6">
                <label for="status">Status</label>
                <input type="text" class="form-control" placeholder="Status" disabled={Flag == "D" || Flag == "null" ? true : false} value={status} onChange={(e) => setStatus(e.target.value)} />
              </div>
              <div class="col-md-6">
                <label for="uploadfile">Upload File</label>
                <input type="file" class="form-control" placeholder="upload" disabled={Flag == "D" || Flag == "null" ? true : false} value={upload} onChange={(e) => setUpload(e.target.value)} />
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                <button type="reset" class="btn btn-info"><Link to="/EmployeeDASCreation_table">Back</Link></button>
              </div>
            </div>

          </div>
        </div>


      </div>
    </div>
  )
}
