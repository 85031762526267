import React, { useState, useEffect } from "react";
import Api from '../Services/ApiCall';
import Base from '../Base';
import '../../App.css'
import '../../Css/Pagination.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { Button, Modal } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "../Admin/Admin_form/Filter/Globalfilter";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import Swal from 'sweetalert2';
import { BeatLoader } from "react-spinners";
import Select from 'react-select';
import JobseekerBase from "../Jobseeker/JobseekerBase";
import EmployerBase from "./EmployerBase";

export default function Jobseekerlists(any, i) {
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup1, setShowPopup1] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [jobname, setJobname] = useState("");
    const [jobnamelist, setJobnamelist] = useState("");

    useEffect(() => {

        GetJobname()
    }, []);
    const handleChange = (value) => {
        setJobname(value)
        fetchDataAndSetState(value)
    }
    async function GetJobname() {

        var id = sessionStorage.getItem('MUserID')
        var url = "/DropDown/GetJobname?id=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setJobnamelist(_response)
    }


    const fetchDataAndSetState = async (value) => {
        setIsLoading(true);
        try {
            var id = (value)
            var url = "/DropDown/GetApplycandidate?id=" + id;
            let _response1 = await Api.BindDropdown(url);
            setRowData(_response1);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    const openPopup = (rowData) => {
        setSelectedRowData(rowData);
        var base64String = rowData.Data;
        // var base64String = Files.split(',')[1];
        // var type = Files.split(';')[0].split('/')[1];
        // setfiletype(type);
        setfile(base64String);
        setShowPopup(true);
    };
    const openPopup1 = (rowData) => {
        setShowPopup(false);
        setShowPopup1(true);
    };

    const closePopup1 = () => {
        setSelectedRowData(null);
        setShowPopup1(false);
    };
    const closePopup = () => {
        setSelectedRowData(null);
        setShowPopup(false);
    };

    const onCellClicked = (params) => {
        openPopup({ id: params.data.Id, Data: params.data.Text5 });
    };

    const columnDefs = [
        { headerName: 'Candidate Name', field: 'Text1', filter: true, enableFilter: true },
        { headerName: 'Job Name', field: 'Text2', filter: true, enableFilter: true },
        { headerName: 'Status', field: 'Text3', filter: true, enableFilter: true },
    ];

    const [quickFilterText, setQuickFilterText] = useState('');

    const onQuickFilterChange = (event) => {
        setQuickFilterText(event.target.value);
    };

    const [aadharnumber, setAadharno] = useState("");
    const [name, setName] = useState("");
    const [dob, setDob] = useState("");
    const [uploadFile, setuploadFile] = useState("");
    const [mobilenumber, setMobilenumber] = useState("");
    const [email, setEmail] = useState("");
    const [qualification, setQualification] = useState([]);
    const [filename, setfilename] = useState("");
    const [plan, setPlan] = useState("");
    const [amount, setAmount] = useState([]);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [pincode, setPincode] = useState("");
    const [CountryList, setCountryList] = useState();
    const [StateList, setStateList] = useState();
    const [CityList, setCityList] = useState();
    const [selectedPlan, setselectedPlan] = useState("");
    const [selectedQualiify, setselectedQualiify] = useState([]);
    const [formFields12, setFormFields] = useState([{ companyname: '', designation: '', years: '', month: '' },])
    const [formFields, setFormField] = useState([{ qualify: '', course: '', institution: '', passedout: '' },])
    useEffect(() => {
        GetQualification()
        // GetPlandetail()
        // GetAmount()
        GetCountryList()
        GetCityList()
        GetDistrict()
    }, []);
    const handleFormChange = (event, index, F) => {

        let data = [...formFields12];
        if (F == 1) {
            data[index].companyname = event.target.value;
        } if (F == 2) {
            data[index].designation = event.target.value;
        } if (F == 3) {
            data[index].years = event.target.value;
        } if (F == 4) {
            data[index].month = event.target.value;
        }
        setFormFields(data);
    }


    const handleFormChange1 = (event, index, F) => {

        let data = [...formFields];
        if (F == 1) {
            data[index].qualify = event.target.value;
        } if (F == 2) {
            data[index].course = event.target.value;
        } if (F == 3) {
            data[index].institution = event.target.value;
        } if (F == 4) {
            data[index].passedout = event.target.value;
        }
        setFormField(data);
    }
    const submit = (e) => {
        e.preventDefault();
    }
    const addFields = () => {
        let object = { companyname: '', designation: '', years: '', month: '' }

        setFormFields([...formFields12, object])
    }
    const removeFields = (index) => {
        let data = [...formFields12];
        data.splice(index, 1)
        setFormFields(data)
    }
    const addFields1 = () => {
        let object = { qualify: '', course: '', institution: '', passedout: '' }

        setFormField([...formFields, object])
    }
    const removeFields1 = (index) => {
        let data = [...formFields];
        data.splice(index, 1)
        setFormField(data)
    }
    async function GetCountryList() {
        let _response = await Api.DirectApiCall('country', "", "")
        setCountryList(_response)
    }
    async function GetStateList(value) {
        setCountry(value)
        var Param = { country: value }
        let _response = await Api.DirectApiCall('state', value)
        setStateList(_response.states)
    }
    async function GetCityList(value) {
        setState(value)
        var url = "/DropDown/GetCities";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setCityList(_response)
    }
    const [district, setDistrict] = useState("");
    const [districtlist, setDistrictlist] = useState("")
    async function GetDistrict() {
        var url = "/DropDown/GetDistrict";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setDistrictlist(_response)
    }
    async function GetPlandetail() {

        var url = "/DropDown/GetPlandetail";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setPlan(_response)
    }
    async function GetAmount(e) {
        setselectedPlan(e.target.value)
        var id = (e.target.value)
        var url = "/DropDown/GetPlandetailamount?id=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setAmount(_response)
    }
    async function GetQualification() {
        var url = "/DropDown/GetQualification";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setQualification(_response)
    }
    const uploadImage = async (e) => {
        const file = e.target.files[0];
        var filename = file.name;
        setfilename(filename)
        const base64 = await convertBase64(file);
        setuploadFile(base64);
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    }
    async function IUD_Delete() {
        openPopup1();
    }
    async function IUDOperation() {
        var AadharNo = aadharnumber;
        var Name = name;
        var Dateofbirth = dob;
        var Mobilenumber = mobilenumber;
        var Email = email;
        var Qualification = qualification;
        var Aadhar = aadharnumber;
        var UploadFile = uploadFile;
        var SelectedQualiify = selectedQualiify;
        var Country = country;
        var State = state;
        var City = city;
        var Address = address;
        var Pincode = pincode;
        var Plan = selectedPlan;
        var Amount = amount;
        var Flag = "U"
        var ID = selectedRowData.id

        var Param = {

            "mstjobseekerid": ID,
            "name": Name,
            "dataofbirth": Dateofbirth,
            "mobilenumber": Mobilenumber,
            "qualifcation": JSON.stringify(formFields12),
            "education": JSON.stringify(formFields),
            "usertype": JSON.stringify(selectedDistricts),
            "email": Email,
            "aadhaarnumber": Aadhar,
            "filedocument": UploadFile,
            "filename": "string",
            "active": "string",
            "searchField": "string",
            "flag": Flag,
            "createdBy": 0,
            "createdDate": "2022-11-28T10:02:17.804Z",
            "subscribe": "string",
            "plantype": "string",
            "planperiod": "string",
            "activestatus": "string",
            "planamount": 0,
            "mstqualifcation": SelectedQualiify,
            "country": Country,
            "state": State,
            "city": City,
            "pincode": Pincode,
            "address": Address,
            "plan": Plan,
            "district": district,


        };

        var url = "/JobSeekerTable/IUDJobSeekerTable";
        let _response = await Api.IUD(Param, url);

        showAlert(_response);
        setShowPopup(false);

    }
    async function IUDOperationDelete() {
        var AadharNo = aadharnumber;
        var Name = name;
        var Dateofbirth = dob;
        var Mobilenumber = mobilenumber;
        var Email = email;
        var Qualification = qualification;
        var Aadhar = aadharnumber;
        var UploadFile = uploadFile;
        var SelectedQualiify = selectedQualiify;
        var Country = country;
        var State = state;
        var City = city;
        var Address = address;
        var Pincode = pincode;
        var Plan = selectedPlan;
        var Amount = amount;
        var Flag = "D"
        var ID = selectedRowData.id

        var Param = {

            "mstjobseekerid": ID,
            "name": Name,
            "dataofbirth": Dateofbirth,
            "mobilenumber": Mobilenumber,
            "qualifcation": JSON.stringify(formFields12),
            "education": JSON.stringify(formFields),
            "usertype": JSON.stringify(selectedDistricts),
            "email": Email,
            "aadhaarnumber": Aadhar,
            "filedocument": UploadFile,
            "filename": "string",
            "active": "string",
            "searchField": "string",
            "flag": Flag,
            "createdBy": 0,
            "createdDate": "2022-11-28T10:02:17.804Z",
            "subscribe": "string",
            "plantype": "string",
            "planperiod": "string",
            "activestatus": "string",
            "planamount": 0,
            "mstqualifcation": SelectedQualiify,
            "country": Country,
            "state": State,
            "city": City,
            "pincode": Pincode,
            "address": Address,
            "plan": Plan,
            "district": district,


        };

        var url = "/JobSeekerTable/IUDJobSeekerTable";
        let _response = await Api.IUD(Param, url);

        showAlert(_response);
        setShowPopup1(false);

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const districtOptions = Array.isArray(districtlist)
        ? districtlist.map((district) => ({
            value: district.Text,
            label: district.Text
        }))
        : [];

    const [file, setfile] = useState()
    const [filetype, setfiletype] = useState()
    function base64toPDF(data, types) {
        var bufferArray = base64ToArrayBuffer(data);
        var blobStore = new Blob([bufferArray]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blobStore);
            return;
        }
        var data = window.URL.createObjectURL(blobStore);
        var link = document.createElement('a');
        document.body.appendChild(link)
            ;
        link.href = data;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(data);
        link.remove();
    }
    function base64ToArrayBuffer(data) {
        var data1 = data.replace('data:documents/pdf;base64,', '');
        var bString = window.atob(data1);
        var bLength = bString.length;
        var bytes = new Uint8Array(bLength);
        for (var i = 0; i < bLength; i++) {
            var ascii = bString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };

    async function Get(id) {

        var Param = { Mstjobseekerid: id }
        var url = "/JobSeekerTable/BindJobSeekerTable";
        let _response = await Api.BindGrid(Param, url)
        setAadharno(_response[0].Aadhaarnumber);
        setName(_response[0].Name);
        setDob(_response[0].Dataofbirth);
        setMobilenumber(_response[0].Mobilenumber);
        setEmail(_response[0].Email);
        setFormFields(JSON.parse(_response[0].Qualifcation));
        setselectedQualiify(_response[0].Mstqualifcation);
        setCountry(_response[0].Country);
        setSelectedDistricts(JSON.parse(_response[0].Usertype));
        setState(_response[0].State);
        setCity(_response[0].City);
        setAddress(_response[0].Address);
        setPincode(_response[0].Pincode);
        setselectedPlan(_response[0].Plan);
        setAmount(_response[0].Planamount);
        setDistrict(_response[0].District);
        setfilename(_response[0].Filename)
        var Files = _response[0].Filedocument;
        var base64String = Files.split(',')[1];
        var type = Files.split(';')[0].split('/')[1];
        setfiletype(type);
        setfile(base64String);
        setFormField(JSON.parse(_response[0].Education));
    }

    const downloadResume = () => {
        try {
            const byteCharacters = atob(file);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: `application/${filetype}` });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'resume.pdf';
            link.click();

            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error("Error decoding base64 string:", error);
        }
    }


    async function Active() {
        var ID = selectedRowData.id;
        var Activestatus = selectedRowData.Activestatus;
        if (Activestatus == "Active") {
            Inactive(ID)
        } else {
            Activate(ID)
        }
    }


    async function Inactive(ID) {
        var Param = {
            "mstjobseekerid": ID,
            "flag": "INACT"
        };
        var url = "/JobSeekerTable/IUDJobseekerInactiveActive";
        let _response = await Api.IUD(Param, url);


        await fetchDataAndSetState();
        setShowPopup1(false);
        setShowPopup(false);
        toast.success(_response);

    }
    async function Activate(ID) {
        var Param = {
            "mstjobseekerid": ID,
            "flag": "ACT"
        };
        var url = "/JobSeekerTable/IUDJobseekerInactiveActive";
        let _response = await Api.IUD(Param, url);


        await fetchDataAndSetState();
        setShowPopup1(false);
        setShowPopup(false);
        toast.success(_response);
    }
    async function ResumeDownload(selectedOptions) {
        var Param = {
            "mstjoblistid": 0,
            "joblistcode": JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode'],
            "jobtitle": "",
            "companyname": "",
            "experiencedetails": "",
            "vacancy": "",
            "disclosefromdate": "",
            "location": "",
            "skils": "",
            "jobdescription": "",
            "industrytype": "",
            "functionalarea": "",
            "role": "",
            "rolecategory": "",
            "employmenttype": "",
            "education": "",
            "active": "",
            "searchField": "",
            "createdBy": 0,
            "createdDate": "2022-11-17T05:12:20.480Z",
            "flag": "I",
            "disclosetodate": "",
        };
        var url = "/Postjobs/DownloadResume";
        let _response = await Api.IUD(Param, url);
        if (_response == "Insert successfully") {
            downloadResume()
        }
        else {
            alert(_response)
        }
    };

    return (
        <div>
            <div>

                <EmployerBase Appliedcandidate={true} />
                <div id="main" class="main">
                    <div class="pagetitle">
                        <nav>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <Link to="/dash">Home</Link>
                                </li>

                                <li class="breadcrumb-item active">Jobseeker List</li>
                            </ol>
                        </nav>
                    </div>
                    <input
                        type="text"
                        placeholder="Search..."
                        onChange={onQuickFilterChange}
                        style={{ marginBottom: '10px' }}
                    />
                    {Api.CheckAdd(13) == 1 ?
                        <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed' }}>
                            <Link to={'/JobseekerForm'}
                                state={{ ID: 0, Flag: 'I' }} style={{ color: "white" }}>
                                Add
                            </Link>
                        </button>
                        : null}
                    <div class="col-md-3 choosejob mb-3">

                        <label for="Companynaame">Choose Jobs Name</label>
                        <select id="inputState" class="form-select" value={jobname} onChange={(e) => handleChange(e.target.value)}>
                            <option selected>Job Name</option>

                            {jobnamelist &&
                                jobnamelist.map((i, index) => (
                                    <option value={i.id}>{i.Text}</option>
                                ))}
                        </select>
                    </div>

                    {isLoading ? (
                        <div className="d-flex justify-content-center my-5 pt-5">
                            <div className='pt-5 mt-5'>
                                <BeatLoader className='mt-5' />
                            </div>
                        </div>
                    ) : (
                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            <AgGridReact
                                rowData={rowData}
                                columnDefs={columnDefs}
                                pagination={true}
                                paginationPageSize={10}
                                domLayout='autoHeight'
                                floatingFilter={true}
                                enableBrowserTooltips={true}
                                onCellClicked={onCellClicked}
                                quickFilterText={quickFilterText}
                            />
                        </div>
                    )}
                    {showPopup && (
                        <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
                            <Modal.Header closeButton>
                                <Modal.Title> {selectedRowData.Id}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div class="row g-3">

                                    <div class="text-center">
                                        <button type="button" class="btn btn-primary" style={{ background: 'green' }} onClick={ResumeDownload}>Download Resume</button>
                                    </div>

                                </div>
                            </Modal.Body>
                        </Modal>
                    )}
                </div>

            </div>
        </div>
    )
}
