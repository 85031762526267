import React, { useState } from 'react'
import { Link } from "react-router-dom";
import '../Css/Register.css';
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { Images } from '../Images/Index';
import {
    validPhoneNumberRegex,
    validPasswordRegex,
    validEmailRegex,
    validOnlyEmailRegex,
    maxLength,
    numberMethod,
} from '../../src/Components/CommanRules'

const testt = {
    background: "rgb(0 0 0 / 26%)"
}


const styles2 = {

    float: "right",
    backgroundColor: 'primary',
    color: "white",
    borderColor: "none",
    height: "30px",
    width: "80px",
    marginRight: "880px",

}

export default function Register() {
    const [checked, setChecked] = useState(false); 
    const [EXPDetails, setEXPDetails] = useState("");
    const [checked1, setChecked1] = useState(false);

    const navigate = useNavigate();
    const test = (i) => { 
        setEXPDetails(i)
    }

    const test1 = () => {
        setChecked(false)
        setChecked1(true)
    }


    const [name, setName] = useState("");
    const [mobilenumber, setMobilenumber] = useState(""); 
    const [emailid, setEmailid] = useState("");
    const [file, setFile] = useState("");
   
    const Register = () => {
if(EXPDetails ==""){
    toast.error("sdfsdf")
}
        if (name.length > 3) {
            if (mobilenumber) {
                        if (validOnlyEmailRegex.test(emailid)) {
                            if (file){

                            }
                        
                        else {
                            toast.error('Please Choose File');
                        }
                    }
                            else {
                                toast.error('Please Enter valid Email id');
                            }
                        }

                        else {
                            toast.error('Please Enter Valid Mobile number');
                             }
                         }
                        else {
            toast.error('Please Enter Valid Name');
        }

    
    }



    return (
        <><div className="Formcontainer">
            <Toaster toastOptions={{ position: "top-right" }} />
            <form className="Formname">
                <img src={Images.mainlogo} alt="pelago" className="jfaimage" />
                <div className="content">
                    <h3 className="form-title">
                    </h3>

                    <div className="form-group mt-3">
                        <label>Name</label>
                        <input
                            type="Text"
                            className="form-control mt-1"

                            placeholder="Enter Name" onChange={(e) => setName(e.target.value)}  />
                    </div>
                    <div className="form-group mt-3">
                        <label>MobileNo</label>
                        <input
                            type="number"
                            className="form-control mt-1"
                            placeholder="Enter you Mobile No" onChange={(e) => setMobilenumber(e.target.value)}  />
                    </div>
                    <div className="form-group mt-3">
                        <label>EmailID</label>
                        <input
                            type="EmailID"
                            className="form-control mt-1"
                            placeholder="Enter you valid EmailID" onChange={(e) => setEmailid(e.target.value)}  />
                    </div>
                    <div className='cardproperty'>
                        <div class="row">

                            <div class="col1-xxl-4 col-md-6" >
                                <div class="card info-card sales-card" style={checked ? testt : {}} >

                                    <div class="card-body" onClick={test("Experienced")}>
                                        <h5 class="card-title" > Experienced</h5>
                                        <div class="d-flex align-items-center">
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="col2-xxl-4 col-md-6 ">
                                <div className="card info-card revenue-card" style={checked1 ? testt : {}}>


                                    <div className="card-body" onClick={test("Fresher")} >
                                        <h5 class="card-title">Fresher</h5>

                                        <div class="d-flex align-items-">

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        
                        <label class="form-label" for="customFile">Upload your resume</label>
                        <input type="file" className="btn btn-primary" id="customFile"  onChange={(e) => setFile(e.target.value)} />

                        {/* <button type="file" className="btn btn-primary" >
                            
                        </button> */}

                        <button type="button" className="btn btn-primary" onClick={Register}>
                            Submit
                        </button>
                        <a href>
                            <Link to='/signin'
                                onClick={() => navigate(-1)}>Already Registered click here
                            </Link></a>





                    </div>
                </div>




            </form>
        </div></>
    )
}
