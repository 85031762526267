import React, { useState,useRef, useEffect } from 'react';
import Api from '../Services/ApiCall';

import jsPDF from 'jspdf';
import JobseekerBase from './JobseekerBase';
import { Button, Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { BeatLoader } from "react-spinners";


export default function Appliedjobs() {

  const [rowData, setRowData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  useEffect(() => {
    bindgrid(0)
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  async function bindgrid(id) {
    setIsLoading(true);
    var id = sessionStorage.getItem('MUserID')
    var url = "/DropDown/GetApplyJobs?id=" + id;
    let _response1 = await Api.BindDropdown(url)
    setRowData(_response1)
    setIsLoading(false);

  }


  const [listdata, setJobseekerlistdata] = useState([]);
  const [listdata1, setJobseekerlistdata1] = useState([]);
  const [listdata2, setJobseekerlistdata2] = useState([]);
  const location = useLocation();
  const ID = sessionStorage.getItem("AccessType") == "jobseeker" ?
    JSON.parse(sessionStorage.getItem('userdetails'))[0]['Mstjobseekerid'] : location.state.ID


  const openPopup = (rowData) => {
    setSelectedRowData(rowData);
    BindGrid(ID)
    setShowPopup(true);
  };

  async function BindGrid(ID) {
    var Param = { mstjobseekerid: ID }
    var url = "/JobSeekerTable/BindJobSeekerTable";
    let _response1 = await Api.BindGrid(Param, url)
    setJobseekerlistdata(_response1)
    setJobseekerlistdata1(_response1[0].Education)
    setJobseekerlistdata2(_response1[0].Qualifcation)

  }
  const pdfRef = useRef(null);
  async function IUDOperation() {
    const content = pdfRef.current;
    const pdf = new jsPDF('pt', 'mm', [1500, 1000]);
    pdf.html(content, {
      callback: function (pdf) {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize('150');
          pdf.setTextColor('lightblue');
          // pdf.text('Elakiyaa Best Jobs', 750, 575, null, null, 'right');
        }
        pdf.save('Resume.pdf');
      }
    });
  }


  const openPopup1 = (rowData) => {
    setShowPopup(false);
    setShowPopup1(true);
  };

  const closePopup1 = () => {
    setSelectedRowData(null);
    setShowPopup1(false);
  };
  const closePopup = () => {
    setSelectedRowData(null);
    setShowPopup(false);
  };

  const onCellClicked = (params) => {
    openPopup({ Jobname: params.data.Text, Flag: 'VJR' });
  };

  const columnDefs = [
    { headerName: 'Applied Job Name', field: 'Text', filter: true, enableFilter: true },
    { headerName: 'Company Name', field: 'Text1', filter: true, enableFilter: true },
    { headerName: 'Date', field: 'Text2', filter: true, enableFilter: true },
  ];

  const [quickFilterText, setQuickFilterText] = useState('');

  const onQuickFilterChange = (event) => {
    setQuickFilterText(event.target.value);
  };


  return (
    <div>
      <JobseekerBase />
      <div id="main" className="main">
        <div class="pagetitle">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/Jobseekerdashboard">Home</Link>
              </li>

              <li class="breadcrumb-item">Report</li>
              <li class="breadcrumb-item active">Applied Jobs</li>
            </ol>
          </nav>
        </div>
        <input
          type="text"
          placeholder="Search..."
          onChange={onQuickFilterChange}
          style={{ marginBottom: '10px' }}
        />
{isLoading ? (
            <div className="d-flex justify-content-center my-5 pt-5">
              <div className='pt-5 mt-5'>
                <BeatLoader className='mt-5' />
              </div>
            </div>
          ) : (
        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={10}
            domLayout='autoHeight'
            floatingFilter={true}
            enableBrowserTooltips={true}
            onCellClicked={onCellClicked}
            quickFilterText={quickFilterText}
          />
        </div>
        )}
        {showPopup && (
          <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
            <Modal.Header closeButton>
              <Modal.Title> {selectedRowData.Jobname}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <button onClick={IUDOperation}>Download</button>
                <div ref={pdfRef} style={{ width: '100%' }}>
                  {listdata.length > 0 ?
                    <>
                      <div style=
                        {{
                          marginTop: "340px",
                          position: "absolute",
                          top: "100 %",
                          left: "59 %",
                          marginLeft: "170px",
                          transform: "translate(5%, 82%)",
                          opacity: "0.5",
                          fontSize: "4em",
                          fontWeight: "bold",
                          color: "#ccc"
                        }}>
                        Elakiyaa Best Jobs
                      </div>
                      {selectedRowData.Flag == "V" ?
                        <>
                          <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <div>
                              <h5>{(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Companyname'])}</h5>
                            </div>
                            <div>
                              <h5>{(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Companyaddress'])}</h5>
                            </div>
                            <div>
                              <h5>{(JSON.parse(sessionStorage.getItem('userdetails'))[0]['CompanyphoneNo'])}</h5>
                            </div>
                          </div>
                        </>
                        : null}
                      <hr></hr>
                      {selectedRowData.Flag == 'V' ? null :
                        <div><h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>Resume</h1></div>
                      }
                      <div style={{  marginTop: '40px' }}>
                        {selectedRowData.Flag == "V" || selectedRowData.Flag == "VJR" ?
                          <>
                            <div style={{ marginBottom: '50px' }}><h3>Application for the Post of:<span>{selectedRowData.Jobname}</span></h3></div>
                          </>
                          : null}
                        <div><h3>Jobseeker Code<span style={{ marginLeft: '135px' }}>:</span><span>{listdata[0].Mstjobseekercode}</span></h3></div>
                        <div><h3>Name<span style={{ marginLeft: '255px' }}>:</span><span>{listdata[0].Name}</span></h3></div>
                        <div><h3>Date of Birth<span style={{ marginLeft: '169px' }}>:</span><span >{listdata[0].Dataofbirth}</span></h3></div>
                        <div><h3>Mobile Number<span style={{ marginLeft: '137px' }}>:</span><span >{listdata[0].Mobilenumber}</span></h3></div>
                        <div><h3>Email<span style={{ marginLeft: '263px' }}>:</span><span >{listdata[0].Email}</span></h3></div>
                        <div><h3>Aadhaar Number<span style={{ marginLeft: '117px' }}>:</span><span >{listdata[0].Aadhaarnumber}</span></h3>
                        </div>
                        <div><h3>Country<span style={{ marginLeft: '234px' }}>:</span><span >{listdata[0].Country}</span></h3></div>
                        <div><h3>State<span style={{ marginLeft: '267px' }}>:</span><span >{listdata[0].State}</span></h3></div>
                        <div><h3>City<span style={{ marginLeft: '284px' }}>:</span><span >{listdata[0].City}</span></h3></div>
                        <div><h3>Pincode<span style={{ marginLeft: '235px' }}>:</span><span >{listdata[0].Pincode}</span></h3></div>
                        <div><h3>Address<span style={{ marginLeft: '230px' }}>:</span><span >{listdata[0].Address}</span></h3></div>
 
                        {selectedRowData.Flag == 'V' ?
                          <>
                            <div>
                              <h3>Declaration:</h3>
                            </div>
                            <div>
                              <h6>I have give the confirmation the above details are correct as per any knowledge.</h6>
                            </div>
                            <div>
                              <h6>I Agree to pay 10% of my first month salary to Elakiyaa Solution who is working as a consaltant of this job.</h6>
                            </div>
                          </>
                          : null}
                      </div>
                      <div>
                        <h5 style={{ textAlign: 'center', marginTop: '40px' }}>Thanks</h5>
                      </div>
                      <div>
                        <h5 style={{ textAlign:'end', marginTop: '40px' }}>Yours</h5>
                        <h5 style={{ textAlign:'end', marginTop: '40px', fontWeight: 'bold' }}>{listdata[0].Name}</h5>
                      </div>
                    </> : null}

                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}

      </div>
    </div>
  )
}

