import React, { useState, useEffect } from 'react';
import Base from './Base';
import Api from './Services/ApiCall';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

function Footer() {
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showTermsConditions, setShowTermsConditions] = useState(false);
    const [showAboutus, setShowAboutus] = useState(false);
    const [showRefundPolicy, setShowRefundpolicy] = useState(false);
    const navigate = useNavigate();
    const [place, setLocation] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [emailid1, setEmailid] = useState("");
    const [contactnumber, setContactnumber] = useState("");
    useEffect(() => {
        CompanyGet()
    }, []);



    async function CompanyGet(id) {
        var Param = { Mstcompanyprofileid: 3 }
        var url = "/Company/BindCompany";
        let _response = await Api.BindGrid(Param, url)

        setCompanyname(_response[0].Companyname);
        setLocation(_response[0].Location);
        setEmailid(_response[0].Emailid);
        setContactnumber(_response[0].Phonenumber);

    }

    const handlePrivacyPolicyClick = () => {
        // setShowPrivacyPolicy(true);
        navigate('/Privacy_policy')
    };

    const handleTermsConditionsClick = () => {
        // setShowTermsConditions(true);
        navigate('/Terms&condition')
    };
    const handleAboutusClick = () => {
        setShowAboutus(true);
    };
    const handleRefundpolicyClick = () => {
        // setShowRefundpolicy(true);
        navigate('/Refund_policy')
    };

    const handleClosePopup = () => {
        setShowPrivacyPolicy(false);
        setShowTermsConditions(false);
        setShowAboutus(false);
        setShowRefundpolicy(false);
    };

    return (
        <div>
            <div className="login_footer">
                {/* <div className="container"> */}
                <div className="row py-5 px-5">
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                        <div className="login_single_footer">
                            <h4>Services</h4>
                            <ul>
                                <li><a >ManPower Consultancy Service</a></li>
                                <ul>
                                    <li class="footerULlist">1) ManPower supply of child careactivities</li>
                                    <li class="footerULlist">2) Hospitality activities</li>
                                    <li class="footerULlist">3) Housekeeping activities</li>
                                </ul>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="login_single_footer login_single_footer_address">
                            <h4>Tips</h4>
                            <ul>
                                <li><a onClick={handleTermsConditionsClick}>Terms & Conditions</a></li>
                                <li><a onClick={handlePrivacyPolicyClick}>Privacy Policy</a></li>
                                <li><a onClick={handleRefundpolicyClick}>Refund Policy</a></li>
                                <li><a onClick={handleAboutusClick}>About us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="login_single_footer">
                            <h4 style={{marginLeft: '40px'}}>Contact</h4>
                            <ul>
                                <li className="mt-3">
                                    <span><FontAwesomeIcon icon={faBuilding} size="sm" /></span> {companyname}
                                </li>
                                <li className="mt-3">
                                    <span><FontAwesomeIcon icon={faPhone} size="sm" /></span> {contactnumber}
                                </li>
                                <li className="mt-3">
                                    <span><FontAwesomeIcon icon={faEnvelope} size="sm" /></span> {emailid1}
                                </li>
                                <li className="mt-3">
                                    <span><FontAwesomeIcon icon={faMapMarkerAlt} size="sm" /></span> {place}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>

            {showRefundPolicy && (
                <div className="login_popup-overlay">
                    <div className="login_popup">
                        <h2>Refund Policy</h2>
                        <p>
                            <h4>Payment and Refund Policy</h4>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>1)Payment for memberships:</span>
                            </div>
                            <div>
                                All payments for services at Elakiyaabestjobs.com have to be made in favour of "Elakiyaa Solution" only. All payments need to be made on 100% advance basis to continue to use uninterrupted Paid Services.
                                The Member, who is liable to pay Subscription Fees, shall pay it on demand even if the Member disputes the same for any reason. In the event that the Company deciding the dispute to be in the Member's favor, the Company shall refund to the Member any excess amount paid by the Member free of interest. In the event of late payment by the Member of any sums due under this Agreement, the Membership and the services would stand terminated.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>2)Refund / Money Back Policy:</span>
                            </div>
                            <div>
                                Once subscribed, the payment made by the Member for Paid service is not refundable and no claim for refund would be entertained. Elakiyaabestjobs.com neither offers any guarantees for the accuracy, timeliness, authenticity nor advocates any of the content posted by the other Members. All the services are on a best effort basis.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>3)Refund on the basis of fraudulent transactions:</span>
                            </div>
                            <div>
                                The Member is solely responsible for maintaining confidentiality, as well as all the activities and transmission performed through his My Folder. He/she shall be solely responsible for carrying out any online or off-line transaction involving credit cards / debit cards or such other forms of instruments or documents for making such transactions. Elakiyaabestjobs.com assumes no responsibility or liability for their improper use of information relating to such usage of credit cards / debit cards used by the subscriber online / off-line.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>4)Refund on the basis of change in preference / delay in process::</span>
                            </div>
                            <div>
                                As we at Elakiyaabestjobs.com keep up high interactions with our clients and carry out the entire process with their approval thus there is no provision for any kind of full or partial refund. Once an order is placed it cannot be cancelled as it is sent for processing immediately. Your personal preferences changed in the course of time, cannot serve a reason for refund or charge back.
                            </div>

                        </p>
                        <button onClick={handleClosePopup}>Close</button>
                    </div>
                </div>
            )}

            {showPrivacyPolicy && (
                <div className="login_popup-overlay">
                    <div className="login_popup">
                        <h2>Privacy Policy</h2>
                        <p>
                            {/* <div>

                                <span style={{ fontWeight: 'Bold' }}> Data security:</span> We take precautions to protect your data and to prevent abuse from the outside. It uses measures such as encryption (SSL encryption), firewalls, hacker defense programs and manual security measures. SSL encryption is active when the key icon at the bottom of your browser is closed and when the address starts with "https: //".

                            </div> */}
                            <div>
                                <span style={{ fontWeight: 'Bold' }}>WHAT DO WE DO WITH YOUR INFORMATION?:</span>
                            </div>
                            <div>
                                When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address and email address.
                                When you browse our store, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system.
                                Email marketing (if applicable): With your permission, we may send you emails about our store, new products and other updates.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>1)CONSENT:</span>
                            </div>
                            <div>
                                How do you get my consent?
                                When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only.
                                If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.
                                How do I withdraw my consent?
                                If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at <span style={{ fontWeight: 'Bold' }}>{emailid1} </span> or mailing us at: <span style={{ fontWeight: 'Bold' }}>{place} </span>
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>2)DISCLOSURE:</span>
                            </div>
                            <div>
                                We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>3)PAYMENT:</span>
                            </div>
                            <div>
                                We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.
                                Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.
                                PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers.
                                For more insight, you may also want to read terms and conditions of razorpay on https://razorpay.com
                            </div>


                            <div>
                                <span style={{ fontWeight: 'Bold' }}>4)THIRD-PARTY SERVICES:</span>
                            </div>
                            <div>
                                In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.
                                However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.
                                For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.
                                In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.
                                Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.
                                Links
                                When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.

                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>5)SECURITY:</span>
                            </div>
                            <div>
                                To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>6)COOKIES:</span>
                            </div>
                            <div>
                                We use cookies to maintain session of your user. It is not used to personally identify you on other websites.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>7)AGE OF CONSENT:</span>
                            </div>
                            <div>
                                By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>8)CHANGES TO THIS PRIVACY POLICY:</span>
                            </div>
                            <div>
                                We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
                                If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>9)REGISTRATION DATA:</span>
                            </div>
                            <div>
                                is defined as all the information and particulars of the Member requested on initial application and subscription, including but without limiting to the Member's name, mailing address, email address, account and telephone number. The words can be used either in the singular or plural sense and those implying one gender shall also include the other.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>10)RATES:</span>
                            </div>
                            <div>
                                The subscription rates for the Services shall be at the price indicated in the Rate Card or the Company may prescribe such other rates from time to time. Liability for the Subscription Fees shall accrue from the Date of Activation.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>11)Data security:</span>
                            </div>
                            <div>
                                We take precautions to protect your data and to prevent abuse from the outside. It uses measures such as encryption (SSL encryption), firewalls, hacker defense programs and manual security measures. SSL encryption is active when the key icon at the bottom of your browser is closed and when the address starts with "https: //".
                                Data transfer is then protected by SSL (Secure Socket Layer) with 128-bit encryption, which prevents third parties from reading your data illegally. If the encryption is not active, you should consider carefully whether you still want to send sensitive information over the Internet.
                                For this reason we recommend our users in this case to replace the sent password with a new personal password. You can change your password at any time in "elakiyaabestjobs.com" under "Change Password". We only store personal data that you enter in "elakiyaabestjobs.com". No other peson-related data is stored.
                                To ensure that your personal data receives adequate levels of protection, we have implemented appropriate measures with third parties with whom we share your personal data in order to ensure that your personal data is obtained from these third parties in a consistent manner with data protection which is consistent with data protection laws.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}>QUESTIONS AND CONTACT INFORMATION:</span>
                            </div>
                            <div>
                                If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our Privacy Compliance Officer at <span style={{ fontWeight: 'Bold' }}>{emailid1} </span> or by mail at <span style={{ fontWeight: 'Bold' }}>{place} </span>
                            </div>
                        </p>
                        <button onClick={handleClosePopup}>Close</button>
                    </div>
                </div>
            )}
            {showAboutus && (
                <div className="login_popup-overlay1">
                    <div className="login_popup1">
                        <h2>About us</h2>
                        <p>Comming Soon.........</p>
                        <button onClick={handleClosePopup}>Close</button>
                    </div>
                </div>
            )}

            {showTermsConditions && (
                <div className="login_popup-overlay">
                    <div className="login_popup">
                        <h2>Terms & Conditions</h2>
                        <p>
                            {/* <div>
                                <span style={{ fontWeight: 'Bold' }}> 1)Acceptance of Terms:</span> By using the website, you agree to the following terms and conditions.
                            </div> */}
                            <div>
                                This website is operated by <span style={{ fontWeight: 'Bold' }}>{companyname} </span>. Throughout the site, the terms “we”, “us” and “our” refer to <span style={{ fontWeight: 'Bold' }}>{companyname} </span>. <span style={{ fontWeight: 'Bold' }}>{companyname} </span>offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
                                By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply  to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.
                                Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
                                Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 1)ONLINE STORE TERMS:</span>
                            </div>
                            <div>
                                By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
                                You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
                                You must not transmit any worms or viruses or any code of a destructive nature.
                                A breach or violation of any of the Terms will result in an immediate termination of your Services.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 2)GENERAL CONDITIONS:</span>
                            </div>
                            <div>
                                We reserve the right to refuse service to anyone for any reason at any time.
                                You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
                                You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.
                                The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 3)ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION:</span>
                            </div>
                            <div>
                                We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.
                                This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.

                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 4)MODIFICATIONS TO THE SERVICE AND PRICES:</span>
                            </div>
                            <div>
                                Prices for our products are subject to change without notice.
                                We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
                                We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 5)PRODUCTS OR SERVICES:</span>
                            </div>
                            <div>
                                Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.
                                We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.
                                We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.
                                We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 6)ACCURACY OF BILLING AND ACCOUNT INFORMATION:</span>
                            </div>
                            <div>
                                We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
                                You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
                                For more detail, please review our Returns Policy.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 7)THIRD-PARTY LINKS:</span>
                            </div>
                            <div>
                                Certain content, products and services available via our Service may include materials from third-parties.
                                Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
                                We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 8)PERSONAL INFORMATION:</span>
                            </div>
                            <div>
                                Your submission of personal information through the store is governed by our Privacy Policy.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 9)INDEMNIFICATION:</span>
                            </div>
                            <div>
                                You agree to indemnify, defend and hold harmless <span style={{ fontWeight: 'Bold' }}>{companyname} </span> and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 10)SEVERABILITY:</span>
                            </div>
                            <div>
                                In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 11)TERMINATION:</span>
                            </div>
                            <div>
                                The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
                                These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
                                If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 12)ENTIRE AGREEMENT:</span>
                            </div>
                            <div>
                                The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
                                These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
                                Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 13)GOVERNING LAW:</span>
                            </div>
                            <div>
                                These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of India .
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 14)CHANGES TO TERMS OF SERVICE:</span>
                            </div>
                            <div>
                                You can review the most current version of the Terms of Service at any time at this page.
                                We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
                            </div>

                            <div>
                                <span style={{ fontWeight: 'Bold' }}> 15)CONTACT INFORMATION:</span>
                            </div>
                            <div>
                                Questions about the Terms of Service should be sent to us at <span style={{ fontWeight: 'Bold' }}>{emailid1} </span>.
                            </div>
                        </p>
                        <button onClick={handleClosePopup}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Footer;
