import React, { useState, useEffect } from 'react';
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import Swal from 'sweetalert2';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { validOnlyEmailRegex, validPhoneNumberRegex } from '../../CommanRules';
import toast, { Toaster } from 'react-hot-toast';
import '../../../App.css';

export default function CompanyProfileForm() {
    const location = useLocation();
    const { ID, Flag } = location.state;
    const navigate = useNavigate();

    useEffect(() => {
        Get();
    }, []);

    const [companyname, setCompanyname] = useState("");
    const [place, setLocation] = useState("");
    const [website, setWebsite] = useState("");
    const [emailid, setEmailid] = useState("");
    const [contactnumber, setContactnumber] = useState("");
    const [pannumber, setPannumber] = useState("");
    const [bankname, setBankName] = useState("");
    const [bankaccnumber, setBankAccNumber] = useState("");
    const [bankbranch, setBankBranch] = useState("");
    const [bankifsc, setBankIFSC] = useState("");
    const [logo, setLogo] = useState("");
    const [image1, setImage1] = useState("");
    const [image2, setImage2] = useState("");
    const [image3, setImage3] = useState("");
    const [image4, setImage4] = useState("");
    const [image5, setImage5] = useState("");
    const [gstnumber, setGstNumber] = useState("");

    async function IUDOperation() {
        if (!companyname) return toast.error('Please enter the Company name');
        if (!place) return toast.error('Please enter Location');
        if (!website) return toast.error('Please enter Website name');
        if (!validOnlyEmailRegex.test(emailid)) return toast.error('Please enter a valid Email ID');
        if (!validPhoneNumberRegex.test(contactnumber)) return toast.error('Please enter a valid Contact number');
        if (!pannumber) return toast.error('Please enter PAN Number');
        if (!bankname) return toast.error('Please enter Bank Name');
        if (!bankaccnumber) return toast.error('Please enter Bank Account Number');
        if (!bankbranch) return toast.error('Please enter Bank Branch');
        if (!bankifsc) return toast.error('Please enter Bank IFSC');
        if (!gstnumber) return toast.error('Please enter GST');

        const Param = {
            mstcompanyprofileid: ID,
            companyprofilecode: "string",
            companyname,
            location: place,
            emailid,
            website,
            phonenumber: contactnumber,
            pannumber,
            bankname,
            bankaccountnumber: bankaccnumber,
            bankbranch,
            bankifsc,
            logo: JSON.stringify(logo),
            active: "string",
            flag: Flag,
            searchField: gstnumber,
            createdBy: 0,
            image1: JSON.stringify(image1),
            image2: JSON.stringify(image2),
            image3: JSON.stringify(image3),
            image4: JSON.stringify(image4),
            image5: JSON.stringify(image5),
        };

        try {
            const _response = await Api.IUD(Param, "/Company/IUDCompany");
            showAlert(_response);
            setTimeout(() => {
                navigate('/companyprofiletable');
            }, 2000);
        } catch (error) {
            console.error("Error in IUDOperation:", error);
            toast.error("Something went wrong. Please try again.");
        }
    }

    async function Get() {
        try {
            const Param = { Mstcompanyprofileid: 3 };
            const _response = await Api.BindGrid(Param, "/Company/BindCompany");

            if (_response && _response.length > 0) {
                const companyData = _response[0];
                setCompanyname(companyData.Companyname);
                setLocation(companyData.Location);
                setWebsite(companyData.Website);
                setEmailid(companyData.Emailid);
                setContactnumber(companyData.Phonenumber);
                setPannumber(companyData.Pannumber);
                setBankName(companyData.Bankname);
                setBankAccNumber(companyData.Bankaccountnumber);
                setBankBranch(companyData.Bankbranch);
                setBankIFSC(companyData.Bankifsc);
                setLogo(JSON.parse(companyData.Logo || '{}'));
                setImage1(JSON.parse(companyData.Image1 || '{}'));
                setImage2(JSON.parse(companyData.Image2 || '{}'));
                setImage3(JSON.parse(companyData.Image3 || '{}'));
                setImage4(JSON.parse(companyData.Image4 || '{}'));
                setImage5(JSON.parse(companyData.Image5 || '{}')); 
                setGstNumber(companyData.SearchField);
            }
        } catch (error) {
            console.error("Error in Get:", error);
            toast.error("Failed to fetch company data. Please try again.");
        }
    }

    const uploadImage = (e, flag) => {
        let file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = handleReaderLoaded.bind(this, flag);
            reader.readAsBinaryString(file);
        }
    };

    const handleReaderLoaded = (flag, e) => {
        let binaryString = e.target.result;
        const imageData = { base64Data: btoa(binaryString) };

        switch (flag) {
            case 'logo':
                setLogo(imageData);
                break;
            case 'image1':
                setImage1(imageData);
                break;
            case 'image2':
                setImage2(imageData);
                break;
            case 'image3':
                setImage3(imageData);
                break;
            case 'image4':
                setImage4(imageData);
                break;
            case 'image5':
                setImage5(imageData);
                break;
            default:
                break;
        }
    };

    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <Base compayprofile={true} />
            <div id="main" className="main">
                <div className="pagetitle">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li className="breadcrumb-item">General Setting</li>
                            <li className="breadcrumb-item active">Profile</li>
                        </ol>
                    </nav>
                </div>

                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Company Profile</h5>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <label htmlFor="companyname">Company Name</label>
                                <input type="text" className="form-control" value={companyname} placeholder="Company Name" onChange={(e) => setCompanyname(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="place">Place</label>
                                <input type="text" className="form-control" value={place} placeholder="Location" onChange={(e) => setLocation(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="website">Website</label>
                                <input type="text" className="form-control" value={website} placeholder="Website" onChange={(e) => setWebsite(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="email">Email Id</label>
                                <input type="text" className="form-control" value={emailid} placeholder="Email Id" onChange={(e) => setEmailid(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="contactnumber">Contact Number</label>
                                <input type="number" maxLength={10} className="form-control no-spinner" value={contactnumber} placeholder="Contact number" onChange={(e) => setContactnumber(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="gstnumber">GST Number</label>
                                <input type="text" className="form-control" value={gstnumber} onChange={(e) => setGstNumber(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="pannumber">Pan Number</label>
                                <input type="text" className="form-control" value={pannumber} placeholder="Pan Number" onChange={(e) => setPannumber(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="bankname">Bank Name</label>
                                <input type="text" className="form-control" value={bankname} placeholder="Bank Name" onChange={(e) => setBankName(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="bankaccnumber">Bank Account Number</label>
                                <input type="number" className="form-control no-spinner" value={bankaccnumber} placeholder="Bank Account Number" onChange={(e) => setBankAccNumber(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="bankbranch">Bank Branch</label>
                                <input type="text" className="form-control" value={bankbranch} placeholder="Bank Branch" onChange={(e) => setBankBranch(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="bankifsc">Bank IFSC</label>
                                <input type="text" className="form-control" value={bankifsc} placeholder="Bank IFSC" onChange={(e) => setBankIFSC(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="logo">Logo</label>
                                <input type="file" className="form-control" onChange={(e) => uploadImage(e, 'logo')} />
                                {logo?.base64Data && <img src={`data:image/jpeg;base64,${logo.base64Data}`} alt="Logo" className="img-thumbnail mt-2" />}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="image1">Image 1</label>
                                <input type="file" className="form-control" onChange={(e) => uploadImage(e, 'image1')} />
                                {image1?.base64Data && <img src={`data:image/jpeg;base64,${image1.base64Data}`} alt="Image 1" className="img-thumbnail mt-2" />}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="image2">Image 2</label>
                                <input type="file" className="form-control" onChange={(e) => uploadImage(e, 'image2')} />
                                {image2?.base64Data && <img src={`data:image/jpeg;base64,${image2.base64Data}`} alt="Image 2" className="img-thumbnail mt-2" />}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="image3">Image 3</label>
                                <input type="file" className="form-control" onChange={(e) => uploadImage(e, 'image3')} />
                                {image3?.base64Data && <img src={`data:image/jpeg;base64,${image3.base64Data}`} alt="Image 3" className="img-thumbnail mt-2" />}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="image4">Image 4</label>
                                <input type="file" className="form-control" onChange={(e) => uploadImage(e, 'image4')} />
                                {image4?.base64Data && <img src={`data:image/jpeg;base64,${image4.base64Data}`} alt="Image 4" className="img-thumbnail mt-2" />}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="image5">Image 5</label>
                                <input type="file" className="form-control" onChange={(e) => uploadImage(e, 'image5')} />
                                {image5?.base64Data && <img src={`data:image/jpeg;base64,${image5.base64Data}`} alt="Image 5" className="img-thumbnail mt-2" />}
                            </div>
                            <div class="text-center">
                                <button className="btn btn-primary" onClick={IUDOperation}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
