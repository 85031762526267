import React, { useState, useEffect } from 'react'
import Base from '../../Base';
import { Link, useNavigate } from "react-router-dom";
import Api from '../../Services/ApiCall';
import Swal from 'sweetalert2';
import '../../../App.css'
import { useLocation } from "react-router-dom";
import {
    validPhoneNumberRegex,
    validPasswordRegex,
    validEmailRegex,
    validOnlyEmailRegex,
    maxLength,
    numberMethod,
} from '../../CommanRules';
import toast, { Toaster } from 'react-hot-toast';

import Staffgradedesignation from '../../Admin/Staffgradedesignation';
export default function Guestform() {


    const location = useLocation();
    const { ID, Flag, UserT, Page } = location.state; 
    useEffect(() => {
        set_Usertype(UserT)
        GetMenu(0)
        GetQualification()
        GetUserType()
        bindStaffGradeDdl(0)
        getEmployeeCode()
        GetPlandetail()
        GetAmount()
        GetDistrict()
        GetDepartment()
        GetCountryList()
        GetCityList()
        getRepEmployeeCode()
        if (ID != 0) {
            Get(ID)
        }
        else {
            GetDepartment()
        }
    }, []);
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [phone, setPhonenum] = useState("");
    const [pannumber, setPannum] = useState("");
    const [aadharnumber, setAadhaarnum] = useState("");
    const [email, setEmail] = useState("");
    const [qualification, setQualification] = useState([]);
    const [usertype, setUsertype] = useState([]);
    const [department, setDepartment] = useState([]);
    const [selectdepartment, setselectDepartment] = useState("");
    const [bankpassbook, setBankpassbook] = useState("");
    const [aadharcard, setAadharcard] = useState("");
    const [pancard, setPancard] = useState("");
    const [bankpassbookThumbnail, setBankpassbookThumbnail] = useState(null);
    const [aadharcardThumbnail, setAadharcardThumbnail] = useState(null);
    const [pancardThumbnail, setPancardThumbnail] = useState(null);

    const [selectedUsertype, setselectedUsertype] = useState([]);
    const [selectedGradeType, setselectedGradeType] = useState("");
    const [selectedGrade, setselectedGrade] = useState("");
    const [user, setUser] = useState("");
    const [address, setAddress] = useState("");
    const [grade, setGrade] = useState("");
    const [designation, setDesignation] = useState("");
    const [agentcode, setAgentcode] = useState("");

    const [officecode, setOfficecode] = useState("");
    const [_Usertype, set_Usertype] = useState("");

    const [ListMenu, setListMenu] = useState([]);
    const [reportempcode, setReportempcode] = useState("")
    const [state, setState] = useState("")
    const [country, setCountry] = useState("")
    const [city, setCity] = useState("")
    const [pincode, setPincode] = useState("")
    const [acholder, setAcholder] = useState("")
    const [accountno, setAccountno] = useState("")
    const [ifsccode, setIfsccode] = useState("")
    const [branchname, setBranchname] = useState("")
    const [bankname, setbankname] = useState("")
    const [reportingempcode, setreportingempcode] = useState("")
    const [accountholdername, seaccountholdername] = useState("")
    const [selectedQualiify, setselectedQualiify] = useState([]);
    const [gradetype, setgradetype] = useState([]);
    const [staffGrade, setStaffGrade] = useState([]);
    const [selectedStaffGrade, setSelectedStaffGrade] = useState([]);
    const [staffGradeType, setStaffGradeType] = useState([]);
    const [staffDesignation, setStaffDesignation] = useState("");
    const [selectedPlan, setselectedPlan] = useState("");
    const [plan, setPlan] = useState("");
    const [amount, setAmount] = useState([]);
    const [employeebranch, setEmployeebranch] = useState("");
    const [incharge, setIncharge] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [companynumber, setCompanynumber] = useState("");
    const [companyaddress, setCompanyaddress] = useState("");
    const [companygst, setCompanygst] = useState("");
    const [SelectReportEmpCode, setSelectReportEmpCode] = useState("");
    const [ListRepemployeeCode, setListRepemployeeCode] = useState([]);
    const [CountryList, setCountryList] = useState([]);
    const [StateList, setStateList] = useState([]);
    const [CityList, setCityList] = useState([]);
    const [officeEmployeename, setOfficeEmployeename] = useState("");
    const [ListemployeeGrade, setListemployeeGrade] = useState([]);
    const [ListemployeeCode, setListemployeeCode] = useState([]);
    const [ListemployeeDesignation, setListemployeeDesignation] = useState("");
    const [SelectOfficegrade, setSelectOfficegrade] = useState("");
    const [SelectOFficeEmpCode, setSelectOFficeEmpCode] = useState("");
    const [SelectOFficeDesignation, setSelectOFficeDesignation] = useState("");
    const [officeinchargename, setOfficeinchargename] = useState("");
    const [AadharImage, setAadharImage] = useState("");
    const [PanImage, setPanImage] = useState("");
    const [BankImage, setBankImage] = useState("");

    async function getRepEmployeeCode() {
        var url = "/DropDown/GetRole_with_management";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setListRepemployeeCode(_response)

    }


    async function GetReportemployeename(e) {

        setSelectReportEmpCode(e);
        var url = "/DropDown/GetEmployeeName_using_Code?code=" + e;
        let _response = await Api.BindDropdown(url)
        setOfficeEmployeename(_response.Text);

    }
    async function GetCountryList() {
        let _response = await Api.DirectApiCall('country', "", "")
        setCountryList(_response)
    }
    async function GetStateList(value) {
        setCountry(value)
        var Param = { country: value }
        let _response = await Api.DirectApiCall('state', value)
        setStateList(_response.states)
    }
    async function GetCityList(value) {
        setState(value) 
    }

    const [district, setDistrict] = useState("");
    const [districtlist, setDistrictlist] = useState("")

    async function GetDistrict() {

        var url = "/DropDown/GetDistrict";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setDistrictlist(_response)
    }



    async function getSelectedStaffGradeTypeDdl(e) {
        setselectedGradeType(e.target.value);
        var Param = { Mststaffgradetypeid: e.target.value }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response = await Api.BindGrid(Param, url);
        setStaffDesignation(_response[0].Designation);
    }
    async function getEmployeeCode() {
        var url = "/DropDown/GetOfficetypecode";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setListemployeeCode(_response)
    }








    async function GetUserType() {

        var url = "/DropDown/GetRole?code=Employee";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];

        if (JSON.parse(sessionStorage.getItem('userdetails'))[0]['UsertypeName'] == 'Business Associates') {

            _response = _response.filter(function (el) {
                return el.Text == "Agents ";
            });
            setUsertype(_response)
        }
        else {
            setUsertype(_response)
        }
    }
    async function getSelectedStaffGradeDdl(e) {
        setGrade(e.target.value);
        setSelectedStaffGrade(e.target.value);
        var staffGrade = e.target.options[e.target.selectedIndex].text;
        var Param = { mststaffgradedesignationid: 0 }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response1 = await Api.BindGrid(Param, url)
        _response1 = _response1.filter(function (el) {
            return el.Staffgradetypecode == e.target.value;
        });
        var sGrade = [...new Set(_response1.map(q => {
            var x = {
                Automaticbaseedongradetype: q.Automaticbaseedongradetype,
                Mststaffgradetypeid: q.Mststaffgradetypeid
            }
            return x;
        }))]
        setStaffGradeType(sGrade);
    }
    async function GetStaffGrademannual(id) {

        try {

            var Param = { mststaffgradedesignationid: 0 }
            var url = "/StaffGradeType/BindStaffGradeType";
            let _response121 = await Api.BindGrid(Param, url);
            _response121 = _response121.filter(function (el) {
                return el.Role == id;
            });
            var sGrade = [...new Set(_response121.map(q => q.Staffgradetypecode))]
            setStaffGrade(sGrade);
        } catch {
        }
    }
    async function GetStaffGradeTypemannual(id) {

        var Param = { mststaffgradedesignationid: 0 }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response1 = await Api.BindGrid(Param, url)
        _response1 = _response1.filter(function (el) {
            return el.Staffgradetypecode == id;
        });
        var sGrade = [...new Set(_response1.map(q => {
            var x = {
                Automaticbaseedongradetype: q.Automaticbaseedongradetype,
                Mststaffgradetypeid: q.Mststaffgradetypeid
            }
            return x;
        }))]
        setStaffGradeType(sGrade);
    }

    async function GetPlandetail() {
        var url = "/DropDown/GetEmployerdetail";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setPlan(_response)
    }

    async function GetAmount(e) {
        setselectedPlan(e.target.value)
        var id = (e.target.value)
        var url = "/DropDown/GetEmployeramount?id=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setAmount(_response)
    }
    async function GetQualification() {

        var url = "/DropDown/GetQualification";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setQualification(_response)
    }
    async function GetMenu(id) {
        var Param = { mstmenuid: id }
        var url = "/Menu/BindMenu";
        let _response = await Api.BindGrid(Param, url)
        setListMenu(_response);
    }
    async function GetRoleType(id) {
        var Param = { Mstroleid: id }
        var url = "/Role/BindRole";
        let _response1 = await Api.BindGrid(Param, url);
        setselectedUsertype(id)
        setListMenu(JSON.parse(_response1[0].RoleList));
        await bindStaffGradeDdl(id)
    }

    async function GetDepartment() {

        var url = "/DropDown/GetDepartment";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setDepartment(_response)
    }

    async function GetCityValue(value) {
        setDistrict(value)
        var Param = { districtName: value,cityName:"" }
        var url = "/CommanAPI/GetCity";
        let _response = await Api.BindGrid(Param, url)
        setCityList(_response)
    }

    async function GetGradeType(id) {
        setselectedGrade(id)
        var url = "/DropDown/GetGradeType?GradeCode=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        try {

            setgradetype(_response[0].Text.split(","))
        }
        catch { }
    }
    async function bindStaffGradeDdl(id) {
        var Param = { mststaffgradedesignationid: 0 }
        var url = "/StaffGradeType/BindStaffGradeType";
        try {

            let _response1 = await Api.BindGrid(Param, url);
            _response1 = _response1.filter(function (el) {
                return el.Role == id;
            });
            var sGrade = [...new Set(_response1.map(q => q.Staffgradetypecode))]
            setStaffGrade(sGrade);
        } catch {
        }
    }

    async function GetGradeTypesManual(ID) {
        var Param = { Staffgradetypecode: ID }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response = await Api.BindGrid(Param, url)
        setStaffGradeType(_response);
    }
    async function getSelectedStaffGradeTypeDdl(e) {
        setselectedGradeType(e.target.value);
        var Param = { Mststaffgradetypeid: e.target.value }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response = await Api.BindGrid(Param, url);
        setStaffDesignation(_response[0].Designation);
    }
    async function Getofficemployeename(e) {
        setSelectOFficeEmpCode(e);
        var Param = { Mstofficetypecreationid: e }
        var url = "/DropDown/GetInchargeNmae?id=" + e;
        let _response = await Api.BindGrid(Param, url);
        setOfficeinchargename(_response[0].Text);
        if (sessionStorage.getItem('AccessType') == 'Agent') {
            setSelectReportEmpCode(_response[0].Text2);
        }
    }
    const [agree, setAgree] = useState(false);
    async function IUDOperation(Flag) { 
        var Name = name;
        var Mobile = phone;
        var Pan = pannumber;
        var Qualify = selectedQualiify;
        var User = selectedUsertype;
        var Email = email;
        var Aadhar = aadharnumber;
        var Address = address;
        var State = state;
        var Country = country;
        var City = city;
        var Pincode = pincode;
        var Acholder = acholder;
        var Accountno = accountno;
        var Ifsccode = ifsccode;
        var Branchname = branchname;
        var SelectedStaffGrade = selectedStaffGrade;
        var StaffGradeType = staffGradeType;
        var Department = selectdepartment;
        var Reportempcode = reportingempcode;
        var Bankname = bankname;
        var Agree = agree;


        if (Flag != 'D') {


            if ((Name == "")) {

                toast.error('Enter  the Name')
                return false;
            }

            else {
                if (!(Name.length > 3)) {
                    toast.error('Enter Valid Name')
                    return false;

                }
            }
            if (Mobile == "") {
                toast.error('Enter Mobile Number')
                return false;
            }
             
            if (Email == "") {
                toast.error('Enter the Email Id')
                return false;
            }
            else {
                if (!validOnlyEmailRegex.test(Email)) {
                    toast.error('Enter the Valid Email Id')
                    return false;
                }
            }
 
        }

        var Param =
        {
            "mstuserregid": ID,
            "msusertregcode": "",
            "name": name,
            "mobilenumber": Mobile,
            "pannumber": pannumber,
            "qualification": "1",
            "usertype": selectedUsertype,
            "emailid": email,
            "aadhaarnumber": aadharnumber,
            "rolecab": _Usertype,
            "agGradetype": selectedGradeType,
            "agGrade": selectedStaffGrade.length == 0 ? "" : selectedStaffGrade,
            "agDesignation": staffDesignation,
            "department": Department,
            "agAgentcode": agentcode,
            "officecode": officecode,
            "reportingempcode": SelectOFficeEmpCode,
            "city": city,
            "state": state,
            "country": country,
            "address": address,
            "pincode": pincode,
            "accountholdername": acholder,
            "accountnumber": accountno,
            "ifsccode": ifsccode,
            "branchname": branchname,
            "bankname": bankname,
            "rolevscab": JSON.stringify(ListMenu),
            "flag": Flag,
            "searchField": _Usertype,
            "district": district,
            "planperiod": SelectReportEmpCode,
            "aadharImage": aadharcard,
            "panImage": pancard,
            "bankImage": bankpassbook
        }
        var url = "/UserReg/IUDUserRegtable";
        let _response = await Api.IUD(Param, url);
        showAlert(_response)
        setTimeout(() => {
            {
                navigate('/guest_table')
            }
        }, 2000)

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function Get(id) {
        var Param = { Mstuserregid: id }
        var url = "/UserReg/GetUserRegtable";

        let _response = await Api.BindGrid(Param, url)
        setName(_response[0].Name)
        setPhonenum(_response[0].Mobilenumber)
        setSelectOFficeEmpCode(_response[0].Reportingempcode)
        Getofficemployeename(_response[0].Reportingempcode)
        setSelectReportEmpCode(_response[0].Planperiod)
        GetReportemployeename(_response[0].Planperiod)
        setPannum(_response[0].Pannumber)
        setselectedQualiify(_response[0].Qualification)
        setselectedUsertype(_response[0].Usertype)
        setEmail(_response[0].Emailid)
        setAadhaarnum(_response[0].Aadhaarnumber)
        set_Usertype(_response[0].Rolecab)
        setGrade(_response[0].selectedGradeType)
        setGrade(_response[0].AgGradetype)
        await GetStaffGrademannual(_response[0].Rolecab)
        setSelectedStaffGrade(_response[0].AgGradetype)
        await GetGradeTypesManual(_response[0].AgGrade)
        await GetStaffGradeTypemannual(_response[0].AgGrade)
        setselectedGradeType(_response[0].AgGradetype)
        setStaffDesignation(_response[0].AgDesignation)
        setselectDepartment(_response[0].Departmentname)
        setselectDepartment(_response[0].Department)
        setAgentcode(_response[0].AgAgentcode)
        setOfficecode(_response[0].Officecode)
        setCity(_response[0].City)
        setCountry(_response[0].Country)
        setState(_response[0].State)
        setAddress(_response[0].Address)
        setPincode(_response[0].Pincode)
        setAcholder(_response[0].Accountholdername)
        setAccountno(_response[0].Accountnumber)
        setIfsccode(_response[0].Ifsccode)
        setDistrict(_response[0].District)
        setBranchname(_response[0].Branchname)
        setbankname(_response[0].Bankname)
        setIfsccode(_response[0].Ifsccode)
        setDistrict(_response[0].District)
        setAadharImage(JSON.parse(_response[0].AadharImage))
        setPanImage(JSON.parse(_response[0].PanImage))
        setBankImage(JSON.parse(_response[0].BankImage))
        setOfficeinchargename(_response[0].Text)
        setOfficecode(_response[0].Officecode)
        setSelectOFficeEmpCode(_response[0].Planperiod)
        Getofficemployeename(_response[0].Reportingempcode)


        set_Usertype(_response[0].Rolecab)
        setBranchname(_response[0].Branchname)
        setSelectOFficeEmpCode(_response[0].Planperiod)

        setListMenu(JSON.parse(_response[0].Rolevscab))

    }


    const handleOnChange = (event, option, index, F) => {

        const values = [...ListMenu];
        if (event.target.checked) {
            if (F === "V") {
                values[index].View = 1;
            } if (F === "A") {
                values[index].Add = 1;
            } if (F === "E") {
                values[index].Edit = 1;
            } if (F === "D") {
                values[index].Delete = 1;
            } if (F === "P") {
                values[index].Print = 1;
            }
        }
        else {
            if (F === "V") {
                values[index].View = 0;
            } if (F === "A") {
                values[index].Add = 0;
            } if (F === "E") {
                values[index].Edit = 0;
            } if (F === "D") {
                values[index].Delete = 0;
            } if (F === "P") {
                values[index].Print = 0;
            }
        }
        setListMenu(values);
    };

    const uploadbankImage = (e, flag) => {
        let file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = handlebankReaderLoaded.bind(this, flag);
            reader.readAsBinaryString(file);
        }
    };
    const handlebankReaderLoaded = (flag, e) => {
        let binaryString = e.target.result;
        if (flag == 'bankpassbook') {
            setBankpassbook({
                base64Data: btoa(binaryString),
            });
        }
    };

    const uploadaadharImage = (e, flag) => {
        let file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = handleaadharReaderLoaded.bind(this, flag);
            reader.readAsBinaryString(file);
        }
    };
    const handleaadharReaderLoaded = (flag, e) => {
        let binaryString = e.target.result;
        if (flag == 'aadhar') {
            setAadharcard({
                base64Data: btoa(binaryString),
            });
        }
    };
    const uploadpancardImage = (e, flag) => {
        let file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = handlepancardReaderLoaded.bind(this, flag);
            reader.readAsBinaryString(file);
        }
    };
    const handlepancardReaderLoaded = (flag, e) => {
        let binaryString = e.target.result;
        if (flag == 'pancard') {
            setPancard({
                base64Data: btoa(binaryString),
            });
        }
    };

    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <Base  />
            <div id="main" class="main">
                <div class="pagetitle">

                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">Registration</li>
                            {/* <li class="breadcrumb-item active">Agent Registration</li> */}
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">{_Usertype} Creation</h5>

                        <div class="row g-3">

                            {/* <div class="divGroup col-md-12">
    <input type="radio" id="User" value="User" disabled={_Usertype=="User"}  checked={_Usertype=="User"} onChange={(e) => set_Usertype(e.target.value)}/><label for="User">User</label>
        <input type="radio" id="Agent" value="Agent"disabled={_Usertype=="Agent"} checked={_Usertype=="Agent"} onChange={(e) => set_Usertype(e.target.value)}/><label for="Agent">Agent</label>
    <input type="radio" id="Employee"  value="Employee"disabled={_Usertype=="Employee"} checked={_Usertype=="Employee"} onChange={(e) => set_Usertype(e.target.value)}/><label for="Employee">Employee</label>
    <input type="radio" id="Employer" value="Employer"disabled={_Usertype=="Employer"}  checked={_Usertype=="Employer"} onChange={(e) => set_Usertype(e.target.value)}/><label for="Employer">Employer</label>
</div> */}


                            {_Usertype === "Agent" || _Usertype === "Employee" ?
                                <>
                                    <div class="col-md-6">
                                        <label for="usertype">User Type</label>
                                        <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "V" ? true : false} value={selectedUsertype} onChange={(e) => GetRoleType(e.target.value)}>
                                            <option selected>User Type</option>
                                            {usertype.map((i, index) => (
                                                <option value={i.id}>{i.Text}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="staffgrade">Staff Grade</label>
                                        <select class="form-select" value={selectedStaffGrade} placeholder="Grade" disabled={Flag == "D" || Flag == "V" ? true : false} onChange={(e) => getSelectedStaffGradeDdl(e)} >
                                            <option selected>Select Staff Grade</option>
                                            {staffGrade ? (
                                                staffGrade.map((i, index) => (
                                                    <option value={i}>{i}</option>
                                                ))) : (null)}
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="gradetype">Grade Type</label>
                                        <select onChange={(e) => getSelectedStaffGradeTypeDdl(e)} class="form-select" disabled={Flag == "D" || Flag == "V" ? true : false} value={selectedGradeType} placeholder="GradeType">
                                            <option selected>Select</option>
                                            {staffGradeType.map((i, index) => (
                                                <option value={i.Mststaffgradetypeid}>{i.Automaticbaseedongradetype}</option>
                                            ))}
                                        </select>

                                    </div>
                                    <div class="col-md-6">
                                        <label for="designation">Designation</label>
                                        <input type="text" class="form-control" disabled={true} placeholder="Designation" value={staffDesignation} onChange={(e) => setDesignation(e.target.value)} />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="department">Department</label>
                                        <select class="form-select" disabled={Flag == "D" || Flag == "V" ? true : false} value={selectdepartment} onChange={(e) => setselectDepartment(e.target.value)} >
                                            <option selected>Select Department Name</option>
                                            {department ? (
                                                department.map((i, index) => (
                                                    <option value={i.id}>{i.Text}</option>
                                                ))) : (null)}
                                        </select>

                                    </div>
                                    {/* <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="Agent Code" value={agentcode} onChange={(e) => setAgentcode(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="Office Code" value={officecode} onChange={(e) => setOfficecode(e.target.value)} />
                            </div> */}



                                    <div class="col-md-6">
                                        <label for="empcode">Reporting Office Code</label>
                                        <select class="form-select" value={SelectOFficeEmpCode} disabled={Flag == "D" || Flag == "V" ? true : false} onChange={(e) => Getofficemployeename(e.target.value)} >
                                            <option selected>Select Office code</option>
                                            {ListemployeeCode ? (
                                                ListemployeeCode.map((i, index) => (
                                                    <option value={i.id}>{i.Text}</option>
                                                ))) : (null)}
                                        </select>
                                    </div>

                                    <div class="col-md-6">
                                        <label for="inchargename">Incharge Name</label>
                                        <input type="text" class="form-control" disabled={true} placeholder="Incharge Name" value={officeinchargename} onChange={(e) => setOfficeinchargename(e.target.value)} />
                                    </div>

                                    {sessionStorage.getItem('AccessType') == 'Agent' ?
                                        <>
                                            <div class="col-md-6">
                                                <label for="empcode">Reporting Employee Code</label>
                                                <input type="text" class="form-control" disabled={true} placeholder="Employee Code" value={SelectReportEmpCode} onChange={(e) => setSelectReportEmpCode(e.target.value)} />
                                            </div>

                                            <div class="col-md-6">
                                                <label for="inchargename">Employee Name</label>
                                                <input type="text" class="form-control" disabled={true} placeholder="Employee Name" value={officeinchargename} onChange={(e) => setOfficeinchargename(e.target.value)} />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div class="col-md-6">
                                                <label for="empcode">Reporting Employee Code</label>
                                                <select class="form-select" value={SelectReportEmpCode} onChange={(e) => GetReportemployeename(e.target.value)}>
                                                    <option selected>Select employee code</option>
                                                    {ListRepemployeeCode ? (
                                                        ListRepemployeeCode.map((i, index) => (
                                                            <option value={i.Text}>{i.Text}</option>
                                                        ))) : (null)}

                                                </select>
                                            </div>

                                            <div class="col-md-6">
                                                <label for="inchargename">Employee Name</label>
                                                <input type="text" class="form-control" disabled={true} placeholder="Employee Name" value={officeEmployeename} onChange={(e) => setOfficeEmployeename(e.target.value)} />
                                            </div>
                                        </>
                                    }

                                </> : null}


                            {_Usertype === "Employer" ?
                                <>
                                    <div class="col-md-6">
                                        <label for="companyname">Company Name</label>
                                        <input type="text" class="form-control" disabled={Flag == "D" || Flag == "V" ? true : false} placeholder="Company Name" value={companyname} onChange={(e) => setCompanyname(e.target.value)} />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="companycontactnumber">Company Contact Number</label>
                                        <input type="number" class="form-control no-spinner" disabled={Flag == "D" || Flag == "U" || Flag == "V" ? true : false} placeholder="Company Contact Number" value={companynumber} onChange={(e) => setCompanynumber(e.target.value)} />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="companyaddress">Company Address</label>
                                        <textarea class="form-control" disabled={Flag == "D" || Flag == "V" ? true : false} placeholder="Company Address" value={companyaddress} onChange={(e) => setCompanyaddress(e.target.value)} ></textarea>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="gstnumber">GST Number</label>
                                        <input type="text" class="form-control" disabled={Flag == "D" || Flag == "V" ? true : false} placeholder="Company  GST" value={companygst} onChange={(e) => setCompanygst(e.target.value)} />
                                    </div>
                                </>
                                : null}
                            <h5 class="card-title"> Personal Details</h5>
                            <div class="col-md-6">
                                <label for="name">Name</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "V" ? true : false} placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="phonenumber">Phone Number</label>
                                <input type="number" maxlength="15" minlength="10" class="form-control no-spinner" disabled={Flag == "D" || Flag == "U" || Flag == "V" ? true : false} placeholder="Phone Number" value={phone} onChange={(e) => setPhonenum(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="pannumber">Pan Number</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "V" ? true : false} placeholder="Pan Number" value={pannumber} onChange={(e) => setPannum(e.target.value)} />
                            </div>
                            {/* <div class="col-md-6">
                                <select id="inputState" class="form-select" value={selectedQualiify} onChange={(e) => setselectedQualiify(e.target.value)}  >
                                    <option selected>Qualification</option>
                                    {qualification.map((i, index) => (
                                        <option value={i.id}>{i.Text}</option>
                                    ))}
                                </select>
                            </div> */}
                            {_Usertype === "Agent" || _Usertype === "Employee" ?
                                <></> :
                                <div class="col-md-6">
                                    <label for="usertype">User Type</label>
                                    <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "V" ? true : false} value={selectedUsertype} onChange={(e) => GetRoleType(e.target.value)}>
                                        <option selected>User Type</option>
                                        {usertype.map((i, index) => (
                                            <option value={i.id}>{i.Text}</option>
                                        ))}
                                    </select>
                                </div>}
                            <div class="col-md-6">
                                <label for="email">Email</label>
                                <input type="email" class="form-control" disabled={Flag == "D" || Flag == "U" || Flag == "V" ? true : false} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="aadhar">Aadhar Number</label>
                                <input type="text" class="form-control no-spinner" disabled={Flag == "D" || Flag == "U" || Flag == "V" ? true : false} placeholder="Aadhar Number" value={aadharnumber} onChange={(e) => setAadhaarnum(e.target.value)} />
                            </div>

                            <div class="col-md-6">
                                <label for="country">Country</label>
                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "V" ? true : false} value={country} onChange={(e) => GetStateList(e.target.value)}>
                                    <option selected>Country</option>
                                    {CountryList.map((i, index) => (
                                        <option value={i.country.toLowerCase()}>{i.country}</option>
                                    ))}

                                </select>
                            </div>

                            <div class="col-md-6">
                                <label for="state">State</label>
                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "V" ? true : false} value={state} onChange={(e) => GetCityList(e.target.value)}>
                                    <option selected >State</option>
                                    {StateList.map((i, index) => (
                                        <option value={i.name.toLowerCase()}>{i.name}</option>
                                    ))}

                                </select>
                            </div>

                            <div class="col-md-6">
                                <label for="district">District</label>
                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "V" ? true : false} value={district} onChange={(e) => GetCityValue(e.target.value)}>
                                    <option selected >District</option>
                                    {districtlist &&
                                        districtlist.map((i, index) => (
                                            <option value={i.Text}>{i.Text}</option>
                                        ))}
                                </select>
                            </div>

                            <div class="col-md-6">
                                <label for="city">City</label>
                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "V" ? true : false} value={city} onChange={(e) => setCity(e.target.value)}>

                                    <option >City</option>
                                    {CityList.map((i, index) => (
                                        <option value={i.CityName}>{i.CityName}</option>
                                    ))}
                                </select>
                            </div>

                            <div class="col-md-6">
                                <label for="pincode">Pincode</label>
                                <input type="number" class="form-control no-spinner" placeholder="Pincode" disabled={Flag == "D" || Flag == "V" ? true : false} value={pincode} onChange={(e) => setPincode(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="address">Address</label>
                                <textarea class="form-control" placeholder="Address" value={address} disabled={Flag == "D" || Flag == "V" ? true : false} onChange={(e) => setAddress(e.target.value)} ></textarea>
                            </div>
                            {/* {_Usertype==="Employee"?<>
                            <div class="col-md-6">
                                <label for="employeebranch">Employee Branch</label>
                                <input type="text" class="form-control" placeholder="employeebranch" disabled={Flag == "D" || Flag == "V" ? true : false} value={employeebranch} onChange={(e) => setEmployeebranch(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="incharge">Incharge</label>
                                <input type="text" class="form-control" placeholder="incharge" value={incharge} disabled={Flag == "D" || Flag == "V" ? true : false} onChange={(e) => setIncharge(e.target.value)} />
                            </div>
                            </>
                                : null} */}
                            {_Usertype === "Employer" ? <>
                                <div class="col-md-6">
                                    <label for="plan"> Plan</label>
                                    <select id="inputState" class="form-select" value={selectedPlan} onChange={(e) => GetAmount(e)}  >
                                        <option selected>Plan</option>
                                        {
                                            plan && plan.map((i, index) => (
                                                <option value={i.id} >{i.Text}</option>
                                            ))}
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="amount"> Amount</label>
                                    {amount && amount.map((i, index) => (

                                        <>

                                            <input type="number" key={index} disabled={true} class="form-control" placeholder="Amount" value={i.Text} />
                                        </>
                                    )
                                    )}
                                </div>
                            </>
                                : null}
                            {/* <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="Role Cab" />
                            </div> */}

                            {_Usertype === "Agent" || _Usertype === "Employee" ? <>
                                <h5 class="card-title"> Bank Details</h5>
                                <div class="col-md-6">
                                    <label for="holdername">Account Holder Name</label>
                                    <input type="text" class="form-control" disabled={Flag == "D" || Flag == "V" ? true : false} placeholder="Account Holder Name" value={acholder} onChange={(e) => setAcholder(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="accountnum">Account Number</label>
                                    <input type="number" class="form-control no-spinner" disabled={Flag == "D" || Flag == "V" ? true : false} placeholder="Account No" value={accountno} onChange={(e) => setAccountno(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="ifsc">IFSC Code</label>
                                    <input type="text" class="form-control" disabled={Flag == "D" || Flag == "V" ? true : false} placeholder="IFSC Code" value={ifsccode} onChange={(e) => setIfsccode(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="branchname">Branch Name</label>
                                    <input type="text" class="form-control" disabled={Flag == "D" || Flag == "V" ? true : false} placeholder="Branch Name" value={branchname} onChange={(e) => setBranchname(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="bankname">Bank Name</label>
                                    <input type="text" class="form-control" disabled={Flag == "D" || Flag == "V" ? true : false} placeholder="Bank Name" value={bankname} onChange={(e) => setbankname(e.target.value)} />
                                </div>
                                {_Usertype === 'Agent' ? (
                                    <>
                                        <div class="col-md-6">
                                            <label for="bankpassbook">Bank Pass Book Image</label>
                                            <input type="file" class="form-control" disabled={Flag === 'D' || Flag === 'V'} placeholder="Bank Pass Book image" onChange={(e) => uploadbankImage(e, 'bankpassbook')} />
                                            {bankpassbookThumbnail && (
                                                <img src={bankpassbookThumbnail} alt="Bank Pass Book Thumbnail" width="100" height="100"
                                                />
                                            )}
                                        </div>
                                        <div class="col-md-6">
                                            <label for="aadharcard">Aadhar Card Image</label>
                                            <input type="file" class="form-control" disabled={Flag === 'D' || Flag === 'V'} placeholder="Aadhar card image" onChange={(e) => uploadaadharImage(e, 'aadhar')}
                                            />
                                            {aadharcardThumbnail && (
                                                <img src={aadharcardThumbnail} alt="Aadhar Card Thumbnail" width="100" height="100" />
                                            )}
                                        </div>
                                        <div class="col-md-6">
                                            <label for="Pancard">Pan Card Image</label>
                                            <input type="file" class="form-control" disabled={Flag === 'D' || Flag === 'V'}
                                                placeholder="Pan card image" onChange={(e) => uploadpancardImage(e, 'pancard')}
                                            />
                                            {pancardThumbnail && (
                                                <img src={pancardThumbnail} alt="Pan Card Thumbnail" width="100" height="100" />
                                            )}
                                        </div>
                                        <div class="col-md-6">
                                            <img src={`data:image;base64,${BankImage}`} style={{ width: "25%" }} />
                                        </div>
                                        <div class="col-md-6">
                                            <img src={`data:image;base64,${AadharImage}`} style={{ width: "25%" }} />
                                        </div>
                                        <div class="col-md-6">
                                            <img src={`data:image;base64,${PanImage}`} style={{ width: "25%" }} />
                                        </div>



                                    </>
                                ) : null}
                            </>
                                : null}
                            {/* {Flag == "V" ? null :
                                <> */}
                            {sessionStorage.getItem('AccessType') == 'User' ?

                                <div class="col-md-12">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>

                                                <th scope="col">Menu Name</th>
                                                <th scope="col">Rights</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ID == 0 ? <>
                                                {ListMenu.map((i, index) => (
                                                    <tr>
                                                        <td>{i.Menuname}</td>
                                                        <td>
                                                            <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "V")} checked={i.View === 1} value="" id={"View_" + i.Mstmenuid + "_"} />View
                                                            <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "A")} checked={i.Add === 1} value="" id={"Add_" + i.Mstmenuid + "_"} />Add
                                                            <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "E")} checked={i.Edit === 1} value="" id={"Edit_" + i.Mstmenuid + "_"} />Edit
                                                            <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "P")} checked={i.Print === 1} value="" id={"Print_" + i.Mstmenuid + "_"} />Print
                                                            <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "D")} checked={i.Delete === 1} value="" id={"Delete_" + i.Mstmenuid + "_"} /> Delete
                                                        </td>
                                                    </tr>

                                                ))}
                                            </>

                                                :
                                                <>
                                                    {ListMenu.map((i, index) => (
                                                        <tr>
                                                            <td>{i.Menuname}</td>
                                                            <td>
                                                                <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "V")} checked={i.View === 1} value="" id={"View_" + i.Mstmenuid + "_"} />View
                                                                <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "A")} checked={i.Add === 1} value="" id={"Add_" + i.Mstmenuid + "_"} />Add
                                                                <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "E")} checked={i.Edit === 1} value="" id={"Edit_" + i.Mstmenuid + "_"} />Edit
                                                                <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "P")} checked={i.Print === 1} value="" id={"Print_" + i.Mstmenuid + "_"} />Print
                                                                <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "D")} checked={i.Delete === 1} value="" id={"Delete_" + i.Mstmenuid + "_"} /> Delete
                                                            </td>
                                                        </tr>

                                                    ))}
                                                </>}
                                        </tbody >
                                    </table >
                                </div>
                                : null}
                            {/* </>} */}
                            {Flag == "V" ? null :
                                <div>
                                    <input type='checkbox' checked={agree} onChange={(e) => { setAgree(!(agree)) }} />
                                    <span style={{ color: 'red' }}> I Agree the Terms & Conditions</span>
                                </div>
                            }
                            <div class="text-center">
                                <button type="button" disabled={Flag == "V" ? true : false} class="btn btn-primary" onClick={() => IUDOperation(Flag)}>Submit</button>
                                <button type="button" class="btn btn-info" >
                                    {
                                        Page == "age_emp" ?
                                            <Link to='/otherusers'>
                                                Back
                                            </Link> :
                                            <Link to='/guest_table'>
                                                Back
                                            </Link>}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>


            </div>


        </div>
    )
}
