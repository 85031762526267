import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Api from '../Components/Services/ApiCall'
import '../App.css'
import "../Css/Signin.css"
import { Images } from '../Images/Index';
import { useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import {
    validPhoneNumberRegex,
    validOnlyEmailRegex
} from '../Components/CommanRules';
import JobseekerIndex from './JobseekerSignup/JobseekerIndex';
import SigninBase from './SigninBase';
import EmployerIndex from './EmployerSignup/EmployerIndex';

export default function Onlineemployersignup() {

    const location = useLocation();
    const { ID, Flag, UserT, Page } = location.state; 
    useEffect(() => {
        set_Usertype(UserT)  
        set_Usertype("JobSeeker")
    }, []);
    const navigate = useNavigate(); 
    const [_Usertype, set_Usertype] = useState("");  
        
    const [listdata, setlistdata] = useState([]);

    async function checkBoxMenuValidation(e) {
        set_Usertype(e.target.value); 
    }

    async function Close() {
        navigate("/");
    } 
    return (
        <div> 
            <div>
                <Toaster toastOptions={{ position: "top-right" }} />
                <SigninBase />
                <div class="card">
                    <div class="card-body">
                        <div class="divGroup col-md-12"> 
                            <p class="signupclr">Please Choose Any One to Create the Application</p>
                            <input type="radio" id="Employer" value="Employer" checked={_Usertype == "Employer"} onChange={(e) => checkBoxMenuValidation(e)} />
                            <label for="Employer" class="test">Employer</label>

                            <input type="radio" id="JobSeeker" value="JobSeeker" checked={_Usertype == "JobSeeker"} onChange={(e) => checkBoxMenuValidation(e)} />
                            <label for="JobSeeker" class="test">JobSeeker</label> 
                        </div> 
                        {_Usertype === "Employer" ?
                            <EmployerIndex />
                            : null}
                        {_Usertype == "JobSeeker" ?
                            <JobseekerIndex />
                            : null} 
                    </div>
                </div>
            </div>
        </div> 
    )
}