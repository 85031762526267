import React from 'react'
// //import '../CSS_Files/globalfilter.css'
// import '../css/style.css';

export const Globalfilter = ({ filter, setFilter }) => {
    return (
        <div className="globalfilter">

        <span className="filterbox">
            Search:  {''}
            <input class="search" classname="grid-searchbox" value={filter || ''} onChange={(e) => setFilter(e.target.value)} />
        </span>
        </div>
    )
}