import React, {useState, useEffect}  from 'react'
//import  ''
import Base from '../Base'
import { Link } from "react-router-dom";
import Api from '../Services/ApiCall';
// import ReactPaginate from "react-paginate";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "./Admin_form/Filter/Globalfilter";
import '../../Css/Pagination.css'
function Table({ columns, data }) {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        useGlobalFilter, useSortBy, usePagination
    );
    const { globalFilter } = state

    return (
        <div>
            <Globalfilter className="form-control" filter={globalFilter} setFilter={setGlobalFilter} />
            <table class="table table-striped" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}  >
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="fa-fa-sort-asc"></i>
                                                : <i className="fa-fa-sort-desc"></i>
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td  {...cell.getCellProps()} className={` ${cell.column.className ?? ""}`}>
                                            {cell.render("Cell")}</td>

                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <br></br>
            <div className="pagination">
            <nav class="paginate page_numbers" aria-label="Page navigation">

            </nav>
            <ul class="pagination first_last">
    
    <li class="page-item"><a class="page-link"  onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
         {"First"}</a>
     </li>{" "}
     <li class="page-item"><a class="page-link"  onClick={() => previousPage()} disabled={!canPreviousPage}>
         {"<"}</a>
     </li>{" "}
     <li class="page-item"><a class="page-link"  onClick={() => nextPage()} disabled={!canNextPage}>
         {">"}</a>
     </li>{" "}
     <li class="page-item"><a class="page-link"  onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
         {"Last"}</a>
     </li>{" "}</ul>
     
     <span className="pageno">
         Page{" "}
         <strong>
             {pageIndex + 1} of {pageOptions.length}
         </strong>{" "}
     </span>
     
     <div className='showpagesize' >
                Show:{" "}
                <select
                className='showpagecss'
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[2, 10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select></div>
            </div>
        </div>
    );
}

export default function Agentcommission(any, i) {
    const [listdata, setlistdata] = useState([]);
    const agentAccessType = sessionStorage.getItem('AccessType');
    const userDetails = JSON.parse(sessionStorage.getItem('userdetails'));
    const userRegCode = userDetails ? userDetails[0]['Msusertregcode'] : null;
  
    const [columns, setcolumns] = useState([
      {
        Header: "ID",
        Cell: (row) => {
          return <div>{Number(row.row.id) + 1}</div>;
        },
      },
      {
        Header: "Agent Code",
        accessor: "active",
        Cell: (row) => {
          const isAccessible =
            agentAccessType === 'Agent' &&
            userRegCode === row.row.original.active;
  
          return isAccessible ? <div>{row.row.original.active}</div> : null;
        },
      },
      {
        Header: "Jobseeker Commission",
        accessor: "commisionvalue",
        Cell: (row) => {
            const isAccessible =
              agentAccessType === 'Agent' &&
              userRegCode === row.row.original.active;
    
            return isAccessible ? <div>{row.row.original.commisionvalue}</div> : null;
          },
      },
      {
        Header: "Employer Commission",
        accessor: "commisiondeduction",
        Cell: (row) => {
            const isAccessible =
              agentAccessType === 'Agent' &&
              userRegCode === row.row.original.active;
    
            return isAccessible ? <div>{row.row.original.commisiondeduction}</div> : null;
          },
      },
    ]);
  
    useEffect(() => {
      bindGrid();
    }, []);
  
    async function bindGrid() {
      var url = "/UserReg/AgentCommisonDetails";
      let _response = await Api.NoDirect(url);
      setlistdata(_response);
    }
  
    return (
      <div>
        <Base Agentcommission={true} />
        <div id="main" className="main">
          <div className="pagetitle">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dash">Home</Link>
                </li>
                <li className="breadcrumb-item">Report</li>
                <li className="breadcrumb-item active">Agent Commission</li>
              </ol>
            </nav>
          </div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Agent Commission</h5>
                <Table columns={columns} data={listdata} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }