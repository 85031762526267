import React,{useState,useEffect} from 'react'
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import Swal from 'sweetalert2';
import { Link , useNavigate,useLocation} from "react-router-dom";
import {
    validEmailRegex,
    validPasswordRegex,
    validOnlyEmailRegex,
    validPhoneNumberRegex,
    numberMethod,
    maxLength
  }  from '../../CommanRules'
import toast, { Toaster } from 'react-hot-toast';
export default function Divisionalofficedistlistform() {

   
 const navigate=useNavigate();
    useEffect(()  => {
        if (ID != 0) {
            // GetMenu(0)  
            //  GetUserType()a
            // Get(ID)
           
        }
        else { 
            //Get(0) 
            // GetUserType()
         
            
        }   BindGrid()
    }, []);
    
    const [listdata, setlistdata] = useState([]);
    const [listdistict, setlistdistict] = useState([]);
    async function BindGrid(id) {
    
        var Param = { Mstdivisionalofficedistlistid: id }
        var url = "/DivisionalOffice/BindDivisional";
        let _response = await Api.BindGrid(Param, url)
        setlistdata(_response)
        var distids=[]
        if (ID != 0) {
            try{
             var x = _response.filter(function (el) {
                return el.Mstdivisionalofficedistlistid != ID ;
              });
              setlistdata(x)
             
           
            try{
                for(var k=0;k<x.length;k++){
                    var f1=JSON.parse(x[k].Suboffice1);
                  for(var k1=0;k1<f1.length;k1++){
                    distids.push(f1[k1]['Suboffice1'])
                  }
                    
                  }
            }catch{}
        }catch{

        }
           setlistdistict(distids)
        } 
    }
    const location = useLocation();
    const { ID,Flag } = location.state;
  
   useEffect(() => {
    GetDivisionOffList()
    GetDistrict()
    if(ID!=0){
    Get(ID);
    }
   
 }, []);


    const [selectdivoffice, setSelectDivoffice] = useState("");
    const [divoffice,setDivoffice] = useState("");
    const [districtlist,setDistrictlist]=useState("")


    
const [formFields, setFormField] = useState([
    {Suboffice1: ''},
])



const handleFormChange1 = (event, index, F) => {

    let data = [...formFields];
    for (var k=0;k<data.length;k++){
        if(event.target.value==data[k].Suboffice1){
            toast.error("Already this District is Choosed")
            return false;
        }
        if(listdistict.includes(event.target.value)){
            toast.error("Already this District is Choosed On Another DO")
            return false;
        }
    }
    if (F == 1) {
        data[index].Suboffice1 = event.target.value;
    }
    setFormField(data);
}

    async function GetDistrict(){

        var url="/DropDown/GetDistrict";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setDistrictlist(_response)
    }



const submit = (e) => {
    e.preventDefault();
    // //console.log(formFields12)
}

const addFields1 = () => {
    let object = { Suboffice1: '' }
    // if(formFields.filter){

    // }
    // const result=formFields.filter
    setFormField([...formFields, object])
    
}
const removeFields1 = (index) => {
    let data = [...formFields];
    data.splice(formFields.length-1, 1)
    setFormField(data)
}
  
    async function GetDivisionOffList() {

        var url = "/DropDown/GetDivisionOffList";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setDivoffice(_response)
    }
    

   async function IUDOperation(flag,id){
       var Mainoffice = selectdivoffice;
       var FormFields = formFields;
       
       if(Mainoffice ==""){
           toast.error('Please enter Main Office')
           return false;
       }      
      
  
       var Param ={
    "mstdivisionalofficedistlistid": ID,
  "mstdivisionalofficedistlistcode": "string",
  "divisionalofficedistlistcode": "string",
  "mainoffice": Mainoffice,
  "suboffice1":  JSON.stringify(FormFields),
  "suboffice2": "string",
  "suboffice3":   "string",
  "suboffice4":  "string",
  "suboffice5":  "string",
  "inchargename": "string",
  "contactnumber": "string",
  "flag": Flag,
  "active": "string",
  "searchField": "string",
  "createdBy": 0,
  "createdDate": "2022-11-01T14:03:47.252Z"
      };
       var url = "/DivisionalOffice/IUDDivisional";
       let _response = await Api.IUD(Param,url);
       showAlert(_response);
       setTimeout(()=>{
        navigate('/divisionalofficedistlisttable')
       },2000)
      // //console.log(_response,"aaaaaaa");
       
   }
   const showAlert = (_response) => {
    Swal.fire({
        title: "Success",
        text: _response,
        icon: "success",
        confirmButtonText: "OK",
    });
};

async function Get(id){
    var Param ={Mstdivisionalofficedistlistid:id}
    var url = "/DivisionalOffice/BindDivisional";
    let _response = await Api.BindGrid(Param,url) 
    setSelectDivoffice(_response[0].Mainoffice);
    setFormField(JSON.parse(_response[0].Suboffice1));
  
 }



  return (
    <div>
       <Toaster toastOptions={{ position: "top-right" }} />      
        <Base Divisional={true}/>
          <div id="main" class="main">
              <div class="pagetitle">
                  
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          <li class="breadcrumb-item">General Setting</li>
                          <li class="breadcrumb-item active">Divisional Office</li>
                      </ol>
                  </nav>
              </div>
             
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Divisional Office Dist List</h5>

              
                      <div class="row g-3">
                          
                          
                             
                          <div class="col-md-6">
                            <label for="mainoffice">Divisional Office</label>
                            <select id="inputState" class="form-select"   disabled={Flag == "D" || Flag == "V" ? true : false} value={selectdivoffice} onChange={(e) => setSelectDivoffice(e.target.value)}>
                             <option selected>Divisional Office</option>
                             { divoffice &&
                                            divoffice.map((i, index) => (
                                                <option value={i.id}>{i.Text}</option>
                                            ))}
                             
                         </select>
                         </div>
                       
                        <div class='row g-3'>
                            {formFields.map((form1, index) => {
                                return (
                                    <div className=' col-md-6' key={index}>    
                                        <div className='col-md-12'>  
                                            <label for="District"> District</label>
                                            <select id="inputState" class="form-select"
                                                disabled={Flag == "D" || Flag == "V" ? true : false}
                                                onChange={event => handleFormChange1(event, index, 1)}
                                                value={form1.Suboffice1}
                                                > 
                                                 <option selected >District</option>
                                                 { districtlist &&
                                            districtlist.map((i, index) => (
                                                <option value={i.id}>{i.Text}</option>
                                            ))}
                             
                                                 </select>  
                                               </div>

                                               
                                              

                                            
                                               
                                                     </div>
                              
                            )
                            
                            
                            })}

   
                        <div className='col-md-12'>    
                         <button class="btn btn-primary" disabled={Flag =="V"||Flag =="D"? true:false} onClick={addFields1}>Add</button>
                         </div>
                       
                         <div className='col-md-12' >    
                         <button class="btn btn-warning"  disabled={Flag =="V"||Flag =="D"? true:false} onClick={( removeFields1) }>X</button>
                         </div>
                       
                          <div class="text-center">
                              <button type="button"  onClick={IUDOperation} class="btn btn-primary">Submit</button>
                              <button type="reset" class="btn btn-info">
                              <Link to='/divisionalofficedistlisttable'>
                                Back
                                </Link>
                                </button>
                          </div>
                      </div>

                  </div>
              </div>

           </div>

          </div>
    </div>
    
  )
}