import React, { useState, useEffect } from 'react'
import { Images } from '../../../Images/Index'
import Api from '../../Services/ApiCall';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import CSS from "../../../App.css";
import LoginBase from '../../SigninBase';
import Swal from 'sweetalert2';

export default function Agentrequestform() {

  const navigate = useNavigate();
  const location = useLocation();
  const { ID, Flag } = location.state;

  useEffect(() => {
    GetDistrict()
    if (ID != 0) {

    }

  }, []);

  const [name1, setName] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  const [email, setEmail] = useState("");
  const [district, setDistrict] = useState("");
  const [districtlist, setDistrictlist] = useState("")

  async function GetDistrict() {

    var url = "/DropDown/GetDistrict";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setDistrictlist(_response)
  }


  async function IUDOperation(flag, id) {

    var Name = name1;
    var Mobilenumber = mobilenumber;
    var Email = email;
    var District = district;

    if (Name == "") {
      toast.error("Please Enter Name");
      return false;
    }
    if (Mobilenumber == "") {
      toast.error("Please enter Mobile Number")
      return false;
    }
    else {
      if (!(Mobilenumber.length == 10)) {
        toast.error("Please Enter valid Mobile Number")
        return false;
      }
    }
    if (Email == "") {
      toast.error("Please Enter Email")
      return false;
    }
    if (District == "") {
      toast.error("Please Choose District")
      return false;
    }



    var Param =
    {
      "mstagentid": ID,
      "agentcode": "string",
      "name": Name,
      "mobilenumber": Mobilenumber,
      "district": District,
      "emailid": Email,
      "active": "string",
      "searchField": "string",
      "createdBy": 0,
      "createdDate": "2022-12-12T07:18:07.244Z",
      "flag": Flag
    }

    var url = "/AgentRequest/IUDAgentRequest";
    let _response = await Api.IUD(Param, url);
    showAlert(_response);
    setTimeout(() => {
      navigate('/')
    }, 2000)

  }
  const showAlert = (_response) => {
    Swal.fire({
        title: "Success",
        text: _response,
        icon: "success",
        confirmButtonText: "OK",
    });
};

  return (

    <>

      <div>
        <LoginBase />
        <Toaster toastOptions={{ position: "top-right" }} />
        <section class="login-block">
          <div class="container">
            <div class="empcard1">
              <div style={{ marginTop: "20px" }}>
                <div class="row loginrow">
                  <div class="col-md-4 login-sec ">
                    <div className="Auth-form-content forget">
                      <h3 className="Auth-form-title">
                        <h4 class="login_letter">Partner Request</h4>
                      </h3>
                      {/* <img src={Images.mainlogo} alt="pelago" className="loginlg_img" /></h3> */}
                      <div >
                        <div className="form-group mt-3">
                          <label for="name">Name</label>
                          <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Name" value={name1} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="form-group mt-3">
                          <label for="mobilenumber">Mobile Number</label>
                          <input type="number" class="form-control no-spinner" disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Mobile Number" value={mobilenumber} onChange={(e) => setMobilenumber(e.target.value)} />
                        </div>
                        <div className="form-group mt-3">
                          <label for="email">Email</label>
                          <input type="email" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group mt-3">
                          <label for="district">District</label>
                          <select id="inputState" class="form-select" value={district} onChange={(e) => setDistrict(e.target.value)}>
                            <option selected >District</option>
                            {districtlist &&
                              districtlist.map((i, index) => (
                                <option value={i.id}>{i.Text}</option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group mt-3">
                          <button type="button" className="btn btn-primary" onClick={IUDOperation} >
                            Submit
                          </button>
                          <button type="button" class="btn btn-info">
                            <Link to='/' class="backbuttonclr">
                              Back
                            </Link>
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8 banner-sec">
                    <div class="signin-img">
                      {/* <img class="d-block sign-img-1" src="https://images.pexels.com/photos/5989933/pexels-photo-5989933.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="First slide" /> */}
                      <img class="d-block sign-img-1" src="https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="First slide" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
