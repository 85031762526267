import React ,{useState,useEffect} from 'react'
import Base from '../Base'
import { Link } from "react-router-dom";
import Api from '../Services/ApiCall';
export default function Agentbrokerperformance() {

    useEffect(() => {
          
        BindGrid()
        //checkmenu()
    }, []);



    const[listdata,setlistdata]=useState();

    async function BindGrid() {
      
        var url = "/Report/GetUserReport";
        let _response = await Api.BindDropdown(url)
    //setlistdata(JSON.parse(_response))
      setlistdata(JSON.parse(_response[0].Text))
    
     
   }



  return (
    <div>
             <Base Agentbrokerperformance={true} />
          <div id="main" class="main">
              <div class="pagetitle">
               
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                            <Link to="/dash">Home</Link>
                            </li>
                            
                          <li class="breadcrumb-item">Report</li>
                          <li class="breadcrumb-item active">Agent Broker Performance</li>
                      </ol>
                  </nav>
              </div>
          <div class="col-lg-12">

              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Agent Broker Performance</h5>
                      {/* {Api.checkmenu(17)[0]['Add'] == 1 ?  */}
                          {/* <button type="button" class="btn btn-primary Add">
                              <Link to='/agentbrokerperformanceform'>
                               Add
                              </Link>
                          </button> */}
                          {/* :null} */}

                          {listdata ?
                      <table class="table table-striped">
                          <thead>
                              <tr>
                                  <th scope="col">SNo</th>
                                  <th scope="col">Details</th>
                                  <th scope="col">Registerd</th>
                                  <th scope="col">Processing</th>
                                  <th scope="col">Active</th>
                                 <th scope="col">Inactive</th>
                                 
                              </tr>
                          </thead>
                       
                          <tbody>
                              <tr>
                                  <th >1</th>
                                  <td class="alignstart">{listdata.Agent.Title}</td>
                                  <td class="alignstart">{listdata.Agent.Total}</td>
                                  <td class="alignstart">{listdata.Agent.Processing}</td>
                                  <td class="alignstart">{listdata.Agent.Approve}</td>
                                  <td class="alignstart">{listdata.Agent.Reject}</td>
                              </tr>
                              <tr>
                                  <th >2</th>
                                  <td class="alignstart">{listdata.Jobseeker.Title}</td>
                                  <td class="alignstart">{listdata.Jobseeker.Total}</td>
                                  <td class="alignstart">{listdata.Jobseeker.Processing}</td>
                                  <td class="alignstart">{listdata.Jobseeker.Approve}</td>
                                  <td class="alignstart">{listdata.Jobseeker.Reject}</td>
                                 
                              </tr>
                              <tr>
                                  <th >3</th>
                                  <td class="alignstart">{listdata.Employer.Title}</td>
                                  <td class="alignstart">{listdata.Employer.Total}</td>
                                  <td class="alignstart">{listdata.Employer.Processing}</td>
                                  <td class="alignstart">{listdata.Employer.Approve}</td>
                                  <td class="alignstart">{listdata.Employer.Reject}</td>
                                
                              </tr>
                            
                          </tbody>
                        
                      </table>  
                  : ""}
                  </div>
                 
              </div>
</div>
    </div>
    
    </div>
  )
}
