import React, { useState, useEffect } from 'react'
import Base from '../Base'
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Api from '../Services/ApiCall';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "./Admin_form/Filter/Globalfilter";
import '../../Css/Pagination.css'


export default function Departmentcreation(any, i) {
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup1, setShowPopup1] = useState(false);

    useEffect(() => {
        fetchDataAndSetState();
    }, []);

    const fetchDataAndSetState = async () => {
        try {
            var Param = { Mstdepartmentcreationid: 0 }
            var url = "/DepartMent/BindDepartment";
            let _response1 = await Api.BindGrid(Param, url);
            setRowData(_response1);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const openPopup = (rowData) => {
        setSelectedRowData(rowData);
        Get(rowData.id)
        setShowPopup(true);
    };
    const openPopup1 = (rowData) => {
        setShowPopup(false);
        setShowPopup1(true);
    };

    const closePopup1 = () => {
        setSelectedRowData(null);
        setShowPopup1(false);
    };
    const closePopup = () => {
        setSelectedRowData(null);
        setShowPopup(false);
    };

    const onCellClicked = (params) => {
        openPopup({ id: params.data.Mstdepartmentcreationid, code: params.data.Departmentcreationcode });
    };

    const columnDefs = [
        // { headerName: 'Id', field: 'Mstdepartmentcreationid', filter: 'agNumberColumnFilter', enableFilter: true },
        { headerName: 'Department Code', field: 'Departmentcreationcode', filter: true, enableFilter: true },
        { headerName: 'Department Name', field: 'Departmentname', filter: true, enableFilter: true },
    ];

    const [quickFilterText, setQuickFilterText] = useState("");

    const onQuickFilterChange = (event) => {
        setQuickFilterText(event.target.value);
    };



    async function IUD_Delete() {
        openPopup1();
    }


    const [departmentcode, setDepcode] = useState("");
    const [departmentname, setDepname] = useState("");


    async function IUDOperation() {
        var Depcode = departmentcode;
        var Depname = departmentname;

        var ID = selectedRowData.id;

        var Param = {
            "departmentcreationcode": Depcode,
            "departmentname": Depname,
            "mstdepartmentcreationid": ID,
            "flag": "U",
            "createdBy": 0,
            "createdDate": "2022-10-12T07:13:51.208Z"
        };
        var url = "/DepartMent/IUDDepartment";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup(false);

    }

    async function IUDOperationDelete() {
        var Depcode = departmentcode;
        var Depname = departmentname;

        var ID = selectedRowData.id;

        var Param = {
            "departmentcreationcode": Depcode,
            "departmentname": Depname,
            "mstdepartmentcreationid": ID,
            "flag": "D",
            "createdBy": 0,
            "createdDate": "2022-10-12T07:13:51.208Z"
        };
        var url = "/DepartMent/IUDDepartment";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup1(false);

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function Get(id) {
        var Param = { mstdepartmentcreationid: id }
        var url = "/DepartMent/BindDepartment";
        let _response1 = await Api.BindGrid(Param, url)
        setDepcode(_response1[0].Departmentcreationcode);
        setDepname(_response1[0].Departmentname);
    }

    return (
        <div>
            <Base Departmentcreate={true} />
            <div id="main" class="main">
                <div class="pagetitle">
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>

                            <li class="breadcrumb-item">General Setting</li>
                            <li class="breadcrumb-item active">Department Creation</li>
                        </ol>
                    </nav>
                </div>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={onQuickFilterChange}
                    style={{ marginBottom: '10px' }}
                />
                {Api.CheckAdd(5) == 1 ?
                    <>
                        <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed' }}>
                            <Link to={'/Departmentcreationform'}
                                state={{ ID: 0, Flag: 'I' }}
                                style={{ color: "white" }}
                            >
                                Add
                            </Link>
                        </button>
                    </>
                    : null}
                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={10}
                        domLayout='autoHeight'
                        floatingFilter={true}
                        enableBrowserTooltips={true}
                        onCellClicked={onCellClicked}
                        quickFilterText={quickFilterText}
                    />
                </div>
                {showPopup && (
                    <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">

                                <div class="col-md-6">
                                    <label for="departmentname">Department Name</label>
                                    <input type="text" class="form-control" placeholder="Department Name" value={departmentname} onChange={(e) => setDepname(e.target.value)} />
                                </div>

                                <div class="text-center">
                                    {Api.CheckUpdate(5) == 1 ?
                                        <button type="button" class="btn btn-primary" onClick={IUDOperation}>Update</button>
                                        : null}
                                    {Api.CheckDelete(5) == 1 ?
                                        <button type="reset" class="btn btn-info" onClick={IUD_Delete} >Delete</button>
                                        : null}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
                {showPopup1 && (
                    <Modal show={true} onHide={closePopup1} >
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are You sure want to Delete?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closePopup1}>
                                Close
                            </Button>
                            <Button variant="secondary" onClick={IUDOperationDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>

        </div>
    )
}
