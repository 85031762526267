import React, { useEffect, useRef, useState } from 'react';
import Base from './Base';
import Api from './Services/ApiCall';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Images } from '../Images/Index';
import jsPDF from 'jspdf';
import SigninBase from './SigninBase';
import JobseekerBase from './Jobseeker/JobseekerBase';
import EmployerBase from './Employer/EmployerBase';

export default function Jobseekerdetails() {
  const [listdata, setJobseekerlistdata] = useState([]);
  const [listdata1, setJobseekerlistdata1] = useState([]);
  const [listdata2, setJobseekerlistdata2] = useState([]);

  const location = useLocation();
  // const { ID } = location.state || {};;
  const Jobname = location.state.Jobname;
  const Flag = location.state.Flag; 
  const ID = sessionStorage.getItem("AccessType") == "jobseeker" ?
    JSON.parse(sessionStorage.getItem('userdetails'))[0]['Mstjobseekerid'] : location.state.ID

  const pdfRef = useRef(null);

  useEffect(() => {

    BindGrid(ID)
  }, []);


  async function IUDOperation() {
    const content = pdfRef.current;
    const pdf = new jsPDF('pt', 'mm', [1500, 1000]);
    pdf.html(content, {
      callback: function (pdf) {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize('150');
          pdf.setTextColor('lightblue');
          // pdf.text('Elakiyaa Best Jobs', 750, 575, null, null, 'right');
        }
        pdf.save('Resume.pdf');
      }
    });
  }




  async function BindGrid(ID) {
    var Param = { mstjobseekerid: ID }
    var url = "/JobSeekerTable/BindJobSeekerTable";
    let _response1 = await Api.BindGrid(Param, url)
    setJobseekerlistdata(_response1)
    setJobseekerlistdata1(_response1[0].Education)
    setJobseekerlistdata2(_response1[0].Qualifcation)

  }


  return (
    <div>
      {sessionStorage.getItem("AccessType") == "jobseeker" ?
        <JobseekerBase /> :
        <EmployerBase />
      }
      <div id="main" class="main alignleft">
        <button onClick={IUDOperation}>Download</button>
        <div ref={pdfRef} style={{ width: '100%' }}>
          {listdata.length > 0 ?
            <>
              <div style=
                {{
                  marginTop: "640px",
                  position: "absolute",
                  top: "100 %",
                  left: "59 %",
                  marginLeft: "170px",
                  transform: "translate(5%, 82%)",
                  opacity: "0.5",
                  fontSize: "4em",
                  fontWeight: "bold",
                  color: "#ccc"
                }}>
                Elakiyaa Best Jobs
              </div>
              {Flag == "V" ?
                <>
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <div>
                      <h5>{(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Companyname'])}</h5>
                    </div>
                    <div>
                      <h5>{(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Companyaddress'])}</h5>
                    </div>
                    <div>
                      <h5>{(JSON.parse(sessionStorage.getItem('userdetails'))[0]['CompanyphoneNo'])}</h5>
                    </div>
                  </div>
                </>
                : null}
              <hr></hr>
              {Flag == 'V' ? null :
                <div><h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>Resume</h1></div>
              }
              <div style={{ marginLeft: '16%', marginTop: '40px' }}>
                {Flag == "V" || Flag == "VJR" ?
                  <>
                    <div style={{ marginBottom: '50px' }}><h3>Application for the Post of:<span>{Jobname}</span></h3></div>
                  </>
                  : null}
                <div><h3>Jobseeker Code<span style={{ marginLeft: '135px' }}>:</span><span>{listdata[0].Mstjobseekercode}</span></h3></div>
                <div><h3>Name<span style={{ marginLeft: '255px' }}>:</span><span>{listdata[0].Name}</span></h3></div>
                <div><h3>Date of Birth<span style={{ marginLeft: '169px' }}>:</span><span >{listdata[0].Dataofbirth}</span></h3></div>
                <div><h3>Mobile Number<span style={{ marginLeft: '137px' }}>:</span><span >{listdata[0].Mobilenumber}</span></h3></div>
                <div><h3>Email<span style={{ marginLeft: '263px' }}>:</span><span >{listdata[0].Email}</span></h3></div>
                <div><h3>Aadhaar Number<span style={{ marginLeft: '117px' }}>:</span><span >{listdata[0].Aadhaarnumber}</span></h3>
                </div>
                <div><h3>Country<span style={{ marginLeft: '234px' }}>:</span><span >{listdata[0].Country}</span></h3></div>
                <div><h3>State<span style={{ marginLeft: '267px' }}>:</span><span >{listdata[0].State}</span></h3></div>
                <div><h3>City<span style={{ marginLeft: '284px' }}>:</span><span >{listdata[0].City}</span></h3></div>
                <div><h3>Pincode<span style={{ marginLeft: '235px' }}>:</span><span >{listdata[0].Pincode}</span></h3></div>
                <div><h3>Address<span style={{ marginLeft: '230px' }}>:</span><span >{listdata[0].Address}</span></h3></div>

                <div>
                  <h3>Qualification</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Qualification</th>
                        <th>Course</th>
                        <th>Institution</th>
                        <th>Passed Out</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listdata1 && JSON.parse(listdata1).map((item) =>
                        <>
                          <td>{item.qualify}</td>
                          <td>{item.course}</td>
                          <td>{item.institution}</td>
                          <td>{item.passedout}</td>
                        </>
                      )}
                    </tbody>
                  </table>

                </div>
                <div style={{ marginBottom: '50px' }}>
                  <h3>Experience Details</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Companyname</th>
                        <th>Designation</th>
                        <th>Years</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listdata2 && JSON.parse(listdata2).map((item) =>
                        <>
                          <td>{item.companyname}</td>
                          <td>{item.designation}</td>
                          <td>{item.years}{item.month}</td>
                        </>
                      )}
                    </tbody>
                  </table>

                </div>
                {Flag == 'V' ?
                  <>
                    <div>
                      <h3>Declaration:</h3>
                    </div>
                    <div>
                      <h6>I have give the confirmation the above details are correct as per any knowledge.</h6>
                    </div>
                    <div>
                      <h6>I Agree to pay 10% of my first month salary to Elakiyaa Solution who is working as a consaltant of this job.</h6>
                    </div>
                  </>
                  : null}
              </div>
              <div>
                <h5 style={{ textAlign: 'center', marginTop: '40px' }}>Thanks</h5>
              </div>
              <div>
                <h5 style={{marginLeft:'725px', marginTop: '40px' }}>Yours</h5>
                <h5 style={{marginLeft:'700px', marginTop: '40px' ,fontWeight:'bold'}}>{listdata[0].Name}</h5>
              </div>
              {/* <div>
                <img src={Images.mainlogo} alt="pelago" className="loginlg_img" style={{ marginLeft: '16%', marginTop: '25px' }} />
              </div> */}
            </> : null}

        </div>
      </div>
    </div >
  );
}
