import React, { useState, useEffect } from 'react'
import LoginBase from './SigninBase'
import { Link } from 'react-router-dom'
import Api from './Services/ApiCall';

export default function Refund_policy() {


    const [place, setLocation] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [emailid1, setEmailid] = useState("");
    const [contactnumber, setContactnumber] = useState("");
    useEffect(() => {
        CompanyGet()
    }, []);


    async function CompanyGet(id) {
        var Param = { Mstcompanyprofileid: 3 }
        var url = "/Company/BindCompany";
        let _response = await Api.BindGrid(Param, url)

        setCompanyname(_response[0].Companyname);
        setLocation(_response[0].Location);
        setEmailid(_response[0].Emailid);
        setContactnumber(_response[0].Phonenumber);

    }


    return (
        <div>
            <LoginBase />
            <div style={{ padding: '5%', borderRadius: '5px' }}>
                <h2 style={{ textAlign: 'center', color: 'blue' }}>Elakiyaa Best Jobs</h2>
                <p>
                    <h4>Payment and Refund Policy</h4>

                    <div>
                        <span style={{ fontWeight: 'Bold' }}>1)Payment for memberships:</span>
                    </div>
                    <div>
                        All payments for services at Elakiyaabestjobs.com have to be made in favour of "Elakiyaa Solution" only. All payments need to be made on 100% advance basis to continue to use uninterrupted Paid Services.
                        The Member, who is liable to pay Subscription Fees, shall pay it on demand even if the Member disputes the same for any reason. In the event that the Company deciding the dispute to be in the Member's favor, the Company shall refund to the Member any excess amount paid by the Member free of interest. In the event of late payment by the Member of any sums due under this Agreement, the Membership and the services would stand terminated.
                    </div>

                    <div>
                        <span style={{ fontWeight: 'Bold' }}>2)Refund / Money Back Policy:</span>
                    </div>
                    <div>
                        Once subscribed, the payment made by the Member for Paid service is not refundable and no claim for refund would be entertained. Elakiyaabestjobs.com neither offers any guarantees for the accuracy, timeliness, authenticity nor advocates any of the content posted by the other Members. All the services are on a best effort basis.
                    </div>

                    <div>
                        <span style={{ fontWeight: 'Bold' }}>3)Refund on the basis of fraudulent transactions:</span>
                    </div>
                    <div>
                        The Member is solely responsible for maintaining confidentiality, as well as all the activities and transmission performed through his My Folder. He/she shall be solely responsible for carrying out any online or off-line transaction involving credit cards / debit cards or such other forms of instruments or documents for making such transactions. Elakiyaabestjobs.com assumes no responsibility or liability for their improper use of information relating to such usage of credit cards / debit cards used by the subscriber online / off-line.
                    </div>

                    <div>
                        <span style={{ fontWeight: 'Bold' }}>4)Refund on the basis of change in preference / delay in process::</span>
                    </div>
                    <div>
                        As we at Elakiyaabestjobs.com keep up high interactions with our clients and carry out the entire process with their approval thus there is no provision for any kind of full or partial refund. Once an order is placed it cannot be cancelled as it is sent for processing immediately. Your personal preferences changed in the course of time, cannot serve a reason for refund or charge back.
                    </div>
                </p>
                <div style={{ textAlign: 'center' }}>
                    <button style={{ background: 'blue' }}>
                        <Link to='/' style={{ color: 'white', textDecoration: 'none' }}>Back</Link></button>
                </div>
            </div>
        </div>
    )
}

