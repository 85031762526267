import React,{useState,useEffect} from 'react'


import { Link,useLocation,useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import CSS from '../../../App.css';



export default function Plannotification() {
  
   

  return (<>
    <div>
    <button type="reset" class="btn btn-secondary butleft">
                 <Link to='/Dash'>
                   X
                   </Link>
                   </button>
    </div>
    <div>      
          <div class="main pad">
              <div class="pagetitle">
                
              
              </div>
             
              <div class="card">
                  <div class="card-body">
                     
                     <div>Dear Customer Your Plan Will Be Expires In Five Days. Please Activate a New Plan 
                        To Continue The Searches.
                     </div>
              
                        
                      </div>

                  </div>
              </div>

           
          </div>
          </>
 
  )
}