import React ,{useState, useEffect} from 'react'
import { Link ,useLocation} from "react-router-dom";
import Api from '../Services/ApiCall';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from '../Admin/Admin_form/Filter/Globalfilter';
// import '../../Css/Pagination.css'
import EmployerBase from './EmployerBase';
import CSS from '../../App.css'


function Table({ columns, data }) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        useGlobalFilter, useSortBy, usePagination
    );
    const { globalFilter } = state

    return (
        <div>
            <Globalfilter className="form-control" filter={globalFilter} setFilter={setGlobalFilter} />
            <table class="table table-striped" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}  >
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="fa-fa-sort-asc"></i>
                                                : <i className="fa-fa-sort-desc"></i>
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td  {...cell.getCellProps()} className={` ${cell.column.className ?? ""}`}>
                                            {cell.render("Cell")}</td>

                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <br></br>
            <div className="pagination">
                <nav class="paginate page_numbers" aria-label="Page navigation">

                

                </nav>
                <ul class="pagination first_last">

                    <li class="page-item"><a class="page-link"  onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {"First"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link"  onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {"<"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link"  onClick={() => nextPage()} disabled={!canNextPage}>
                        {">"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link"  onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {"Last"}</a>
                    </li>{" "}</ul>

                <span className="pageno">
                    Page{" "}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                </span>


            
                <div className='showpagesize' >
                    Show:{" "}
                    <select
                        className='showpagecss'
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[2, 10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select></div>
            </div>
        </div>
    );
}


export default function Viewedcandidate() {

    const[jobname,setJobname]=useState("");
   
    const[jobnamelist,setJobnamelist]=useState("");    

    const [listdata, setlistdata] = useState([]);
    const [columns, setcolumns] = useState([

        {
            Header: "ID", Cell: (row) => {
                return <div>{Number(row.row.id) + 1}</div>;
            },
        },
        
        {
            Header: "Viewed Candidate", accessor: "Text1",
        },

        {
            Header: "Actions", Cell: (row) => {

                return <div>{<nav>

                    <ol class="breadcrumb">

                        <li class="breadcrumb-item">
                            <Link to={'/CandidateDescription'} state={{
                                ID: row.data[row.row.id].Text, Flag: 'V'
                            }}>
                                <i class="bi bi-eye" /> </Link> </li>

                    </ol>
                </nav>}</div>;
            },



        }

    ])

    // const location = useLocation();
    // const { ID,Flag } = location.state;
  

    useEffect(() => {
      
        GetJobname()
       // GetViewcandidate()
       
       
    }, []);

    


   
    // async function jobNamefilter(id) {
    //    setJobname(id)
    //    if(id !=0){

    
    //    var id=sessionStorage.getItem('MUserID')
    //    // var id=setJobname(id)
    //    var url = "/DropDown/GetViewcandidate?id="+id;
    //    let _response1 = await Api.BindDropdown( url)
      
    //    _response1=_response1.filter(function (el)
    //    {
    //      return el.id ==id ;
    //    }
    //    ); 
       
    //    setlistdata(_response1)
    // }

    // }
    const handleChange=(value)=> {
        
        setJobname(value)
       
        GetViewcandidate(value)
    }
    async function GetJobname() {

        var id=sessionStorage.getItem('MUserID')
        var url = "/DropDown/GetJobname?id="+id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setJobnamelist(_response)
       
    }


    async function GetViewcandidate(value) {
       
        setJobname(value)
        var id = (value)
        var url = "/DropDown/GetViewcandidate?id="+id;
        let _response1 = await Api.BindDropdown( url)
        setlistdata(_response1)

    }



  return (
    <div>
 <EmployerBase />
            <div id="main" class="main">
                <div class="pagetitle">
                    {/* <h1>Tables</h1> */}
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to="/Employerdashboard">Home</Link>
                            </li>
                            <li class="breadcrumb-item">Viewed Candidate</li>

                        </ol>
                    </nav>
                </div>
                <div class="col-lg-12">

                    <div class="card">
                        <div class="card-body alignleft">
                            <h5 class="card-title">Jobs</h5>
                            

                            <div class="col-md-3 choosejob">
                               
                               <label for="Companynaame">Choose Jobs Name</label>
                                   <select id="inputState" class="form-select" type='select' name="jobname" value={jobname} onChange={(e)=>handleChange(e.target.value)}>
                                     <option selected>Job Name</option>
                                    
                                  { jobnamelist &&
                                            jobnamelist.map((i, index) => (
                                                <option value={i.id}>{i.Text}</option>
                                            ))}
                                       </select>
                                       </div>
                                    
                            <Table columns={columns} data={listdata} />
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}
