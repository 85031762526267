import React,{useState,useEffect} from 'react'
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import { Link,useLocation,useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';


const style ={
    backGround: "red"
 }

export default function Officegradecreationform() {
  
    const navigate=useNavigate();
    const location = useLocation();
    const { ID,Flag } = location.state;
    ////console.log('%%%', ID, Flag);

    useEffect(() => {
        if(ID!=0){
        Get(ID);
        }
     }, []);




    const [grade,setGrade] = useState("");
    // //console.log(grade,"hhhhhhhhhhh");
    const [gradetype,setGradetype] = useState("");
    // //console.log(gradetype,"gggg");
    const [remark,setRemark] = useState("");

//     async function BindGrid(id){
//         var Param ={Id:id}
//         var url = "/Demo";
//         let _response = await Api.BindGrid(Param,url)
//         //console.log(_response)
//    }

   async function IUDOperation(flag,id){
       var Grade = grade;
       var Gradetype = gradetype;
       var Remark = remark;
       
       if(Grade ==""){
           toast.error('Please enter Grade')
           return false;
       }  
       if(Gradetype==""){
           toast.error('please enter Grade type')
           return false;
    }   
       var Param ={
        "mstofficegradecreationid": ID,
        "officegradecreationcode": "string",
        "selectofficegrade": Grade,
        "ogc": Gradetype,
        "active": "string",
        "flag": Flag,
        "searchField": "string",
        "remarks": Remark
      };

       var url = "/OfficeGradeCreation/IUDOfficeGradeCreation";
       let _response = await Api.IUD(Param,url);
      // //console.log(_response);
      showAlert(_response);
       setTimeout(()=>{
        navigate('/officegradecreationtable')
       },2000)
       
   }
   const showAlert = (_response) => {
    Swal.fire({
        title: "Success",
        text: _response,
        icon: "success",
        confirmButtonText: "OK",
    });
};


async function Get(id){
    var Param ={mstofficegradecreationid:id}
    var url = "/OfficeGradeCreation/BindOfficeGradeCreation";

    // //console.log("Param",Param,"URL",url)

    let _response = await Api.BindGrid(Param,url) 

    // var _response ={
    //     Grade:"",Gradetype:"",Remark:""
    // }
    // setofficegrade(_response.Officegrade);
    setGrade(_response[0].Selectofficegrade);
    setGradetype(_response[0].Ogc);
    setRemark(_response[0].Remarks);

    ////console.log(_response)

   // //console.log(_response,"+++++++");
 }

  return (
    <div>
        <Toaster toastOptions={{position: "top-right" }}/>
              <Base Officegrade={true}/>
          <div id="main" class="main">
              <div class="pagetitle">
                 
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          <li class="breadcrumb-item">General Setting</li>
                          <li class="breadcrumb-item active">Office Grade</li>
                      </ol>
                  </nav>
              </div>
             
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Office Grade  Creation</h5>

              
                         <div class="row g-3">
                          {/* <div class="col-md-6">
                              <select id="inputState" class="form-select"value={officegrade}onChange={(e) => setOfficegrade(e.target.value)}>
                                  <option selected>Select Office Grade</option>
                                  <option>HO</option>
                                  <option>RO</option>
                                  <option>DO</option>
                                  <option>BO</option>
                              </select>
                          </div> */}
                       
                           <div class="col-md-6">
                           <label for="grade">Grade</label>
                              <input type="text" class="form-control" disabled={Flag == "D" || Flag == "U" || Flag == "null" ? true : false} value={grade} placeholder="Office Grade"onChange={(e) => setGrade(e.target.value)}/>
                          </div>
                          <div class="col-md-6">
                          <label for="gradetype">Grade Type</label>
                              <input type="text" class="form-control"   disabled={Flag == "D" || Flag == "null" ? true : false} value={gradetype} placeholder="Office grade type"onChange={(e) => setGradetype(e.target.value)}/>
                          </div>
                          <div class="col-md-6">
                          <label for="remark">Remark</label>
                              <input type="text" class="form-control"   disabled={Flag == "D" || Flag == "null" ? true : false}   value={remark} placeholder="Remark"onChange={(e) => setRemark(e.target.value)}/>
                          </div> 


                          <div class="text-center">
                              <button type="button" class="btn btn-primary"onClick={IUDOperation}>Submit</button>
                              <button type="reset" class="btn btn-info">
                              <Link to='/officegradecreationtable'>
                                Back
                                </Link>
                                </button>
                          </div>
                      </div>

                  </div>
              </div>

           
          </div>

    </div>
  )
}