import React from 'react'
import Base from '../Base'
import { Link } from "react-router-dom";
export default function Employeeperformance() {
  return (
    <div>
         <Base Employeeperformance={true} />
          <div id="main" class="main">
              <div class="pagetitle">
             
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                            <Link to="/dash">Home</Link>
                            </li>
                            
                          <li class="breadcrumb-item">Report</li>
                          <li class="breadcrumb-item active">Employe Performance</li>
                      </ol>
                  </nav>
              </div>
          <div class="col-lg-12">

              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Employee performance </h5>
                          {/* <button type="button" class="btn btn-primary Add">
                              <Link to='/employeeperformanceform'>
                               Add
                              </Link>
                          </button> */}

                     

                          <table class="table table-striped">
                              <thead>
                                  <tr>
                                      <th scope="col">Date</th>
                                      <th scope="col">Cust Name</th>
                                      <th scope="col">Location</th>
                                      <th scope="col">Meeting with Owner/Staff</th>
                                      <th scope="col">Next Schedule</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Photo Updating</th>
                                      {/* <th scope='col'>Action</th> */}
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <th scope="row">12-3-2020</th>
                                      <td>test</td>
                                      <td>Trinelveli</td>
                                      <td>Owner</td>
                                      <td>8/9/2022</td>
                                      <td>good</td>
                                      <td>Active</td>
                                      {/* <td>-</td> */}
                                      {/* <td>
                                          <nav>
                                              <ol class="breadcrumb">
                                                  <li class="breadcrumb-item">    <i class="bi bi-pencil-square" /></li>
                                                  <li class="breadcrumb-item active">
                                                      <i class="bi bi-trash" /></li>
                                              </ol>
                                          </nav>
                                      </td> */}
                                  </tr>
                                  <tr>
                                      <th scope="row">12-3-2020</th>
                                      <td>test123</td>
                                      <td>madurai</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>Active</td>
                                      {/* <td>-</td> */}
                                      {/* <td>
                                          <nav>
                                              <ol class="breadcrumb">
                                                  <li class="breadcrumb-item">    <i class="bi bi-pencil-square" /></li>
                                                  <li class="breadcrumb-item active">
                                                      <i class="bi bi-trash" /></li>
                                              </ol>
                                          </nav>

                                      </td> */}
                                  </tr>



                              </tbody>
                          </table>

                  </div>
              </div>
</div>
    </div>
    </div>
  )
}
