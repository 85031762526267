import React, { useState, useEffect ,useRef} from 'react'
import Api from '../Services/ApiCall';
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { BeatLoader } from "react-spinners";
import JobseekerBase from '../Jobseeker/JobseekerBase';


export default function Jobseekerledgerreporttable(any, i) {

    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup1, setShowPopup1] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hasFetchedData = useRef(false);  

    useEffect(() => {
        if (!hasFetchedData.current) {
            fetchDataAndSetState();
            hasFetchedData.current = true;   
        }
    }, []);

    const fetchDataAndSetState = async () => {
        setIsLoading(true);
        try {
            var Param = { mstuserregid: sessionStorage.getItem('MUserID') }
            var url = "/Report/GetJobseekerLedgerReport";
            let _response1 = await Api.BindGrid(Param, url);
            setRowData(_response1);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };



    // const onCellClicked = (params) => {
    //     openPopup({ id: params.data.Mstemployerplancreationid, code: params.data.Plantype });
    // };

    const columnDefs = [
        { headerName: 'Date', field: 'Date', filter: true, enableFilter: true },
        { headerName: 'Description', field: 'Description', filter: true, enableFilter: true },
        { headerName: 'Debit', field: 'Debit', filter: true, enableFilter: true },
        { headerName: 'Credit', field: 'Credit', filter: true, enableFilter: true },
        { headerName: 'Balance', field: 'Balance', filter: true, enableFilter: true },
        { headerName: 'Mode of Payment', field: 'ModeofPayment', filter: true, enableFilter: true },
    ];

    const [quickFilterText, setQuickFilterText] = useState("");

    const onQuickFilterChange = (event) => {
        setQuickFilterText(event.target.value);
    };



    return (
        <div>
            <JobseekerBase JobseekerLedgerReport={true} />
            <div id="main" class="main">
                <div class="pagetitle">
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">Report</li>
                            <li class="breadcrumb-item active">Ledger Report</li>
                        </ol>
                    </nav>
                </div>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={onQuickFilterChange}
                    style={{ marginBottom: '10px' }}
                />

                {isLoading ? (
                    <div className="d-flex justify-content-center my-5 pt-5">
                        <div className='pt-5 mt-5'>
                            <BeatLoader className='mt-5' />
                        </div>
                    </div>
                ) : (

                    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            pagination={true}
                            paginationPageSize={10}
                            domLayout='autoHeight'
                            floatingFilter={true}
                            enableBrowserTooltips={true}
                            // onCellClicked={onCellClicked}
                            quickFilterText={quickFilterText}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
