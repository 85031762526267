import React, { useState, useEffect } from 'react'
import { Images } from '../Images/Index';
import { Link } from "react-router-dom";
// import '../Css/Notification.css';
import { useNavigate } from "react-router-dom";
// import Api from "../../src/Components/Services/ApiCall";
import Api from "../Components/Services/ApiCall";
import CSS from "../App.css";
import $ from 'jquery';
import Departmentcreation from './Admin/Departmentcreation';
import Agentcommision from './Admin/Agentcommision';

function SigninBase() {
  const navigate = useNavigate();

  const logout = () => {
    if(sessionStorage.getItem("AccessType")=="jobseeker"){
      navigate('/Jobseekerdashboard')
    }
   else if(sessionStorage.getItem("AccessType")=="Employer"){
      navigate('/Employerdashboard')
    }
    else{
    navigate('/')
    }
  }
  return (
    <>
      <body  >

        <div id="header" class="header fixed-top d-flex align-items-center">

          <div class="d-flex align-items-center justify-content-between">
            <a class="logo d-flex align-items-center">
              <img src={Images.mainlogo} alt="pelago" className="userlg_img" />
            </a>

            {/* <i class="bi bi-list toggle-sidebar-btn" ></i> */}




          </div>


          <nav class="header-nav ms-auto" >
            <ul class="d-flex align-items-center">

              <li class="nav-item dropdown pe-3">

                <a data-bs-auto-close="outside" data-bs-toggle="dropdown">
                  {/* <i class="bi bi-person-circle"></i> */}
                  <i class="bi bi-house" onClick={logout} style={{ fontSize: "25px", color: "blue",cursor:"pointer" }}></i>
                </a>

              </li>

            </ul>
          </nav>

        </div>
      </body>
    </>
  )
}
export default SigninBase