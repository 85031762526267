import React,{useState,useEffect} from 'react';
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import { Link ,Navigate,useNavigate,useLocation} from "react-router-dom";
import toast,{Toaster} from 'react-hot-toast';
import Swal from 'sweetalert2';


export default function Declarecommissionform() {

    const[commission,setCommission]=useState("")
    const[commission_name,setCommission_Name]=useState("")
    const navigate= useNavigate();
    const location = useLocation();
    const { ID,Flag } = location.state; 

    useEffect(() => {
        if (ID != 0){
        Get(ID);
    }else { 
   
    }
}, []);

async function IUDOperation (flag,id){

    var Commission = commission;

    if(Commission ==""){
        toast.error('Please enter Commission Percentage')
        return false;
    }
    var Param ={ 
  "mstcommissionid": ID,
  "commisioncode": "string",
  "commissionid": Commission,
  "commissionname": commission_name,
  "active": "string",
  "searchField": "string",
  "createdBy": 0,
  "createdDate": "2023-02-06T11:51:51.362Z",
  "flag": "U"
 };
    var url = "/Commission/IUDCommission";
    let _response = await Api.IUD(Param,url);
    showAlert(_response);
    setTimeout(()=>{
     navigate('/DeclareCommision')
    },2000)
    
}
const showAlert = (_response) => {
    Swal.fire({
        title: "Success",
        text: _response,
        icon: "success",
        confirmButtonText: "OK",
    });
};

    async function Get(id){
        var Param ={mstcommissionid:id}
        var url = "/Commission/BindCommission";
        let _response = await Api.BindGrid(Param,url) 
        setCommission(_response[0].Commissionid);
        setCommission_Name(_response[0].Commissionname);
     } 
  return (
    <div>
          <Toaster toastOptions={{ position: "top-right" }} />
         <Base />
          <div id="main" class="main">
              <div class="pagetitle">
                
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          <li class="breadcrumb-item">Commission Declaration</li>
                         
                      </ol>
                  </nav>
              </div>
             
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Commission Declaration</h5>

              
                      <div class="row g-3">
                          {/* <div class="col-md-6">
                          <label for="departmentcode">Department Code</label>
                              <input type="text" class="form-control"  disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Department Code" value={departmentcode}  onChange={(e) => setDepcode(e.target.value)}/>
                          </div> */}
                          <div class="col-md-6">
                          <label for="employmenttype">Commission</label>
                              <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false}  placeholder="Declare Commission"value={commission} onChange={(e) => setCommission(e.target.value)}/>
                          </div>
                          <div class="col-md-6">
                          <label for="employmenttype">Commission Name</label>
                              <input type="text" class="form-control" disabled={true}  placeholder="Commission Name"value={commission_name} onChange={(e) => setCommission_Name(e.target.value)}/>
                          </div>
                          
                          <div class="text-center">
                              <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                              <button type="reset" class="btn btn-info">
                              <Link to='/DeclareCommision'>
                                Back
                                </Link>
                                </button>
                          </div>
                      </div>

                  </div>
              </div>

           
          </div>
    </div>
  )
}
