import React, {useState, useEffect}   from 'react'
import Base from './Base';
import { Link } from "react-router-dom";
import Api from './Services/ApiCall';

import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from './Admin/Admin_form/Filter/Globalfilter';
import '../Css/Pagination.css'

function Table({ columns, data }) {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        useGlobalFilter, useSortBy, usePagination
    );
    const { globalFilter } = state

    return (
        <div>
            <Globalfilter className="form-control" filter={globalFilter} setFilter={setGlobalFilter} />
            <table class="table table-striped" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}  >
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="fa-fa-sort-asc"></i>
                                                : <i className="fa-fa-sort-desc"></i>
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td  {...cell.getCellProps()} className={` ${cell.column.className ?? ""}`}>
                                            {cell.render("Cell")}</td>

                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <br></br>
            <div className="pagination">
            <nav class="paginate page_numbers" aria-label="Page navigation">

            </nav>
            <ul class="pagination first_last">
    
    <li class="page-item"><a class="page-link"  onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
         {"First"}</a>
     </li>{" "}
     <li class="page-item"><a class="page-link"  onClick={() => previousPage()} disabled={!canPreviousPage}>
         {"<"}</a>
     </li>{" "}
     <li class="page-item"><a class="page-link"  onClick={() => nextPage()} disabled={!canNextPage}>
         {">"}</a>
     </li>{" "}
     <li class="page-item"><a class="page-link"  onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
         {"Last"}</a>
     </li>{" "}</ul>
     
     <span className="pageno">
         Page{" "}
         <strong>
             {pageIndex + 1} of {pageOptions.length}
         </strong>{" "}
     </span>
     
     <div className='showpagesize' >
                Show:{" "}
                <select
                className='showpagecss'
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[2, 10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select></div>
            </div>
        </div>
    );
}
export default function EmployeePaymentReport() {


    const[fromdate,setFromdate]=useState("");
    const[todate,setTodate]=useState("");

    const [listdata, setlistdata] = useState([]);
    const [columns,setcolumns] = useState([

        {
            Header: "ID",  Cell: (row) => {
                return <div>{Number(row.row.id) + 1}</div>;
              },
        },
        {
            Header: "Employee Code", accessor: "commisiondeduction",
        },
        {
            Header: "Amount", accessor: "payamount",
        },
       

        ])
    
    useEffect(() => {
        bindGrid()
    }, []);

    async function bindGrid() {
        var url = "/Report/EmployeePayment";
        let _response = await Api.NoDirect(url)
        setlistdata( _response)
    }

  return (
    <>
<div>
         <Base EmploeePaymentReport={true}/>
          <div id="main" class="main">
              <div class="pagetitle">
                 
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                            <Link to="/dash">Home</Link>
                            </li>
                            
                          <li class="breadcrumb-item">Report</li>
                          <li class="breadcrumb-item active">Cash Payment Report</li>
                      </ol>
                  </nav>
              </div>
          <div class="col-lg-12">

              <div class="card">
                  <div class="card-body alignleft">
                      <h5 class="card-title"> Cash Payment Report</h5>

                        {/* <div class="marlef59">
                            <span>From:</span>
                            <input type= "date" value={fromdate}  onChange={(e) => setFromdate(e.target.value)}/>
                            <span class="marlef12">To:</span>
                            <input  type= "date" value={todate}  onChange={(e) => setTodate(e.target.value)}/>
                        </div> */}

                          <Table columns={columns} data={listdata} />
                             </div>
                  </div>
    </div>
        </div>
    
    
        </div>

    </>
  )
}
