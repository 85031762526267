import React, { useState } from 'react'
import Base from '../Base'
import { Link } from "react-router-dom";

const viewbtn = {
    borderRadius: "10px",
    float: "right",
    backgroundColor: "#222a44",
    color: "#fff",
    borderColor: "none",
    height: "35px",
    width: "60px",
}

const doublebtn = {
    marginTop: "54px"

}
const experience = {
    marginTop: "20px",
}

export default function Updatejob() {

    return (
        <div>
            <Base />
            <div id="main" class="main">
                <div class="pagetitle">
                    <h1>Tables</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>

                            <li class="breadcrumb-item active">Form</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Job  Update </h5>


                        <form class="row g-3">

                            <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="Employer Name" />
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="Designation" />
                            </div>
                            <div class="col-md-6">
                                <select id="inputState" class="form-select">
                                    <option selected>Qualification Required</option>
                                    <option>10th</option>
                                    <option>12th</option>
                                    <option>UG</option>
                                    <option>PG</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <select id="inputState" class="form-select">
                                    <option selected>Experience</option>
                                    <option>Fresher</option>
                                    <option>Experienced</option>

                                </select>
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="Experience in years" />
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="No of Vacancy" />
                            </div>
                            <div class="col-md-6">
                                <input type="email" class="form-control" placeholder="Location" />
                            </div>

                            <div class="col-md-6">
                                <input type="number" class="form-control" placeholder="Updated on" />
                            </div>
                        </form>


                        {/* <div class="col-md-12 text-center">
                            <button type="submit" style={viewbtn} >Add</button>
                        </div> */}
                        <div class="text-center" style={doublebtn}>
                            <button type="button" class="btn btn-primary" >Submit</button><span>

                                <button type="reset" class="btn btn-info">
                                    <Link to='/dash'>
                                        Back
                                    </Link>
                                </button></span>
                        </div>



                    </div>
                </div>


            </div>


        </div>
    )
}

