import React, { useState, useEffect } from 'react'
import Base from '../Base';
import EmployerBase from '../Employer/EmployerBase';
import JobseekerBase from '../Jobseeker/JobseekerBase';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Api from '../Services/ApiCall';
import CSS from '../../App.css'

export default function Customersupporttable() {

  const location = useLocation();
  const { ID, Flag } = location.state;
  const navigate = useNavigate();

  const [name1, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [DO_number, set_DO_number] = useState("");
  const [DO_email, set_DO_email] = useState("");

  async function IUDOperation() {
    var Name = name1;
    var Email = email;
    var Subject = subject;
    var Message = message;


    if (Subject == "") {
      toast.error("Please Enter Subject")
      return false;
    }
    if (Message == "") {
      toast.error("Please Enter Message")
      return false;
    }
    const accessType = sessionStorage.getItem('AccessType');
    const userDetails = JSON.parse(sessionStorage.getItem('userdetails'));

    const code = accessType === 'jobseeker' ? userDetails[0].Mstjobseekercode : userDetails[0].Msusertregcode;
    const userName = sessionStorage.getItem('username');
    const userEmail = sessionStorage.getItem('Email');

    var Param = {
      "mstcustomersupportid": ID,
      "mycustomersupportcode": code,
      "name": userName,
      "email": userEmail,
      "subject": Subject,
      "message": Message,
      "active": "string",
      "searchField": "string",
      "flag": "I"
    };
    var url = "/CustomerSupport/IUDCustomerSupport";
    let _response = await Api.IUD(Param, url);
    toast.success(_response);
    clearState();
  }
  const clearState = () => {

    setName('');
    setEmail('');
    setSubject('');
    setMessage('')
  }

  useEffect(() => {
    Get();
    Get_DO_number();
  }, []);

  const [emailid, setEmailid] = useState("");
  const [contactnumber, setContactnumber] = useState("");
  const [place, setLocation] = useState("");

  async function Get(id) {
    var Param = { Mstcompanyprofileid: 3 }
    var url = "/Company/BindCompany";
    let _response = await Api.BindGrid(Param, url)

    setEmailid(_response[0].Emailid);
    setContactnumber(_response[0].Phonenumber);
    setLocation(_response[0].Location);

  }
  async function Get_DO_number() {
    const accessType = sessionStorage.getItem('AccessType');
    const userDetails = JSON.parse(sessionStorage.getItem('userdetails'));

    const code = accessType === 'jobseeker' ? userDetails[0].Mstjobseekercode : userDetails[0].Msusertregcode;
    
    var url = "DropDown/GetDO_Office?code="+code;
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    set_DO_number(_response.Text)
    set_DO_email(_response.Text1)

  }






  return (
    <>
      <div>
        <Toaster toastOptions={{ position: "top-right" }} />
        {Flag == "EmpCS" ?
          <EmployerBase /> : null}
        {Flag == "JobCS" ?
          <JobseekerBase /> : null}
        {Flag == "CS" ?
          <Base /> : null}

        <div id="main" class="main">
          <div class="pagetitle">
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/dash">Home</Link>
                </li>

                <li class="breadcrumb-item">Customer Support</li>

              </ol>
            </nav>
          </div>
          <section class="section contact">
            <div class="row gy-4">
              <div class="col-xl-6">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="card" style={{  height: '150px'}}>
                      <i class="bi bi-geo-alt" style={{ color: 'blue' }}></i>
                      <h3 style={{ color: '#4154f1' }}>Address</h3>
                      <p>{place}</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class=" card" style={{  height: '150px'}}>   
                      <i class="bi bi-telephone" style={{ color: 'blue' }}></i>
                      <h3 style={{ color: '#4154f1' }}>Call Us</h3>
                      <p>{contactnumber}<div></div>{DO_number}</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class=" card" style={{  height: '150px'}}>
                      <i class="bi bi-envelope" style={{ color: 'blue' }}></i>
                      <h3 style={{ color: '#4154f1' }}>Email Us</h3>
                      <p>{emailid}<div></div>{DO_email}</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card" style={{  height: '150px'}}>
                      <i class="bi bi-clock" style={{ color: 'blue' }}></i>
                      <h3 style={{ color: '#4154f1' }}>Open Hours</h3>
                      <p>Monday - Saturday<div></div>
                        <p></p>
                        9:00AM - 07:00PM</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="card p-4">
                  <div class="row gy-4 customercon">
                    {/* <div class="col-md-6">
                      <input type="text" name="name" class="form-control" disabled={true} autoComplete='off'placeholder="Your Name" value={name1} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div class="col-md-6 ">
                      <input type="email" class="form-control" name="email" disabled={true} autoComplete='off' placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div> */}
                    <div class="col-md-12">
                      <input type="text" class="form-control" name="subject" autoComplete='off' placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </div>
                    <div class="col-md-12">
                      <textarea class="form-control" name="message" rows="6" autoComplete='off' placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} >
                      </textarea>
                    </div>
                    <div class="col-md-12 text-center">
                      <button type="button" class="btn btn-primary" onClick={IUDOperation}>Send Message</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </>


  )
}