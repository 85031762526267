import React, { useState, useEffect } from 'react'
import Base from '../../Base';
import { Link, useLocation } from 'react-router-dom';
// import '../../../Css/Rolecreation.css';
import toast, { Toaster } from 'react-hot-toast';
import Api from '../../Services/ApiCall';
// import { GetApp } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

export default function Rolecreationform() {

    const navigate = useNavigate();
    const location = useLocation();
    const { ID, Flag } = location.state;
    useEffect(() => {
        if (ID != 0) {
            Get(ID)
        }
        else {
            GetMenu(0)
        }


    }, []);
    const [role, setRole] = useState("");
    const [role_for, setRole_For] = useState("");

    async function IUDOperation(flag, id) {
        var Role = role;
        if (Role == "") {
            toast.error('Enter the Role')
            return false;
        }
        var Param = {
            "mstroleid": ID,
            "rolecode": role_for,
            "rolename": Role,
            "roleList": JSON.stringify(ListMenu),
            "flag": Flag
        };
        var url = "/Role/IUDRole";
        let _response = await Api.IUD(Param, url);
        showAlert(_response)
        setTimeout(() => {
            navigate('/Rolecreationgrid')
        }, 2000)

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };


    const [ListMenu, setListMenu] = useState([]);
    async function GetMenu(id) {
        var Param = { mstmenuid: id }
        var url = "/Menu/BindMenu";
        let _response = await Api.BindGrid(Param, url)
        //  //console.log("get", _response)


        setListMenu(_response);

        // //console.log(_response);
    }

    async function Get(id) {
        var Param = { Mstroleid: id }
        var url = "/Role/BindRole";
        let _response1 = await Api.BindGrid(Param, url);

        setRole_For(_response1[0].Rolecode);
        setRole(_response1[0].Rolename);
        setListMenu(JSON.parse(_response1[0].RoleList));
        // //console.log(_response1, "+++++++++++++")


    }
    const handleOnChange = (event, option, index, F) => {

        const values = [...ListMenu];
        if (event.target.checked) {
            if (F === "V") {
                values[index].View = 1;
            } if (F === "A") {
                values[index].Add = 1;
            } if (F === "E") {
                values[index].Edit = 1;
            } if (F === "D") {
                values[index].Delete = 1;
            } if (F === "P") {
                values[index].Print = 1;
            }
        }
        else {
            if (F === "V") {
                values[index].View = 0;
            } if (F === "A") {
                values[index].Add = 0;
            } if (F === "E") {
                values[index].Edit = 0;
            } if (F === "D") {
                values[index].Delete = 0;
            } if (F === "P") {
                values[index].Print = 0;
            }
        }
        setListMenu(values);
        // //console.log(ListMenu)
    };

    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <Base Rolecreationgrid={true} />
            <div id="main" class="main">
                <div class="pagetitle">

                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">General Setting</li>
                            <li class="breadcrumb-item active">Role</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Role  Creation </h5>



                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="role">Role For</label>
                                <select class="form-select"  value={role_for} onChange={(e) => setRole_For(e.target.value)}>
                                    <option>Select</option>
                                    <option>Agent</option>
                                    <option>Employee</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="role">Role</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Role" value={role} onChange={(e) => setRole(e.target.value)} />
                            </div>
                            <table class="table table-striped">
                                <thead>
                                    <tr>

                                        <th scope="col">Menu Name</th>
                                        <th scope="col">Rights</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {ID == 0 ? <>
                                        {ListMenu.map((i, index) => (
                                            <tr>
                                                <td>{i.Menuname}</td>
                                                <td>
                                                    <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "V")} checked={i.View === 1} value="" id={"View_" + i.Mstmenuid + "_"} />View
                                                    <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "A")} checked={i.Add === 1} value="" id={"Add_" + i.Mstmenuid + "_"} />Add
                                                    <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "E")} checked={i.Edit === 1} value="" id={"Edit_" + i.Mstmenuid + "_"} />Edit
                                                    <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "P")} checked={i.Print === 1} value="" id={"Print_" + i.Mstmenuid + "_"} />Print
                                                    <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "D")} checked={i.Delete === 1} value="" id={"Delete_" + i.Mstmenuid + "_"} /> Delete
                                                </td>
                                            </tr>

                                        ))}
                                    </>

                                        :
                                        <>
                                            {ListMenu.map((i, index) => (
                                                <tr>
                                                    <td>{i.Menuname}</td>
                                                    <td>
                                                        <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "V")} checked={i.View === 1} value="" id={"View_" + i.Mstmenuid + "_"} />View
                                                        <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "A")} checked={i.Add === 1} value="" id={"Add_" + i.Mstmenuid + "_"} />Add
                                                        <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "E")} checked={i.Edit === 1} value="" id={"Edit_" + i.Mstmenuid + "_"} />Edit
                                                        <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "P")} checked={i.Print === 1} value="" id={"Print_" + i.Mstmenuid + "_"} />Print
                                                        <input class="form-check-input" type="checkbox" onChange={(e) => handleOnChange(e, i, index, "D")} checked={i.Delete === 1} value="" id={"Delete_" + i.Mstmenuid + "_"} /> Delete
                                                    </td>
                                                </tr>

                                            ))}
                                        </>}
                                </tbody >
                            </table >

                        </div>
                        <div class="rolebutton">
                            <div class="text-center ">
                                <button type="button" class="btn btn-primary " onClick={IUDOperation}>Submit</button>
                                <button type="reset" class="btn btn-danger ">
                                    <Link to='/Rolecreationgrid'>
                                        Back
                                    </Link>
                                </button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>


        </div>



    )
}

