import React, { useState } from 'react'
import Base from './Base';
import { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';

export default function Reinvoice_grid() {

    const [invoice_number, setInvoicenumber] = useState("");
    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />

            <Base />
            <div id="main" class="main">
                <div class="pagetitle">
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">Reprint</li>
                            <li class="breadcrumb-item active">Invoice</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Invoice Reprint</h5>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="invoice number">Invoice Number</label>
                                <input type="text" class="form-control" value={invoice_number} placeholder="Invoice number" onChange={(e) => setInvoicenumber(e.target.value)} />
                            </div>
                            <div class="text-center">
                                <Link to='/reinvoice'state={{Invoice_number:invoice_number}}>
                                    <button type="button" class="btn btn-primary" >Submit</button>
                                </Link>
                                <button type="reset" class="btn btn-info">
                                    <Link to='/dash'>
                                        Back
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
