import React, { useState, useEffect } from 'react';
import Api from '../Services/ApiCall';
import Base from '../Base';
import { Link } from "react-router-dom";
import '../../App.css'


export default function Otherusers() {
    const [listdata, setlistdata] = useState([]);
   
    const [Griddata, setlGriddata] = useState([]);
    let _a = [];
    const menu = JSON.parse(sessionStorage.getItem("roleaccess"))
    useEffect(() => {

        Checkvalidation()
      
    }, []);

    async function Checkvalidation() {


        try {
             if (checkmenu(4)[0]['View'] == 1) {
                set_Usertype("Employer")
                _a.push("Employer")
                let _a1 = [];
                _a1.push("Employer")
                var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a1) }
                var url = "/UserReg/GetUserRegtable";
                let _response1 = await Api.BindGrid(Param, url)
                ////console.log(_response1)
                setlistdata(_response1)
            }
            else if (checkmenu(2)[0]['View'] == 1) {
                set_Usertype("Employee")
                _a.push("Employee")
                let _a1 = [];
                _a1.push("Employee")
                var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a1) }
                var url = "/UserReg/GetUserRegtable";
                let _response1 = await Api.BindGrid(Param, url)
               // //console.log(_response1)
                setlistdata(_response1)
            }
            // if(checkmenu(5)[0]['View']==1){
            //     set_Usertype("User")
            //    // _a.push("User")

            // }
            // else if(checkmenu(3)[0]['View']==1){
            //     set_Usertype("Agent")
            //     //_a.push("Agent")
            // }
            // else  if(checkmenu(4)[0]['View']==1){
            //     set_Usertype("Employer")
            //     //_a.push("Employer")
            // }
            // else  if(checkmenu(2)[0]['View']==1){
            //     set_Usertype("Employee")
            //    // _a.push("Employee")
            // }
            //setlGriddata(_a);
        }
        catch {

        }

    }
    function checkmenu(id) {
        return menu.filter(function (menu) {
            try {

                return menu.MenuID == id;
            }
            catch {

                return menu.MenuID == id;
            }

        });
    }
    async function BindGrid() {
        let _a1 = [];
        _a1.push(_Usertype)
        var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a1) }
        var url = "/UserReg/GetUserRegtable";
        let _response1 = await Api.BindGrid(Param, url)
       // //console.log(_response1)
        setlistdata(_response1)
    }
    const [_Usertype, set_Usertype] = useState("");
    async function checkBoxMenuValidation(e) {
        set_Usertype(e.target.value);
        let _a1 = [];
        _a1.push(e.target.value)
        var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a1) }
        var url = "/UserReg/GetUserRegtable";
        let _response1 = await Api.BindGrid(Param, url)
        ////console.log(_response1)
        setlistdata(_response1)
    }
  return (
    <div>
          <Base />
          <div id="main" class="main">
              <div class="pagetitle">
                  {/* <h1>Tables</h1> */}
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                              <Link to="/dash">Home</Link>
                          </li>

                          <li class="breadcrumb-item">Other Users Registration</li>
                          {/* <li class="breadcrumb-item active">General</li> */}
                      </ol>
                  </nav>
              </div>
              <div class="col-lg-12">

                  <div class="card">
                      <div class="card-body">
                          <div class="divGroup col-md-12">
                              {/* {checkmenu(5)[0]['View'] == 1 ? <>
                                  <input type="radio" id="User" value="User" checked={_Usertype == "User"} onChange={(e) => checkBoxMenuValidation(e)} /><label for="User">User</label>
                              </> : null}
                                {checkmenu(3)[0]['View'] == 1 ? <> <input type="radio" id="Agent" value="Agent" checked={_Usertype == "Agent"} onChange={(e) => checkBoxMenuValidation(e)} /><label for="Agent">Agent</label>
                              </> : null}   */}
                              {checkmenu(2)[0]['View'] == 1 ? <> <input type="radio" id="Employee" value="Employee" checked={_Usertype == "Employee"} onChange={(e) => checkBoxMenuValidation(e)} />
                              <label for="Employee">Employee</label>
                              </> : null} 
                               {checkmenu(4)[0]['View'] == 1 ? <> <input type="radio" id="Employer" value="Employer" checked={_Usertype == "Employer"} onChange={(e) => checkBoxMenuValidation(e)} />
                               <label for="Employer">Employer</label>
                              </> : null}
                          </div>
                          <h5 class="card-title"> {_Usertype} </h5>
                          {Api.CheckAdd(14) == 1 ?
                          <button type="button" class="btn btn-primary Add">
                              <Link to={'/guestform'}
                                  state={{ ID: 0, Flag: 'I', UserT: _Usertype, Page: "age_emp" }}
                              >
                                  Add
                              </Link>
                          </button>
                         : null}


                          <table class="table table-striped">
                              <thead>
                                  <tr>
                                      <th scope="col">SNo</th>
                                      <th scope="col">Code</th>
                                      <th scope="col">Name</th>
                                      <th scope="col">MobileNo</th>
                                      <th scope="col">EmailID</th>
                                      <th scope="col">AadharNo</th>
                                      <th scope="col">PanNo</th>


                                      <th scope="col">Action </th>

                                  </tr>
                              </thead>
                              <tbody>
                                  {listdata.map((i, index) => (

                                      <><tr key={index}>
                                          <th scope="row">{index + 1}</th>
                                          <td>{i.Msusertregcode}</td>
                                          <td>{i.Name}</td>
                                          <td>{i.Mobilenumber}</td>
                                          <td>{i.Emailid}</td>
                                          <td>{i.Aadhaarnumber}</td>
                                          <td>{i.Pannumber}</td>

                                          <td>
                                              <ol class="breadcrumb">
                                              {Api.CheckUpdate(14) == 1 ?
                                                  <li class="breadcrumb-item">
                                                      <Link to={'/guestform'} state={{ ID: i.Mstuserregid, Flag: 'U', UserT: i.Rolecab , Page : "age_emp" }}>
                                                          <i class="bi bi-pencil-square" /> </Link> </li>
                                                           : null}
                                                    {Api.CheckDelete(14) == 1 ?
                                                  <li class="breadcrumb-item active">
                                                      <Link to={'/guestform'} state={{ ID: i.Mstuserregid, Flag: 'D', UserT: i.Rolecab, Page: "age_emp" }}>
                                                          <i class="bi bi-trash" /></Link></li>
                                                            : null}
                                              </ol>
                                          </td>

                                      </tr><td>
                                          </td></>
                                  ))}
                              </tbody>
                          </table>
                        

                      </div>
                  </div>
              </div>
          </div>

    </div>
  )
}
