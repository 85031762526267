import React, { useEffect, useState } from 'react'
import Api from '../../Services/ApiCall'
import Base from '../../Base';
import { Link,useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import {
    validPhoneNumberRegex,
    validPasswordRegex,
    validEmailRegex,
    validOnlyEmailRegex,
    maxLength,
    numberMethod,
}from '../../CommanRules'
export default function Staffgradedesignationform() {

     
    //  const location = useLocation();
    //  const { ID,Flag } = location.state;
    //  //console.log('%%%', ID, Flag);

    useEffect(() => {
        Get();
    }, []);


    const[gradetype,setGradetype] =useState("");
    const [gradetype1, setGradetype1] = useState("");

    async function bindgrid(id)
 {
        var Param = { Id: id }
        var url = "/Demo";
        let _response = await Api.BindGrid(Param, url)
       // //console.log(_response)
    }

    async function IUDOperation(flag, id)
    {
       
       var Grade = gradetype;
       var Grade1 = gradetype1;
     

      

       if (Grade == "") {
           toast.error('Please enter a gradetype')
           return false;
       }
       

       if (Grade1 == "") {
           toast.error('Please enter a Grade type')
           return false;
       }
       

   
   var Param = {  "Grade": Grade, "Grade1": Grade1,  "ID": id, "flag": flag };
   var url = "/Demo";
   let _response = await Api.IUD(Param, url);
   showAlert(_response)
   ////console.log(_response);

}
const showAlert = (_response) => {
    Swal.fire({
        title: "Success",
        text: _response,
        icon: "success",
        confirmButtonText: "OK",
    });
};

async function Get(id)
 {
   var Param = { Id: id }
   var url = "/Demo";
   ////console.log("Param", Param, "URL", url)
   let _response1 = await Api.BindGrid(Param, url)
   var _response = {
    Grade: "", Grade1:""
   }
  
   setGradetype(_response.Grade);
   setGradetype1(_response.Grade1);

   ////console.log(_response);
}



  return (
    <div>
        <Toaster toastOptions={{ position: "top-right" }} />

        <Base />
          <div id="main" class="main">
              <div class="pagetitle">
                  <h1>Tables</h1>
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          <li class="breadcrumb-item">Admin</li>
                          <li class="breadcrumb-item active">Form</li>
                      </ol>
                  </nav>
              </div>
             
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Staff Grade Designation</h5>

              
                      <div class="row g-3">
                      <div class="col-md-6">
                              <select id="inputState" class="form-select" value={gradetype} onChange={(e) => setGradetype(e.target.value)}>
                                  <option selected>Select Grade Type</option>
                                  <option>D</option>
                                  <option>T</option>
                                  <option>M</option>
                                  <option>E</option>
                                  <option>A</option>
                              </select>
                          </div>
                          <div class="col-md-6">
                              <input type="text" class="form-control" placeholder="Automatic Based on Grade type" value={gradetype1} onChange={(e) => setGradetype1(e.target.value)}/>
                          </div>
                          <div class="text-center">
                              <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                              <button type="reset" class="btn btn-info">
                              <Link to='/staffgradedesignationtable'>
                                Back
                                </Link>
                                </button>
                          </div>
                      </div>

                  </div>
              </div>

           
          </div>
    </div>
  )
}