import React from 'react';

function Footer() {
    return (
        <div>
            <div className="">
                <div className="mt-3">
                 <div className="container loginrow1" style={{ border: '1px solid black', padding: '10px' }}>
                    <h4>Jobsss</h4>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Job Title</th>
                                        <th>Company Name</th>
                                        <th>Vacancies</th>
                                        <th>Experience</th>
                                        <th>Location</th>
                                        <th>
                                            <button className="btn btn-primary">Post Jobs</button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-left align-middle important-align">1</td>
                                        <td className="text-left align-middle important-align">Data 1</td>
                                        <td className="text-left align-middle important-align">Data 2</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align"><a href='/'>Apply</a></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left align-middle important-align">2</td>
                                        <td className="text-left align-middle important-align">Data 1</td>
                                        <td className="text-left align-middle important-align">Data 2</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align"><a href='/'>Apply</a></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left align-middle important-align">3</td>
                                        <td className="text-left align-middle important-align">Data 1</td>
                                        <td className="text-left align-middle important-align">Data 2</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align"><a href='/'>Apply</a></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left align-middle important-align">4</td>
                                        <td className="text-left align-middle important-align">Data 1</td>
                                        <td className="text-left align-middle important-align">Data 2</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align"><a href='/'>Apply</a></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left align-middle important-align">5</td>
                                        <td className="text-left align-middle important-align">Data 1</td>
                                        <td className="text-left align-middle important-align">Data 2</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align"><a href='/'>Apply</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="mt-3">
               <div className="container loginrow1" style={{ border: '1px solid black', padding: '10px' }}>
                    <h4>Jobseekers</h4>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Jobseeker Code</th>
                                        <th>Jobseeker Name</th>
                                        <th>Date of Birth</th>
                                        <th>Email</th>
                                        <th>Contact Number</th>
                                        <th>
                                            <button className="btn btn-primary">Register</button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-left align-middle important-align">1</td>
                                        <td className="text-left align-middle important-align">Data 1</td>
                                        <td className="text-left align-middle important-align">Data 2</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align"><a href='/'>Impress</a></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left align-middle important-align">2</td>
                                        <td className="text-left align-middle important-align">Data 1</td>
                                        <td className="text-left align-middle important-align">Data 2</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align"><a href='/'>Impress</a></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left align-middle important-align">3</td>
                                        <td className="text-left align-middle important-align">Data 1</td>
                                        <td className="text-left align-middle important-align">Data 2</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align"><a href='/'>Impress</a></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left align-middle important-align">4</td>
                                        <td className="text-left align-middle important-align">Data 1</td>
                                        <td className="text-left align-middle important-align">Data 2</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align"><a href='/'>Impress</a></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left align-middle important-align">5</td>
                                        <td className="text-left align-middle important-align">Data 1</td>
                                        <td className="text-left align-middle important-align">Data 2</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align">Data 3</td>
                                        <td className="text-left align-middle important-align"><a href='/'>Impress</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
