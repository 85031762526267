import React, { useState ,useEffect} from 'react'
import Select from "react-select";
import toast,{Toaster} from 'react-hot-toast';
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import { Link ,useLocation} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
// import Select from "react-select";
import {
    validEmailRegex,
    validPasswordRegex,
    validOnlyEmailRegex,
    validPhoneNumberRegex,
    numberMethod,
    maxLength
} from '../../CommanRules'

export default function Agentregistrationform() {

    const navigate = useNavigate();

    const location = useLocation();
    const { ID, Flag } = location.state;
   // //console.log('%%%', ID, Flag);
    useEffect(() => {
        if (ID != 0) {
            Get(ID)
        }
        GetQualification()
    }, []);

    const [name, setName] = useState("");
    const [Mobilenumber, setMobilenumber] = useState("");
    const [Pannumber, setPannumber] = useState("");
    // const [Qualification, setQualification] = useState("");
    const [Emailid, setEmailid] = useState("");
    const [Aadharnumber, setAadharnumber] = useState("");
    const [qualification, setQualification] = useState([])
    const [selectedQualiify, setselectedQualiify] = useState([]);
    const [gradetype, setgradetype] = useState([]);
   // //console.log(selectedQualiify, "selected")

    async function GetQualification() {

        var url = "/DropDown/GetQualification";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setQualification(_response)
       // //console.log(_response, "qualify")
    }

    async function GetGradeType(id) {

        var url = "/DropDown/GetGradeType?GradeCode=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setgradetype(_response[0].Text.split(","))
       // //console.log(_response, "setgradetype")
    }

    var dt = new Date();
    async function BindGrid(id) {
        var Param = {
            Id: id, "createdBy": 0,
            "createdDate": dt
        }
        var url = "/Demo";
        let _response = await Api.BindGrid(Param, url)
        ////console.log(_response)
    }

    async function IUDOperation() {



        var Name = name;
        var Phone = Mobilenumber;
        var Pannum = Pannumber;
        var Qualification = selectedQualiify;
        var Email = Emailid;
        var AadharNo = Aadharnumber;

        if (Name.length > 3) {
            if (validPhoneNumberRegex.test(Mobilenumber)) {
                if (Pannum) {
                    if (Qualification) {
                        if (validOnlyEmailRegex.test(Emailid)) {
                            if (AadharNo.length > 11) {
                            }
                            else {
                                toast.error('Please Enter valid Aadharnumber');
                            }
                        }

                        else {
                            toast.error('Please Enter Valid Email id');
                        }
                    }
                    else {
                        toast.error('Please select Qualification');
                    }
                }
                else {
                    toast.error('Please Enter Valid Pannumber');
                }
            }
            else {
                toast.error('Please Enter Valid Mobilenumber');
            }
        }
        else {
            toast.error('Please Enter Valid Name');
        }
        var Param = {
           
            "name": Name,
            "mobilenumber": Phone,
            "pannumber": Pannum,
            "qualification": Qualification,
            "password": "string",
            "emailid": Email,
            "aadhaarnumber": AadharNo,
            "Mstagentregistrationid": ID,
             "flag": Flag, 
             "createdBy": 0,
            "createdDate": dt
        };
        var url = "/AgentRegistration/IUDAgentRegis";
       // //console.log(Param)
        let _response = await Api.IUD(Param, url);
        ////console.log(_response, "+++++++++++++");
        showAlert(_response);
        setTimeout(()=>{
            navigate('/Agentregistration')
           },2000)

        // navigate('/agentreg')

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function Get(id) {
        ////console.log(id,"id")
        var Param = {
            Mstagentregistrationid: id }
        var url = "/AgentRegistration/BindAgentRegis";
        let _response = await Api.BindGrid(Param, url)
       // //console.log("get", _response)

        //   //console.log(_response.AadharNo,"+++++++++++++");
        setName(_response[0].Name);
        setMobilenumber(_response[0].Mobilenumber);
        setPannumber(_response[0].Pannumber);
        setEmailid(_response[0].Emailid);
        setAadharnumber(_response[0].Aadhaarnumber);
        setselectedQualiify(_response[0].Qualification);
        ////console.log(_response);
    }

    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <Base />
            <div id="main" class="main">
                <div class="pagetitle">
                    <h1>Tables</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">Admin</li>
                            <li class="breadcrumb-item active">Form</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Agent Registration</h5>

                        <div class="row g-3">
                            <div class="col-md-6">
                                <select class="form-select" placeholder="Grade" onChange={(e) => GetGradeType(e.target.value)} >
                                    <option selected>Select</option>
                                    <option value="A">A</option>
                                    {/* <option value="D">D</option> */}
                                </select>
                            </div>
                            <div class="col-md-6">
                                <select class="form-select" placeholder="GradeType">
                                    <option selected>Select</option>
                                    {gradetype.map((i, index) => (
                                        <option value={i}>{i}</option>
                                    ))}
                                </select>

                            </div>
                            {/* <div class="col-md-6">
                                <input type="text" class="form-control" />
                            </div> */}
                            <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="DESIGINATION" />
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <input type="email" class="form-control" placeholder="Mobile Number" value={Mobilenumber} onChange={(e) => setMobilenumber(e.target.value)} />
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="Pan Number" value={Pannumber} onChange={(e) => setPannumber(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <select id="inputState" class="form-select" onChange={(e) => setselectedQualiify(e.target.value)}  >
                                    <option selected>Qualification</option>
                                    {qualification.map((i, index) => (
                                        <option value={i.id}>{i.Text}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="Email ID" value={Emailid} onChange={(e) => setEmailid(e.target.value)} />
                            </div>

                            <div class="col-6">
                                <input type="text" class="form-control" placeholder="Aadhaar Number" value={Aadharnumber} onChange={(e) => setAadharnumber(e.target.value)} />
                            </div>

                            <div class="text-center">
                                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                                <button type="reset" class="btn btn-info">
                                    <Link to='/agentreg'>
                                        Back
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}