import React,{useState,useEffect} from 'react'
import Api from'../../Services/ApiCall';
import Base from'../../Base';
import { Link,useLocation } from "react-router-dom";
import PDFGEN from './PDFGen';
// import { Document, Page, Text, View, StyleSheet, PDFViewer,PDFDownloadLink } from '@react-pdf/renderer';

export default function Jobseekerpreview() {
    const [listdata, setlistdata] = useState([]);
    const location = useLocation();
    const { ID, Flag } = location.state;
    ////console.log('%%%', ID, Flag);
    const [aadharnumber, setAadharno] = useState("");
    const [name, setName] = useState("");
    const [dob, setDob] = useState("");
    const [uploadFile, setuploadFile] = useState("");
    const [mobilenumber, setMobilenumber] = useState("");
    const [email, setEmail] = useState("");
    const [qualification, setQualification] = useState([]);
    const [updateon, setUpdateon] = useState("");

    const [selectedQualiify, setselectedQualiify] = useState([]);
    const [formFields12, setFormFields] = useState([]);
  
    const [formFields, setFormField] = useState([]);
    const [Overalldata, setOveralldata]=useState([]);
   // //console.log(formFields, "experience")
   // //console.log(Overalldata,"test")
    useEffect(() => {
        if (ID != 0) {
           
            BindGrid(ID)
            Get(ID)
           
        }
        // GetQualification()
       /// BindGrid(0)

    }, []);



    async function BindGrid(id) {
        var Param = { mstjobseekerid: ID }
        var url = "/JobSeekerTable/BindJobSeekerTable";
        let _response1 = await Api.BindGrid(Param, url)
       // //console.log("fddfdf", _response1)
        // setAadharno(_response1[0].Aadhaarnumber);
        // setDob(_response1[0].Dataofbirth);
        // setEmail(_response1[0].Email);
        // setMobilenumber(_response1[0].Mobilenumber);
        // setName(_response1[0].Name);
        // setFormFields(JSON.parse(_response1[0].qualification))
        // //console.log(_response1)

        setAadharno(_response1[0].Aadhaarnumber);
        setName(_response1[0].Name);
        setDob(_response1[0].Dataofbirth);
        setMobilenumber(_response1[0].Mobilenumber);
        setEmail(_response1[0].Email);
       // //console.log("teste", JSON.parse(_response1[0].Qualifcation));
        setFormFields(JSON.parse(_response1[0].Qualifcation));
        setFormField(JSON.parse(_response1[0].Education));
       // //console.log(formFields12, "formFields12")
        setUpdateon(_response1[0].Updateon);
        setOveralldata(_response1[0])
    }
    async function Get(id) {

        var Param = { Mstjobseekerid: id }
        var url = "/JobSeekerTable/BindJobSeekerTable";
        ////console.log("Param", Param, "URL", url)
        let _response = await Api.BindGrid(Param, url)

        ////console.log(_response, "fgfgrdrfgddr");
        setAadharno(_response[0].Aadhaarnumber);
        setName(_response[0].Name);
        setDob(_response[0].Dataofbirth);
        setMobilenumber(_response[0].Mobilenumber);
        setEmail(_response[0].Email);
        setFormFields(JSON.parse(_response[0].Qualification));
        ////console.log(formFields12, "formFields12")
        setUpdateon(_response[0].Updateon);
        setFormField(JSON.parse(_response[0].Education));
       // //console.log(formFields, "formFields12")




    }
  return (
    <div>
          <div>

              <Base />
              <div id="main" class="main">
                  <div class="pagetitle">
                      <h1>Form</h1>
                      <nav>
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                              <li class="breadcrumb-item"><Link to="/Jobseeker_table">Table</Link></li>
                              <li class="breadcrumb-item active">Jobseeker</li>
                          </ol>
                      </nav>
                  </div>

                  <div class="card">
                      <div class="card-body">
                          <h5 class="card-title">JobSeeker </h5>


                          <div class="row g-3">

                              <div class="col-md-6">
                                  <label for="fname" >First name:{name}</label>
                              </div>
                              <div class="col-md-6">
                                  <label for="fname">Aadhar Number:{aadharnumber} </label>
                              </div>

                              <div class="col-md-6">
                                  <label for="dob">Date of Birth:{dob}</label>
                              </div>
                              <div class="col-md-6">
                                  <label for="mobilenumber">Mobile number:{mobilenumber}</label>
                              </div>

                              <div class="col-md-6">
                                  <label for="email">Email ID:{email}</label>
                              </div>
                              {/* <div class="col-md-6">
                                  <label for="dob">Qualification:{selectedQualiify}</label>
                               
                              </div> */}
                              <div class="col-md-6">
                                  <label for="dob">Updated on:{updateon}</label>
                                   </div>
                              <div class="col-md-6">
                                  <label for="uploadstatus">File Upload Status:</label>
                                 
                              </div>
                          </div>



                            
                            
                            
                              <h5>Additional  Education Details</h5>
                              <table class="table table-striped">
                                  <thead>
                                      <tr>
                                          <th scope="col">S.No</th>
                                          <th scope="col">Qualifcation</th>
                                          <th scope="col"> Course</th>
                                          <th scope="col">Institution Name</th>
                                          <th scope="col">Passedout Year</th>

                                      </tr>
                                  </thead>
                                  <tbody>
                                      {formFields.map((form1, index) => {
                                          return (
                                              <><tr key={index}>
                                                  <th scope="row">{index + 1}</th>
                                                  <td>{form1.qualify}</td>
                                                  <td>{form1.course}</td>
                                                  <td>{form1.institution}</td>
                                                  <td>{form1.passedout}</td>
                                                
                                                  <td>
                                                    
                                                  </td>

                                              </tr><td>
                                                  </td></>
                                          )})}

                                    </tbody>
                                    </table>



                             
                             
                             
                             
                             
                             
                             
                              <h5>Additional  Experience  Details</h5>
                              <table class="table table-striped">
                                  <thead>
                                      <tr>
                                          <th scope="col">S.No</th>
                                          <th scope="col">Company Name</th>
                                          <th scope="col"> designation</th>
                                          <th scope="col">Joining Date</th>
                                          <th scope="col">Releiving Date</th>

                                      </tr>
                                  </thead>
                                  <tbody>
                                        {formFields12.map((form, index) => {
                                          return(


                                              <><tr key={index}>
                                                  <th scope="row">{index + 1}</th>
                                      <td>{form.companyname}</td>
                                      <td>{form.designation}</td>
                                      <td>{form.years}</td>
                                      <td>{form.month}</td>


                                                  <td>

                                                  </td>

                                              </tr><td>
                                                  </td></>
                                         ) })}

                                  </tbody>
                              </table>













                             





                            
                            
                                  {/* <PDFDownloadLink
                                  document={<PDFGEN data={name} aadharnumber={aadharnumber} dob={dob} mobilenumber={mobilenumber} email={email} formFields={formFields} formFields12={formFields12  } qualify={selectedQualiify} updateon={updateon}  />}
                                  
                                  fileName="somename.pdf"
                                  >
                              {({ loading }) => loading ? "loading data...." : <button class="btn btn-primary" type="button"> Export PDF </button>}
                                  </PDFDownloadLink> */}
                                
                                  {/* <PDFGEN data={name} aadharnumber={aadharnumber} /> */}
                                 
                                 
                                  <div class="text-center">
                                      <button type="reset" class="btn btn-info"><Link to="/Jobseeker_table">Back</Link></button>
                                  </div>
                              
                                 

                            
                          
                            
                     

                      </div>
                  </div>


              </div>
          </div>
    </div>
  )
}
