import React, { useState, useEffect } from 'react'
import '../Css/Filterjobs.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Api from './Services/ApiCall';
import { Images } from '../Images/Index';
import SigninBase from './SigninBase';


export default function Filterjobs() {

    const navigate = useNavigate();
    const location = useLocation();
    const { ID, Flag } = location.state;

    useEffect(() => {
        GetViewjobs()
        if (ID != 0) {

        }

    }, []);

    const [viewjobs, setViewjobs] = useState([]);



    async function GetViewjobs() {

        var Paycheck = JSON.parse(sessionStorage.getItem('userdetails'))[0]['Subscribe'];

        var Param = {
            Flag: Paycheck
        };
        var url = "/Postjobs/ViewJobs";
        let _response = await Api.BindGrid(Param, url)
        let Arry_Title1 = [];
        setViewjobs(_response)
    }


    return (
        <>

            <div class="main" >

               <SigninBase />

                <div class="col-lg-12 col-md-12 mt-5">
                    <div class="job-listing-area pt-120 pb-120">

                        <div class="container">
                            <div class="row">
                                <div class='col-xl-3 lg-3 md-4'>
                                    <div class="col-12">
                                        <div class="small-section-tittle mb-45">
                                            <div class="ion">
                                                <h4>Filter jobs</h4>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="job-category listing mb-50 hi">
                                        <div class="single-listing">
                                            <div class="small-section-tittle2">






                                                <h4>Job category</h4>


                                                <select className='alltag'>
                                                    <option><input type="checkbox"></input>All category</option>
                                                </select>
                                            </div>
                                            <br>
                                            </br>
                                            <br></br>
                                            <div class="select-categories pt-80 pb-50">

                                                <div class="small-section-tittle">

                                                    <h4 >Job Type</h4>
                                                </div>
                                                <div className='order2'>
                                                    <ul className='a'>
                                                        <div className='check1'>
                                                            <input type="checkbox"></input>
                                                        </div>
                                                        <li >  Full Time</li>

                                                        <div className='check1'>
                                                            <input type="checkbox"></input>
                                                        </div>
                                                        <li>part Time</li>

                                                        <div className='check1'>
                                                            <input type="checkbox"></input>
                                                        </div>
                                                        <li>Remote</li>

                                                        <div className='check1'>
                                                            <input type="checkbox"></input>
                                                        </div>
                                                        <li>Free lance</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <br></br>
                                            <br></br>
                                            {/* <input type="text">All category</input> */}
                                            <div class="small-section-tittle2">
                                                <h4>Job Location</h4>
                                            </div>
                                            <select className='alltag'>
                                                <option><input type="checkbox"></input>Any where</option>
                                            </select>
                                            <div className='exp'>
                                                <p>Experience</p>
                                            </div>
                                            <div className='orderlist'>
                                                <ul className='a'>
                                                    <div className='check1'>
                                                        <input type="checkbox" />
                                                    </div>
                                                    <li >1-2 years</li>
                                                    <div className='check1'>
                                                        <input type="checkbox" />
                                                    </div>

                                                    <li>2-3 years</li>
                                                    <div className='check1'>
                                                        <input type="checkbox" />
                                                    </div>


                                                </ul>
                                            </div>

                                            <div class="select-categories pb-50">
                                                <div class="small-section-tittle">
                                                    <h4>Posted within</h4>
                                                </div>
                                                <div className='orderlist'>
                                                    <ul className='a'>
                                                        <div className='check1'>
                                                            <input type="checkbox"></input>
                                                        </div>
                                                        <li >Any</li>
                                                        <div className='check1'>
                                                            <input type="checkbox"></input>
                                                        </div>

                                                        <li>Today</li>
                                                        <div className='check1'>
                                                            <input type="checkbox"></input>
                                                        </div>

                                                        <li>Last 2 days</li>
                                                        <div className='check1'>
                                                            <input type="checkbox"></input>
                                                        </div>

                                                        <li>Last 3 days</li>


                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <br></br>
                        <br></br> */}
                                            <div class="small=-section-tittle2">
                                                <h4>Filter Jobs</h4>
                                            </div>

                                            <div class="price_text">
                                                <p>price:0 to 500</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='col-xl-9 col-lg-9 col-md-8'>
                                    <div class="col-lg-12">
                                        <div class="count-job mb-35">
                                            <h4>{viewjobs.length} Jobs found</h4>
                                            <div class="select-job-items">
                                                <span>Sort by</span>

                                                <select name="select" >
                                                    <option value="">None</option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                    <section class="featured-job-area">
                                        <div class="container">
                                            <div class="row">
                                                {viewjobs && viewjobs.map((i, index) => (
                                                    <div class="single-job-items mb-30">
                                                        <div class="job-items">
                                                            <div class="company-img">
                                                                <span class="services-ion"><i class="bi bi-flower1 jobicon"></i></span>
                                                            </div>
                                                            <div class="job-tittle job-tittle2">
                                                                <a>
                                                                    <h4>{i.Jobtitle}</h4>

                                                                </a>
                                                                <ul>
                                                                    <li>Company Name:{i.Companyname}</li>
                                                                    <li>Number Of Vacancy:{i.Vacancy}</li>
                                                                    <li><i class="fas fa-map-marker-alt"></i>Location:{i.Location}</li>
                                                                </ul>

                                                            </div>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <div class="items-link items-link2 f-right">
                                                                <Link to="/Jobdescription" state={{ ID: i.Mstjoblistid }}>

                                                                    View
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
