import React, { useState, useEffect } from "react";

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const images = [
    "https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    "https://images.unsplash.com/photo-1523240795612-9a054b0db644?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    "https://images.unsplash.com/photo-1448932223592-d1fc686e76ea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDZ8fGpvYiUyMHNlYXJjaGluZ3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((activeIndex + 1) % images.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  const activeImageStyle = {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 2,
    opacity: 1,
    transition: "opacity 0.5s ease-in-out",
  };

  const inactiveImageStyle = {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%) scale(0.8)",
    zIndex: 1,
    opacity: 0.5,
    transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
  };

  return (
    <div className="carousel" style={{ position: "relative" }}>
      {images.map((image, index) => (
        <img
          key={index}
          style={index === activeIndex ? activeImageStyle : inactiveImageStyle}
          src={image}
          alt={`image ${index}`}
        />
      ))}
    </div>
  );
};

export default Carousel;
