import React, { useState, useEffect } from 'react'
import { Images } from '../../Images/Index';
import { Link } from 'react-router-dom';

function Header() {
    const [state, setState] = useState({
        menu: false,
        isOpen: false,
        homeLinkClass: "nav-item nav-link",
        aboutLinkClass: "nav-item nav-link",
        menuClass: ""
    });

    const toggleMenu = () => {
        setState({
            ...state,
            menu: !state.menu
        });
    };

    const toggleOpen = () => setState({ ...state, isOpen: !state.isOpen });

    const show = state.menu ? "show" : "";
    const menuClass = `dropdown-menu${state.isOpen ? " show" : ""}`;

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link className="navbar-brand" to="/">
                <img src={Images.mainlogo} alt="Logo" width="200px" height="100px" />
            </Link>
            <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                <span className="navbar-toggler-icon" />
            </button>

            <div className={"collapse navbar-collapse " + show}>
                <div className="navbar-nav marlefauto">
                    {/* <Link
                            className="nav-item nav-link btn btn-primary"
                            style={{color:"white"}}
                            to="/"
                        >
                            Home
                        </Link> */}

                    <Link
                        style={{ color: "white" }}
                        to="/"
                    >
                        <button className='btn btn-primary'>Home</button>
                    </Link>

                    {/* <Link
                            className={state.aboutLinkClass}
                            to="/about"
                            onClick={() =>
                                state.aboutLinkClass === "nav-item nav-link"
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                            }
                        >
                            About
                        </Link> */}
                </div>

                <div className="dropdown" onClick={toggleOpen}>
                    <div
                        className="dropdown-toggle btn btn-primary"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                    >
                        Services
                    </div>
                    <div className={menuClass} aria-labelledby="dropdownMenuButton">
                        <Link className="dropdown-item" state={{ ID: 0, Flag: 'I' }} to="/Signup">
                            Jobseeker Registration
                        </Link>
                        <Link className="dropdown-item" state={{ ID: 0, Flag: 'I' }} to="/Signup">
                            Employer Registration
                        </Link>
                    </div>
                </div>
                <div>
                    <Link

                        style={{ color: "white" }}
                        to="/"
                    >
                        <button className='btn btn-primary'>Login</button>
                    </Link>
                </div>
            </div>

        </nav>
    );
}

export default Header;
