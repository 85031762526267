import React from 'react'
import Header from './header'
import Footer from '../Footer'
import Footer1 from './footer'

function Frontpage() {
    return (
        <div>
            <Header />
            <Footer1 />
            <div className='mt-6'>
                <Footer />
            </div>
        </div>
    )
}

export default Frontpage
