import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Api from './Services/ApiCall';
import SigninBase from './SigninBase';
import Swal from 'sweetalert2';

export default function Jobsdescription() {
    const navigate = useNavigate();
    const location = useLocation();
    const { ID, WalletAmount, intapplyamount } = location.state;
    const [success, setSuccess] = useState(false);
    const [walletAmount, setWalletAmount] = useState("");
    const [viewjobs, setViewjobs] = useState([]);
    const [agree, setAgree] = useState(false);
    const [jobId, setJobId] = useState("");
    
    const hasFetched = useRef(false); // To track if API calls have been made

    useEffect(() => {
        const fetchData = async () => {
            if (!hasFetched.current) {
                hasFetched.current = true; // Mark as fetched
                
                try {
                    if (ID) {
                        await GetViewjobs(ID);
                        if (WalletAmount > intapplyamount) {
                            await IUDView('View');
                        }
                    }
                    await BindGrid();
                } catch (error) {
                    console.error("Error fetching data", error);
                }
            }
        };

        fetchData();
    }, [ID, WalletAmount, intapplyamount]);

    async function BindGrid() {
        try {
            const userId = sessionStorage.getItem("MUserID");
            const params = { Mstjobseekerid: userId, AccessType: 'Jobseeker' };
            const url = "/JobSeekerTable/BindJobSeekerTable";
            const response = await Api.BindGrid(params, url);
            const rechargeAmount = response[0]?.Recharge_amount || "0";
            setWalletAmount(rechargeAmount);
            setSuccess(parseInt(rechargeAmount) > 200);
        } catch (error) {
            console.error("Error fetching job seeker data", error);
        }
    }

    async function GetViewjobs(id) {
        try {
            const paycheck = JSON.parse(sessionStorage.getItem('userdetails'))[0]?.Subscribe || '';
            const params = { Flag: paycheck, mstjoblistid: id };
            const url = "/Postjobs/ViewJobs";
            const response = await Api.BindGrid(params, url);
            setViewjobs(response);
        } catch (error) {
            console.error("Error fetching job details", error);
        }
    }

    async function IUDView(status) {
        try {
            const params = {
                mstappliedjobsid: 0,
                appliedjobscode: "",
                jobid: ID.toString(),
                jobseekerid: sessionStorage.getItem('MUserID').toString(),
                currentstatus: status,
                currentstatusdate: new Date().toISOString(),
                active: " ",
                searchField: " ",
                createdBy: 0,
                createdDate: new Date().toISOString(),
                flag: "I"
            };

            const url = "/Appliedjobs/IUDAppliedjobs";
            const response = await Api.IUD(params, url);
            setJobId(response); 
        } catch (error) {
            console.error("Error updating job view status", error);
        }
    }

    const showAlert = (response) => {
        Swal.fire({
            title: "Success",
            text: response,
            icon: response === "Insert successfully" || response === "Applied successfully" || response==="Data Saved Successfully" ? "success" : "error",
            confirmButtonText: "OK",
        });
    };
    async function IUDOperation(status) {
        if (!agree) {
            toast.error('Please agree to the terms and conditions');
            return;
        }

        try {
            const params = {
                mstappliedjobsid: parseInt(jobId) || 0,
                appliedjobscode: " ",
                jobid: ID.toString(),
                jobseekerid: sessionStorage.getItem('MUserID').toString(),
                currentstatus: 'Apply',
                currentstatusdate: new Date().toISOString(),
                active: " ",
                searchField: " ",
                createdBy: 0,
                createdDate: new Date().toISOString(),
                flag: "I"
            };

            const url = "/Appliedjobs/IUDAppliedjobs";
            const response = await Api.IUD(params, url);
            showAlert(response);
            setTimeout(() => {
                if (status === "Apply") {
                    navigate('/Jobs');
                }
            }, 2000);
        } catch (error) {
            console.error("Error applying for job", error);
        }
    }

    return (
        <>
            <Toaster />
            <div className="backgroundimg">
                <SigninBase />
                <div>
                    {viewjobs.map((job, index) => (
                        <div key={index} className="job-tittle jobdescimg jobdesc" style={{ marginTop: "81px" }}>
                            <h3>Job Details</h3>
                            <table>
                                {!success && (
                                    <div className="mt-2 mb-3">
                                        <h2 style={{ color: '#0633fe' }}>
                                            Your Wallet Amount is Insufficient. Please recharge to view the company details.
                                        </h2>
                                    </div>
                                )}
                                <div className="jobdescleft">
                                    <p>Company Name: {success ? job.Companyname : ''}</p>
                                    <p>Education: {job.Education}</p>
                                    <p>Employment Type: {job.Employmenttype}</p>
                                    <p>Experience Details: {job.Experiencedetails}</p>
                                    <p>Functional Area: {job.Functionalarea}</p>
                                    <p>Industry Type: {job.Industrytype}</p>
                                    <p>Job Description: {job.Jobdescription}</p>
                                    <p>Location: {job.Location}</p>
                                    <p>Role: {job.Role}</p>
                                    <p>Role Category: {job.Rolecategory}</p>
                                    <p>Skills: {job.Skils}</p>
                                    <p>Vacancy: {job.Vacancy}</p>
                                    <p>Salary: {job.SearchField}</p>
                                </div>
                            </table>
                        </div>
                    ))}
                    <div>
                        <input
                            type="checkbox"
                            className="jobdescleft"
                            checked={agree}
                            onChange={() => setAgree(!agree)}
                        />
                        I agree to accept the company terms and conditions (Deduct 10 percent amount from my first month's salary) and pay to Elakiyaa Solution.
                    </div>
                    <div className="text-center mb-5">
                        <button type="button" className="btn btn-primary" onClick={() => IUDOperation('Apply')}>Apply</button>
                        <button type="reset" className="btn btn-info">
                            <Link to='/Jobs' state={{ ID: "0", Flag: "Back" }}>Back</Link>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
