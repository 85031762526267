import React,{ useState} from 'react'
import Base from '../../Base'
import Api from '../../Services/ApiCall'
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import CSS from '../../../App.css';
import css from '../../../App.css';
import Swal from 'sweetalert2';

export default function Customersupportform() {
   
    const location = useLocation();
    const { ID, Flag } = location.state;
    const navigate=useNavigate();
  
    const[name1,setName]=useState("");
    const[email,setEmail]=useState("");
    const[subject,setSubject]=useState("");
    const[message,setMessage]=useState("");
  
    async function IUDOperation()
    {  
      var Name=name1;
      var Email=email;
      var Subject=subject;
      var Message=message;
      
      if(Name==""){
        toast.error("Please Enter Name")
        return false;
      }
      if(Email==""){
        toast.error("Please Enter Email")
        return false;
      }
      if(Subject==""){
        toast.error("Please Enter Subject")
        return false;
      }
      if(Message==""){
        toast.error("Please Enter Message")
        return false;
      }
  
      var Param={
        "mstcustomersupportid": ID,
        "mycustomersupportcode": "string",
        "name": Name,
        "email": Email,
        "subject": Subject,
        "message": Message,
        "active": "string",
        "searchField": "string",
        "flag": Flag
      };
      var url="/CustomerSupport/IUDCustomerSupport";
      let _response= await Api.IUD(Param, url);
      showAlert(_response);
      setTimeout(()=>{
       navigate('/Customersupporttable')
      },1000)
  
     }
     const showAlert = (_response) => {
      Swal.fire({
          title: "Success",
          text: _response,
          icon: "success",
          confirmButtonText: "OK",
      });
  };

  

    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <Base />
            <div id="main" class="main">
                <div class="pagetitle">

                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">Customer Support</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Customer Support</h5>
                        {/* <section class="section contact"> */}
            {/* <div class="row gy-4">
              <div class="col-xl-6">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="info-box card">
                      <i class="bi bi-geo-alt"></i>
                      <h3>Address</h3>
                      <p>Ambasamudram<div></div>Tirunelveli District</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="info-box card">
                      <i class="bi bi-telephone">
                      </i><h3>Call Us</h3>
                      <p>9978667690<div></div>8825444546</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="info-box card">
                      <i class="bi bi-envelope"></i>
                      <h3>Email Us</h3>
                      <p>info@jobforall.com<div></div>contact@jobforall</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="info-box card">
                      <i class="bi bi-clock"></i>
                      <h3>Open Hours</h3>
                      <p>Monday - Saturday<div></div>9:00AM - 07:00PM</p>
                    </div>
                  </div>
                </div>
              </div> */}
              <div class="col-xl-12">
               
                 <div class="row gy-4">
                      
                 <div class="col-md-6">
                      <label for="name">Name</label>
                      <input type="text" name="name" class="form-control" placeholder="Your Name" value={name1} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div class="col-md-6 ">
                    <label for="email">Email</label>
                        <input type="text" class="form-control" name="email" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)}  />
                      </div>
                      <div class="col-md-12">
                      <label for="subject">Subject</label>
                        <input type="text" class="form-control" name="subject" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                      </div>
                      <div class="col-md-12">
                      <label for="message">Message</label>
                        <textarea class="form-control" name="message" rows="6" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} >
                        </textarea>
                      </div>
                      <div class="col-md-12 text-center">
                       <button type="button" class="btn btn-primary" onClick={IUDOperation}>Send Message</button>
                      </div>
					  
                    </div>
                  
                </div>
              </div>
            </div>
        
                        </div>
                      
                    </div>
            
           
    )
}
