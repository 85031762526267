import React from 'react'
import Base from '../../Base';
import { Link } from "react-router-dom";
export default function Agentbrokerperformanceform() {
  return (
    <div>
         <Base Agentbrokerperformance={true} />
          <div id="main" class="main">
              <div class="pagetitle">
                
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          <li class="breadcrumb-item">Report</li>
                          <li class="breadcrumb-item active">Agent Broker Performance</li>
                      </ol>
                  </nav>
              </div>
             
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Agent Broker Performance</h5>

              
                      <div class="row g-3">
                      <div class="col-md-6">
                              <select id="inputState" class="form-select">
                                  <option selected>Select Detail</option>
                                  <option>Agent</option>
                                  <option>Job Seeker</option>
                                  <option>Employer</option>
                              </select>
                          </div>
                          <div class="col-md-6">
                              <input type="text" class="form-control" placeholder="Register"/>
                          </div>
                          <div class="col-md-6">
                              <input type="text" class="form-control" placeholder="Approved"/>
                          </div>
                          <div class="col-md-6">
                              <input type="text" class="form-control" placeholder="Processing"/>
                          </div>
                          <div class="col-md-6">
                              <input type="text" class="form-control" placeholder="Reject"/>
                          </div>
                          <div class="col-6">
                              <input type="text" class="form-control" placeholder="Commision Eligible Agent"/>
                          </div>
                          <div class="col-md-6">
                              <input type="text" class="form-control" placeholder="Remarks"/>
                          </div>
                        
                       
                          <div class="text-center">
                              <button type="button" class="btn btn-primary">Submit</button>
                              <button type="reset" class="btn btn-info">
                              <Link to='/agentbrokerperformancetable'>
                                Back
                                </Link>
                                </button>
                          </div>
                      </div>

                  </div>
              </div>

           
          </div>

    </div>
  )
}
