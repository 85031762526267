import React, { useState, useEffect } from 'react'
import Base from './Base';
import { Link } from "react-router-dom";
import Api from './Services/ApiCall';
import * as XLSX from 'xlsx';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from './Admin/Admin_form/Filter/Globalfilter';
import '../Css/Pagination.css'

function Table({ columns, data }) {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        useGlobalFilter, useSortBy, usePagination
    );
    const { globalFilter } = state

    return (
        <div>
            <Globalfilter className="form-control" filter={globalFilter} setFilter={setGlobalFilter} />
            <table class="table table-striped" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}  >
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="fa-fa-sort-asc"></i>
                                                : <i className="fa-fa-sort-desc"></i>
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td  {...cell.getCellProps()} className={` ${cell.column.className ?? ""}`}>
                                            {cell.render("Cell")}</td>

                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <br></br>
            <div className="pagination">
                <nav class="paginate page_numbers" aria-label="Page navigation">

                </nav>
                <ul class="pagination first_last">

                    <li class="page-item"><a class="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {"First"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {"<"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                        {">"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {"Last"}</a>
                    </li>{" "}</ul>

                <span className="pageno">
                    Page{" "}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                </span>

                <div className='showpagesize' >
                    Show:{" "}
                    <select
                        className='showpagecss'
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[2, 10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select></div>
            </div>
        </div>
    );
}

export default function OnlinePaymentReport() {

    const [fromdate, setFromdate] = useState("");

    const [todate, setTodate] = useState("");

    const [listdata, setlistdata] = useState([]);
    const ws = XLSX.utils.json_to_sheet(listdata);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelData = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const [columns, setcolumns] = useState([

        {
            Header: "ID", Cell: (row) => {
                return <div>{Number(row.row.id) + 1}</div>;
            },
        },
        {
            Header: "Customer Code", accessor: "Text",
        },
        {
            Header: "Bill Number", accessor: "Text2",
        },
        {
            Header: "Amount", accessor: "Text1",
        },
        {
            Header: "Payment Date", accessor: "Text3",
        },


    ])

    useEffect(() => {
        bind()
    }, []);

    // if(todate.length>0)
    // {
    //     bindGrid()
    // }
    const haldlechange = (value) => {
        setTodate(value)
        bindGrid(value)
    }

    async function bind() {
        var url = '/DropDown/OnlinePaymentReport';
        let _response = await Api.BindDropdown(url)
        setlistdata(_response)
    }
    async function bindGrid(value) {
        var Fromdate = fromdate;
        var Todate = value;
        var url = `/DropDown/CashPaymentReport?fromdate=${Fromdate}&todate=${Todate}`;
        let _response = await Api.BindDropdown(url)
        setlistdata(_response)
    }

    const downloadExcel = async () => {
        // Make an API call to fetch the data for Excel export
       
        // const Fromdate = fromdate;
        // const Todate = todate;
        // const url1 = `/DropDown/CashPaymentReport?fromdate=${Fromdate}&todate=${Todate}`;
        // const response = await Api.BindDropdown(url1);
    
        // Convert the response data to Excel format using xlsx
        const excelData = convertDataToExcelFormat(listdata);
    
        // Create a Blob with the Excel data
        const blob = new Blob([excelData], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
    
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
    
        // Create an anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'online_payment_report.xlsx';
    
        // Trigger the click event on the anchor to initiate the download
        a.click();
    
        // Release the URL
        window.URL.revokeObjectURL(url);
      };
    
      // Function to convert data to Excel format using xlsx library
      const convertDataToExcelFormat = (data) => {
        // Create a worksheet from your data
        const ws = XLSX.utils.json_to_sheet(data);
      
        // Modify the header row (first row) to change the column headings
        ws['A1'] = { t: 's', v: 'Id' }; // Change 'A1' for the first column
        ws['B1'] = { t: 's', v: 'User code' }; // Change 'A1' for the first column
        ws['C1'] = { t: 's', v: 'Amount' };    // Change 'B1' for the second column
        ws['D1'] = { t: 's', v: 'Code' };      // Change 'C1' for the third column
        ws['E1'] = { t: 's', v: 'Date' };      // Change 'D1' for the fourth column
        ws['F1'] = { t: 's', v: '' };      // Change 'D1' for the fourth column
        ws['G1'] = { t: 's', v: '' };      // Change 'D1' for the fourth column
      
        // Create a workbook with the modified worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      
        // Generate an Excel file in binary form
        const excelData = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      
        // Convert the binary data to a Blob
        const blob = new Blob([excelData], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
      
        return blob;
      };
      


    return (
        <>
            <div>
                <Base OnlinePaymentReport={true} />
                <div id="main" class="main">
                    <div class="pagetitle">

                        <nav>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <Link to="/dash">Home</Link>
                                </li>

                                <li class="breadcrumb-item">Report</li>
                                <li class="breadcrumb-item active">Online Payment Report</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-lg-12">

                        <div class="card">
                            <div class="card-body alignleft">
                                <h5 class="card-title"> Online Payment Report</h5>


                                <div class="marlef59">
                                    <span>From:</span>
                                    <input type="date" value={fromdate} onChange={(e) => setFromdate(e.target.value)} />
                                    <span class="marlef12">To:</span>
                                    <input type="date" value={todate} onChange={(e) => haldlechange(e.target.value)} />
                                    <button onClick={downloadExcel} className="mt-3 btn btn-primary">
                                        Download Excel
                                    </button>
                                </div>

                                <Table columns={columns} data={listdata} />
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}
