import React, { useState, useEffect } from 'react'
import Base from '../Base';
import Api from '../Services/ApiCall';
import { Link, } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { Button, Modal } from 'react-bootstrap';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "./Admin_form/Filter/Globalfilter";
import '../../Css/Pagination.css'

function Table({ columns, data }) {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        useGlobalFilter, useSortBy, usePagination
    );
    const { globalFilter } = state

    return (
        <div>
            <Globalfilter className="form-control" filter={globalFilter} setFilter={setGlobalFilter} />
            <table class="table table-striped" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}  >
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="fa-fa-sort-asc"></i>
                                                : <i className="fa-fa-sort-desc"></i>
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td  {...cell.getCellProps()} className={` ${cell.column.className ?? ""}`}>
                                            {cell.render("Cell")}</td>

                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <br></br>
            <div className="pagination">
                <nav class="paginate page_numbers" aria-label="Page navigation">

                    {/* <ul class="pagination page_numbers">
            <li class="page-item">
                <a  class="page-link" aria-label="Previous">
                    <span aria-hidden="true">«</span>
                </a>
            </li>
            <li class="page-item"><a class="page-link" >1</a></li>
            <li class="page-item"><a class="page-link" >2</a></li> 
            <li class="page-item"><a class="page-link" >3</a></li>
            <li class="page-item"><a class="page-link" >4</a></li>
            <li class="page-item"><a class="page-link" >5</a></li>
            <li class="page-item">
                <a  class="page-link" aria-label="Next">
                    <span aria-hidden="true">»</span>
                </a>
            </li>
        </ul> */}

                </nav>
                <ul class="pagination first_last">

                    <li class="page-item"><a class="page-link"  onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {"First"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link"  onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {"<"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link"  onClick={() => nextPage()} disabled={!canNextPage}>
                        {">"}</a>
                    </li>{" "}
                    <li class="page-item"><a class="page-link"  onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {"Last"}</a>
                    </li>{" "}</ul>

                <span className="pageno">
                    Page{" "}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                </span>


                {/* <div  className="goto_page">   
                <span className="pageno_box">
                    Go to page:{" "}
                    <input className='gotopage_number'
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                        style={{ width: "100px" }}
                    />
                </span>{" "}</div> */}

                <div className='showpagesize' >
                    Show:{" "}
                    <select
                        className='showpagecss'
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[2, 10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select></div>
            </div>
        </div>
    );
}
export default function EmployeePerformanceReport() {

    const [listdata, setlistdata] = useState([]);
    const [columns, setcolumns] = useState([

        {
            Header: "ID", Cell: (row) => {
                return <div>{Number(row.row.id) + 1}</div>;
            },
        },
        {
            Header: "Employee Id", accessor: "Custname",
        },
        {
            Header: "Date", accessor: "Date",
        },
        {
            Header: "Performance", accessor: "Meetingwithownerorstaff",
        },
        {
            Header: "Actions", Cell: (row) => {

                return <div>{<nav>

                    <ol class="breadcrumb">
                    {Api.CheckUpdate(17) == 1 ?
                                         <li class="breadcrumb-item"> 
                                         <Link to={'/Employeeperformancereportform'}
                                          state={{ ID:  row.data[row.row.id].Mstemployeeperformancetableid, Flag: 'U' }}>
                                                 <i class="bi bi-pencil-square" /> 
                                                 </Link> </li>
                                                 :null}
                                                  {Api.CheckDelete(17) == 1 ?
                                         <li class="breadcrumb-item active">
                                              <Link to={'/Employeeperformancereportform'} 
                                              state={{ ID: row.data[row.row.id].Mstemployeeperformancetableid, Flag: 'D' }}>
                                                 <i class="bi bi-trash" />
                                                 </Link></li>
                                                 :null}
                                     </ol>
                </nav>}</div>;
              },



        },
        // {
        //     Header: "Pay", Cell: (row) => {

        //         return <div>{<nav>

        //                             <ol class="breadcrumb">

        //                         <li class="breadcrumb-item active"
        //             onClick={()=>openPopup(row.data[row.row.id].Mstexpensesid)} >

        //               <i class="bi bi-check" />
        //             </li>

        //             </ol>
        //         </nav>}</div>;
        //     }

        // },

    ])

    useEffect(() => {
        bindGrid(0)
    }, []);
   

    async function bindGrid(id) {
        var Param = { mstemployeeperformancetableid: id ,
        SearchField:sessionStorage.getItem('AccessType')}
        var url = "/EmployeePerformanceTable/BindEmployeePerformance";
            let _response1 = await Api.BindGrid(Param, url)
            setlistdata(_response1)
    }

    return (
        <div>
            <div>

                <Base />
                <div id="main" class="main">
                    <div class="pagetitle">

                        <nav>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <Link to="/dash">Home</Link>
                                </li>

                                <li class="breadcrumb-item">Employee Performance</li>
                                {/* <li class="breadcrumb-item active">Employee Performance</li> */}
                            </ol>
                        </nav>
                    </div>
                    <div class="col-lg-12">

                        <div class="card">
                            <div class="card-body alignleft">
                                <h5 class="card-title">Employee Performance</h5>
                                {/* {Api.CheckAdd(17) == 1 ? */}
                                    <button type="button" class="btn btn-primary Add">
                                        <Link to={'/Employeeperformancereportform'}
                                            state={{ ID: 0, Flag: 'I' }}>
                                            Add
                                        </Link>
                                    </button>
                                    {/* : null} */}

                                <Table columns={columns} data={listdata} />



                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}